import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  requestNews,
  requestFactors,
  requestFactors2,
  requestDocumentLanguages,
} from "./actions/news.actions";
import { environment } from "../environments/environment";
import { Observable } from "rxjs";
import { State } from "./reducers";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "NCAI";
  wp = environment.wp ? true : false;

  loggedIn$: Observable<boolean> = this.store.select(
    (state) => state.authentication.loggedIn
  );
  first = true;

  constructor(private store: Store<State>) {}
  ngOnInit() {
    console.log("ENV:", environment);

    this.loggedIn$.subscribe((status) => {
      console.log("LOGGED IN:");
      if (status && this.first) {
        this.store.dispatch(requestFactors());
        this.store.dispatch(requestDocumentLanguages());
        this.first = false;
      }
    });
  }
}
