
        
            <mat-card id="login-form" style="pointer-events:all;">
                <form [formGroup]="form"  id="login-form" >
                <div style="margin-bottom:0.6em;">
                    <div *ngIf="username.invalid && (username.dirty || username.touched)" class="alert alert-danger">
                        <div *ngIf="username.errors.required" style="color:red;">
                            Name is required.
                        </div>
                    </div>

                    <div *ngIf="password.invalid && (password.dirty || password.touched)" class="alert alert-danger">
                        <div *ngIf="password.errors.required" style="color:red;">
                            Password is required.
                        </div>
                    </div>

                    <div *ngIf="wrongUsernamePassword" class="alert alert-danger">
                        <div style="color:red;">
                            Password or Username is wrong.
                        </div>
                    </div>
                </div>

                <mat-form-field color="white" class="login-form-fields">
                    <input matInput placeholder="Username" class="form-control" type="text" name="username" formControlName="username" required>
                </mat-form-field>
                <mat-form-field class="login-form-fields">
                    <input matInput placeholder="Password" class="form-control" type="password" name="password" formControlName="password" required>
                </mat-form-field>


                <button id="loginButton" mat-flat-button color="primary" class="login-form-fields" (click)="login()">Login</button>
            </form>
            </mat-card>
