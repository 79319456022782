<div id="wrapper">
    <div 
        gdAreas="content5 content5 | content6 content6 | map map | side side "
        gdRows="auto auto auto 20em"
        gdColumns="50% 50%"
        >
    
        <mat-card class="mat-elevation-z1,card" gdArea="content5" sytle="width:100%;height:100%">
            <div class="card-header">Tag Cloud</div>
            <angular-tag-cloud
                [data]="tagcloud_data"
                [width]="tagcloud_options.width"
                [height]="tagcloud_options.height"
                [overflow]="tagcloud_options.overflow">
            </angular-tag-cloud>
        </mat-card>
    
        <mat-card class="mat-elevation-z1,card" gdArea="content6">
            <div class="card-header">Overview</div>
            <div gdArea="left_section"
            gdAreas="col1 col2 graph"
            gdRows="auto"
            gdColumns="40% 40% 20%">
                    <div gdArea="col1" style="margin-top:1em;padding-right:3em;padding-left:1em;">
                        <p style="width:100%"><span style="font-weight: bold;">New News Today</span> <span style="float:right;">{{(generalStats$ | async).today}}</span></p>
                        <p style="width:100%"><span style="font-weight: bold;">New News Yesterday</span> <span style="float:right;">{{(generalStats$ | async).yesterday}}</span></p>
                        <p style="width:100%"><span style="font-weight: bold;">New News this Week</span> <span style="float:right;">{{(generalStats$ | async).week}}</span></p>
                        <p style="width:100%" *ngIf="(generalStats$ | async).perUserWeek.users"><span style="font-weight: bold;">Average no. of News per User/Week    </span> <span style="float:right;">{{((generalStats$ | async).perUserWeek.total / (generalStats$ | async).perUserWeek.users.length) | number : '1.2-2'}}</span></p>
                    </div>
                    <div gdArea="col2" style="margin-top:1em;padding-right:3em">
                        <p style="width:100%"><span style="font-weight: bold;">Total no. of News</span> <span style="float:right;">{{(generalStats$ | async).total.total}}</span></p>
                        <p style="width:100%"><span style="font-weight: bold;">Total no. of set Tags</span> <span style="float:right;">{{totalSetTags}}</span></p>
                        <p style="width:100%"><span style="font-weight: bold;">Average Tags per News</span> <span style="float:right;">{{(totalSetTags / (generalStats$ | async).total.total) | number : '1.2-2'}}</span></p>
                        <p style="width:100%" *ngIf="mostUsedTag"><span style="font-weight: bold;">Most used Tag</span> <span style="float:right;">{{mostUsedTag.item}}</span></p>
                    </div>
                    <div style="width:100%;height:100%" gdArea="graph">
                        <canvas #doughnut style="width:100%;height:100%"  baseChart [datasets]="doughnutChartData" [labels]="doughnutChartLabels" [options]="doughnutChartOptions" [plugins]="doughnutChartPlugins"  [colors]="doughnutChartColors" [legend]="doughnutChartLegend" [chartType]="doughnutChartType"></canvas>
                    </div>
                </div>
        </mat-card>

        <mat-card style="height:20em;margin-bottom:4em;" class="mat-elevation-z1,card" gdArea="map">
            <div class="card-header" fxLayout="row wrap">
                <span style="position: relative;top:0.8em;">Incidents</span>
                <span style="flex:1 0 auto"></span>
                <div style="display:inline-block;">
                <mat-slider
                    class="example-margin"
                    [max]="12"
                    [min]="3"
                    [step]="1"
                    [tickInterval]="1"
                    [(ngModel)]="geoSlider"
                    (change)="updateMap()"
                    >
                </mat-slider>
                Resolution {{(geoRadii[geoSlider-1])/1000}}km</div>
                
            </div>
                <mat-card-content  style="width:100%;height:100%" class="card-text">
            <div style="width:100%;height:100%" leaflet [leafletOptions]="options" [leafletLayers]="layers" (leafletMapReady)="onMapReady($event)"></div>
        </mat-card-content>
        </mat-card>
        <mat-card class="mat-elevation-z1,card" style="margin-top:2em;" gdArea="side">
            <div class="card-header">Fetched News Total
                <button color="primary" mat-raised-button aria-label="Refresh" (click)="updateMap()" >
                    <span>Refresh</span>
                    <mat-icon>refresh</mat-icon>
                </button>
                <mat-form-field appearance="outline" class="selects" style="float: right; margin-top: -15px; margin-right: -15px;">
                    <mat-label>Datum from/to</mat-label>
                    <mat-date-range-input [formGroup]="searchDateRange" [rangePicker]="picker3">
                        <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="updateMap()">
                        <input matEndDate formControlName="end" placeholder="End date" (dateChange)="updateMap()">
                        <mat-error *ngIf="searchDateRange.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                        <mat-error *ngIf="searchDateRange.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                    </mat-date-range-input>
    
                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker3></mat-date-range-picker>
                </mat-form-field>
            </div>
                <mat-card-content style="width:100%;height:85%" class="card-text">
            <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins">
            </canvas>
            </mat-card-content>
        </mat-card>
    

        
    </div>
    </div>