import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, ElementRef, EventEmitter, Inject, Injectable, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { combineLatest, Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { zoomIdentity, stratify } from "d3";
import { distinctUntilChanged, take } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';
import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

 export class TagNode {
  children?: TagNode[];
  item: string;
  id: string;
  data: {  
    id?: string;
    item?: string;
    uid?: number;
    color?: string;
    position?: number;
    active?: boolean;
    platform?: string;
    description?: string;
    deleted?: boolean;
  };
}


export class AbstractNode {
  item: string;
  data: {  
    id?: string;
    item?: string;
    uid?: number;
    color?: string;
    position?: number;
    active?: boolean;
    platform?: string;
    description?: string;
    deleted?: boolean;
  };
}


export class TagFlatNode {
  id: string;
  item: string;
  level: number;
  expandable: boolean;
  platform: string;
  active: boolean;
  uid: number;
  position: number;
  color: string;
  description: string;
  deleted: boolean;
}


/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class TagEditDatabase {
  dataChange = new BehaviorSubject<TagNode[]>([]);
  startId = -1;

  root;

  get data(): TagNode[] { return this.dataChange.value; }

  constructor() {
    this.dataChange.asObservable().subscribe((items) => {
      //console.log("TAGLIST CHANGED", items);
    });
  }

  initialize(rawTags: any[]) {
    this.startId = rawTags.length;

    let strat = stratify().parentId(function (d: any) {
      return d.id.substring(0, d.id.lastIndexOf("\t"));
    });

    // Temporarely use UID as ID for stratify if new tags are used...
    if (typeof rawTags[rawTags.length - 1].parent != 'undefined') { // First Tag has no parent.
      rawTags = rawTags.map(e => {return {...e, name: e.id, id: e.uid};}); 
      // Save original ID in name so we can set it back later on transform.
      strat = stratify().parentId(function (d: any) {
        return d.parent;
      });
    }

    const data: any = strat(rawTags).sort(function (a, b) {
      var aVal = 0;
      var bVal = 0;

      if (a.data && b.data && typeof a.data.position != 'undefined' && typeof b.data.position != 'undefined') {
        aVal = a.data.position;
        bVal = b.data.position;
      }

      return aVal - bVal || a.height - b.height || a.id.localeCompare(b.id);
    });

    let that = this;

    (function transform(parent) {
      if (parent.data && parent.data.name) {
        parent.id = parent.data.name; // Undo themporary ID change...
        parent.data = {...parent.data, id: parent.id};
      }
      const splitted = parent.id.split("\t");
      parent.item = splitted.pop();
      if (parent.data.uid && parent.data.uid >= that.startId) {
        that.startId = parent.data.uid + 1;
      }
      if (
        !parent.children ||
        (parent.children && parent.children.length == 0)
      )
        return;
      // parent.children = parent.children.filter((node)=>node.data.permission=="user")
      for (const node of parent.children) {
        transform(node);
      }
    })(data);
    this.root = [data];
    this.dataChange.next(data.children);
  }


  updateChildPositions(node: TagNode) {
    const parentNode = node;
    if (!(parentNode && parentNode.children)) return;
    let size = parentNode.children.length;
    for (let i = 0; i < size; i++) {
      parentNode.children[i].data = {...parentNode.children[i].data} as any;
      parentNode.children[i].data.position = i;
    }
  }


  insertItem(parent: TagNode, node: AbstractNode): TagNode {
    if (!parent.children) {
      parent.children = [];
    }
    const newItem = { ...node, data: {...node.data, uid: node.data.uid || this.startId++, position: parent.children.length}, id: parent.id + "\t" + node.item } as TagNode;
    parent.children.push(newItem);
    this.updateChildPositions(parent);
    this.dataChange.next(this.data);
    return newItem;
  }

  insertItemAbove(parent: TagNode, node: AbstractNode): TagNode {
    const parentNode = this.getParentFromNodes(parent);
    let pos = 0;
    if (typeof parent.data.position != 'undefined') {
      pos = parent.data.position - 1;
    } 
    if (parentNode != null) {
      const newItem = { ...node, data: {...node.data, uid: node.data.uid || this.startId++, position: pos}, id: parentNode.id + "\t" + node.item } as TagNode;
      parentNode.children.splice(parentNode.children.indexOf(parent), 0, newItem);
      this.updateChildPositions(parentNode);
      this.dataChange.next(this.data);
      return newItem;
    } else {
      alert("Somthing went wrong... Very wrong... Basically... RUN");
      const newItem = { ...node, data: {...node.data, uid: node.data.uid || this.startId++, position: pos}, id: node.item } as TagNode;
      this.data.splice(this.data.indexOf(parent), 0, newItem);
      this.dataChange.next(this.data);
      return newItem;
    }
  }

  insertItemBelow(parent: TagNode, node: AbstractNode): TagNode {
    const parentNode = this.getParentFromNodes(parent);
    let pos = 0;
    if (typeof parent.data.position != 'undefined') {
      pos = parent.data.position + 1;
    }
    if (parentNode != null) {
      const newItem = { ...node, data: {...node.data, uid: node.data.uid || this.startId++, position: pos}, id: parentNode.id + "\t" + node.item } as TagNode;
      parentNode.children.splice(parentNode.children.indexOf(parent) + 1, 0, newItem);
      this.updateChildPositions(parentNode);
      this.dataChange.next(this.data);
      return newItem;
    } else {
      alert("Somthing went wrong... Very wrong... Basically... RUN");
      const newItem = { ...node, data: {...node.data, uid: node.data.uid || this.startId++, position: pos}, id: node.item } as TagNode;
      this.data.splice(this.data.indexOf(parent) + 1, 0, newItem);
      this.dataChange.next(this.data);
      return newItem;
    }

  }

  getParentFromNodes(node: TagNode): TagNode {
    for (let i = 0; i < this.root.length; ++i) {
      const currentRoot = this.root[i];
      const parent = this.getParent(currentRoot, node);
      if (parent != null) {
        return parent;
      }
    }
    return null;
  }

  getParent(currentRoot: TagNode, node: TagNode): TagNode {
    if (currentRoot.children && currentRoot.children.length > 0) {
      for (let i = 0; i < currentRoot.children.length; ++i) {
        const child = currentRoot.children[i];
        if (child === node) {
          return currentRoot;
        } else if (child.children && child.children.length > 0) {
          const parent = this.getParent(child, node);
          if (parent != null) {
            return parent;
          }
        }
      }
    }
    return null;
  }

  updateItem(node: TagNode, info: AbstractNode) {
    node.item = info.item;
    node.id = node.id.substring(0, node.id.lastIndexOf("\t")) + "\t" + info.item;
    node.data = info.data;
    this.dataChange.next(this.data);
  }

  deleteItem(node: TagNode) {
    this.deleteNode(this.data, node);
    this.dataChange.next(this.data);
  }

  copyPasteItem(from: TagNode, to: TagNode): TagNode {
    let copy =  {...from};
    try {
      copy.children = undefined;
      delete copy.id;
      delete copy.data.position;
    } catch(e) {} 
    
    const newItem = this.insertItem(to, copy as any);
    if (from.children) {
      from.children.forEach(child => {
        this.copyPasteItem(child, newItem);
      });
    }
    return newItem;
  }

  copyPasteItemAbove(from: TagNode, to: TagNode): TagNode {
    let copy =  {...from};
    try {
      copy.children = undefined;
      delete copy.id;
      delete copy.data.position;
    } catch(e) {} 
    const newItem = this.insertItemAbove(to, copy as any);
    if (from.children) {
      from.children.forEach(child => {
        this.copyPasteItem(child, newItem);
      });
    }
    return newItem;
  }

  copyPasteItemBelow(from: TagNode, to: TagNode): TagNode {
    let copy =  {...from};
    try {
      copy.children = undefined;
      delete copy.id;
      delete copy.data.position;
    } catch(e) {} 
    const newItem = this.insertItemBelow(to, copy as any);
    if (from.children) {
      from.children.forEach(child => {
        this.copyPasteItem(child, newItem);
      });
    }
    return newItem;
  }

  deleteNode(nodes: TagNode[], nodeToDelete: TagNode) {
    const index = nodes.indexOf(nodeToDelete, 0);
    if (index > -1) {
      nodes.splice(index, 1);
    } else {
      nodes.forEach(node => {
        if (node.children && node.children.length > 0) {
          this.deleteNode(node.children, nodeToDelete);
        }
      });
    }
  }

  currentlyDisplayedTagsToJson() {
    console.log(this.root);
    const flattedTree: TagFlatNode[] = [];
    (function transform(parent): any {
      flattedTree.push({...parent.data, name: parent.id, id: parent.id, item: parent.item, source: "CDRC", type: parent.data.platform});
      if (
        !parent.children ||
        (parent.children && parent.children.length == 0)
      ) {
        return;
      }
      for (const node of parent.children) {
        node.data.parent = parent.data.uid;
        if (parent.data.id) {
          node.data.id = parent.id + "\t" + node.item;
          node.id = node.data.id;
        }
        
        transform(node);
      }
    })(this.root[0]);
    //console.log("new", flattedTree);

    return flattedTree;
  }

}


@Component({
  selector: 'app-tag-edit-tree',
  templateUrl: './tag-edit-tree.component.html',
  styleUrls: ['./tag-edit-tree.component.css'],
  providers: [TagEditDatabase],
})
export class TagEditTreeComponent implements OnInit {

  @Input() TREE_DATA;
  @Input() showDeletedTags = false;
  @Output() onSave = new EventEmitter<TagFlatNode[]>();


  flatNodeMap = new Map<TagFlatNode, TagNode>();
  nestedNodeMap = new Map<TagNode, TagFlatNode>();
  selectedParent: TagFlatNode | null = null;
  treeControl: FlatTreeControl<TagFlatNode>;
  treeFlattener: MatTreeFlattener<TagNode, TagFlatNode>;
  dataSource: MatTreeFlatDataSource<TagNode, TagFlatNode>;


  /* Drag and drop */
  dragNode: any;
  dragNodeExpandOverWaitTimeMs = 750;
  dragNodeExpandOverNode: any;
  dragNodeExpandOverTime: number;
  dragNodeExpandOverArea: string;
  @ViewChild('emptyItem') emptyItem: ElementRef;
  

  constructor(private database: TagEditDatabase, private dialog: MatDialog) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TagFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    database.dataChange.subscribe(data => {
      this.dataSource.data = [];
      this.dataSource.data = data;
      if(data.length > 0) {
        this.transformer(this.database.root[0], 0);
      }
    });
   }

   getLevel = (node: TagFlatNode) => node.level;

   isExpandable = (node: TagFlatNode) => node.expandable;
 
   getChildren = (node: TagNode): TagNode[] => node.children;
 
   hasChild = (_: number, _nodeData: TagFlatNode) => _nodeData.expandable;
 
   hasNoContent = (_: number, _nodeData: TagFlatNode) => _nodeData.item === '';
 
   /**
    * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
    */
   transformer = (node: TagNode, level: number) => {
     const existingNode = this.nestedNodeMap.get(node);
     const flatNode = existingNode && existingNode.item === node.item
       ? existingNode
       : new TagFlatNode();


      node.data = {...node.data} as any;

     flatNode.item = node.item;
     flatNode.level = level;
     flatNode.color = node.data.color;
     flatNode.position = node.data.position;
     flatNode.uid = node.data.uid;
     flatNode.active = node.data.active;
     flatNode.description = node.data.description;
     flatNode.platform = node.data.platform;
     flatNode.deleted = node.data.deleted;

    if (typeof node.data.uid == 'undefined') {
      node.data.uid = this.database.startId++;
    }
    if (typeof node.data.active == 'undefined') {
      node.data.active = true;
    }
    if (typeof node.data.deleted == 'undefined') {
      node.data.deleted = false;
    }

     flatNode.expandable = (node.children && node.children.length > 0);
     if (flatNode.expandable) {
      this.database.updateChildPositions(node);
     }
     this.flatNodeMap.set(flatNode, node);
     this.nestedNodeMap.set(node, flatNode);
     return flatNode;
   }

  ngOnInit(): void {
    this.database.initialize(this.TREE_DATA);
  }

  
  /** Select the category so we can insert the new item. */
  editItem(node: TagFlatNode) {
    const nestedNode = this.flatNodeMap.get(node);

    const dialogRef = this.dialog.open(TagEditDialogComponent, {data: {node: {...nestedNode, data: {...nestedNode.data}}}});
    dialogRef.afterClosed().subscribe(result => {
      //close dialog ref
      if(result && result.state){
        this.database.updateItem(nestedNode, result.node);

        // In delete case move children to parent. Node is not actually deleted too ensure UIDs are unique.
        if (result.node.data.deleted && nestedNode.children) {
          for (let child of nestedNode.children) {
            let data = {...child};
            data.data.deleted = true;
            data.data.active = false;
            this.database.updateItem(child, data);
          }
        }
      }
    });
  }


  addNewItem(node: TagFlatNode) {
    const nestedNode = this.flatNodeMap.get(node);

    const dialogRef = this.dialog.open(TagEditDialogComponent, {data: {node: {item: "", data: {active: true, deleted: false, platform: nestedNode.data.platform}, id: nestedNode.id + "\t"}}});
    dialogRef.afterClosed().subscribe(result => {
      //close dialog ref
      if(result && result.state){
        const parentNode = this.flatNodeMap.get(node);
        this.database.insertItem(parentNode, result.node);
        this.treeControl.expand(node);
      }
    });
  }

  /** ONLY FOR CREATE BUTTON */
  saveNode(node: TagFlatNode, itemValue: string) {
    const nestedNode = this.flatNodeMap.get(node);
    this.database.updateItem(nestedNode, {item: itemValue, data: {active: true, deleted: false, platform: nestedNode.data.platform} });
  }

  getNodeColor(node: TagFlatNode) {
    const nestedNode = this.flatNodeMap.get(node);
    if (nestedNode.data.color && nestedNode.data.color.startsWith("#")) {
      return nestedNode.data.color;
    }
    let parent = this.database.getParentFromNodes(nestedNode);
    if (parent) {
      let parent1 = this.nestedNodeMap.get(parent);
      return this.getNodeColor(parent1);
    } else {
      return "#ffffff";
    }
  }

  isParentInActive(node: TagFlatNode) {
    const nestedNode = this.flatNodeMap.get(node);
    if (!nestedNode.data.active) {
      return true;
    }
    let parent = this.database.getParentFromNodes(nestedNode);
    if (parent) {
      let parent1 = this.nestedNodeMap.get(parent);
      return this.isParentInActive(parent1);
    } else {
      return false;
    }
  }

  handleDragStart(event, node) {
    // Required by Firefox (https://stackoverflow.com/questions/19055264/why-doesnt-html5-drag-and-drop-work-in-firefox)
    event.dataTransfer.setData('foo', 'bar');
    event.dataTransfer.setDragImage(this.emptyItem.nativeElement, 0, 0);
    this.dragNode = node;
    this.treeControl.collapse(node);
  }

  handleDragOver(event, node) {
    event.preventDefault();

    // Handle node expand
    if (node === this.dragNodeExpandOverNode) {
      if (this.dragNode !== node && !this.treeControl.isExpanded(node)) {
        if ((new Date().getTime() - this.dragNodeExpandOverTime) > this.dragNodeExpandOverWaitTimeMs) {
          this.treeControl.expand(node);
        }
      }
    } else {
      this.dragNodeExpandOverNode = node;
      this.dragNodeExpandOverTime = new Date().getTime();
    }

    // Handle drag area
    const percentageX = event.offsetX / event.target.clientWidth;
    const percentageY = event.offsetY / event.target.clientHeight;
    if (percentageY < 0.25) {
      this.dragNodeExpandOverArea = 'above';
    } else if (percentageY > 0.75) {
      this.dragNodeExpandOverArea = 'below';
    } else {
      this.dragNodeExpandOverArea = 'center';
    }
  }

  handleDrop(event, node) {
    event.preventDefault();
    if (node !== this.dragNode) {
      let newItem: TagNode;
      if (this.dragNodeExpandOverArea === 'above') {
        newItem = this.database.copyPasteItemAbove(this.flatNodeMap.get(this.dragNode), this.flatNodeMap.get(node));
      } else if (this.dragNodeExpandOverArea === 'below') {
        newItem = this.database.copyPasteItemBelow(this.flatNodeMap.get(this.dragNode), this.flatNodeMap.get(node));
      } else {
        if (this.flatNodeMap.get(node).data.deleted && !this.flatNodeMap.get(this.dragNode).data.deleted) {
          return;
        }
        newItem = this.database.copyPasteItem(this.flatNodeMap.get(this.dragNode), this.flatNodeMap.get(node));
      }
      this.database.deleteItem(this.flatNodeMap.get(this.dragNode));
      //this.treeControl.expandDescendants(this.nestedNodeMap.get(newItem));
    }
    this.dragNode = null;
    this.dragNodeExpandOverNode = null;
    this.dragNodeExpandOverTime = 0;
  }

  handleDragEnd(event) {
    this.dragNode = null;
    this.dragNodeExpandOverNode = null;
    this.dragNodeExpandOverTime = 0;
  }


  saveNewTags() {
    const tags = this.database.currentlyDisplayedTagsToJson(); // TODO
    this.onSave.emit(tags);
  }

}

@Component({
  selector: "edit-tag-diag",
  templateUrl: './tag-edit-dialog.html',
  styleUrls: ['./tag-edit-dialog.css']
})
export class TagEditDialogComponent {
  factor;
  saveDisabled = true;

  inputForm = new FormGroup({});
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA, SEMICOLON] as const;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TagEditDialogComponent>,
    private _ngZone: NgZone,
    private _snackBar: MatSnackBar
  ) {
    this.inputForm = new FormGroup({
      name: new FormControl(this.data.node.item),
      nameEN: new FormControl(this.data.node.data.itemEN),
      note: new FormControl(this.data.node.data.note),
      color: new FormControl(this.data.node.data.color),
      colorToggle: new FormControl(this.data.node.data.color ? false : true),
      description: new FormControl(this.data.node.data.description),
      platform: new FormControl(this.data.node.data.platform),
      deleted: new FormControl(this.data.node.data.deleted),
      active: new FormControl(this.data.node.data.active)
    });

    if (!this.data.node.data.synonyme) {
      this.data.node.data.synonyme = [];
    } else {
      // Make it editable...
      this.data.node.data.synonyme = [...this.data.node.data.synonyme];
    }
      

    this.inputForm.valueChanges.subscribe(res => {
      try {
        try {
          if (res.color.hex) {
            this.data.node.data.color = "#" + res.color.hex;
          }
        } catch(e) {};
        if (res.colorToggle) {
          this.data.node.data.color = undefined;
        }

        this.data.node.item = res.name;
        this.data.node.data.item = res.name;
        // node.data.id and node.data.item is actually not required sice there already is node.id and node.item
        // but stratify sets it for some reason in the data field too and it dosn't sit well with me too keep them unupdated even if they are later discarded.
        try {
          this.data.node.data.id = this.data.node.id.substring(0, this.data.node.id.lastIndexOf("\t")) + "\t" + this.data.node.item;
          this.data.node.id = this.data.node.id.substring(0, this.data.node.id.lastIndexOf("\t")) + "\t" + this.data.node.item;
        } catch(err) {
          this.data.node.data.id = "Tags\t" + this.data.node.item;
          this.data.node.id = this.data.node.data.id;
        }
                  // I am actually setting alot of unneccasarry fields like "node.data.name" or "type" even if they get set later on anyways. 
          // But i wanted to be sure not to miss a field.
        this.data.node.data.name = this.data.node.data.id;
        this.data.node.data.description = res.description;
        this.data.node.data.platform = res.platform;
        this.data.node.data.type = res.platform;
        this.data.node.data.active = res.active;
        this.data.node.data.deleted = res.deleted;
        this.data.node.data.itemEN =  res.nameEN;
        this.data.node.data.note = res.note;

        if (typeof res.active == "undefined") {
          this.data.node.data.active = false;
        }

        if (res.deleted && this.data.node.data.active) {
          this.inputForm.controls.active.setValue(false);
          this.data.node.data.active = false;
        }

        if(res.deleted && !this.inputForm.controls.active.disabled) {
          this.inputForm.controls.active.disable();
          this._snackBar.open("Deleting a tag will result in its removal from all news that ever used it. This can be undone. Are you sure you don't actually wan't to set 'active' false?", "I understand!");
        }

        if (!res.deleted && this.inputForm.controls.active.disabled) {
          this.inputForm.controls.active.enable();
        }
      } catch(e) {
        console.error(e);
      }

      if (res.name && res.platform && typeof res.description != 'undefined' && this.data.node.id) {
        this.saveDisabled = false;
      } else {
        this.saveDisabled = true;
      }
    });

  }

  addSyn(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.data.node.data.synonyme.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    if (this.data.node.data.item && this.data.node.data.platform && typeof this.data.node.data.description != 'undefined' && this.data.node.id) {
      this.saveDisabled = false;
    }
  }

  removeSyn(syn: string): void {
    const index = this.data.node.data.synonyme.indexOf(syn);

    if (index >= 0) {
      this.data.node.data.synonyme.splice(index, 1);
    }

    if (this.data.node.data.item && this.data.node.data.platform && typeof this.data.node.data.description != 'undefined' && this.data.node.id) {
      this.saveDisabled = false;
    }
  }

  onClick(wantDeleted: boolean): void {
    console.log(this.data.node);
    this.dialogRef.close({state: wantDeleted, node: this.data.node});
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }
}