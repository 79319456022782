import { createReducer, on } from '@ngrx/store';
import { createReportResult, loadReportsSuccess } from '../actions/news.actions';


export const initialState = {
  reports:[],
  generated:false,
  refresh:2
};

const _reportsReducer = createReducer(initialState,
    on(loadReportsSuccess, (state, action) => {
        return {...state,reports:[...action.news.filter((e)=>e!=null)],refresh:Math.random()};
    }),
    on(createReportResult, (state, action) => {
      return {...state,generated:true,refresh:Math.random()};
  })
  );

export function reportsReducer(state, action) {
    return _reportsReducer(state, action);
  }
