import { createReducer, on } from "@ngrx/store";
import {
  statsNews24hGeomapSuccess,
  requestPermissions,
  requestPermissionsSucess,
} from "../actions/news.actions";

export const initialState = {
  canSearch: false,
  canCreateEntries: false,
  canUpdateEntries: false,
  canMarkEntriesChecked: false,
  canMarkEntriesConfirmed: false,
  canViewUsers: false,
  canCreateUsers: false,
  canCreateMappings: false,
  canViewMappings: false,
  canAccessAnalyzer: false,
  canViewCategories: false,
  canCreateTags: false,
  canViewDashboard: false,
  canAccessVideoDownloader: false,
};

const _permissionReducer = createReducer(
  initialState,
  on(requestPermissionsSucess, (state, action) => {
    return { ...action.permissions };
  })
);

export function permissionReducer(state, action) {
  return _permissionReducer(state, action);
}
