
<div style="width:100vw;height:600px" leaflet [leafletOptions]="options" [leafletLayers]="layers" (leafletMapReady)="onMapReady($event)"></div>

   
  
  <div
  style="border-radius:5px;padding-top:30px;padding-left:20px;background-color:white;" 
   #chartContainer id="chartContainer"></div>
  <br>
   <p *ngIf="(selectedRange | async)">
    Selected range {{(selectedRange | async)?((selectedRange | async)[0] | date:'short'):""}} , {{(selectedRange | async)?((selectedRange | async)[1] | date:'short'):""}}
   </p>