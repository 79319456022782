import { Component, OnInit, ViewChild, Renderer2, AfterViewInit, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subject, Subscription } from 'rxjs';
import { Store, select, ActionsSubject } from '@ngrx/store';
import { requestNews, addItem, changePage, requestStatsNews24h, requestMentionsGraph, editSearch, setItem, loadReports, requestArticle, checkItem, confirmItem, uncheckItem, unconfirmItem, closeEditorMap, deleteItemSuccess } from 'src/app/actions/news.actions';
import { State } from 'src/app/reducers';
import { SearchParameter } from 'src/app/models/search-parameter';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ResizeEvent } from 'angular-resizable-element';
import { MatPaginator } from '@angular/material/paginator';
import { map, distinct, switchMap } from 'rxjs/operators';
import { FormArray, FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { selectUnique } from 'src/app/selectors/query.selector';
import { getNewsStateSelector } from 'src/app/selectors/report.selector';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from "../../../environments/environment";
import { articleReducer } from 'src/app/reducers/article.reducer';
import { ofType } from '@ngrx/effects';
import { ComponentCanDeactivate } from 'src/app/guards/can-deactivate.guard';
import { EditorComponent } from '../editor/editor.component';

@Component({
  selector: 'app-news-editor',
  templateUrl: './news-editor.component.html',
  styleUrls: ['./news-editor.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        width: '100%',
      })),
      state('closed', style({
        width: '80%',
      })),
      transition('open => closed', [
        animate('0.4ms cubic-bezier(.25,.8,.25,1)')
      ]),
      transition('closed => open', [
        animate('0.4ms cubic-bezier(.25,.8,.25,1)')
      ]),
    ]),
  ]
})
export class NewsEditorComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  permissions$: Observable<any> = this.store.select(
    (state) => state.permissions
  );


  isExtension = environment.extension;

  sidebarStyle: any = {
    display: 'flex',
      backgroundColor: 'white',
      justifyContent: 'space-between',
      // paddingRight: '5px',
      boxSizing: 'border-box',
      width: 'calc(50vw)',
      height: '100%',
      minWidth: '5px',
      overflow:"hidden"
  };
  updateMap$ = new BehaviorSubject(null);

  

  listView = false;
  showDuplicates = true;

  loaded = false;

  form: FormGroup;
  size = 0;

  navHeight="0px";

  selectedArticleId = "";
  @ViewChild('drawer') drawer;

  @ViewChild('editor') editor: EditorComponent;

  openMap$ = this.store.select((store)=>store.mapSettings.openMap);

  subs = [];



  constructor(private store: Store<State>, private ren: Renderer2, private formBuilder: FormBuilder,private activatedRoute: ActivatedRoute,private location: Location) { }

  check(id,reports){
    return reports.map((e)=>e.id).includes(id);
  }

  ngOnInit() {
    // Load News
    this.activatedRoute.params.subscribe((params) => {
      if(params.id && params.id.length>0){
        this.selectedArticleId = params.id;
        this.store.dispatch(
          requestArticle({
            id: params.id,
          })
        );
      }
    });

    

    this.subs.push(this.openMap$.subscribe((data)=>{
      console.log("now data",data);
      
    }));
  }

  /*
  ngAfterViewInit(){
    setTimeout(this.onResize,50)
   // document.body.style.overflow = "hidden";
  }*/


  toggleDrawer() {
    this.drawer.toggle();
  }

  closeMap(){
    this.store.dispatch(closeEditorMap())
  }


  onResizeEnd(event: ResizeEvent): void {
    this.sidebarStyle = {
      width: `${event.rectangle.width}px`,
      display: 'flex',
      backgroundColor: 'white',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
      height: '100%',
      minWidth: '5px',
      overflow:"hidden"
    };
    this.updateMap$.next(true);
  }

  
  validate(event: ResizeEvent): boolean {
    const MIN_DIMENSIONS_PX = 450;
    if (
      event.rectangle.width &&
      event.rectangle.height &&
      (event.rectangle.width < MIN_DIMENSIONS_PX ||
        event.rectangle.height < MIN_DIMENSIONS_PX)
    ) {
      return false;
    }
    return true;
  }

  /*
  @HostListener('window:resize')
  onResize() {
    const height = window.innerHeight;
    const navheight = (document.querySelector("mat-toolbar") as any).offsetHeight;
    const content = (document.querySelector("app-news-editor > mat-sidenav-container > mat-sidenav-content") as any);
    const navs:any = document.querySelectorAll("mat-sidenav");

    navs[0].style.height = (height-navheight)+"px";
    content.style.height = (height-navheight)+"px";
    this.navHeight = navheight+"px";

    console.log((height-navheight)+"px",height,navheight.offsetHeight,content.offsetHeight)
  }*/

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return this.editor.canBeClosed();
  }

  ngOnDestroy(){
    document.body.style.overflow = "scroll";
    this.subs.forEach((e:Subscription)=>e.unsubscribe())
  }
}
