import { createReducer, on } from "@ngrx/store";
import { statsNews24hSuccess } from "../actions/news.actions";
import { NewsCompound } from "../models/news-stats.model";

export const initialState: any = {
  aggs: [],
  urlTime: [],
  // data:[],
  geohash: {
    push: [],
    pull: [],
    undefined: [],
  }
};

const _statsReducer = createReducer(
  initialState,
  on(statsNews24hSuccess, (state, action) => {
    console.log("test", action); // urlTime:[...action.news.urlTime]
    return {
      aggs: [...action.news.aggs],
      geohash: {
        ...action.news.geohash,
      } /*data:[...action.news.data],urlTime:[]*/,
    };
  })
);

export function statsReducer(state, action) {
  return _statsReducer(state, action);
}
