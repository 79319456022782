import { Component, OnInit, AfterViewInit, HostListener } from "@angular/core";
import * as d3 from "d3";
import { OnChanges, Input, ViewChild, ElementRef } from "@angular/core";
// import DataDrivenRangeSlider from "data-driven-range-slider";
import { RangeSlider } from "../range-slider/rangeslider";

import { MiscService } from "src/app/services/misc.service";
import {
  requestStatsNews24h,
  requestAnalyzer,
} from "src/app/actions/news.actions";
import { Store } from "@ngrx/store";
import { State } from "src/app/reducers";
import { NewsStats, StatsGeoHash } from "src/app/models/news-stats.model";
import { Observable, Subject, ReplaySubject } from "rxjs";
import {
  tileLayer,
  latLng,
  Map,
  circle,
  polygon,
  marker,
  Icon,
  icon,
} from "leaflet";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
declare var L;
@Component({
  selector: "app-visual-analyzer",
  templateUrl: "./visual-analyzer.component.html",
  styleUrls: ["./visual-analyzer.component.css"],
})
export class VisualAnalyzerComponent
  implements OnInit, OnChanges, AfterViewInit {
  constructor(private miscService: MiscService, private store: Store<State>) {}

  data;
  @ViewChild("chartContainer") chartContainer: ElementRef;
  chart;
  selectedRange = new ReplaySubject(1);
  selectedData = [];

  optionsSpec: any = {
    layers: [
      {
        url: "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution: "",
      },
    ],
    zoom: 2,
    center: [48.274234, 16.455118],
  };

  // Leaflet bindings
  zoom = this.optionsSpec.zoom;
  center = latLng(this.optionsSpec.center);
  options = {
    preferCanvas: true,
    layers: [
      // tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', { attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' }) ,
      tileLayer(this.optionsSpec.layers[0].url, {
        attribution: this.optionsSpec.layers[0].attribution,
      }),
    ],
    zoom: this.optionsSpec.zoom,
    center: latLng(this.optionsSpec.center),
  };

  layers: any = [
    circle([48.274234, 16.455118], { radius: 100 }),
    polygon([
      [46.8, -121.85],
      [46.92, -121.92],
      [46.87, -121.8],
    ]),
  ];
  geoRadii = [
    5000_000,
    1200_000,
    156_000,
    39_000,
    5_000,
    1_000,
    150,
    40,
    5,
    1,
    1,
    1,
  ];

  newsStats$: Observable<NewsStats[]> = this.store.select(
    (state) => state.sliderAnalyzer.aggs
  );

  geoStats$: Observable<StatsGeoHash> = this.store.select(
    (state) => state.geoAnalyzer
  );

  onMapReady(map) {
    // Do stuff with map
    // tslint:disable-next-line: new-parens
    const legend = new (L.Control.extend({
      options: { position: "bottomright" },
    }))();

    const vm = this;
    legend.onAdd = function (map) {
      const div = L.DomUtil.create("div", "legend");
      const labels = [
        "Sales greater than " + 5,
        "Sales greater than " + 3,
        "Sales equal or less than " + 2,
      ];
      const grades = [5 + 1, 2 + 1, 3];
      div.innerHTML = "<div><b>Legend</b></div>";
      div.innerHTML +=
        '<i style="background:' +
        "green" +
        '"> &nbsp; &nbsp;</i> &nbsp; &nbsp;' +
        "Push<br>";
      div.innerHTML +=
        '<i style="background:' +
        "blue" +
        '"> &nbsp; &nbsp;</i> &nbsp; &nbsp;' +
        "Pull<br>";
      div.innerHTML +=
        '<i style="background:' +
        "white" +
        '"> &nbsp; &nbsp;</i> &nbsp; &nbsp;' +
        "Undefined<br>";
      return div;
    };
    legend.addTo(map);
  }

  ngOnInit(): void {
    this.store.dispatch(requestAnalyzer({ resolution: 3 }));

    this.selectedRange
      .pipe(
        debounceTime(50),
        // If previous query is diffent from current
        distinctUntilChanged()
      )
      .subscribe((range) => {
        this.store.dispatch(
          requestAnalyzer({
            resolution: 3,
            fromDate: range[0].toISOString(),
            toDate: range[1].toISOString(),
          })
        );
      });

    const sub2 = this.newsStats$.subscribe((stats) => {
      console.log("STATS", stats);
      this.data = stats;
      if (!this.chart) {
        this.chart = new RangeSlider();
      }
      if (stats.length == 0) {
        return;
      }
      this.updateChart();
    });

    // GEOMAP
    const sub = this.geoStats$.subscribe((geo) => {
      this.layers = [];
      const resolution = 3;
      console.log("GEO", geo);
      for (const entry2 of geo.undefined) {
        const entry: any = { ...entry2 };
        entry.event = {};
        entry.event.rating = "pull";
        entry.event.name = "";
        this.layers.push(
          circle([entry.location.latitude, entry.location.longitude], {
            radius: this.geoRadii[resolution],
            color: "white",
          })
        );
      }
      for (const entry2 of geo.pull) {
        const entry: any = { ...entry2 };
        entry.event = {};
        entry.event.rating = "pull";
        entry.event.name = "";
        this.layers.push(
          circle([entry.location.latitude, entry.location.longitude], {
            radius: this.geoRadii[resolution],
            color: "green",
          })
        );
      }
      for (const entry2 of geo.push) {
        const entry: any = { ...entry2 };
        entry.event = {};
        entry.event.rating = "push";
        entry.event.name = "";
        this.layers.push(
          circle([entry.location.latitude, entry.location.longitude], {
            radius: this.geoRadii[resolution],
            color: "blue",
          })
        );
      }

      // console.log('kkwkkwk', this.layers);
    });
  }

  ngAfterViewInit() {}

  ngOnChanges() {
    if (!this.data) {
      return;
    }
    if (!this.chart) {
      return;
    }
    this.updateChart();
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (!this.data) {
      return;
    }
    if (!this.chart) {
      return;
    }
    this.updateChart();
  }

  updateChart() {
    this.chart
      .container(this.chartContainer.nativeElement)
      .data(this.data)
      .svgWidth(window.innerWidth - 50)
      .svgHeight(100)
      .accessor((d) => new Date(d.key_as_string))
      .aggregator((group) => {
        //  console.log("g", group);
        return group.values[0].doc_count;
      })
      .onBrush((d) => {
        this.selectedRange.next(d.range);
        this.selectedData = d.data;
      })
      .render();
  }
}
