<div id="wrapper">
<div 
    gdAreas="map map | side content | button ghg"
    gdRows="auto auto"
    gdColumns="50% 50%"
    >

    <mat-expansion-panel class="mat-elevation-z1,card" expanded="false" style="margin: 10px;" gdArea="map" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <span style="position: relative;top:0.8em;">Incidents</span>
            </mat-panel-title>
            <mat-panel-description>
                <p style="position: relative;top:0.8em; text-align: right; align-self: right;">click to expand</p>
                <span style="flex:1 0 auto"></span>
                <div style="display:inline-block;">
                <mat-slider
                    class="example-margin"
                    [max]="12"
                    [min]="3"
                    [step]="1"
                    [tickInterval]="1"
                    [(ngModel)]="geoSlider"
                    (change)="updateMap()"
                    >
                </mat-slider>
                Resolution {{(geoRadii[geoSlider-1])/1000}}km</div>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-card-content  style="min-width:100%;min-height:100%" class="card-text">
            <div id="map" style="min-width:100%;min-height:26em;" leaflet [leafletOptions]="options" [leafletLayers]="layers" (leafletMapReady)="onMapReady($event)"></div>
        </mat-card-content>
    </mat-expansion-panel>

    <!--
    <mat-card style="height:22em;margin-bottom:4em;" class="mat-elevation-z1,card" gdArea="map">
        <div class="card-header" fxLayout="row wrap">
            <span style="position: relative;top:0.8em;">Incidents</span>
            <span style="flex:1 0 auto"></span>
            <div style="display:inline-block;">
            <mat-slider
                class="example-margin"
                [max]="12"
                [min]="3"
                [step]="1"
                [tickInterval]="1"
                [(ngModel)]="geoSlider"
                (change)="updateMap()"
                >
            </mat-slider>
            Resolution {{(geoRadii[geoSlider-1])/1000}}km</div>
            
        </div>
        <mat-card-content  style="width:100%;height:100%" class="card-text">
            <div style="width:100%;height:100%" leaflet [leafletOptions]="options" [leafletLayers]="layers" (leafletMapReady)="onMapReady($event)"></div>
        </mat-card-content>
    </mat-card>-->

    <mat-card class="mat-elevation-z1,card" gdArea="side">
            <div class="card-header">Fetched News Total
                <button color="primary" mat-raised-button aria-label="Refresh" (click)="updateMap()" >
                    <span>Refresh</span>
                    <mat-icon>refresh</mat-icon>
                </button>
                <mat-form-field appearance="outline" class="selects" style="float: right; margin-top: -15px; margin-right: -15px;">
                    <mat-label>Date from/to</mat-label>
                    <mat-date-range-input [formGroup]="searchDateRange" [rangePicker]="picker3">
                        <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="updateMap()">
                        <input matEndDate formControlName="end" placeholder="End date" (dateChange)="updateMap()">
                        <mat-error *ngIf="searchDateRange.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                        <mat-error *ngIf="searchDateRange.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                    </mat-date-range-input>
    
                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker3></mat-date-range-picker>
                </mat-form-field>
            </div>
            <mat-card-content  style="width:100%;height:100%" class="card-text">
        <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins">
        </canvas>
        </mat-card-content>
    </mat-card>

    <mat-card class="mat-elevation-z1,card" gdArea="content">
            <div class="card-header">News by User
                <button color="primary" mat-raised-button aria-label="Refresh" (click)="updateNewsByUserChart()" >
                    <span>Refresh</span>
                    <mat-icon>refresh</mat-icon>
                </button>
                <mat-slide-toggle style="margin-left: 1em; margin-top: 7px;" [(ngModel)]="isUserDaily" (change)="updateNewsByUserChart()">{{isUserDaily ? "Daily" : "Weekly"}}</mat-slide-toggle>
                <div [formGroup]="orderForm" style="float: right; margin-top: 7px;" >
                    <mat-radio-group aria-label="Select an option" formControlName="alpha" (change)="updateNewsByUserChart()">
                        <mat-radio-button checked style="margin-right: 10px;" [value]="true">Alphabet</mat-radio-button>
                        <mat-radio-button [value]="false">Count</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <mat-card-content  style="width:100%;height:100%;" class="card-text">
        <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType"></canvas>
    </mat-card-content>
    </mat-card>
    

</div>
</div>