import { Injectable } from "@angular/core";
import { Observable, of, throwError, from, forkJoin } from "rxjs";
import { NewsStats, NewsCompound } from "../models/news-stats.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { concatMap, flatMap, map, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { SearchParameter } from "../models/search-parameter";
import { Tag } from "../models/tags.model";
import { Mapping } from "../models/mapping.model";

@Injectable({
  providedIn: "root",
})
export class MappingService {
  constructor(private http: HttpClient) {}

  public getNewsMappings() {
    return this.http.get<any[]>(`${environment.SERVER_URL}/mappings`).pipe(tap(arrayOfObservables => console.log(arrayOfObservables)));
  }
  public getNewsMapping(domain) {
    console.log("service: GET MAPPING for domain: ",domain)
    return (this.http.get(
      `${environment.SERVER_URL}/mappings/${domain}`
    ) as Observable<Mapping>).pipe(
      tap((x)=>console.log("FROM MAPPING SERVICE:",x))
    );
  }
  public saveMapping(obj) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    console.log(`saveMappingObj`, obj);
    return this.http.post(
      `${environment.SERVER_URL}/mappings`,
      obj,
      httpOptions
    );
  }


  public deleteSourceMapping(domain) {
    const httpOptions: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };
    return this.http
      .delete(`${environment.SERVER_URL}/mappings/${domain}`, httpOptions)
      .toPromise()
      .then((data) => {
        console.log(data);
        return Promise.resolve(data);
      });
  }


  public getHPSStats(query: any) {
    const httpOptions: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };
    return this.http
      .post(`${environment.SERVER_URL}/stats/hpsanalyse`, query, httpOptions);
  }
}
