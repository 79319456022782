<ng-container>
<mat-card style="width: 55em;">
    <mat-form-field appearance="outline" class="full-width">
        <mat-label>Search</mat-label>
        <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Search">
        <mat-icon style="cursor:pointer;" class="chooser" matSuffix [routerLink]="['./detail/new']">note_add</mat-icon>
    </mat-form-field>
    <mat-card-title>HPS
        <mat-slide-toggle [formControl]="showInactive" style="font-size: 16px; font-weight: 400; margin-left: 1em; margin-top: 7px;">Show inactive</mat-slide-toggle> 
        <button color="warn" style="margin-left: 1em;" mat-raised-button aria-label="Refresh" (click)="downloadExport('export.csv')" >
            <span>Export</span>
        </button>
    </mat-card-title>
    <table mat-table [dataSource]="unknownDataSource"  class="full-width" matSort >
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> Option </th>
            <td mat-cell *matCellDef="let row"><button mat-raised-button (click)="navigateDetail(row)" style="float:right;">Edit</button></td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
        </ng-container>
        <ng-container matColumnDef="domain">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Domain </th>
            <td mat-cell *matCellDef="let row"> {{row.domain}} </td>
        </ng-container>
        <ng-container matColumnDef="language">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Language </th>
            <td mat-cell *matCellDef="let row"> {{row.language}} </td>
        </ng-container>
        <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> User </th>
            <td mat-cell *matCellDef="let row">{{row.updated_by || row.created_by}} </td>
        </ng-container>
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="date"> Date </th>
            <td mat-cell *matCellDef="let row"> {{(row.updated_at || row.created_at) | date}} </td>
        </ng-container> 
        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
            <td mat-cell *matCellDef="let row"> {{row.active}} </td>
        </ng-container>
        <ng-container matColumnDef="done">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Done </th>
            <td mat-cell *matCellDef="let row"> 
                <button *ngIf="row.done === undefined" (click)="hpsCheckDone(row)" mat-mini-fab color="primary" aria-label="Checks if the HPS was used on news entry today.">
                    <mat-icon>refresh</mat-icon>
                </button>
                <button *ngIf="row.done !== undefined && row.done" (click)="hpsCheckDone(row)" mat-mini-fab color="success" matTooltip="Amount of news entered today">
                    {{row.done}}
                </button>
                <button *ngIf="row.done !== undefined && !row.done" (click)="hpsCheckDone(row)" mat-mini-fab color="danger" matTooltip="This HPS hasn't been entered today">
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="platform">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Platform </th>
            <td mat-cell *matCellDef="let row"> {{row.platforms}} </td>
        </ng-container>
        <ng-container matColumnDef="usage">
            <th mat-header-cell *matHeaderCellDef> Usage </th>
            <td mat-cell *matCellDef="let row"> {{(row.enabled || row.created_at) | date :'short'}} - {{row.disabled | date :'short'}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
    </table>
    <h2 *ngIf="loading">Loading...</h2>
    <mat-paginator id="paginator" #paginator [length]="totalLength" [pageSize]="10" [pageSizeOptions]="[5,10,20,50,100,10000]" (page)="changePage($event)">
    </mat-paginator>
  
</mat-card>
<button mat-icon-button color="primary" style="top:4em;right:10em;position:absolute;" (click)="openDownload()">
    Download Extension <mat-icon aria-label="info">info</mat-icon>
</button>  
</ng-container>