<mat-card>
    <h2>Create/Update Role</h2>
    <p *ngIf="isSuperAdmin" style="color:red;">Can't edit Superadmin</p>
    <mat-form-field style="width:100%">
        <input  matInput [(ngModel)]="name" name="name" placeholder="Name" [disabled]="isSuperAdmin">
    </mat-form-field>
    <mat-form-field style="width:100%">
        <mat-chip-list #chipList>
            <mat-chip *ngFor="let term of terms" [selectable]="selectable" [removable]="removable" (removed)="remove(term)">
                {{term}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input placeholder="Add Role Permission" #termInput [formControl]="termCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let term of filteredTerms | async" [value]="term" [disabled]="isSuperAdmin">
                {{term}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <br>
    <button id="save" mat-raised-button (click)="save();" [disabled]="isSuperAdmin">Create/Update Role</button>
        <button (click)="deleteRole();" mat-raised-button color="warn" id="resetButton" style="margin-left:0.5em;" [disabled]="isSuperAdmin">Delete</button>
</mat-card>