<div id="wrapper">
    <div 
        gdAreas="button | logging | language"
        gdRows="auto auto"
        gdColumns="100%"
        >
    
        <mat-card class="mat-elevation-z1,card" gdArea="button">
            <div class="card-header">Delete cForm entrys and export anew: 
                <button color="warn" [disabled]="(reindexState$ | async).inProgress" mat-raised-button aria-label="Reindex" (click)="reindexCForm()" >
                    <span>Reindex</span>
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
            <mat-card-content  style="width:100%;height:100%;" class="card-text"></mat-card-content>
        </mat-card>

        <app-logging gdArea="logging"></app-logging>

        <mat-card class="mat-elevation-z1,card" gdArea="language">
            <div class="card-header">Article Languages
            </div>
            <mat-card-content style="width:100%;height:100%;" class="card-text">
                <div class="mat-elevation-z8">
                    <mat-form-field style="padding-left:1em;padding-right:1em;width:100%;height:100%;" appearance="standard">
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. de" #input>
                    </mat-form-field>
                    <table mat-table [dataSource]="languages" style="width:100%;height:100%;">
                
                        <ng-container matColumnDef="languageSource">
                            <th mat-header-cell style="width: 11em;" *matHeaderCellDef> Language Source </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                            (change)="sourceSelection.toggle(row); updateLanguages();"
                                            [checked]="sourceSelection.isSelected(row)"
                                            [aria-label]="row.iso">
                                </mat-checkbox>
                            </td>
                            <td mat-footer-cell style="width: 11em;" *matFooterCellDef> 
                                <mat-checkbox (change)="$event ? masterToggleSource() : null"
                                [checked]="sourceSelection.hasValue() && isAllSelectedSource()"
                                [indeterminate]="sourceSelection.hasValue() && !isAllSelectedSource()"
                                aria-label="Select all source">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="languageDocument">
                            <th mat-header-cell style="width: 11em;" *matHeaderCellDef> Language Document </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                            (change)="documentSelection.toggle(row); updateLanguages();"
                                            [checked]="documentSelection.isSelected(row)"
                                            [aria-label]="row.iso">
                                </mat-checkbox>
                            </td>
                            <td mat-footer-cell style="width: 11em;" *matFooterCellDef> 
                                <mat-checkbox (change)="$event ? masterToggleDocument() : null"
                                [checked]="documentSelection.hasValue() && isAllSelectedDocument()"
                                [indeterminate]="documentSelection.hasValue() && !isAllSelectedDocument()"
                                aria-label="Select all document">
                                </mat-checkbox>
                            </td>
                        </ng-container>
                    
                        <!-- iso Column -->
                        <ng-container matColumnDef="iso">
                        <th mat-header-cell *matHeaderCellDef> ISO </th>
                        <td mat-cell *matCellDef="let element"> {{element.iso}} </td>
                        <td mat-footer-cell *matFooterCellDef> ISO </td>
                        </ng-container>
                    
                        <!-- name Column -->
                        <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                        <td mat-footer-cell *matFooterCellDef> Name </td>
                        </ng-container>
                    
                    
                        <tr mat-header-row *matHeaderRowDef="['languageSource', 'languageDocument', 'iso', 'name']"></tr>
                        <tr mat-row *matRowDef="let row; columns: ['languageSource', 'languageDocument', 'iso', 'name'];"></tr>
                        <tr mat-footer-row *matFooterRowDef="['languageSource', 'languageDocument', 'iso', 'name']"></tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                          </tr>
                    </table>
                  
                    <mat-paginator #pagi1 [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100, 200, 500]"
                                   showFirstLastButtons 
                                   aria-label="Select page of language elements">
                    </mat-paginator>
                  </div>
            </mat-card-content>
        </mat-card>
    
    </div>
</div>