import { Component, OnInit ,ViewChild} from '@angular/core';
import { NewsService } from '../../../services/news.service';
import { Router } from '@angular/router';
import * as SHA256 from "crypto-js/sha256";
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-user-manager',
  templateUrl: './user-manager.component.html',
  styleUrls: ['./user-manager.component.css']
})
export class UserManagerComponent {

  @ViewChild("paginator") paginator: MatPaginator;
  constructor(private authService: AuthService,private router: Router) { }
  DATA;

  users;

  columnsToDisplay = ['firstname','lastname','username','role','id'];
  totalLength

  skip=0;
  show=10;

  search="";
  size=1;

  @ViewChild(MatSort, {static: true}) sort: MatSort;


  ngAfterViewInit() {
    this.authService.getUsers().then((data:any)=>{
      console.log(data)
      
      this.DATA = data;
      this.users = new MatTableDataSource();
      this.users.sort = this.sort;
      this.users.data = this.DATA.filter((e,i)=>!e.known);
      this.totalLength = this.DATA.filter((e,i)=>!e.known).length;
      this.users.paginator = this.paginator;
    });
    
  }
  public doFilter = (value: string) => {
    this.users.filter = value.trim().toLocaleLowerCase();
  }

 
  changePage(event){
    console.log('event',event)
    this.show = event.pageSize;
    this.skip = event.pageSize * event.pageIndex;
    this.users.data = this.DATA.filter((e,i)=>!e.known);
    
  }
    
  navigateDetail(row){
    const url = `userEditor/`+row.id;
    console.log(url)
    this.router.navigate([url]);
  }

  
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}