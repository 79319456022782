// Search
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { createReport, createReportResult, genericError, loadReports, loadReportsSuccess, setItem, setItemFeedback, resetReport, resetReportSuccess } from '../actions/news.actions';
import { ReportService } from '../services/report.service';
import { NgxSpinnerService } from 'ngx-spinner';



@Injectable()
export class ReportEffects {

      

    setReport$ = createEffect(() => this.actions$.pipe(
        ofType(setItem),
        tap((data) => console.log('DATA IN EFF', data)),
        switchMap((data) => this.reportService.setReportsItem(data.id, data.operation).pipe(
          map((news) => {
            console.log('in effffff', news);
            return setItemFeedback({operation: true});
      
          } ),
          catchError((err) => {
            console.log('ERR', err);
            return of(genericError({error:err}));
          })
        )),
        )
      );
      resetReport$ = createEffect(() => this.actions$.pipe(
        ofType(resetReport),
        tap((data) => console.log('DATA IN EFF', data)),
        tap((data) => this.spinner.show()),
        switchMap(() => this.reportService.resetReport().pipe(
          switchMap(() => {
            this.spinner.hide();
            return [resetReportSuccess(),loadReports()];
      
          } ),
          catchError((err) => {
            console.log('ERR', err);
            this.spinner.hide();
            return of(genericError({error:err}));
          })
        )),
        )
      );
      
      loadReports$ = createEffect(() => this.actions$.pipe(
        ofType(loadReports),
        tap((data) => console.log('DATA IN EFF', data)),
        switchMap((data) => this.reportService.loadReports().pipe(
          map((news) => {
            console.log('in effffff2', news);
            return loadReportsSuccess({news});
      
          } ),
          catchError((err) => {
            console.log('ERR', err);
            return of(genericError({error:err}));
          })
        )),
        )
      );
      createReport$ = createEffect(() => this.actions$.pipe(
        ofType(createReport),
        tap((data) => console.log('DATA IN EFF', data)),
        tap((data) => this.spinner.show()),
        switchMap((data) => this.reportService.createReport(data.summary).pipe(
          map((news) => {
            console.log('in effffff2', news);
            this.spinner.hide()
            return createReportResult();
      
          } ),
          catchError((err) => {
            console.log('ERR', err);
            this.spinner.hide()
            return of(genericError({error:JSON.stringify(err)}));
          })
        )),
        )
      );
      

    constructor(
        private actions$: Actions,
        private spinner: NgxSpinnerService,
        private reportService: ReportService,
      ) {}

}


