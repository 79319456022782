import { createReducer, on } from "@ngrx/store";
import { analyzerGeomapSuccess } from "../actions/news.actions";

export const initialState = {
  push: [],
  pull: [],
  undefined: [],
};

const _geoAnalyzerReducer = createReducer(
  initialState,
  on(analyzerGeomapSuccess, (state, action) => {
    return { ...action.news };
  })
);

export function geoAnalyzerReducer(state, action) {
  return _geoAnalyzerReducer(state, action);
}
