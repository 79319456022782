import { Component, OnInit, ViewChild, Renderer2, AfterViewInit, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subject, Subscription, ReplaySubject } from 'rxjs';
import { Store, select, ActionsSubject } from '@ngrx/store';
import { requestMentionsGraph, editSearch, setItem, loadReports, requestArticle, checkItem, confirmItem, uncheckItem, unconfirmItem, changePage, closeEditorMap, deleteItemSuccess, changeSortField, reloadResults, confirmPage } from 'src/app/actions/news.actions';
import { State } from 'src/app/reducers';
import { SearchParameter } from 'src/app/models/search-parameter';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ResizeEvent } from 'angular-resizable-element';
import { MatPaginator } from '@angular/material/paginator';
import { distinctUntilChanged, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { selectUnique } from 'src/app/selectors/query.selector';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from "../../../environments/environment";
import { News } from 'src/app/models/news.model';
import { MatDialog } from '@angular/material/dialog';
import { EditorMapModalWrapperComponent } from '../news-editor/editor-map-modal-wrapper/editor-map-modal-wrapper.component';
import { ofType } from '@ngrx/effects';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { SearchService } from 'src/app/services/search.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss'],
    animations: [
        trigger('openClose', [
            // ...
            state('open', style({
                width: '100%',
            })),
            state('closed', style({
                width: '80%',
            })),
            transition('open => closed', [
                animate('0.4ms cubic-bezier(.25,.8,.25,1)')
            ]),
            transition('closed => open', [
                animate('0.4ms cubic-bezier(.25,.8,.25,1)')
            ]),
        ]),
    ],
    host: {
        '(document:keydown)': '_keydown($event)',
    }
})
export class NewsComponent implements OnInit, AfterViewInit, OnDestroy {

    uniqueToggle = new BehaviorSubject(true);
    tagMap: Map<number, any>;

    currentSearch$: Observable<SearchParameter> = this.store.select(state => state.currentSearch);
    news$: Observable<any[]> = this.uniqueToggle.asObservable().pipe(switchMap((bool) => {
        //console.log('mit');
        if (bool) {
            return this.store.select(state => state.news.results);
        } else {
            return this.store.pipe(select(selectUnique));
        }
    })).pipe(withLatestFrom(this.store.select(state => state.tagMapping)), distinctUntilChanged(), map(([news, tagMap]) => {
        news = news.map(art => {
            try {
                art = {...art, article: {...art.article, tags: [...art.article.tags]}};
                let temp = [];
                art.article.tags.forEach(uid => {
                    let ret = tagMap.get(uid);
                    if (ret) temp.push(ret);
                });
                art.article.tags = temp;
            } catch(err) {console.warn(err);}
            return art;
        });
        return news;
    }));
    
    newsCount$: Observable<number> = this.store.select(state => state.news.count);
    geoStats$: Observable<any[]> = this.store.select(state => state.news.results);
    reports$: Observable<any[]> = this.store.select(state => state.reports.reports);
    permissions$: Observable<any> = this.store.select(
        (state) => state.permissions
    );

    graphResults$: Observable<any[]> = this.store.select(state => state.semanticGraphResults.results);

    newItem: string;
    length = 5000;
    pageSize = 10;
    pageSizeOptions: number[] = [5, 10, 25, 100, 500];


    previewOpen = true;
    isOpen = true;

    isExtension = environment.extension;

    selectedEntryName = "";

    graphSelect = 'heatmap';
    sidebarStyle: any = {
        display: 'flex',
        backgroundColor: 'white',
        justifyContent: 'space-between',
        // paddingRight: '5px',
        boxSizing: 'border-box',
    //    width: '40vw',
        height: '100%',
    //  minWidth: '5px',
        overflow: "hidden"
    };
    sidebarStyle2: any = {
        display: 'flex',
        backgroundColor: 'white',
        justifyContent: 'space-between',
        // paddingRight: '5px',
        boxSizing: 'border-box',
    // width: '40vw',
        minHeight: '100%',
        minWidth: '450px',
        overflow:"hidden"
    };
    updateMap$ = new BehaviorSubject(null);

    openMap$ = this.store.select((store)=>store.mapSettings.openMap);

    listView$ = this.store.select((store)=>store.currentSearch.listView)

    selectedID = null;
    displayedColumns = ['select','article_title','date','article_created_by'];
    selection = new SelectionModel<string>(true, []);

    pageLoadedSize: number = 0;

    @ViewChild(MatSort) sort: MatSort;

    listView = false;
    showDuplicates = true;

    loaded = false;

    form: FormGroup;
    size = 0;

    navHeight = "0px";

    selectedArticleId = "";
    currentQueryParams = {};
    subs = [];

    displayNews: any[];

    @ViewChild('drawer') drawer;
    @ViewChild('drawer_right') drawer_right;
    @ViewChild('paginator') vc: MatPaginator;
    @ViewChild('drawer_right_content') drawer_right_content: ElementRef;

    article$: Observable<News> = this.store.select(
        (state) => state.currentArticle
    );


    constructor(private store: Store<State>, private ren: Renderer2, private activatedRoute: ActivatedRoute, private location: Location, private dialog: MatDialog, private actions$: ActionsSubject, private searchService: SearchService) {
        this.store.select(state => state.tagMapping).pipe(untilDestroyed(this)).subscribe(e => this.tagMap = e)
     }

    check(id, reports) {
        return reports.map((e) => e.id).includes(id);
    }

    ngOnInit() {
        this.store.dispatch(loadReports());
        this.reloadResults();
        this.currentSearch$.subscribe((state) => {
            if(this.listView==state.listView)
                this.loaded = false;
            this.listView = state.listView;
            
        });
        this.news$.subscribe((state) => {
            this.pageLoadedSize = state.length;
            this.displayNews = state //.map(val => Object.assign({}, val)); Removed because of UID tags

            for (let i = 0; i < this.displayNews.length; i++) {
                if (typeof this.displayNews[i].article == 'undefined') {
                    continue;
                }
                if (typeof this.displayNews[i].article.title_translated == 'undefined') {
                    this.displayNews[i].article.title_translated = "";
                }
                this.displayNews[i].displayTags = this.getPreviewTags(this.displayNews[i].article.tags);
            }
            this.loaded = true;
        });
        this.newsCount$.subscribe((count) => {
            this.size = count;
            this.selection = new SelectionModel<string>(true, []);
        });

        this.graphResults$.subscribe((res) => {
            this.size = res.length;
        })

        this.subs.push(this.actions$.pipe(ofType(deleteItemSuccess)).subscribe(()=>{
            this.selectedArticleId=null;
        }));

        

        
    }
    ngAfterViewInit() {
        setTimeout(()=>document.querySelector("#news-link").classList.add("active-link"),0)
        
        this.onResize();
        setTimeout(this.onResize, 0)
        document.body.style.overflow = "hidden";

        this.subs.push(this.sort.sortChange.subscribe((data)=>{
            let field = data.active;
            let direction = data.direction;
            this.store.dispatch(changeSortField({field,direction}));
            this.store.dispatch(reloadResults());
        }));

        const sub2 = this.activatedRoute.queryParams.subscribe((params) => {
            this.currentQueryParams = params
            if (params.article_id && params.article_id.length > 0) {
                console.log("open")
                this.selectedArticleId = params.article_id;
                this.store.dispatch(
                    requestArticle({
                        id: params.article_id,
                    })
                );
                

                this.updateQueryParams(params);
            } else {
            /*  console.log("close")
                this.previewOpen = false;
                this.drawer.open();
                this.drawer_right.close();*/
            }
            this.previewOpen = true;
            this.drawer.close();
            this.drawer_right.open();
        });
        this.subs.push(sub2);
    }


    confirmPage(){
        //alert("TODO confirmPage Call");
        this.store.dispatch(confirmPage({ids: this.selection.selected}));
    }

    showArticle(event) {
    /* this.store.dispatch(
            requestArticle({
                id: event.id,
            })
        );*/
        this.previewOpen = true;
        this.drawer.close();
        this.drawer_right.open();
    }
    checkBoxUpdate(e, entry) {
        console.log('Set checkbox ' + e.value + ' To: ' + !e.checked, entry);
        this.store.dispatch(setItem({ id: entry.id, operation: !e.checked }));
    }
    addToReport(id) {
        this.store.dispatch(setItem({ id, operation: true }));
    }
    debug(e) {
        console.log('debug', e);
    }
    listToggle(event) {
        // Toggle between list view and full view

        const ev = {
            pageIndex: this.vc.pageIndex,
            pageSize: this.vc.pageSize
        };
        this.listView = event.checked;
        //   this.updatePage(ev);
    }
    listToggleDup(event) {
        this.uniqueToggle.next(event.checked);
    }

    updatePage(event) {
        console.log('update PAGE--------------------------');
        const size: number = event.pageSize;
        const index: number = event.pageIndex;
        this.store.dispatch(changePage({ size, index }));
    }
    toggleGraph() {
        this.drawer.toggle();
    }
    toggleEditor() {
        this.previewOpen = !this.previewOpen;
        this.drawer_right.toggle();
    }

    selectGraph(name) {
        this.selectedEntryName = name;
    }

    onResizeEnd(event: ResizeEvent): void {
        this.sidebarStyle = {
            width: `${event.rectangle.width}px`,
            display: 'flex',
            backgroundColor: 'white',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
            height: '100%',
            minWidth: '5px',
            overflow: "hidden"
        };
        this.updateMap$.next(true);
    }


    onResizeEnd2(event: ResizeEvent): void {
        this.sidebarStyle2 = {
            left: `${event.rectangle.left}px`,
            top: `${event.rectangle.top}px`,
            width: `${event.rectangle.width}px`,
            // height: `${event.rectangle.height}px`,
            display: 'flex',
            boxSizing: 'border-box',

            justifyContent: 'space-between',
            // paddingRright: '5px'
        };
        this.updateMap$.next(true);
    }
    openInNewTab(url) {
        console.log("open", url)
        const win = window.open(url, '_blank');
        win.focus();
    }

    checkState(el) {
        setTimeout(() => {
            if (this.selectedID && this.selectedID === el.value) {
                el.checked = false;
                this.ren.removeClass(el._elementRef.nativeElement, 'cdk-focused');
                this.ren.removeClass(el._elementRef.nativeElement, 'cdk-program-focused');
                this.selectedID = null;
            } else {
                this.selectedID = el.value;
            }
            this.updateGraph(this.selectedID);
        });
    }
    updateGraph(id) {
        this.store.dispatch(requestMentionsGraph({ id }));
    }
    reloadResults() {
        if (this.graphSelect == 'mentions') {
            this.store.dispatch(editSearch({ state: { hasMentions: true } }));
        } else {
            this.store.dispatch(editSearch({ state: { hasMentions: false } }));
        }


    }
    refreshPreview(id) {
        if(this.selectedArticleId == id){
            this.previewOpen = !this.previewOpen;
            this.drawer_right.close();
            this.selectedArticleId = null;
        }else{
            this.selectedArticleId = id;
            this.updateQueryParams({ ...this.currentQueryParams, article_id: this.selectedArticleId });
            setTimeout(() => {
                console.log("open")
                this.drawer.close();
                this.drawer_right.open();
            }, 0)


            this.store.dispatch(
                requestArticle({
                    id,
                })
            );
        }
        

    }
    toISO(dateString) {
        return `${dateString.substring(0, 4)}-${dateString.substring(4, 6)}-${dateString.substring(6, 8)}T${dateString.substring(8, 10)}:${dateString.substring(10, 12)}:${dateString.substring(12, 14)}`;
    }
    updateQueryParams(queryParams) {
        const query = new URLSearchParams();

        if (queryParams?.fulltext?.length > 0) {
            query.append("query", queryParams.fulltext);
        }

        if (queryParams?.dateBegin) {
            query.append("from", queryParams.dateBegin);
        }

        if (queryParams?.dateEnd) {
            query.append("to", queryParams.dateEnd);
        }

        if (this?.selectedArticleId) {
            query.append("article_id", this.selectedArticleId);
        }

        query.append("ownEntries", (queryParams.ownEntries ?? true));


        this.location.replaceState("/news", query.toString());
    }
    truncate(text) {
        if (text && text.length > 150) {
            return text.substring(0, 150) + '...';
        }
        return text ?? "";
    }

    _keydown(event) {
        /*if (event.key === 'ArrowRight') {
            this.vc.nextPage();
        }
        if (event.key === 'ArrowLeft') {
            this.vc.previousPage();
        }*/
    }

    validate(event: ResizeEvent): boolean {
        const MIN_DIMENSIONS_PX = 450;
        if (
            event.rectangle.width &&
            event.rectangle.height &&
            (event.rectangle.width < MIN_DIMENSIONS_PX ||
                event.rectangle.height < MIN_DIMENSIONS_PX)
        ) {
            return false;
        }
        return true;
    }

    @HostListener('window:resize')
    onResize() {
        const height = window.innerHeight;
        const navheight = (document.querySelector("mat-toolbar") as any).offsetHeight;
        const content = (document.querySelector("app-news > mat-sidenav-container > mat-sidenav-content") as any);
        const navs: any = document.querySelectorAll("mat-sidenav");

        navs[0].style.height = (height - navheight) + "px";
        navs[1].style.height = (height - navheight) + "px";
        content.style.height = (height - navheight) + "px";
        this.navHeight = navheight + "px";

        console.log((height - navheight) + "px", height, navheight.offsetHeight, content.offsetHeight)
    }

    ngOnDestroy() {
        document.body.style.overflow = "scroll";
        this.subs.forEach((sub: Subscription)=>sub.unsubscribe());
        document.querySelector("#news-link").classList.remove("active-link")
    }

    checkEntry(id, checked) {
        if (!checked)
            this.store.dispatch(checkItem({ id }))
        else
            this.store.dispatch(uncheckItem({ id }));
    }

    confirmEntry(id, confirmed) {
        if (!confirmed)
            this.store.dispatch(confirmItem({ id }))
        else
            this.store.dispatch(unconfirmItem({ id }));
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.pageLoadedSize;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
        return;
        }
        /*let search = {...await this.currentSearch$.toPromise()};
        search.index = 0;
        search.size = this.size + 1;*/

        /*let data = await this.searchService.search(search).pipe(take(1)).toPromise();
        this.selection.select(...data.results.map(x => x.id));*/
        this.news$.pipe(take(1)).subscribe((val) => {
            this.selection.select(...val.map(x => x.id));
        });
        
    }


    getPreviewTags(article_tags: any[]): any[] {

        let tss = [...article_tags].sort((x, y) => {
            let sX = x.id.split("\t");
            let sY = y.id.split("\t");
            let lastI = Math.min(sX.length, sY.length);
    
            let equals = true;
            for (let i = 0; i < lastI-1; i++) {
              if (sX[i] != sY[i]) {
                equals = false;
                break;
              } 
            }
    
            if (equals) 
              return sX.length - sY.length;
            else
              return x.id.localeCompare(y.id);
          });

        let all_seperate_tags = new Set<string>();
        let reals = new Map<string, string>();
        for (let tag of tss) {
          let res = tag.id.split("\t");
          for (let part of res) {
            if (!part.startsWith("Tag")) {
              all_seperate_tags.add(part);
            }
          }
          reals.set(res[res.length - 1], this.getTagPreviewColor(tag));
        }
        return Array.from(all_seperate_tags).map(tag => reals.has(tag) ? {tag: tag, color: reals.get(tag)} : {tag: tag, color: ""});
    }


    getTagPreviewColor(tag) {
        if (!tag) return "#cddc39";
    
        if (tag.color && (tag.color.startsWith('#') || tag.color.startsWith('r'))) {
          return tag.color;
        } else {
          return this.getTagPreviewColor(this.tagMap.get(tag.parent));
        }
      }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: News): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row.id) ? 'deselect' : 'select'}`;
  }
}

class MyDataSource extends DataSource<any> {
    private _dataStream = new ReplaySubject<any>();
  
    constructor(initialData: any) {
      super();
      this.setData(initialData);
    }
  
    connect(): Observable<any> {
      return this._dataStream;
    }
  
    disconnect() {}
  
    setData(data: any) {
      this._dataStream.next(data);
    }
  }