import { Injectable } from "@angular/core";
import { Observable, of, throwError, from } from "rxjs";
import { NewsStats, NewsCompound } from "../models/news-stats.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { flatMap, map, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { SearchParameter } from "../models/search-parameter";
import { Tag } from "../models/tags.model";
import { Mapping } from "../models/mapping.model";

@Injectable({
  providedIn: "root",
})
export class ScrappingService {
  constructor(private http: HttpClient) {}

  public async scrape(params) {

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    let result: any;
    try {
      result = await this.http.post(
        `${environment.SERVER_URL}/mappings/findByUrl`,
        {url: params.url},
        httpOptions
      ).toPromise();
    } catch(e) {}


    console.log(`scrape`, {...params, hps: result});
    return await this.http.post(
      `${environment.SERVER_URL}/scrape`,
      {...params, hps: result},
      httpOptions
    ).toPromise();
  }
}
