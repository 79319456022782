import { Component, OnInit, OnDestroy } from "@angular/core";
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  tileLayer,
  latLng,
  Map,
  circle,
  polygon,
  marker,
  Icon,
  icon,
} from "leaflet";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { Color, Label } from "ng2-charts";

import * as moment from "moment";
import { generate, Observable, Subscription } from "rxjs";
import { reduce, take } from "rxjs/operators";

import {
  NewsStats,
  StatsGeo,
  StatsGeoHash,
} from "../../models/news-stats.model";
import { Store } from "@ngrx/store";
import { requestReindexCForm, requestStatsNews24h } from "src/app/actions/news.actions";
import { State } from "src/app/reducers";
import { StatsService } from "src/app/services/stats.service";
import { I } from "@angular/cdk/keycodes";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { FormControl, FormGroup } from "@angular/forms";
import * as L from "leaflet";


@UntilDestroy()
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  constructor(private store: Store<State>, private statsService:StatsService) {}


  isUserDaily = true;

  geoSlider = 3;
  geoRadii = [
    5000_000,
    1200_000,
    156_000,
    39_000,
    5_000,
    1_000,
    150,
    40,
    5,
    1,
    1,
    1,
  ];

  newsStats$: Observable<NewsStats[]> = this.store.select(
    (state) => state.newsStats.aggs
  );

  geoStats$: Observable<StatsGeoHash> = this.store.select(
    (state) => state.geoStats
  );

  optionsSpec: any = {
    layers: [
      {
        url: "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution: "",
      },
    ],
    zoom: 1.2,
    center: [22.274234, 16.455118],
  };

  // Leaflet bindings
  zoom = this.optionsSpec.zoom;
  center = latLng(this.optionsSpec.center);
  options = {
    preferCanvas: true,
    layers: [
      // tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', { attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' }) ,
      tileLayer(this.optionsSpec.layers[0].url, {
        attribution: this.optionsSpec.layers[0].attribution,
      }),
    ],
    zoom: this.optionsSpec.zoom,
    center: latLng(this.optionsSpec.center),
  };

  layers: any = [
    circle([48.274234, 16.455118], { radius: 100 }),
    polygon([
      [46.8, -121.85],
      [46.92, -121.92],
      [46.87, -121.8],
    ]),
  ];


  public lineChartData: ChartDataSets[] = [
    // (function() {const arr = []; for (let i = -12; i <= 12; i++) {arr.push(Math.pow(i,2)+10) }return arr;})()
    { data: [], label: "All", borderColor: 'rgba(220,220,220,1)', backgroundColor: 'rgba(220,220,220,0.5)' },
    { data: [], label: "Cyber", borderColor: '#86c7f3', backgroundColor: '#86c7f3c0' },
    { data: [], label: "Krimisi", borderColor: '#97ffb5', backgroundColor: '#97ffb5c0'},
    { data: [], label: "Innotech", borderColor: '#ffd28c', backgroundColor: '#ffd28cc0' },
  ];
  public lineChartColors: Color[] = [
    {
      borderColor: 'rgba(220,220,220,1)', backgroundColor: 'rgba(220,220,220,0.5)'
    },
    {
      borderColor: '#86c7f3', backgroundColor: '#86c7f3b5'
    },
    {
      borderColor: '#97ffb5', backgroundColor: '#97ffb5b5'
    },
    {
      borderColor: '#ffd28c', backgroundColor: '#ffd28cb5'
    },
  ];

  public lineChartLabels: Label[] = [];
  public lineChartOptions: ChartOptions = {
    aspectRatio: 1.2,
    responsive: true,
    maintainAspectRatio:true,
  tooltips: {
    mode: 'index',
    intersect: false
  },
  hover: {
      mode: 'index',
      intersect: false
  }
  };


  public lineChartLegend = true;
  public lineChartType = "line";
  public lineChartPlugins = [];

  public barChartOptions: ChartOptions = {
    aspectRatio: 1.2,
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins:{
      datalabels:{
        color:"white",
        labels:{
          value:{
            anchor:"start",
            align:"right",
            formatter: function(value, context) {
              return String(value.x).padStart(3, ' ');
            }
          },
          date:{
            anchor:"end",
            align:"left",
            formatter: function(value, context) {
              if(value.x<3){
                return "";
              }
              let m:Date = value.date;
              return ("0" + m.getHours()).slice(-2) + ":" +
              ("0" + m.getMinutes()).slice(-2)// + ":" +
            //  ("0" + m.getSeconds()).slice(-2);
            }
          }
        },
        font:{
          weight:"normal",
          size:15
        }
      }
    }
  };
  public barChartLabels: Label[] = [];

  public barChartType: ChartType = "horizontalBar";
  public barChartLegend = true;
  public barChartPlugins = [ChartDataLabels];

  public barChartData: ChartDataSets[] = [
    { data: [], label: "Created news per user " + (this.isUserDaily ? 'since midnight.' : 'in the last 7d.') },
  ];


  orderForm = new FormGroup({
    alpha: new FormControl(true)
  }); 


  searchDateRange = new FormGroup({
    start: new FormControl(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 1)).toISOString()),
    end: new FormControl(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 7)).toISOString()),
  });


  updateMap() {
    this.store.dispatch(requestStatsNews24h({ resolution: this.geoSlider, startDate: new Date(this.searchDateRange.controls.start.value).toISOString(), endDate: new Date(this.searchDateRange.controls.end.value).toISOString()}));
  }


  onMapReady(map) {
    // Do stuff with map
    // tslint:disable-next-line: new-parens
    const legend = new (L.Control.extend({
      options: { position: "bottomright" },
    }))();

    const vm = this;
    legend.onAdd = function (map) {
      const div = L.DomUtil.create("div", "legend");
      const labels = [
        "Sales greater than " + 5,
        "Sales greater than " + 3,
        "Sales equal or less than " + 2,
      ];
      const grades = [5 + 1, 2 + 1, 3];
      div.innerHTML = "<div><b>Legend</b></div>";
      div.innerHTML +=
        '<i style="background:' +
        "green" +
        '"> &nbsp; &nbsp;</i> &nbsp; &nbsp;' +
        "Push<br>";
      div.innerHTML +=
        '<i style="background:' +
        "blue" +
        '"> &nbsp; &nbsp;</i> &nbsp; &nbsp;' +
        "Pull<br>";
      div.innerHTML +=
        '<i style="background:' +
        "white" +
        '"> &nbsp; &nbsp;</i> &nbsp; &nbsp;' +
        "Undefined<br>";
      return div;
    };
    legend.addTo(map);
  }


  updateNewsByUserChart() {
    this.barChartData = [
      { data: [], label: "Created news per user " + (this.isUserDaily ? 'since midnight' : 'this week (Mo - So)') },
    ];

    let orderAlpha = this.orderForm.controls.alpha.value;
    this.statsService.byUser24(!this.isUserDaily).pipe(take(1)).subscribe((arr:any)=>{
      this.orderForm.controls.alpha.setValue(orderAlpha);
      if (orderAlpha) {
        arr = arr.sort((x, y) => { 
            var nameA = x.key.toUpperCase(); 
            var nameB = y.key.toUpperCase(); 
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
        });
      }
      console.log("USERS",arr)
      let data = [];
      this.barChartLabels.splice(0, this.barChartLabels.length);
      for(const i of arr){
        this.barChartLabels.push(i.key)
        data.push({x:i.doc_count,date:moment.utc(i.date.hits.hits[0]._source.date,"YYYYMMDDHHmmss").toDate()});
      }
      this.barChartData[0].data = data;
    });
  }


  ngOnInit() {
    this.updateMap();

    this.updateNewsByUserChart();

    for (let i = 0; i <= 24; i++) {
      // this.lineChartLabels.unshift(moment().subtract('h').fromNow())
    }

    // Fetched News
    this.newsStats$.pipe(untilDestroyed(this)).subscribe((stats) => {
      this.lineChartLabels = [];
      this.lineChartData[0].data = [];
      this.lineChartData[1].data = [];
      this.lineChartData[2].data = [];
      this.lineChartData[3].data = [];
      for (const entry of stats) {
        this.lineChartLabels.push(
          moment(entry.key_as_string).subtract("h").fromNow()
        );
        this.lineChartData[0].data.push(entry.doc_count);
        for (let spec of entry.sub.buckets) {
          if (spec.key == "cyber") {
            this.lineChartData[1].data.push(spec.doc_count);
          } else if (spec.key == "krimisi") {
            this.lineChartData[2].data.push(spec.doc_count);
          } else {
            this.lineChartData[3].data.push(spec.doc_count);
          }
        }

        for (let i = 1; i <= 3; i++) {
          if (this.lineChartData[i].data.length < this.lineChartData[0].data.length)
            this.lineChartData[i].data.push(0);
        }
      }
    });

    // GEOMAP
    this.geoStats$.pipe(untilDestroyed(this)).subscribe((geo) => {
      this.layers = [];
      const resolution = this.geoSlider - 1;
      for (const entry2 of geo.undefined) {
        const entry: any = { ...entry2 };
        entry.event = {};
        entry.event.rating = "pull";
        entry.event.name = "";
        this.layers.push(
          circle([entry.location.latitude, entry.location.longitude], {
            radius: this.geoRadii[resolution],
            color: "white",
          })
        );
      }
      for (const entry2 of geo.pull) {
        const entry: any = { ...entry2 };
        entry.event = {};
        entry.event.rating = "pull";
        entry.event.name = "";
        this.layers.push(
          circle([entry.location.latitude, entry.location.longitude], {
            radius: this.geoRadii[resolution],
            color: "green",
          })
        );
      }
      for (const entry2 of geo.push) {
        const entry: any = { ...entry2 };
        entry.event = {};
        entry.event.rating = "push";
        entry.event.name = "";
        this.layers.push(
          circle([entry.location.latitude, entry.location.longitude], {
            radius: this.geoRadii[resolution],
            color: "blue",
          })
        );
      }

      // console.log('kkwkkwk', this.layers);
    });

  }


  toISO(dateString) {
    return `${dateString.substring(0, 4)}-${dateString.substring(
      4,
      6
    )}-${dateString.substring(6, 8)}T${dateString.substring(
      8,
      10
    )}:${dateString.substring(10, 12)}:${dateString.substring(12, 14)}`;
  }
}
