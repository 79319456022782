import { createReducer, on } from '@ngrx/store';
import { statsNews24hGeomapSuccess } from '../actions/news.actions';


export const initialState = {
  push:[],
  pull:[],
  undefined:[]
};

const _geoReducer = createReducer(initialState,
    on(statsNews24hGeomapSuccess, (state, action) => {
        return {...action.news};
    })
  );

export function geoReducer(state, action) {
    return _geoReducer(state, action);
  }
