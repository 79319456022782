// Search
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import {
  createReport,
  createReportResult,
  genericError,
  loadReports,
  loadReportsSuccess,
  setItem,
  setItemFeedback,
  resetArticleForm,
  requestArticle,
} from "../actions/news.actions";
import { ReportService } from "../services/report.service";

@Injectable()
export class RouterEffects {
  constructor(
    private actions$: Actions,
    private reportService: ReportService
  ) {}

  // EDITOR Component
  /* editorLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType("@ngrx/router-store/navigation"),
      tap((data) => console.log("Router navigation", data)),
      map((event: any) => {
        if (event.payload.routerState.component === "editor") {
          if (event.payload.routerState.params.id === "new") {
            return resetArticleForm();
          } else {
            return requestArticle({
              id: event.payload.routerState.params.id,
            });
          }
        }
        return genericError({ error: "kek" });
      }),
      catchError((err) => {
        console.log("ERR", err);
        return of(genericError({ error: err }));
      })
    )
  );*/
}
