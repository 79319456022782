import { createReducer, on } from '@ngrx/store';
import { logEntrysReceived } from '../actions/news.actions';


export const initialState = {total: 0, result: []};

const _logReducer = createReducer(initialState,
    on(logEntrysReceived, (state, action) => {
        return action.result;
    })
  );

export function logReducer(state, action) {
    return _logReducer(state, action);
  }
