import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { AuthService } from "../../../services/auth.service";
import {
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { NewsService } from "src/app/services/news.service";
import { MatDialog } from "@angular/material/dialog";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { Observable, of } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { treemapSquarify } from "d3";
import { DeleteDialogComponent } from "../../misc-components/delete-dialog/delete-dialog.component";
import { RoleService } from "src/app/services/role.service";
import { ToastrService } from "ngx-toastr";
import * as generate from 'secure-random-password'

import {environment} from 'src/environments/environment'


@Component({
  selector: "app-user-editor",
  templateUrl: "./user-editor.component.html",
  styleUrls: ["./user-editor.component.css"],
})
export class UserEditorComponent implements OnInit {
  
  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private newsService: NewsService,
    private router: Router,
    private roleService: RoleService,
    private toastr: ToastrService
  ) { }
  id;
  newUser = false;


  formGroup = new FormGroup({
    username: new FormControl("",[Validators.required,Validators.minLength(3)]),
    password: new FormControl("",[Validators.required,Validators.minLength(10)]),
    confirmPassword: new FormControl("",[Validators.required]),
    role: new FormControl("",[Validators.required]),
    description: new FormControl("",[]),
    email: new FormControl("",[ ]),
    firstname: new FormControl("",[]),
    lastname: new FormControl("",[]),
    title: new FormControl(""),
    tel: new FormControl("",[Validators.pattern(/(0|(\+|00)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263))(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20}$/
    )]),
    skills: new FormControl(""),
    organization: new FormControl("",[Validators.required]),
  },);

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  termCtrl = new FormControl();
  filteredTerms: Observable<string[]>;
  terms: any = [];
  allTerms: string[] = [];

  roles = [];

  @ViewChild("termInput") termInput: ElementRef;

  ngOnInit() {
    const id = this.activatedRoute.snapshot.params.id;
    this.id = id;
    console.log("ID", this.id, this.id == "new");
    if (!this.id || this.id == "new") {
      this.newUser = true;
      this.formGroup.setValidators(checkSamePasswordValidator())
    }
    if (!this.newUser) {
      this.formGroup.controls.password.clearValidators()
      this.formGroup.controls.confirmPassword.clearValidators();
      this.formGroup.updateValueAndValidity();
      this.authService.getUser(id).then((d: any) => {
        console.log("Loaded User Data", d);
        this.formGroup.patchValue(d);
      });
    }

    this.filteredTerms = this.termCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) =>
        fruit ? this._filter(fruit) : this.allTerms.slice()
      )
    );

    this.roleService.getRoles().then((data: any) => {
      this.roles = data.map((e) => e.name);
    });
  }
  deleteUser() {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.authService.deleteUser(this.id).then(() => {
          setTimeout(() => this.router.navigate(["/userManagement"]), 1000);
        });
      }
    });
  }
  save() {
    if(this.formGroup.valid){
      if (this.newUser) {
        this.authService
          .createUser(
            this.formGroup.controls.username.value,
            this.formGroup.controls.password.value,
            this.formGroup.controls.role.value,
            this.formGroup.controls.description.value,
            this.formGroup.controls.email.value,
            this.formGroup.controls.firstname.value,
            this.formGroup.controls.lastname.value,
            this.formGroup.controls.title.value,
            this.formGroup.controls.tel.value,
            this.formGroup.controls.skills.value,
            this.formGroup.controls.organization.value,
            this.terms
          )
          .then(() => {
            this.router.navigateByUrl("/userManagement");
          }).catch((e)=>{
            console.log(e)
            this.toastr.error(e.error,"Oops");
          })
      }else{
        this.authService
        .updateUser(
            this.formGroup.controls.username.value,
            this.formGroup.controls.password.value,
            this.formGroup.controls.role.value,
            this.formGroup.controls.description.value,
            this.formGroup.controls.email.value,
            this.formGroup.controls.firstname.value,
            this.formGroup.controls.lastname.value,
            this.formGroup.controls.title.value,
            this.formGroup.controls.tel.value,
            this.formGroup.controls.skills.value,
            this.formGroup.controls.organization.value,
          this.id,
          this.terms
        )
        .then(() => {
          this.router.navigateByUrl("/userManagement");
        })
        .catch((e)=>{
          console.log(e)
          this.toastr.error(e.error,"Oops");
        })
      }
    }else{
      this.formGroup.markAllAsTouched();
    }
    
    
  }

  add(event: MatChipInputEvent): void {
    /*const input = event.input;
  const value = event.value;
  // Add our fruit
  if ((value || '').trim()) {
    this.terms.push(value.trim());
  }

  // Reset the input value
  if (input) {
    input.value = '';
  }


  this.termCtrl.setValue(null);*/
  }
  remove(fruit: string): void {
    const index = this.terms.indexOf(fruit);
    console.log(
      this.terms.map((d: any) => d.name),
      fruit
    );

    if (index >= 0) {
      this.terms.splice(index, 1);
    }
  }

  genPassword(){
    let newPassword = generate.randomPassword({length:10, characters: [generate.lower, generate.upper, generate.digits] });
    this.formGroup.patchValue({
      password: newPassword,
      confirmPassword:newPassword
    })
  }

  export(){
    if(this.formGroup.valid){

      let output = `${this.formGroup.controls.username.value}\n${this.formGroup.controls.email.value}\n${this.formGroup.controls.password.value}\n${environment.HOST}`;


      navigator.clipboard.writeText(output).then(()=> {
        console.log('Async: Copying to clipboard was successful!');
        this.toastr.success("Copy to clipboard succesful","Success");
      }, (err)=> {
        console.error('Async: Could not copy text: ', err);
        this.toastr.error("Could not copy to clipboard","Error");
      });
    }else{
      this.toastr.error("To export complete the form first","Invalid form");
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    console.log(event.option.value);
    this.terms.push(event.option.value);
    this.termInput.nativeElement.value = "";
    this.termCtrl.setValue(null);
  }

  private _filter(value): string[] {
    const filterValue = value.toLowerCase();

    return this.allTerms.filter(
      (fruit: any) => fruit.name.toLowerCase().indexOf(filterValue) === 0
    );
  }
  change(e) {
    console.log(e);
  }

  
}
export function checkSamePasswordValidator(): ValidatorFn {
  return (form: FormGroup): ValidationErrors | null => {
    console.log("kek validator",form)
    const passwordTest = form.value.password !== form.value.confirmPassword;
    console.log( passwordTest ? {passwordsUnequal: true} : null)
    return passwordTest ? {passwordsUnequal: true} : null;
  };
}