import { createReducer, on } from '@ngrx/store';
import { requestReindexCForm, requestReindexCFormFinished } from '../actions/news.actions';


export const initialState = {
  inProgress: false,
  failed: false
};

const _reindexStateReducer = createReducer(initialState,
    on(requestReindexCForm, (state, action) => {
        return {inProgress: true, failed: false};
    }),
    on(requestReindexCFormFinished, (state, action) => {
      return {inProgress: false, failed: action.failed};
  })
  );

export function reindexStateReducer(state, action) {
    return _reindexStateReducer(state, action);
  }
