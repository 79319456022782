// Search
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { NgxSpinnerService } from "ngx-spinner";
import { of } from "rxjs";
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import {
  changePage,
  genericError,
  requestMentionsGraph,
  requestMentionsGraphSuccess,
  requestStatsNews24h,
  searchRequest,
  statsNews24hGeomapSuccess,
  statsNews24hSuccess,
  requestAnalyzer,
  analyzerSliderSuccess,
  analyzerGeomapSuccess,
  requestPermissions,
  requestPermissionsSucess,
  requestStatsTagUsageCount,
  statsTagUsageCountSuccess,
  requestReindexCForm,
  genericNotification,
  requestReindexCFormFinished,
  requestGeneralStats,
  generalStatsSuccess,
  requestLogEntrys,
  logEntrysReceived,
} from "../actions/news.actions";
import { SearchParameter } from "../models/search-parameter";
import { MentionsService } from "../services/mentions.service";
import { StatsService } from "../services/stats.service";
import { MiscService } from "../services/misc.service";
import { RoleService } from "../services/role.service";

@Injectable()
export class MiscEffects {
  requestMentionsGraph$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestMentionsGraph),
      tap((data) => console.log("DATA IN EFF", data)),
      withLatestFrom(this.store.select((state) => state.currentSearch)),
      switchMap(([action, search]) =>
        this.mentionsService.getStatsMentions(search, action.id).pipe(
          tap((data) => console.log("________----", data)),
          switchMap((stats) => [requestMentionsGraphSuccess({ data: stats })]),
          catchError((err) => {
            console.log("ERR", err);
            return of(genericError({ error: err.message }));
          })
        )
      )
    )
  );

  changePage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changePage),
      withLatestFrom(this.store.select((state) => state.currentSearch)),
      // tap((data) => this.spinner.show()),
      map(([action, storeState]) => {
        const combined: any = { ...storeState, ...action };
        delete combined.type;
        return searchRequest({ request: combined });
      })
    )
  );

  loadStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestStatsNews24h),
      tap((data) => console.log("GEO STATS REq", data)),
      switchMap((dat) =>
        this.statsService.requestStats(dat.resolution, dat.startDate, dat.endDate).pipe(
          tap((data) => console.log("________----", data)),
          switchMap((stats) => [
            statsNews24hSuccess({ news: stats }),
            statsNews24hGeomapSuccess({ news: stats.geohash }),
          ]),
          catchError((err) => {
            console.log("ERR", err);
            return of(genericError({ error: err }));
          })
        )
      )
    )
  );

  loadTagUsageCount$ = createEffect(() =>
  this.actions$.pipe(
    ofType(requestStatsTagUsageCount),
    tap((data) => console.log("TAG COUNT REq", data)),
    switchMap((dat) =>
      this.statsService.tagUsageCount().pipe(
        tap((data) => console.log("________----", data)),
        switchMap((stats) => [
          statsTagUsageCountSuccess({result: stats})
        ]),
        catchError((err) => {
          console.log("ERR", err);
          return of(genericError({ error: err }));
        })
      )
    )
  )
);


loadGeneralStats$ = createEffect(() =>
this.actions$.pipe(
  ofType(requestGeneralStats),
  tap((data) => console.log("General Stats for", data)),
  switchMap((dat) =>
    this.statsService.requestGeneralStats(dat.timestamp).pipe(
      tap((data) => console.log("General Stats", data)),
      switchMap((stats) => [
        generalStatsSuccess({result: stats})
      ]),
      catchError((err) => {
        console.log("ERR", err);
        if (err && err.error && err.error.message) 
          return of(genericError({ error: err.error.message }))
        else 
          return of(genericError({ error: err.message }));
      })
    )
  )
)
);


  loadStatsAnalyzer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestAnalyzer),
      tap((data) => console.log("GEO STATS REq", data)),
      switchMap((dat) =>
        this.statsService
          .requestAnalyzerStats(dat.resolution, dat.fromDate, dat.toDate)
          .pipe(
            tap((data) => console.log("________----", data)),
            switchMap((stats) => [
              analyzerSliderSuccess({ news: stats }),
              analyzerGeomapSuccess({ news: stats.geohash }),
            ]),
            catchError((err) => {
              console.log("ERR", err);
              return of(genericError({ error: err }));
            })
          )
      )
    )
  );

  requestPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestPermissions),
      switchMap((role) =>
        this.roleService.getRoleByName(role.role).pipe(
          map((permissions: any) =>
            requestPermissionsSucess({ permissions })
          ),
          catchError((err) => {
            console.log("ERR", err);
            return of(genericError({ error: err }));
          })
        )
      )
    )
  );


  reindexCForm$ = createEffect(() =>
  this.actions$.pipe(
    ofType(requestReindexCForm),
    switchMap((action) => {
      return this.miscService.reindexCForm().pipe(
        tap((data) => console.log("________----", data)),
        switchMap((data) => [
          genericNotification({info: "cForm reindexation started. Exporting: " + data.count + " News"}),
          requestReindexCFormFinished({failed: false})
        ]),
        catchError((err) => {
          console.log("ERR", err);
          if (err && err.error && err.error.message) 
            return of(genericError({ error: err.error.message }), requestReindexCFormFinished({failed: true}))
          else 
            return of(genericError({ error: err.message }), requestReindexCFormFinished({failed: true}));
        })
      );}
    )
  )
);

requestLogentrys$ = createEffect(() =>
this.actions$.pipe(
  ofType(requestLogEntrys),
  switchMap((action) => {
    return this.miscService.requestLogentrys(action).pipe(
      tap((data) => console.log("Request LogEntrys: ", action, "Result: ", data)),
      switchMap((data) => [logEntrysReceived({result: data})
      ]),
      catchError((err) => {
        console.log("ERR", err);
        if (err && err.error && err.error.message) 
          return of(genericError({ error: err.error.message }))
        else 
          return of(genericError({ error: err.message }));
      })
    );}
  )
)
);


  constructor(
    private actions$: Actions,
    private mentionsService: MentionsService,
    private statsService: StatsService,
    private spinner: NgxSpinnerService,
    private roleService: RoleService,
    private miscService: MiscService,
    private store: Store<{ currentSearch: SearchParameter }>
  ) {}
}
