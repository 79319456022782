<div id="wrapper">
    <div id="spacer"></div>
    <h1>Statistics</h1>

    
       <!-- <mat-card style="height:30em;margin-bottom:4em;" class="mat-elevation-z1,card" >
            <div class="card-header">Incidents</div>
                <mat-card-content  style="width:inherit;height:inherit" class="card-text">
            <app-map></app-map>
        </mat-card-content>
        </mat-card>-->
    
        <mat-card class="mat-elevation-z1,card" gdArea="side">
            <div class="card-header">News count by domain in the last 24h</div>
            <mat-card-content  style="width:100%;height:100%" class="card-text">
        <canvas style="width:10em;height:20em;" baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins">
        </canvas>
        </mat-card-content>
    </mat-card>

   <!--<mat-card class="mat-elevation-z1,card" gdArea="side">
        <div class="card-header">Mentions Count per EventCode in the last 24h</div>
        <mat-card-content  style="width:100%;height:100%" class="card-text">
    <canvas style="width:10em;height:20em;" baseChart [datasets]="lineChartData2" [labels]="lineChartLabels2" [options]="lineChartOptions2" [colors]="lineChartColors2" [legend]="lineChartLegend2" [chartType]="lineChartType2" [plugins]="lineChartPlugins2">
    </canvas>
    </mat-card-content>
</mat-card>--> 
        
</div>