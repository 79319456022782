import { Injectable, OnInit } from '@angular/core';
import { async } from '@angular/core/testing';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import {  Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';
import { take } from 'rxjs/operators';
import { requestPermissions } from '../actions/news.actions';
import { State } from '../reducers';
import { AuthService } from './auth.service';
import { RoleService } from './role.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate,OnInit {
  constructor(public auth: AuthService, public router: Router, private store:Store<State>,private roleService:RoleService){
    
  }

  


  ngOnInit(){
    console.log("auth on")
    
  }

  permissionDenied(res){
    this.router.navigate(['permissionError']);
    res(false);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise((res)=>{
        (async ()=>{
          this.auth.isAuthenticated().then(async (data)=>{
            if(!data){
              this.router.navigate(['login']);
              res(false);
            }else{
              let username = this.auth.username;
              //Check permission
              try{
                var userPerms:any = (await this.store.pipe(take(1)).toPromise()).permissions;
                this.store.dispatch(requestPermissions({ role: this.auth.getRole() }));
              }catch(e){
                this.router.navigate(['login']);
                res(false);
              }
              



              console.log("userPerms",userPerms,state.url)
  
              let url = state.url;
              let pathSegment = url;
  
              if(url.indexOf("/",1)!=-1){
                pathSegment = pathSegment.substring(0,url.indexOf("/",1));
              }
              if(url.indexOf("?")!=-1){
                pathSegment = pathSegment.substring(0,url.indexOf("?"));
              }
              
  
  
              switch(pathSegment){
                case "/dashboard":
                  if(!userPerms.canViewDashboard)this.permissionDenied(res);
                break;
                case "/videoManager":
                  if(!userPerms.canAccessVideoDownloader)this.permissionDenied(res);
                break;
                case "/news":
                  if(!userPerms.canSearch){
                    
                  }
                break;
                case "/newsEditor":
                  if(!userPerms.canSearch)this.permissionDenied(res);
                break;
                case "/newsEditor":
                  if(!userPerms.canSearch)this.permissionDenied(res);
                break;
                case "/categories":
                  if(!userPerms.canCreateTags)this.permissionDenied(res);
                break;
                case "/reports":
                  if(!userPerms.canAddEntryToReport)this.permissionDenied(res);
                break;
                case "/userManagement":
                  if(!userPerms.canViewUsers)this.permissionDenied(res);
                break;
                case "/roleManagement":
                  if(!userPerms.canManageRoles)this.permissionDenied(res);
                break;
                case "/analyzer":
                  if(!userPerms.canAccessAnalyzer)this.permissionDenied(res);
                break;
                case "/userEditor":
                  if(!userPerms.canCreateUsers)this.permissionDenied(res);
                break;
                case "/roleEditor":
                  if(!userPerms.canManageRoles)this.permissionDenied(res);
                break;
                case "/sourceManagement":
                  if(!userPerms.canCreateMappings)this.permissionDenied(res);
                break;
                case "/userEditor":
                  if(!userPerms.canCreateUsers)this.permissionDenied(res);
                break;
                case "/siteManagement":
                  if(!userPerms.canAccessSiteManagement)this.permissionDenied(res);
                break;
                case "/tagEdit":
                  if(!userPerms.canCreateTags)this.permissionDenied(res);
                break;
              }
  
              res(true)
            }
          })
        })();
        

      });
      
  }
  
}
