import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { requestDocumentLanguages, requestReindexCForm, saveDocumentLanguages } from 'src/app/actions/news.actions';
import { State } from 'src/app/reducers';
import { StatsService } from 'src/app/services/stats.service';

@UntilDestroy()
@Component({
  selector: 'app-site-management',
  templateUrl: './site-management.component.html',
  styleUrls: ['./site-management.component.css']
})
export class SiteManagementComponent implements OnInit, AfterViewInit {

  constructor(private store: Store<State>, private dialog: MatDialog) {
    this.store.dispatch(requestDocumentLanguages());
  }

  languages = new MatTableDataSource<any>([]);
  sourceSelection = new SelectionModel<any>(true, []);
  documentSelection = new SelectionModel<any>(true, []);
  lastLanguageFilter = "";

  reindexState$: Observable<any> = this.store.select(
    (state) => state.reindexState
  );

  languages$: Observable<any[]> = this.store.select(
    (state) => state.translationLanguages
  );


  @ViewChild("pagi1") paginator1: MatPaginator;

  reindexCForm() {
    const dialogRef = this.dialog.open(ReIndexDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      //close dialog ref
      if(result){
        this.store.dispatch(requestReindexCForm());
      }
  });
  }

  ngOnInit(): void {
    this.store.dispatch(requestDocumentLanguages());
  }

  ngAfterViewInit() {
    this.languages$.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(data => {
      this.languages = new MatTableDataSource<any>([...data]);
      this.languages.paginator = this.paginator1;
      this.sourceSelection = new SelectionModel<any>(true, [...data.filter(e => e.languageSource)]);
      this.documentSelection = new SelectionModel<any>(true, [...data.filter(e => e.languageDocument)]);
      this.applyFilter({target: {value: this.lastLanguageFilter}});
    });
    
  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.languages.filter = filterValue.trim().toLowerCase();
    this.lastLanguageFilter = filterValue;
    if (this.languages.paginator && event.hasOwnProperty("isTrusted")) {
      this.languages.paginator.firstPage();
    }
  }

  async updateLanguages() {
    let neu: any[] = await this.languages$.pipe(take(1), map((data) => {
      return data.map(r => {
        return {iso: r.iso, id: r.id, name: r.name, languageSource: this.sourceSelection.isSelected(r), languageDocument: this.documentSelection.isSelected(r)};
      });
    })).toPromise();
    this.store.dispatch(saveDocumentLanguages({languages: neu}));
  }


  isAllSelectedSource() {
    const numSelected = this.sourceSelection.selected.length;
    const numRows = this.languages.data.length;
    return numSelected === numRows;
  }

  isAllSelectedDocument() {
    const numSelected = this.documentSelection.selected.length;
    const numRows = this.languages.data.length;
    return numSelected === numRows;
  }

  masterToggleSource() {
    if (this.isAllSelectedSource()) {
      this.sourceSelection.clear();
      return;
    }
    this.sourceSelection.select(...this.languages.data);
    this.updateLanguages();
  }

  masterToggleDocument() {
    if (this.isAllSelectedDocument()) {
      this.documentSelection.clear();
      return;
    }
    this.documentSelection.select(...this.languages.data);
    this.updateLanguages();
  }

}

@Component({
  selector: "reindex-diag",
  template: `
    <div mat-dialog-content>
      <p class="mat-h4">Do you really want to delete ALL cForm exports and export them anew from OSDP? Could lead to dataloss on failure.</p>
    </div>
    <div mat-dialog-actions>
      <button
        mat-button
        color="warn"
        (click)="onClick(true)"
        cdkFocusInitial
      >
        ReIndex NOW
      </button>&nbsp;
      <button mat-button (click)="onClick(false)">
        Abort
      </button>
    </div>
  `,
})
export class ReIndexDialogComponent {
  factor;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ReIndexDialogComponent>
  ) {}

  onClick(wantDeleted: boolean): void {
    this.dialogRef.close(wantDeleted);
  }
}