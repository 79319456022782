<mat-card class="mat-elevation-z1,card" >
    <div class="card-header">OSDP History
        <button color="primary" mat-raised-button aria-label="Refresh" (click)="updateLog()" >
            <span>Refresh</span>
            <mat-icon>refresh</mat-icon>
        </button>
        <mat-form-field appearance="outline" style="float: right; margin-top: -15px; margin-right: -15px; padding-left: 30px;">
            <mat-label>Category</mat-label>
            <mat-select [formControl]="category" (selectionChange)="updateLog()">
              <mat-option [value]="null">ALL/NONE</mat-option>
              <mat-option value="articles">articles</mat-option>
              <mat-option value="users">users</mat-option>
              <mat-option value="sourcemappings">sourcemappings</mat-option>
              <mat-option value="roles">roles</mat-option>
              <mat-option value="tags">tags</mat-option>
              <mat-option value="languages">languages</mat-option>
              <mat-option value="reports">reports</mat-option>
            </mat-select>
          </mat-form-field>
        <mat-form-field appearance="outline" style="float: right; margin-top: -15px; margin-right: -15px; padding-left: 30px;">
            <mat-label>Min Level</mat-label>
            <mat-select [formControl]="minLevel" (selectionChange)="updateLog()">
              <mat-option [value]="0">Show level 0 and above</mat-option>
              <mat-option [value]="1">Show level 1 and above</mat-option>
              <mat-option [value]="2">Show level 2 and above</mat-option>
              <mat-option [value]="3">Show only level 3</mat-option>
            </mat-select>
          </mat-form-field>
        <mat-form-field appearance="outline" class="selects" style="float: right; margin-top: -15px; margin-right: -15px;">
            <mat-label>Date from/to</mat-label>
            <mat-date-range-input [formGroup]="searchDateRange" [rangePicker]="picker3">
                <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="updateLog()">
                <input matEndDate formControlName="end" placeholder="End date" (dateChange)="updateLog()">
                <mat-error *ngIf="searchDateRange.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                <mat-error *ngIf="searchDateRange.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-date-range-input>

            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-date-range-picker #picker3></mat-date-range-picker>
        </mat-form-field>
    </div>
    <mat-card-content style="width:100%;height:100%;" class="card-text">
        <div class="mat-elevation-z8">
            <mat-form-field style="padding-left:1em;padding-right:1em;width:100%;height:100%;" appearance="standard">
                <mat-label>Filter</mat-label>
                <input matInput (focusout)="applyFilter($event)" (keyup.enter)="applyFilter($event)" placeholder="Ex. de" #input>
            </mat-form-field>
            <table mat-table [dataSource]="logentrys" style="width:100%;height:100%;">
        
                <!-- Level Column -->
                <ng-container matColumnDef="level">
                    <th mat-header-cell *matHeaderCellDef> Level </th>
                    <td mat-cell *matCellDef="let element"> {{element.level}} </td>
                </ng-container>

                <!-- Operator Column -->
                <ng-container matColumnDef="modul">
                    <th mat-header-cell *matHeaderCellDef> Modul </th>
                    <td mat-cell *matCellDef="let element"> {{element.modul}} </td>
                </ng-container>

                <!-- Operator Column -->
                <ng-container matColumnDef="operator">
                    <th mat-header-cell *matHeaderCellDef> Operator </th>
                    <td mat-cell *matCellDef="let element"> {{element.operator}} </td>
                </ng-container>

                <!-- Message Column -->
                <ng-container matColumnDef="message">
                <th mat-header-cell *matHeaderCellDef> Message </th>
                <td mat-cell *matCellDef="let element"> {{element.message.substring(0, 80)}} <ng-container *ngIf="element.message.length > 80">...</ng-container></td>
                </ng-container>
            
                <!-- Timestamp Column -->
                <ng-container matColumnDef="timestamp">
                <th mat-header-cell *matHeaderCellDef> Timestamp </th>
                <td mat-cell *matCellDef="let element"> {{element.timestamp | date :'short'}} </td>
                </ng-container>

                <!-- Timestamp Column -->
                <ng-container matColumnDef="details">
                <th mat-header-cell *matHeaderCellDef> Details </th>
                <td mat-cell *matCellDef="let element"> <button *ngIf="element.old_state || element.new_state || element.message.length > 80" mat-raised-button (click)="showDetails(element);" color="primary">Details <mat-icon>launch</mat-icon></button> </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="['level', 'modul', 'operator', 'message', 'timestamp', 'details']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['level', 'modul', 'operator', 'message', 'timestamp', 'details'];"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">Loading or no data matching the filter "{{input.value}}"</td>
                </tr>
            </table>
        
            <mat-paginator #pagi [length]="this.total" [pageSize]="this.size" [pageSizeOptions]="[5, 10, 20, 50, 100, 200, 500]"
                [pageIndex]="this.from" (page)="updatePage($event)"
                aria-label="Select page of log entrys" showFirstLastButtons >
            </mat-paginator>
            <p style="margin: 0px;">* WARNING: OSDP History can only have 10k results per search max. Use Min/Max Date to shift the window of which 10k results are returned.</p>
          </div>
    </mat-card-content>
</mat-card>