// Search
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { genericError, requestFactors, requestFactors2, requestFactors2Success, requestFactorsSuccess, saveFactors, saveFactorsSuccess } from '../actions/news.actions';
import { TagService } from '../services/tag.service';



@Injectable()
export class FactorsEffects {

      

  // Factors
 
    requestFactors$ = createEffect(() => this.actions$.pipe(
      ofType(requestFactors),
      switchMap(() => this.factorsService.requestFactors().pipe(
        map((tags) => requestFactorsSuccess({tags})),
        catchError((err) => {
          console.log('ERR', err);
          return of(genericError({error:err}));
        })
      )),
      ));
  
    saveFactors$ = createEffect(() => this.actions$.pipe(
      ofType(saveFactors),
      tap((data) => console.log('DATA IN EFF', data)),
      switchMap((factors) => this.factorsService.saveFactors(factors.tags).pipe(
        switchMap(() => [saveFactorsSuccess(),requestFactors()]),
        catchError((err) => {
          console.log('ERR', err);
          return of(genericError({error:err}));
        })
      )),
      )
  );
  saveFactorsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(saveFactorsSuccess),
    tap(() => {
      this.toastr.success( 'Successfully updated tags!', 'Success');
    })
    ),
    {dispatch: false}
  );

    constructor(
        private actions$: Actions,
        private factorsService: TagService,
        private toastr: ToastrService,
      ) {}

}


