import { state } from "@angular/animations";
import { createReducer, on } from "@ngrx/store";
import {
  requestNews,
  newsSucess,
  addItem,
  articleSuccess,
  resetArticleForm,
  addCurrentTag,
  removeCurrentTag,
  updateArticle,
  programmaticArticleUpdate,
  checkItemSuccess,
  confirmItemSuccess,
  fileUpload,
  translateDocument,
  translateDocumentSuccess,
} from "../actions/news.actions";
import { News } from "../models/news.model";

export const initialState: News = {
  // BASE
  article_author: "",
  article_title: "",
  article_subtitle: "",
  article_content: "",
  article_source: "",
  article_domain: "",
  article_summary: "",
  article_created_at: new Date(new Date().setUTCHours(0, 0, 0)).toISOString(),
  article_geolocation: {
    lat: 0,
    lon: 0,
  },
  date: new Date().toISOString(),
  datasource: "",
  // BH
  article_raw_url: "",
  article_language: "",
  article_languageErfassung: "",
  article_place: "",
  article_tags: [],
  article_platforms: ["cyber","krimisi","innotech"],
  article_previewImage: null,
  article_checked: false,
  article_confirmed: false,
  article_created_by: "",
  article_updated_at: "",
  article_updated_by: "",
  article_log: [],
  article_files:[],
  article_content_translated:"",
  article_title_translated:"",
  article_subtitle_translated:"",
  // GDELT
  gdelt_GlobalEventID: 0,
  gdelt_Day: 0,
  gdelt_MonthYear: 0,
  gdelt_Year: 0,
  gdelt_FractionDate: 0,
  gdelt_Actor1Code: "",
  gdelt_Actor1Name: "",
  gdelt_Actor1CountryCode: "",
  gdelt_Actor1KnownGroupCode: "",
  gdelt_Actor1EthnicCode: "",
  gdelt_Actor1Religion1Code: "",
  gdelt_Actor1Religion2Code: "",
  gdelt_Actor1Type1Code: "",
  gdelt_Actor1Type2Code: "",
  gdelt_Actor1Type3Code: "",
  gdelt_Actor2Code: "",
  gdelt_Actor2Name: "",
  gdelt_Actor2CountryCode: "",
  gdelt_Actor2KnownGroupCode: "",
  gdelt_Actor2EthnicCode: "",
  gdelt_Actor2Religion1Code: "",
  gdelt_Actor2Religion2Code: "",
  gdelt_Actor2Type1Code: "",
  gdelt_Actor2Type2Code: "",
  gdelt_Actor2Type3Code: "",
  gdelt_IsRootEvent: "",
  gdelt_EventCode_name: "",
  gdelt_EventCode_rating: "",
  gdelt_EventBaseCode_name: "",
  gdelt_EventBaseCode_rating: "",
  gdelt_EventRootCode_name: "",
  gdelt_EventRootCode_rating: "",
  gdelt_QuadClass: 0,
  gdelt_GoldsteinScale: 0,
  gdelt_NumMentions: 0,
  gdelt_NumSources: 0,
  gdelt_NumArticles: 0,
  gdelt_AvgTone: "",
  gdelt_Actor1Geo_Type: "",
  gdelt_Actor1Geo_Fullname: "",
  gdelt_Actor1Geo_CountryCode: "",
  gdelt_Actor1Geo_ADM1Cod$: "",
  gdelt_Actor1Geo_ADM2Code: "",
  gdelt_Actor1Geo_Lat: 0,
  gdelt_Actor1Geo_Long: 0,
  gdelt_Actor1Geo_FeatureID: "",
  gdelt_Actor2Geo_Type: "",
  gdelt_Actor2Geo_Fullname: "",
  gdelt_Actor2Geo_CountryCode: "",
  gdelt_Actor2Geo_ADM1Code: "",
  gdelt_Actor2Geo_ADM2Code: "",
  gdelt_Actor2Geo_Lat: 0,
  gdelt_Actor2Geo_Long: 0,
  gdelt_Actor2Geo_FeatureID: "",
  gdelt_ActionGeo_Type: "",
  gdelt_ActionGeo_Fullname: "",
  gdelt_ActionGeo_CountryCode: "",
  gdelt_ActionGeo_ADM1Code: "",
  gdelt_ActionGeo_ADM2Code: "",
  gdelt_ActionGeo_Lat: 0,
  gdelt_ActionGeo_Long: 0,
  gdelt_ActionGeo_FeatureID: "",
  gdelt_DATEADDED: "",
  gdelt_SOURCEURL: "",
  article_filesID: ""
};

const _articleReducer = createReducer(
  initialState,
  on(articleSuccess, (state, action) => {
    return { ...action.news, article_platforms:["cyber","krimisi","innotech"] };
  }),
  on(updateArticle, (state, action) => {
    const plats =  [];
    /*
    if(action.article_platforms[0])
      plats.push("cyber");
    if(action.article_platforms[1])
      plats.push("innotech");
    if(action.article_platforms[2])
      plats.push("krimisi");*/

    return { ...state, ...action, article_platforms:["cyber","krimisi","innotech"] };
  }),
  on(programmaticArticleUpdate, (state, action) => {
    return { ...state, ...action };
  }),
  on(resetArticleForm, (state, action) => {
    return initialState;
  }),
  on(addCurrentTag, (state, action) => {
    return {
      ...state,
      article_tags: [
        ...state.article_tags.filter((tag) => tag !== action.id),
        action.id,
      ],
    };
  }),
  on(removeCurrentTag, (state, action) => {
    return {
      ...state,
      article_tags: [...state.article_tags.filter((tag) => tag !== action.id)],
    };
  }),
  on(checkItemSuccess, (state:any, action) => {
    if(state.id==action.id)
      return {...state,article_checked:action.state, article_log: [...state.article_log, {type: "checked", state:action.state, operator: action.operator, timestamp: new Date().toISOString()}]}
    return state;
  }),
  on(confirmItemSuccess, (state:any, action) => {
    if(state.id==action.id)
      return {...state,article_confirmed:action.state}
    return state;
  }),
  on(fileUpload, (state:any, action) => {
    console.log({...state,article_files:[...new Set([...state.article_files,action.name])]})
      return {...state,article_files:[...new Set([...state.article_files,action.name])]}
  }),
  on(translateDocumentSuccess, (state:any, action) => {
    let newObj = {
        ...state,
        article_content_translated:action.content,
        article_title_translated: action.title,
        article_subtitle_translated: action.subtitle,
    };
    if(action.language){
      newObj = {...newObj,
      article_language: action.language,
      article_languageErfassung: "en"
      }
    }
    return newObj;
  }),
);

export function articleReducer(state, action) {
  return _articleReducer(state, action);
}
