import { createReducer, on } from "@ngrx/store";
import { analyzerSliderSuccess } from "../actions/news.actions";
import { NewsCompound } from "../models/news-stats.model";

export const initialState: any = {
  aggs: [],
  urlTime: [],
  geohash: {
    push: [],
    pull: [],
    undefined: [],
  },
};

const _statsAnalyzerReducer = createReducer(
  initialState,
  on(analyzerSliderSuccess, (state, action) => {
    console.log("test", action); // urlTime:[...action.news.urlTime]
    return {
      aggs: [...action.news.aggs],
      geohash: { ...action.news.geohash },
      urlTime: [],
    };
  })
);

export function statsAnalyzerReducer(state, action) {
  return _statsAnalyzerReducer(state, action);
}
