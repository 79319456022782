import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  Inject,
  AfterViewChecked, Input, ChangeDetectionStrategy
} from "@angular/core";
import { ActivatedRoute, Router, NavigationStart } from "@angular/router";
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";
import { NewsService } from "../../services/news.service";
import { zoomIdentity, stratify } from "d3";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { ElementRef, ViewChild } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable, BehaviorSubject, Subject, of } from "rxjs";
import {
  map,
  startWith,
  filter,
  take,
  debounceTime,
  distinctUntilChanged,
  withLatestFrom,
  switchMap,
  throttleTime,
} from "rxjs/operators";

import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { ChromeService } from "../../services/chrome.service";
import { TagTreeComponent } from "../tag-tree/tag-tree.component";
import { environment } from "../../../environments/environment";
import { AuthService } from "src/app/services/auth.service";
import { Tag } from "src/app/models/tags.model";
import { Store, select } from "@ngrx/store";
import { State } from "src/app/reducers";
import { MappingService } from "src/app/services/mapping.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  requestArticle,
  resetArticleForm,
  deleteItem,
  addItem,
  articleSuccess,
  genericNotification,
  updateArticle,
  setItem,
  loadReports,
  updateItem,
  removeCurrentTag,
  addCurrentTag,
  updateEditorMap,
  programmaticArticleUpdate,
  openEditorMap,
  closeEditorMap,
  checkItem,
  uncheckItem,
  confirmItem,
  unconfirmItem,
  translateDocument,
  genericError,
  checkURLDuplicatesOnCreate,
  duplicateURLError,
  disableCreateButton,
  enableCreateButton,
  requestDocumentLanguages
} from "../../actions/news.actions";
import { selectActive, selectCAMEO, selectCDRC } from "src/app/selectors/tag.selector";
import { News } from "src/app/models/news.model";
import { NewsLogEntry } from "src/app/models/news-logentry.model";
import { NgxSpinnerService } from 'ngx-spinner';
import { Actions, ofType } from "@ngrx/effects";
import { MiscService } from "src/app/services/misc.service";
import { v4 as uuidv4 } from "uuid";
import { EditorMapComponent } from "../news-editor/editor-map/editor-map.component";
import { ScrappingService } from "src/app/services/scrapping.service";
import { EditorMapModalWrapperComponent } from "../news-editor/editor-map-modal-wrapper/editor-map-modal-wrapper.component";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { DatePipe } from '@angular/common';

declare var chrome: any;
@UntilDestroy()
@Component({
  selector: "app-editor",
  templateUrl: "./editor.component.html",
  styleUrls: ["./editor.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorComponent implements OnInit, AfterViewChecked, OnDestroy {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private chromeService: ChromeService,
    public authService: AuthService,
    private store: Store<State>,
    private mappingService: MappingService,
    private miscService: MiscService,
    private spinnerService: NgxSpinnerService,
    private actions$: Actions,
    private scrapeService: ScrappingService,
    private newsService: NewsService
  ) {
    this.isExtension = environment.extension;
  }
  public Editor = ClassicEditor;
  public Editor2 = ClassicEditor;

  platforms = [
    {value:"cyber",name:"Cyber"},
    {value:"innotech", name:"Innotech"},
    {value:"krimisi",name:"KriMiSi"}
  ];

  inputForm = new FormGroup({
    article_source: new FormControl(),
    article_title: new FormControl(),
    article_subtitle: new FormControl(),
    article_content: new FormControl(""),
    article_author: new FormControl(),
    article_created_at: new FormControl(),
    article_language: new FormControl(),
    article_languageErfassung: new FormControl(),
    article_place: new FormControl(),
    article_confirmed: new FormControl(),
    article_checked: new FormControl(),
    article_platforms: new FormArray([
      new FormControl(true),
      new FormControl(false),
      new FormControl(false)
    ]),
    lat:new FormControl({disabled:true,value:0}),
    lon: new FormControl({disabled:true,value:0}),
    article_content_translated:new FormControl(""),
    article_title_translated:new FormControl(),
    article_subtitle_translated:new FormControl(),
  });
  get article_platforms() {
    return this.inputForm.get('article_platforms') as FormArray;
  }

  externalExtract = false;
  disableSave = false;
  // Inputs
  isExtension;

  // vars

  newArticle = false;
  tags = [];
  lockTags;
  public maxDate = new Date(new Date().setHours(23, 59, 59));

  // Readonly(won't be saved)
  news;

  filesID;
  

  isGDELT = environment.ENABLE_GDELT;

  // Vars for tags
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  termCtrl = new FormControl();
  filteredTermsSub: any = new BehaviorSubject([
    {
      id: "Tags Test",
      platform: "cyber",
    },
  ]);

  savedSelection;
  savedSelection2;

  filteredTermsByPlatform: any = [];
  terms = [];
  allTerms = [];
  tagTreeDirty = false;
  ready = false;

  tagBuffer = new Set();
  embedded = false;

  displayPreview = false;
  previewData = null;
  previewWidth = "100";
  previewHeight = "100";
  zoomToggle = false;
  subs = [];
  init = false;


  languages = [];
  languagesSource = [];
  languagesDocument = [];


  reports$: Observable< any[]> = this.store.select(state => state.reports.reports);

  createButtonDisabled$: Observable< any[]> = this.store.select(state => state.editorState.createButtonDisabled);

  availableTags$: Observable<Tag[]> = this.store.pipe(select(selectActive));
  refresh$: Observable<any> = this.store.select(state => state.reports.refresh);
  article$: Observable<News> = this.store.select(
    (state) => state.currentArticle
  );

  translationLanguages$: Observable<any[]> = this.store.select(
    (state) => state.translationLanguages
  );

  permissions$: Observable<any> = this.store.select(
    (state) => state.permissions
  );

  clearSub = new Subject();
  actSub;


  lat = 0;
  lon = 0;

  id;

  displayTags: string[];
  rawDetailTags: any[];
  //stringTagsToUID: Map<string, number>;

  articleLogHistory?; 

 @Input() isNew = false;

 openMap$ = this.store.select((store)=>store.mapSettings.openMap);
    
  

  @ViewChild("termInput") termInput: ElementRef;
  @ViewChild("historyPaginator") historyPaginator: MatPaginator;

  gdeltToArray() {
    return Object.entries(this.news).filter(([key, value])=>key.includes("gdelt")).map(([key, value]) => ({key, value}));
  }
  deleteData() {
    // TODO
    /*
    this.store.dispatch(
      deleteItem({
        id: this.news.id,
      })
    );
    */
  }
  deleteReport(id){
  
    const sub = this.refresh$.subscribe(() => {
      if(this.init){
        this.store.dispatch(loadReports());
        console.log("fresh");
        this.spinnerService.hide();
        this.init = false;
      }
    })
    this.subs.push(sub);
    
    console.log("delete report")

    this.store.dispatch(setItem({ id, operation: false }));
    this.spinnerService.show();
    this.init = true;
  
  
  
  }
  addToReport(id) {
    console.log("add report")
    this.store.dispatch(setItem({id, operation: true}));
  }
  checkReport(id,reports){
    // console.log("report chedk")
    return reports.map((e)=>e.id).includes(id);
  }

  translate(){
    this.store.dispatch(translateDocument())
  }

  onCKReady(editor){
      // CKEDITOR hack that saves the carret position on new data
      editor.model.document.on("change:data",( change => {
        const selection = editor.model.document.selection.getFirstPosition();
        if(!(selection.path[0]==0 && selection.path[1] ==0)){
          this.savedSelection = selection;
        }else if(this.savedSelection){
          editor.model.change((writer)=>{
            if(this.savedSelection,editor.getData()){
              const pos = writer.createPositionFromPath( editor.model.document.getRoot(), this.savedSelection.path );
              
              // Check if path is valid
              try{
                const pa = [...pos.path];
                let el = pa.shift();
                let head = editor.model.document.getRoot();

                while(el!=undefined){
                  if(head._children){
                    const index = head.offsetToIndex(el);
                    head = [...head.getChildren()][index];
                  } 
                  el =  pa.shift();
                }
              }catch(e){
                return;
              } 
              
              // Only if path is valid set it
              try{
                writer.setSelection(pos );
              }catch(e){
                console.log("myerror",e)
              }
              
            }
              
          })
        } 
     } ));
  }

  onCKReady2(editor){
    // CKEDITOR hack that saves the carret position on new data
    editor.model.document.on("change:data",( change => {
      const selection = editor.model.document.selection.getFirstPosition();
      if(!(selection.path[0]==0 && selection.path[1] ==0)){
        this.savedSelection2 = selection;
      }else if(this.savedSelection2){
        editor.model.change((writer)=>{
          if(this.savedSelection2,editor.getData()){
            const pos = writer.createPositionFromPath( editor.model.document.getRoot(), this.savedSelection2.path );
            
            // Check if path is valid
            try{
              const pa = [...pos.path];
              let el = pa.shift();
              let head = editor.model.document.getRoot();

              while(el!=undefined){
                if(head._children){
                  const index = head.offsetToIndex(el);
                  head = [...head.getChildren()][index];
                } 
                el =  pa.shift();
              }
            }catch(e){
              return;
            } 
            
            // Only if path is valid set it
            try{
              writer.setSelection(pos );
            }catch(e){
              console.log("myerror",e)
            }
            
          }
            
        })
      } 
   } ));
}

  ngOnInit() {
    this.store.dispatch(resetArticleForm());
    this.store.dispatch(requestDocumentLanguages());
    this.ready = true;

    ClassicEditor.create(document.querySelector("#editor")).catch((error) => {
      // console.log(error);
    });

    this.translationLanguages$.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(data => {
      this.languages = data;
      this.languagesDocument = [];
      this.languagesSource = [];
      data.forEach(lang => {
        if (lang.languageSource) {
          this.languagesSource.push(lang);
        }
        if (lang.languageDocument) {
          this.languagesDocument.push(lang);
        }
      });
    });

    this.isExtension = environment.extension;
    this.actSub = this.activatedRoute.params
      .pipe( untilDestroyed(this))
      .subscribe((params) => {
        console.log("router", params);
        if (params.id == "new") {
          // this.resetForm();
          this.newArticle = true;
          this.store.dispatch(resetArticleForm());
        } else if(params.id) {
          this.newArticle = false;
          console.log("i am requesting")
          this.store.dispatch(
            requestArticle({
              id: params.id,
            })
          );
          this.id = params.id;
        }else{
          this.newArticle = this.isNew;
          this.embedded=true;
          console.log("here we go again");

        }
      });



    // Initialize the store when new data is arriving
    this.article$.pipe(untilDestroyed(this),distinctUntilChanged(),withLatestFrom(this.store.select("defaultPlattform")), withLatestFrom(this.store.select("tagMapping"))).subscribe(([[article,plattform], tagMap]) => {
      
      console.log("article", article);
      let {
        article_checked,
        article_confirmed,
        article_author,
        article_content,
        article_subtitle,
        article_title,
        article_source,
        article_created_at,
        article_language,
        article_languageErfassung,
        article_place,
        article_platforms,
        article_geolocation,
        article_filesID,
        article_content_translated,
        article_title_translated,
        article_subtitle_translated,
        article_log,
        article_tags
      } = article;

      this.filesID = article_filesID;
      //Generate new fileID if not exisitng
      if(!article_filesID){
        this.filesID = article_filesID || uuidv4();
        this.store.dispatch(programmaticArticleUpdate({...article as any,article_filesID:this.filesID}))
      }

      article_language = article_language || "en";
      article_languageErfassung = article_languageErfassung || "en";
      article_place = article_place || "";

    //  if((!article_platforms || article_platforms.length==0)&&!(article as any).id){
        // TODO move to effects
        article_platforms = ["cyber","innotech","krimisi"];
       // this.store.dispatch(programmaticArticleUpdate({...article as any,article_platforms}))
     // }


      if (article_log) {
        this.articleLogHistory = new MatTableDataSource<NewsLogEntry>([...article_log].reverse());
        if (this.historyPaginator) {
          this.articleLogHistory.paginator = this.historyPaginator;
        }
      } else {
        this.articleLogHistory = undefined;
      }

      if (article_checked) {
        this.inputForm.disable({onlySelf: false, emitEvent: false});
        this.inputForm.controls.article_checked.enable({onlySelf: false, emitEvent: false});
        this.inputForm.controls.article_confirmed.enable({onlySelf: false, emitEvent: false});
        this.store.dispatch(disableCreateButton());
      } else {
        this.inputForm.enable({onlySelf: false, emitEvent: false});
        if (!article_confirmed)
          this.inputForm.controls.article_confirmed.disable({onlySelf: false, emitEvent: false});
        this.store.dispatch(enableCreateButton());
      }

      // Beginn TAG UID to String ID handling.
      try {
        this.rawDetailTags = [];
        article_tags.forEach(uid => {
          let ret = tagMap.get(uid);
          if (ret) this.rawDetailTags.push(ret);
        });

        // Copy to prevent sorting of source object.
        let sorti = [...this.rawDetailTags].sort((x, y) => {
          let sX = x.id.split("\t");
          let sY = y.id.split("\t");
          let lastI = Math.min(sX.length, sY.length);

          let equals = true;
          for (let i = 0; i < lastI-1; i++) {
            if (sX[i] != sY[i]) {
              equals = false;
              break;
            } 
          }

          if (equals) 
            return sX.length - sY.length;
          else
            return x.id.localeCompare(y.id);
        });

        let all_seperate_tags = new Set<any>();
        for (let tag of sorti) {
          let current = "";
          let uid = tag.uid;
          for (let part of tag.id.split("\t")) {
            current += part;
            if (!part.startsWith("Tag")) {
              if (current == tag.id) {
                all_seperate_tags.add({id: current, uid: uid, color: this.getTagPreviewColor(tag, tagMap)});
              } else {
                all_seperate_tags.add({id: current, color: ''});
              }
            }
            current += "\t";
          }
        }
        this.displayTags = Array.from(all_seperate_tags).filter((value, index, self) => {
          let found = index === self.findIndex((t) => (
            t.id === value.id && t.uid
          ))
          if (found) return true;
          return index === self.findIndex((t) => (
            t.id === value.id
          ))
          }
        );
      } catch(err) {
        console.warn(err);
      }
      // End TAG Handling


      const hasCyber = article_platforms.includes("cyber");
      const hasInnotech = article_platforms.includes("innotech");
      const hasKrimisi = article_platforms.includes("krimisi");

      const article_platforms_bools=[hasCyber,hasInnotech,hasKrimisi];
      

      

      const lat = article_geolocation.lat ?? 0;
      const lon = article_geolocation.lon ?? 0;



      this.news = article;
      this.id = this.news.id;
      this.inputForm.patchValue(
        {
          article_author,
          article_content,
          article_subtitle,
          article_title,
          article_source,
          article_created_at,
          article_language,
          article_languageErfassung,
          article_place,
          article_checked,
          article_confirmed,
          article_platforms:article_platforms_bools,
          lat,
          lon,
          article_content_translated:article_content_translated||"",
          article_title_translated,
          article_subtitle_translated
        },
        { emitEvent: false }
      );
    
    });

    // Update form when geo event
    this.actions$.pipe(ofType(updateEditorMap)).subscribe((data)=>{
     // this.inputForm.patchValue(,{emitEvent:false})
      this.store.dispatch(programmaticArticleUpdate({article_geolocation:{lat:data.lat,lon:data.lon},article_place:data.place} as any))
      //
    })


    this.actions$.pipe(ofType(articleSuccess)).subscribe((data)=>{
      this.savedSelection = null;
    })

    // Update store when the form is changed
    this.inputForm.valueChanges
      .pipe(untilDestroyed(this), debounceTime(250), distinctUntilChanged())
      .subscribe((data: News) => {
        //include uploaded files

        data.article_filesID=this.filesID;

        this.store.dispatch(updateArticle(data));
      });

    /* this.chromeService.onMessage.subscribe((value) => {
      console.log("Observer value", value);
      this.receiveMessage(value);
    });
    this.chromeService.loadExtensionConnection();*/

    // load tags

    this.availableTags$
      .pipe(
        filter((arr) => arr.length > 0),
        take(1)
      )
      .subscribe((data: any) => {
        console.log("tag change")
        this.allTerms = data.map((d) => d);
        // console.log("TAGS FROM STORE: ", this.allTerms);
        this.termCtrl.valueChanges
          .pipe(
            startWith(null),
            map((fruit: string | null) =>
              typeof fruit == 'string'
                ? this._filter(fruit)
                : this.allTerms// TODO filter for permission
            ),
            throttleTime(100, undefined, { leading: false ,trailing: true })
          )
          .subscribe(this.filteredTermsSub);
        this.filteredTermsByPlatform = [
          {
            name: "general",
            observable: this.filteredTermsSub.pipe(
              map((el: any) =>
                el.filter(
                  (e) =>
                    e.type != "innotech" &&
                    e.type != "cyber" &&
                    e.type != "krimisi"
                )
              ),
              map((element: any) => element)
            ),
          },
          {
            name: "cyber",
            observable: this.filteredTermsSub.pipe(
              map((el: any) => el.filter((e) => e.type == "cyber")),
              map((element: any) => element)
            ),
          },
          {
            name: "krimisi",
            observable: this.filteredTermsSub.pipe(
              map((el: any) => el.filter((e) => e.type == "krimisi")),
              map((element: any) => element)
            ),
          },
          {
            name: "innotech",
            observable: this.filteredTermsSub.pipe(
              map((el: any) => el.filter((e) => e.type == "innotech")),
              map((element: any) => element)
            ),
          },
        ];
      });
    // Standard platform = cyber //TODO


    this.chromeService.loadExtensionConnection();
      console.log("ed-------------",this.Editor)

    this.subs.push(this.openMap$.subscribe((data)=>{
      if(!data) return;
      console.log("map open?",data);
      const dialogRef = this.dialog.open(EditorMapModalWrapperComponent);

      dialogRef.afterClosed().subscribe(result => {
          //close dialog ref
          if(result){
            this.store.dispatch(updateEditorMap(result))
          }
          this.store.dispatch(closeEditorMap());
      });
      
    }));
   
  }
  ngOnDestroy() {
    // this.tagSub.unsubscribe();
    this.actSub.unsubscribe();
    this.subs.forEach(e=>e.unsubscribe())
  }

  extractData() {
    this.chromeService.extractData();
  }

  onLanguageSourceKey(input) {
    this.languagesSource = this.languages.filter(lang => {
      return (lang.languageSource && (lang.name.toLowerCase().startsWith(input.toLowerCase()) || lang.iso.toLowerCase().startsWith(input.toLowerCase())));
    });
  }

  onLanguageDocumentKey(input) {
    this.languagesDocument = this.languages.filter(lang => {
      return (lang.languageDocument && (lang.name.toLowerCase().startsWith(input.toLowerCase()) || lang.iso.toLowerCase().startsWith(input.toLowerCase())));
    });
  }

  async checkURLDuplicates(){
    if (this.inputForm.controls.article_source.value && this.isNew) {
      this.store.dispatch(checkURLDuplicatesOnCreate({url:this.inputForm.controls.article_source.value}));
      try {
        let url = this.inputForm.controls.article_source.value;
        var domain:any = (new URL(url));
        this.store.dispatch(programmaticArticleUpdate({...this.news, article_domain: domain.hostname}));
      } catch(e) {
        console.log(e);
        this.store.dispatch(genericError({error: "Please provide a valid source URL."}));
      }
    }
  }

  async serverSideExtract(det: any){
    if(this.news.article_source && this.news.article_source.length>0){
      const url = this.news.article_source;
      try{
        var domain:any = (new URL(url));
      }catch(e){
        genericNotification({
          info: `Invalid url`
        })
        return;
      }
      
      domain = domain.hostname;
      this.store.dispatch(
        genericNotification({
            info: `Attempt serverside extract for url: ${this.news.article_source}`,
        })
      );
      try{
          let data:any = {article_author:"Autor ungenannt",article_source:url,article_domain:domain};
          data = {...data,  ...await this.scrapeService.scrape({url: url, news: true, html: false, ...det})}; // Change HTML to true to fill content_raw
          data = {...data, article_raw_url: data.article_raw_url}
          if(!data.article_created_at){
            data = {...data,article_created_at:new Date(new Date().setUTCHours(0, 0, 0, 0)).toISOString()};
            this.store.dispatch(genericError({error:"No date can be extracted, using current date!"}))
          }
          if(!data.article_author){
            data.article_author="Autor ungenannt";
          }

          if (data.article_content) {
            data.article_content = data.article_content.replace(/(<p>(?:\s|&nbsp;)*<\/p>)+/g, "<p></p>");
          }

          if (data.article_content_translated) {
            data.article_content_translated = data.article_content_translated.replace(/(<p>(?:\s|&nbsp;)*<\/p>)+/g, "<p></p>");
          }

          if (data.article_content_translated) {
            data.article_content_translated = data.article_content_translated.replace(/<em(\s[^><]*?)?>/g, "<i>").replace(/<\/em(\s[^><]*?)?>/g, "</i>");
          }

          if (data.article_content) {
            data.article_content = data.article_content.replace(/<em(\s[^><]*?)?>/g, "<i>").replace(/<\/em(\s[^><]*?)?>/g, "</i>");
          }

          this.store.dispatch(programmaticArticleUpdate(data));
      }catch(e){
          if (e.status==401) {
            this.store.dispatch(genericError({error:"Not logged in!"}));
            window.open("https://news.cdfz.at/#/login?closeAfterLogin=true", "_blank", "popup width=800 height=700");
          }
          this.store.dispatch(
              genericError({
                  error: `Serverside scrapper failed\n${e.message}`,
              })
          );
      }
    }else{
      this.store.dispatch(
        genericError({
            error: `No url found, please enter a url first!`,
        })
      );
    }
  }

  updateData(isNew) {

    try {
      let url = this.news.article_source;
      var domain:any = (new URL(url));

      let article_content = this.news.article_content;
      if (article_content) {
        article_content = article_content.replace(/(<p[^>]*>(?:\s|&nbsp;)*<\/p[^>]*>)+/g, "<p></p>");
      }

      this.store.dispatch(programmaticArticleUpdate({...this.news, article_domain: domain.hostname, article_content: article_content}));
    } catch(e) {
      console.log(e);
      this.store.dispatch(genericError({error: "Please provide a valid source URL."}));
      return;
    }

    if (new Date(this.inputForm.controls.article_created_at.value).setHours(0, 0, 0) > new Date().setHours(23, 59, 59)) {
      console.log(new Date(this.inputForm.controls.article_created_at.value).setHours(0, 0, 0), new Date().setHours(23, 59, 59));
      this.store.dispatch(genericError({error: "Article date is not allowed to be greater than current day."}));
      return;
    }

    if(isNew)
      this.store.dispatch(disableCreateButton())
    this.store.dispatch(updateItem());
    this.clearSub.next();
  }


  resetForm() {
    // TODO
  }

  containsTagById(list, tagId) {
    return list.some(e => e.id === tagId);
  }

  getTagPreviewColor(tag, tagMap) {
    if (!tag) return "#cddc39";

    if (tag.color && (tag.color.startsWith('#') || tag.color.startsWith('r'))) {
      return tag.color;
    } else {
      return this.getTagPreviewColor(tagMap.get(tag.parent), tagMap);
    }
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    
    /*
    
    console.log("filter", filterValue);
    let firstFilter = this.allTerms.filter(
      (e) =>
        e.permission == this.authService.permission ||
        this.authService.permission == "admin"
    );
    // .filter((d) => this.news.article.platform.includes(d.type.trim()));
*/

    
      let resort = () => {
        function areArgsValid(mainString, targetStrings) {
          if (typeof mainString !== 'string') return false;
          if (!Array.isArray(targetStrings)) return false;
          if (!targetStrings.length) return false;
          if (targetStrings.find( function (s) { return typeof s !== 'string'})) return false;
          return true;
        }

        function compareTwoStrings(first, second) {
          first = first.replace(/\s+/g, '')
          second = second.replace(/\s+/g, '')
        
          if (first === second) return 1; // identical or empty
          if (first.length < 2 || second.length < 2) return 0; // if either is a 0-letter or 1-letter string
        
          let firstBigrams = new Map();
          for (let i = 0; i < first.length - 1; i++) {
            const bigram = first.substring(i, i + 2);
            const count = firstBigrams.has(bigram)
              ? firstBigrams.get(bigram) + 1
              : 1;
        
            firstBigrams.set(bigram, count);
          };
        
          let intersectionSize = 0;
          for (let i = 0; i < second.length - 1; i++) {
            const bigram = second.substring(i, i + 2);
            const count = firstBigrams.has(bigram)
              ? firstBigrams.get(bigram)
              : 0;
        
            if (count > 0) {
              firstBigrams.set(bigram, count - 1);
              intersectionSize++;
            }
          }
        
          return (2.0 * intersectionSize) / (first.length + second.length - 2);
        }
      

          return (arr, str) => {
              return arr.sort((a, b) => {
                let tagA = a.id.substring(a.id.lastIndexOf("\t"), a.id.length).toLowerCase();
                let tagB = b.id.substring(b.id.lastIndexOf("\t"), b.id.length).toLowerCase();

                let numTagA = compareTwoStrings(tagA, str);
                let numTagB = compareTwoStrings(tagB, str);

                let delta = numTagB - numTagA;

                if (delta < 0) {
                  return -1;
                } else {
                  return 1;
                }

              });
          };
      };
    //*/

    let filtered = this.allTerms.filter(
      (fruit) =>
        fruit.id.toLowerCase().indexOf(filterValue) > -1 &&
        !this.rawDetailTags.some(e => e.id === fruit.id)
    );

    filtered = resort()(filtered, filterValue);

    return filtered;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our fruit
    console.log(input, value);

    this.termCtrl.setValue(null);
  }
  remove(fruit: string): void {
    const index = this.terms.map((x) => x.content).indexOf(fruit);

    if (index >= 0) {
      this.terms.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const cur = "";
    // this.news.tags.push(event.option.value);
    this.store.dispatch(addCurrentTag({ id: parseInt(event.option.value + "", 10)}));

    this.termInput.nativeElement.value = "";
    this.termCtrl.setValue(null);
  }
  deleteTag(tag) {
    this.store.dispatch(removeCurrentTag({ id: parseInt(tag + "", 10)}));
  }

  openTab() {
    window.open(this.news.article_source, "_blank");
  }
  saveTags(tags) {
    this.tags = tags;
    this.tagTreeDirty = true;
    this.lockTags = true;
  }
  ngAfterViewChecked() {
    this.lockTags = false;
  }
  openMap(){
    this.store.dispatch(openEditorMap());
  }

  onCheck(event){
    console.log("event",event)
    if (event.checked) {
      this.store.dispatch(checkItem({ id:this.id }));
      this.inputForm.disable();
      this.inputForm.controls.article_checked.enable();
      this.inputForm.controls.article_confirmed.enable();
    } else {
      this.store.dispatch(uncheckItem({ id:this.id }));
      this.inputForm.enable();
    }

    if (this.inputForm.controls.article_confirmed.value)
      this.inputForm.controls.article_confirmed.enable();

  }
  onConfirm(event){
    if (event.checked)
      this.store.dispatch(confirmItem({ id:this.id }))
    else
        this.store.dispatch(unconfirmItem({ id:this.id }))
  }

  promptDelete() {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      //  console.log("nwessssss", this.news);
      /*
            if (result) {
                this.newsService.deleteArticle(this.news.id).then(() => {
                    setTimeout(() => this.router.navigate(['/news']), 1000);
                })

            }
            */
           if(result){
            this.store.dispatch(deleteItem({ id: this.news.id }));
            this.clearSub.next();
           }
      
      // TODO implement delete functionality
    });
  }


  public canBeClosed() {
    if (this.inputForm.controls.article_source.value || this.inputForm.controls.article_title.value || this.inputForm.controls.article_content.value) {
      return false;
    }
    return true;
  }

}

@Component({
  selector: "delete-diag",
  template: `
    <div mat-dialog-content>
      <p class="mat-h4">Do you really want to delete this entry?</p>
    </div>
    <div mat-dialog-actions>
      <button
        mat-button
        color="warn"
        (click)="onClick(true)"
        cdkFocusInitial
      >
        Delete
      </button>&nbsp;
      <button mat-button (click)="onClick(false)">
        Cancel
      </button>
    </div>
  `,
})
export class DeleteDialogComponent {
  factor;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeleteDialogComponent>
  ) {}

  onClick(wantDeleted: boolean): void {
    this.dialogRef.close(wantDeleted);
  }
}
