import { createReducer, on } from "@ngrx/store";
import { generalStatsSuccess, requestDocumentLanguagesSuccess, statsNews24hSuccess } from "../actions/news.actions";
import { NewsCompound } from "../models/news-stats.model";

export const initialState: any[] = [];

const _languageReducer = createReducer(
    initialState,
    on(requestDocumentLanguagesSuccess, (state, action) => {
        return action.result;
    })
);

export function languageReducer(state, action) {
  return _languageReducer(state, action);
}
