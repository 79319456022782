import { createReducer, on } from "@ngrx/store";
import { statsNews24hGeomapSuccess } from "../actions/news.actions";

export const initialState = {};

// tslint:disable-next-line: variable-name
const _userSettingsReducer = createReducer(initialState);

export function userSettingsReducer(state, action) {
  return _userSettingsReducer(state, action);
}
