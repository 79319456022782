<div id="vertical-wrap">
  <div id="headerline">
    <mat-slide-toggle
      class="example-margin"
      (change)="toggleLinks($event)"
      style="position: relative; top: 0.8em; margin-right: 1em;"
    >Enable Links?
    </mat-slide-toggle>
    <button mat-mini-fab id="backButton" (click)="backClicked()">
      <mat-icon aria-label="back">chevron_left</mat-icon>
    </button>
    <div id="floatProxy"></div>
  </div>

  <form [formGroup]="inputForm">
    <mat-card class="line" id="info">
      <div gdAreas="tab tab2"
      gdRows="auto" fxLayout="row"
      gdColumns="50% 50%">
      <table gdArea="tab" style="margin-right: 1em; width: 35em;">
        <tr>
          <th><b>Domain:</b></th>
          <td>
            <mat-form-field class="inputs"
              ><input
                matInput
                formControlName="domain"
                name="domain"
                placeholder="Domain"
              />
              <mat-icon class="chooser" matSuffix (click)="openTab()"
                >open_in_new</mat-icon
              ></mat-form-field
            >
          </td>
        </tr>
        <tr>
          <th><b>Entry Domains:</b></th>
          <td>
            <mat-form-field class="inputs">
              <mat-label>Entry Domains</mat-label>
              <mat-chip-list #chipList aria-label="Entry domains selection">
                <mat-chip *ngFor="let syn of this.entry_domains" (removed)="removeEntryDomain(syn)" (click)="openLink('http://' + syn);">
                  {{syn}}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
                <input placeholder="New entry domain..."
                       [matChipInputFor]="chipList"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       [matChipInputAddOnBlur]="true"
                       (matChipInputTokenEnd)="addEntryDomain($event)">
              </mat-chip-list>
            </mat-form-field>
          </td>
        </tr>

        <tr>
          <th><b>Name:</b></th>
          <td>
            <mat-form-field class="inputs">
              <input
                #inbox3
                matInput
                formControlName="name"
                name="name"
                placeholder="Name"
              />
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <th><b>Amount (total):</b></th>
          <td>
            <mat-form-field class="inputs">
              <input
                #inbox3
                matInput
                formControlName="amount_total"
                name="amount_total"
                placeholder="Amount (total)"
                type="number"
              />
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <th><b>Amount (relevant):</b></th>
          <td>
            <mat-form-field class="inputs">
              <input
                #inbox3
                matInput
                formControlName="amount_relevant"
                name="amount_relevant"
                placeholder="Amount (relevant)"
                type="number"
              />
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <th><b>Importance:</b></th>
          <td>
            <!-- Also change importance and truthfullness in source-management ts -->
            <mat-form-field class="inputs">
              <mat-label>Importance</mat-label>
              <mat-select formControlName="importance">
                <mat-option
                  *ngFor="let impo of ['low', 'medium', 'high']; let idx = index"
                  [value]="idx"
                >
                  {{impo}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <th><b>Truthfulness:</b></th>
          <td>
            <mat-form-field class="inputs">
              <mat-label>Truthfulness</mat-label>
              <mat-select formControlName="truthfulness">
                <mat-option
                  *ngFor="let impo of ['correct','lurid', 'dubious', 'fakenews/disinformation']; let idx = index"
                  [value]="idx"
                >
                  {{impo}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr>
      </table>
      <table gdArea="tab2"style="margin-left: 1em; width: 35em;">
        <tr>
          <th><b>Platforms:</b></th>
          <td>
            <mat-form-field class="inputs">
              <mat-label>Platforms</mat-label>
              <mat-select formControlName="platforms" multiple>
                <mat-option
                  *ngFor="let platform of availablePlatforms"
                  [value]="platform"
                  >{{ platform }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <th><b>Language:</b></th>
          <td>
            <mat-form-field class="inputs">
              <mat-label>Language</mat-label>
              <mat-select formControlName="language">
                <mat-option disabled style="position: sticky; z-index: 1; top: 0; background-color: white; box-shadow: 1px -2px 10px 0px grey;">
                  <input matInput (keyup)="onLanguageKey($event.target.value)" placeholder="Search...">
                </mat-option>
                <mat-option
                  *ngFor="let language1 of languagesDisplay"
                  [value]="language1.iso"
                >
                  {{ language1.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <th><b>Description:</b></th>
          <td>
            <mat-form-field class="area">
              <textarea
                matInput
                placeholder="Description"
                formControlName="description"
              ></textarea>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <th><b>Note:</b></th>
          <td>
            <mat-form-field class="area">
              <textarea
                matInput
                placeholder="Note"
                formControlName="note"
              ></textarea>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <th><b>Disinformation:</b></th>
          <td class="inputs">
            <mat-checkbox  formControlName="disinformation">Disinformation</mat-checkbox>
          </td>
        </tr>
        <tr>
          <th><b>Active:</b></th>
          <td class="inputs">
            <mat-checkbox  formControlName="active">Active</mat-checkbox>
          </td>
        </tr>
        <tr>
          <th><b>Type:</b></th>
          <td class="inputs">{{ type }}</td>
        </tr>
      </table>
      </div>
    </mat-card>
  </form>

  <form [formGroup]="inputMappings">
  <div id="rules" fxLayout="row wrap" fxLayoutAlign="center">
    <mat-card
      class="line"
      *ngFor="let line of (mapping$ | async).mappings; let i = index"
      (click)="setFocus(i)"
      [class.border]="i == currentField"
    >
  
    <ng-container *ngIf="line.field">
      <div style="margin-bottom: 0.5em;">
        DB Field: &nbsp;<b>{{ line.field }}</b>
      </div>
      <br />
      <div fxLayout="row" fxLayoutAlign="space-evenly">
        <mat-form-field class="full-width" style="width: 100%;">
          <input
            #inbox
            matInput
            [formControlName]="line.field+'_whitelist'"
            name="querySelector"
            placeholder="Selector"
            (keypress.enter)="$event.preventDefault()"
            (keyup.enter)="onEnter(inbox.value, null, $event)"
          />
        </mat-form-field>
        <button mat-mini-fab id="minus" (click)="dec(i)">
          <mat-icon aria-label="remove">remove</mat-icon></button
        >&nbsp;&nbsp;
        <button mat-mini-fab id="plus" (click)="inc(i)">
          <mat-icon aria-label="add">add</mat-icon>
        </button>
      </div>

      <br />

      <mat-form-field style="width: 100%;">
        <input
          #inbox2
          matInput
          [formControlName]="line.field+'_blacklist'"
          name="querySelector2"
          placeholder="Blacklist"
          (focus)="setFocus(i)"
        />
      </mat-form-field>
      <div style="width: 100%;" *ngIf="false">
        <mat-form-field fxLayout="column" fxFlex="1 0 auto">
          <input
            style="width: 100%;"
            #inbox4
            matInput
            [formControlName]="line.field+'_regex'"
            name="regex"
            placeholder="Regex"
            (focus)="setFocus(line)"
            (blur)="triggerRegex(line)"
            (keyup.enter)="triggerRegex(i)"
          />
        </mat-form-field>
        &nbsp;
        <mat-form-field>
          <mat-label>Regex Select</mat-label>
          <select matNativeControl [formControlName]="line.field+'_regexSelect'">
            <option
              [value]="regexOption.instruction"
              *ngFor="let regexOption of line.regexBuffer"
              >{{ regexOption.value }}</option
            >
          </select>
        </mat-form-field>
      </div>
      <br />

      <mat-form-field style="width: 100%;">
        <textarea
          matInput
          placeholder="Output"
          [formControlName]="line.field+'_result'"
        ></textarea>
      </mat-form-field>

      <!-- <mat-card-actions>
            <button mat-raised-button id="delButton" (click)="delItem(i)"  color="warn">
                <mat-icon aria-label="del">delete</mat-icon>
            </button>
          </mat-card-actions>-->
        </ng-container>
    </mat-card>
  </div>
</form>

  <button
    id="save"
    color="accent"
    mat-raised-button
    (click)="save()"
    style="margin-right: 0.5em;"
  >
    Save
  </button>
  <button *ngIf="false"
    mat-raised-button
    color="primary"
    id="extractButton"
    (click)="saveAndExtract()"
    style="margin-right: 0.5em;"
  >
    Save & Extract
  </button>
  <button
    mat-raised-button
    color="warn"
    id="resetButton"
    (click)="promptDelete()"
  >
    Delete
  </button>
</div>
