<mat-sidenav-container class="example-container" [hasBackdrop]="false" autosize>


    <mat-sidenav opened="false" #drawer mode="side" closed class="sidenav left-sidenav"  [fixedInViewport]="true">
        <div mwlResizable class="resizeConf" [enableGhostResize]="true"[resizeCursorPrecision]="25" (resizeEnd)="onResizeEnd($event)" [ngStyle]="sidebarStyle">
            <app-map style="width:100%;height:100%;" *ngIf="graphSelect=='heatmap'" [data]="geoStats$" [updateMap]="updateMap$" (showArticle)="showArticle($event)"></app-map>
            <div class="resize-handle-right"
            mwlResizeHandle
            [resizeEdges]="{ right: true }"></div>
        </div>

    </mat-sidenav>

    <mat-sidenav opened="true" #drawer_right position="end" mode="side"  open [fixedInViewport]="true" class="sidenav " >
        <div id="right-sidenav" mwlResizable class="resizeConf2" #drawer_right_content [enableGhostResize]="true" [validateResize]="validate"   [resizeCursorPrecision]="25" (resizeEnd)="onResizeEnd2($event)"  [ngStyle]="sidebarStyle2">
            <div class="resize-handle-left"
            mwlResizeHandle
            [resizeEdges]="{ left: true }"></div>

            <app-editor [style.display]="this.selectedArticleId ? 'block' : 'none'">"></app-editor>
            <div style="position:relative;height:5em;top:3em; left:3em;width:100%;" [style.display]="!this.selectedArticleId ? 'block' : 'none'">
                <h1 >No article selected!</h1>
            </div>
        </div>
     </mat-sidenav>

    <mat-sidenav-content style="top:inherit;min-width:20vw;">
        <div [@openClose]="isOpen ? 'open' : 'closed'" style="margin-top:10px;" fxLayout="row" fxLayoutAlign="space-between">
            <button id="graphToggle" type="button" mat-stroked-button (click)="toggleGraph()" style="margin-left: 1em;">
                Toggle Graph
            </button>
            <button id="editorToggle" type="button" mat-stroked-button (click)="toggleEditor()" style="margin-right: 1em;">
                Toggle Editor
            </button>
        </div>
        <app-search *ngIf="graphSelect!='semanticGraph'" [@openClose]="isOpen ? 'open' : 'closed'"></app-search>

        <mat-card class="mat-elevation-z1" style="width:100% ;padding:0;" [@openClose]="isOpen ? 'open' : 'closed'">
            <div style="height:auto;position: absolute;top: 50%;transform: translateY(-50%);padding-left:16px;">
                <mat-checkbox *ngIf="(currentSearch$ | async).confirmed" (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()" style="margin-right: 5px;">
            </mat-checkbox> <button *ngIf="selection.hasValue()" type="button" mat-stroked-button  (click)="confirmPage()">Confirm Selected</button>
            </div>
            <mat-paginator #paginator style="width:100% !important;" [length]="newsCount$ | async" [pageSize]="(currentSearch$ | async).size" [pageIndex]="(currentSearch$ | async).index" [pageSizeOptions]="pageSizeOptions" (page)="updatePage($event)">
            </mat-paginator>
        </mat-card>

        <table mat-table [dataSource]="news$" matSort class="mat-elevation-z8" [style.display]="(listView$ | async)?'block':'none'" style="width:100% ;padding:0;">

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox *ngIf="(currentSearch$ | async).confirmed"  (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                [aria-label]="checkboxLabel()">
                </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                <mat-checkbox *ngIf="(currentSearch$ | async).confirmed" (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row.id) : null"
                                [checked]="selection.isSelected(row.id)"
                                [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
                </td>
            </ng-container>

            <!-- Position Column -->
            <ng-container matColumnDef="article_title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
              <td mat-cell *matCellDef="let entry">
                   <span class="table-title">{{entry.article.title_translated?entry.article.title_translated:entry.article.title}}</span> <br>
                   <mat-chip  *ngFor="let tag of entry.article.tags"><div  [matTooltip]="tag.id" style="display:inline-block">{{tag.item}}</div></mat-chip>
                </td>
            </ng-container>

            <!-- Position Column -->
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                <td mat-cell *matCellDef="let entry"> {{entry.date}} </td>
            </ng-container>

            <!-- Position Column -->
            <ng-container matColumnDef="article_created_by">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> User </th>
                <td mat-cell *matCellDef="let entry"> {{entry.article.created_by}} </td>
            </ng-container>
          
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"  (click)="refreshPreview(row.id)" style="cursor: pointer;" [class.selectedRow]="selectedArticleId==row.id"></tr>
          </table>

         

        <div fxLayout="column" id="sideContent" *ngIf="!(listView$ | async)">
            <mat-radio-group>
                

                    <content-loader *ngIf="!loaded">
                        <svg:rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
                        <svg:rect x="20" y="20" rx="3" ry="3" width="220" height="10" />
                        <svg:rect x="20" y="40" rx="3" ry="3" width="170" height="10" />
                        <svg:rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
                        <svg:rect x="20" y="80" rx="3" ry="3" width="200" height="10" />
                        <svg:rect x="20" y="100" rx="3" ry="3" width="80" height="10" />
                    </content-loader>
                    <ng-container *ngIf="loaded && graphSelect!='semanticGraph'">
                        <mat-card *ngFor="let entry of displayNews" class="mat-elevation-z1" [@openClose]="isOpen ? 'open' : 'closed'"  [class.selectedRow]="selectedArticleId==entry.id">
                            <!--[style.backgroundColor]="entry.id==selectedID ?  'rgba(173,216,230 ,0.7 )': 'white'" -->
                            <a fxLayout="row wrap">
                                <mat-radio-button #button *ngIf="graphSelect=='mentions'" [value]="entry.id" (click)="checkState(button)" style="position:relative;top:calc(50% - 10px);margin-right:1em;">
    
                                </mat-radio-button>
                                
                                <!--[routerLink]="['/editor/'+entry.id]"-->
                                
                                    <h1 *ngIf="entry.article && entry.article.title" fxFlex="1 0 100%" style="font-size: 20pt;">
                                        <mat-checkbox *ngIf="(currentSearch$ | async).confirmed" (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(entry.id) : null"
                                        [checked]="selection.isSelected(entry.id)"
                                        [aria-label]="checkboxLabel(entry)">
                                        </mat-checkbox>
                                        <a  (click)="refreshPreview(entry.id)"  target="_blank" *ngIf="!isExtension && !entry.article.title_translated" style="display: inline;">  {{entry.article.title}}</a>
                                        <a  (click)="refreshPreview(entry.id)"  target="_blank" *ngIf="!isExtension && entry.article.title_translated" style="display: inline;">{{entry.article.title_translated}}</a>
                                        <ng-container *ngIf="isExtension">{{entry.article.title}}</ng-container>
                                        <a [href]="entry.article.source" target="_blank" style="display: inline;"><mat-icon matSuffix>
                                            open_in_new</mat-icon></a>
                                    </h1>
                                    <h1 *ngIf="entry.article && !entry.article.title" style="font-size: 20pt;">No title found</h1>
                                    <a fxFlex="1 0 100%" (click)="refreshPreview(entry.id)"  >
                                    <mat-chip-list>
                                        <ng-container *ngIf="entry.article && entry.article.tags">
                                            <mat-chip *ngFor="let platform of entry.article.platforms"><div  style="display:inline-block">{{platform}}</div></mat-chip>
                                            <mat-chip *ngFor="let txt of entry.displayTags" [ngStyle]="{'background-color': txt.color}" ><div  [matTooltip]="txt.tag" style="display:inline-block">{{txt.tag}}</div></mat-chip>
                                        </ng-container>
                                    </mat-chip-list>
                                    
                                   <!-- <p class="source"><ng-container *ngIf="entry.article">{{entry.article.source}}</ng-container></p>-->
                                    <p [innerHTML]="entry.highlight"></p>
                                   <!-- <p *ngIf="!entry.highlight && !entry.article.content_translated" [innerHTML]="truncate(entry.article.content)"></p>
                                    <p *ngIf="!entry.highlight && entry.article.content_translated" [innerHTML]="truncate(entry.article.content_translated)"></p>
                                    <p *ngIf="entry.mentionCount">#Mentions: {{entry.mentionCount}}</p>-->
                                    <p>Created by: {{entry.article.created_by}}<br>Veröffentlicht am {{entry.date.replace("Z", "") +"Z" | date:'medium'}}<!-- | date:'medium'-->
                                    </p>
                                </a>
                             <!--<div fxFlex="1 0 40em"></div>-->   
                            </a>
                            <div fxLayout="column" fxLayoutAlign="space-between" style="margin-left:1em;">
                                <button *ngIf="entry.article.title_translated" disabled [style.color]="entry.article.title_translated?'green':'black'"  fxFlex="1 1 100%" mat-button  type="checkbox" >Translated</button>
                                <button *ngIf="(permissions$ | async).canAddEntryToReport" fxFlex="1 1 100%" mat-button #check type="checkbox" (click)="addToReport(entry.id)" >Add to Report</button><!--*ngIf="!check(entry.id,reports$ | async)"-->
                                <button *ngIf="(permissions$ | async).canMarkEntriesChecked" disabled fxFlex="1 1 100%" mat-button #check2 type="checkbox" (click)="checkEntry(entry.id,entry.article.checked)" [style.color]="entry.article.checked?'green':'black'">{{entry.article.checked?'Checked':'Not Checked'}}</button><!--Disabled due to concerns of abuse-->
                                <button *ngIf="(permissions$ | async).canMarkEntriesConfirmed" disabled fxFlex="1 1 100%" mat-button #check3 type="checkbox" (click)="confirmEntry(entry.id,entry.article.confirmed)" [style.color]="entry.article.confirmed?'green':'black'">{{entry.article.confirmed?'Confirmed':'Not Confirmed'}}</button>
                            </div>
                            
                        </mat-card>

                        <mat-card *ngIf="loaded && size==0" class="mat-elevation-z1" style="width:97%" > 
                            <p>No Results found</p>
                        </mat-card>
                    </ng-container>
                    
            </mat-radio-group>

        </div>
        <mat-card class="mat-elevation-z1" style="width:100% ;padding:0;" [@openClose]="isOpen ? 'open' : 'closed'">
            <div  style="height:auto;position: absolute;top: 50%;transform: translateY(-50%);padding-left:16px;">
                <mat-checkbox *ngIf="(currentSearch$ | async).confirmed" style="margin-right: 5px;" (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()">
            </mat-checkbox> <button *ngIf="selection.hasValue()" type="button" mat-stroked-button  (click)="confirmPage()">Confirm Selected</button></div>
            <mat-paginator #paginator2 style="width:100% !important;" [length]="newsCount$ | async" [pageSize]="(currentSearch$ | async).size" [pageIndex]="(currentSearch$ | async).index" [pageSizeOptions]="pageSizeOptions" (page)="updatePage($event)">
            </mat-paginator>
        </mat-card>
        
    </mat-sidenav-content>
</mat-sidenav-container>