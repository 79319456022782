import { Component, OnInit } from '@angular/core';
import { SocketService } from 'src/app/services/socket.service';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { Observable, Subscription } from 'rxjs';
import { NewsService } from 'src/app/services/news.service';
import * as moment from 'moment';
import { MiscService } from 'src/app/services/misc.service';
import {environment} from "../../../environments/environment"


@Component({
  selector: 'app-video-manager',
  templateUrl: './video-manager.component.html',
  styleUrls: ['./video-manager.component.scss']
})
export class VideoManagerComponent implements OnInit {

  constructor(private store: Store<State>, private socketService: SocketService, private newsService: NewsService,private miscService: MiscService) { }
  // private socket: SocketService
  files = [];
  files2 = [];
  id="";
  eta = "00h03m00s"
  timer;
  ready=false;
  conversion = false;

  progressPercent=0;

  active$: Observable<any> = this.store.select(state => Object.values(state.videoManager.active));
  waiting$: Observable<any> = this.store.select(state => Object.values(state.videoManager.waiting));
  completed$: Observable<any> = this.store.select(state => Object.values(state.videoManager.completed));

  subs: Subscription[] = [];



  displayedColumns: string[] = [ 'url', 'progress', 'status','action'];

  url: string;


  ngOnInit() {
    this.completed$.subscribe((e)=>{
      console.log("kek",e)
    })
  }
  ngOnDestroy(){
    this.subs.forEach((sub)=>{
      sub.unsubscribe();
    })
  }

  addToQueue(){
    console.log("GET info");

    this.socketService.addToQueue(this.url);
  }



  stringify(json){
    return JSON.stringify(json)
  }
  download(token){
    window.open(`${environment.SERVER_URL}/video/download/${token}`);
  }

}


export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
];