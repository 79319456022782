import { createReducer, on } from "@ngrx/store";
import { statsNews24hGeomapSuccess } from "../actions/news.actions";

export const initialState = {
  platforms: ["cyber", "innotech", "krimisi"],
};

// tslint:disable-next-line: variable-name
const _globalSettingsReducer = createReducer(initialState);

export function globalSettingsReducer(state, action) {
  return _globalSettingsReducer(state, action);
}
