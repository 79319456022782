import { createReducer, on } from '@ngrx/store';
import { disableCreateButton, enableCreateButton, statsNews24hGeomapSuccess } from '../actions/news.actions';


export const initialState = {
  createButtonDisabled:false
};

const _editorStateReducer = createReducer(initialState,
    on(disableCreateButton, (state, action) => {
        return {createButtonDisabled:true};
    }),
    on(enableCreateButton, (state, action) => {
      return {createButtonDisabled:false};
  })
  );

export function editorStateReducer(state, action) {
    return _editorStateReducer(state, action);
  }
