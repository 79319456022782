import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NewsComponent } from "../components/news/news.component";
import { EditorComponent } from "../components/editor/editor.component";
import { DashboardComponent } from "../components/dashboard/dashboard.component";
import { SourceMappingComponent } from "../components/source-management/source-mapping/source-mapping.component";
import { SourceManagementComponent } from "../components/source-management/source-management.component";
import { TagEditorComponent } from "../components/tag-editor/tag-editor.component";
import { StatsComponent } from "../components/stats/stats.component";
import { ChoroplethGraphComponent } from "../components/graphs/choropleth-graph/choropleth-graph.component";
import { VideoManagerComponent } from "../components/video-manager/video-manager.component";
import { ReportsComponent } from "../components/reports/reports.component";
import { UserEditorComponent } from "../components/userComponents/user-editor/user-editor.component";
import { UserManagerComponent } from "../components/userComponents/user-manager/user-manager.component";
import { LoginComponent } from "../components/login/login.component";
import { AuthGuard } from "../services/auth.guard";
import { VisualAnalyzerComponent } from "../components/visual-analyzer/visual-analyzer.component";
import { RoleManagerComponent } from "../components/roleComponents/role-manager/role-manager.component";
import { RoleEditorComponent } from "../components/roleComponents/role-editor/role-editor.component";
import { BhStatsComponent } from "../components/bh-stats/bh-stats.component";
import { NewsEditorComponent } from "../components/news-editor/news-editor.component";
import { PermissionErrorComponent } from "../components/permission-error/permission-error.component";
import { CanDeactivateGuard } from "../guards/can-deactivate.guard";
import { SiteManagementComponent } from "../components/site-management/site-management.component";
import { TagsComponent } from "../components/tags/tags.component";
const routes: Routes = [
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "videoManager",
    component: VideoManagerComponent,
    canActivate: [AuthGuard],
  },
  { path: "news", component: NewsComponent, canActivate: [AuthGuard] },
  { path: "newsEditor/:id", component: NewsEditorComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: "newsEditor", component: NewsEditorComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  {
    path: "sourceManagement",
    component: SourceManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "tagEdit",
    component: TagsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "categories",
    component: TagEditorComponent,
    canActivate: [AuthGuard],
  },
  { path: "stats", component: StatsComponent, canActivate: [AuthGuard] },
  {
    path: "test",
    component: ChoroplethGraphComponent,
    canActivate: [AuthGuard],
  },
  { path: "reports", component: ReportsComponent, canActivate: [AuthGuard] },
  { path: "login", component: LoginComponent },
  {
    path: "userManagement",
    component: UserManagerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "roleManagement",
    component: RoleManagerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "analyzer",
    component: VisualAnalyzerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "userEditor/:id",
    component: UserEditorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "roleEditor/:id",
    component: RoleEditorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sourceManagement/detail/:id",
    component: SourceMappingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "bh-stats",
    component: BhStatsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "permissionError",
    component: PermissionErrorComponent,
  },
  {
    path: "siteManagement",
    component: SiteManagementComponent,
    canActivate: [AuthGuard],
  },
  /* {
    path: 'dashboard',
    component: DashboardComponent,
  },*/
  { path: "", redirectTo: "/news", pathMatch: "full",  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      useHash: true,
      relativeLinkResolution: 'legacy'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
