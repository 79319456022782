import { createReducer, on } from "@ngrx/store";
import { updateMappingURL } from '../actions/news.actions';
import { clearMapping } from '../actions/news.actions';
import { requestMappingSuccess, updateMapping, updateMappingField } from "../actions/news.actions";
import { Mapping, MappingField } from "../models/mapping.model";

const defaultQuerySelector = {
  whitelist: "",
  blacklist: "",
  whitelistSplitted: [],
  whitelistCount: 0,
  whitelistOrig: "",
  regexSelect: "",
  regex: "",
  regexBuffer: "",
};

export const initialState: Mapping = {
  id: "",
  name: "",
  note: "",
  statuscomment: "",
  organization: "",
  amount_relevant: 0,
  amount_total: 0,
  importance: 0,
  truthfulness: 0,
  disinformation: false,
  platforms: [],
  entry_domains: [],
  originLink: "",
  type: "",
  domain: "",
  language: "",
  description: "",
  active: true,
  mappings: [
    {
      field: "article_title",
      name: "Title",
      options: { plainText: true, parseDate: false },
      whitelist: "",
      blacklist: "",
      whitelistSplitted: [],
      whitelistCount: 0,
      whitelistOrig: "",
      regexSelect: "",
      regex: "",
      regexBuffer: "",
    },
    {
      field: "article_subtitle",
      name: "Subtitle",
      options: { plainText: true, parseDate: false },
      whitelist: "",
      blacklist: "",
      whitelistSplitted: [],
      whitelistCount: 0,
      whitelistOrig: "",
      regexSelect: "",
      regex: "",
      regexBuffer: "",
    },
    {
      field: "article_author",
      name: "Author",
      options: { plainText: true, parseDate: false },
      whitelist: "",
      blacklist: "",
      whitelistSplitted: [],
      whitelistCount: 0,
      whitelistOrig: "",
      regexSelect: "",
      regex: "",
      regexBuffer: "",
    },
    {
      field: "article_place",
      name: "Location",
      options: { plainText: true, parseDate: false },
      whitelist: "",
      blacklist: "",
      whitelistSplitted: [],
      whitelistCount: 0,
      whitelistOrig: "",
      regexSelect: "",
      regex: "",
      regexBuffer: "",
    },
    {
      field: "article_created_at",
      name: "Date",
      options: { plainText: false, parseDate: true },
      whitelist: "",
      blacklist: "",
      whitelistSplitted: [],
      whitelistCount: 0,
      whitelistOrig: "",
      regexSelect: "",
      regex: "",
      regexBuffer: "",
    },
    {
      field: "article_content",
      name: "Content",
      options: { plainText: false, parseDate: false },
      whitelist: "",
      blacklist: "",
      whitelistSplitted: [],
      whitelistCount: 0,
      whitelistOrig: "",
      regexSelect: "",
      regex: "",
      regexBuffer: "",
    },
    {
      field: "consent",
      name: "Conset/Cookie Accept button:",
      options: { plainText: false, parseDate: false },
      whitelist: "",
      blacklist: "",
      whitelistSplitted: [],
      whitelistCount: 0,
      whitelistOrig: "",
      regexSelect: "",
      regex: "",
      regexBuffer: "",
    },
  ],
};

const defaultFields = [
  "article_title",
  "article_subtitle",
  "article_author",
  "article_place",
  "article_created_at",
  "article_content",
  "consent"
];

const _mappingReducer = createReducer(
  initialState,
  on(requestMappingSuccess, (state, action) => {
    action = {...action}
    console.log("state mapping update",state,action);

    let mapthings = [...action.mapping.mappings];
    for (let el of defaultFields) {
      if (!mapthings.some((e) => e.field == el)) {
        mapthings.push(    {
          field: el,
          name: el,
          options: { plainText: false, parseDate: false },
          whitelist: "",
          blacklist: "",
          whitelistSplitted: [],
          whitelistCount: 0,
          whitelistOrig: "",
          regexSelect: "",
          regex: "",
          regexBuffer: "",
        });
      }
    }
    action.mapping = {...action.mapping, mappings: mapthings}

    const transformedState = {
      ...action.mapping,
      mappings: action.mapping.mappings.map((mapping) => {
        let out: any = {};
        if (mapping.field.includes("article")) out = mapping;
        else if (mapping.field.includes("top"))
          out = { ...mapping, field: "article_created_at" };
        else out = { ...mapping, field: mapping.field };
        if (out.querySelector) {
          out = { ...out, ...out.querySelector };
          delete out.querySelector;
        }


        return out;
      }),
    };
    console.log("transformedState", transformedState);
    return transformedState;
  }),
  on(updateMappingField, (state, action) => {
    console.log("state mapping update",state,action);

    const updatedState = {
      ...state,
      mappings:[
        ...state.mappings.map((entry)=>{
          if(entry.field==action.mapping.field)
            return action.mapping;
          else
            return entry;
        })

      ]
    }

    return updatedState;
  }),
  on(clearMapping, (state, action) => {
    console.log("state mapping update",state,action);
    return initialState;
  }),
  on(updateMappingURL, (state, action) => {
    console.log("state mapping update",state,action);
    return {
      ...state,
      domain: (action.update.domain + "").replace("http://", "").replace("https://", ""),
      originLink: action.update.url
    };
  }),
  on(updateMapping, (state, action) => {
    console.log("state mapping update",state,action);
    const data = action.mapping as any;


    // convert flat to nested

    if (action.mapping.domain || action.mapping.entry_domains || typeof action.mapping.disabled != 'undefined') {
      const transformedMapping = {
        ...state,
        ...action.mapping,
      };
      return transformedMapping;
    }

    const workingMappings = data.mappings;

    const mergedMappings = [];

    for (const field of defaultFields) {
      const newMappings = Object.entries(workingMappings).filter(
        ([key, value]) => {
          return key.includes(field);
        }
      );
      const oldMappingObj: MappingField = {
        ...state.mappings.filter((entry) => entry.field == field)[0],
      };

      // modify old objects
      const cutLength = oldMappingObj.field.length + 1;

      for (const newMapping of newMappings) {
        const key = newMapping[0].substring(cutLength);
        oldMappingObj[key] = newMapping[1];
      }

      console.log(oldMappingObj);

      mergedMappings.push(oldMappingObj);
    }

    const transformedMapping = {
      ...state,
      mappings: mergedMappings,
    };
    return transformedMapping;
  })
);

export function mappingReducer(state, action) {
  return _mappingReducer(state, action);
}
