import { Injectable, OnInit } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { State } from "../reducers";

import {
  loginSucess,
  login,
  logout,
  logoutSucess,
  requestPermissions,
} from "../actions/news.actions";
import { Observable } from "rxjs";

const SERVER_URL = environment.SERVER_URL;

@Injectable({
  providedIn: "root",
})
export class AuthService {
  username = null;
  role;
  terms;
  settings = {
    check: false,
    confirmed: false,
  };

  permissions;
  permissions$: Observable<any> = this.store.select(
    (state) => state.permissions
  );

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store<State>
  ) {
    this.settings.check = localStorage.getItem("check") === "true";
    this.settings.confirmed = localStorage.getItem("confirmed") === "true";
    console.log("ng on calles")
    this.permissions$.subscribe((e)=>{
      this.permissions = e;
    })
  }


  public getPermissions(){
    return this.permissions;
  }

  public getUsername(){
    return this.username;
  }
  public getRole(){
    return this.role;
  }

  public async isAuthenticated(): Promise<boolean> {
    if (this.username) return true;
    else {
      return new Promise((res) => {
        this.store.dispatch(
          login({ username: "anon", password: "anonTest", platform: "cyber" })
        );
        this.http
          .get(`${environment.SERVER_URL}/checkAuth`)
          .toPromise()
          .then((e: any) => {
            this.username = e.username;
            this.role = e.role;
            this.store.dispatch(requestPermissions({ role: e.role }));
            this.store.dispatch(loginSucess({ role: e.role,username:e.username }));
            res(true);
          })
          .catch((err) => {
            console.log("failed");
            res(false);
          });
      });
    }
  }
  public setCheck(check) {
    this.settings.check = check;
    localStorage.setItem("check", check);
  }
  public setConfirmed(confirmed) {
    this.settings.confirmed = confirmed;
    localStorage.setItem("confirmed", confirmed);
  }

  public authenticate(username, password) {
    const httpOptions: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    console.log(`kek`);
    this.store.dispatch(login(username)); // TODO move to login component
    return this.http
      .post(
        `${SERVER_URL}/login`,
        { username, password },
        httpOptions
      )
      .toPromise()
      .then((data: any) => {
        console.log(data);
        const body = data.body;
        this.store.dispatch(requestPermissions({ role: body.role }));
        this.store.dispatch(loginSucess({ role: body.role, username:body.username }));
        this.username = body.username;
        this.role = body.role;
        return Promise.resolve();
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  public logout() {
    this.store.dispatch(logout());
    this.store.dispatch(logoutSucess());
    this.http.get(`${SERVER_URL}/logout`, { responseType: "text", }).toPromise().then(()=>{
      console.log("Logout successful")
    }).catch((e)=>{
      console.log(e);
    })
  }
  public createUser(
    username,
    password,
    role,
    description,
    email,
    firstname,
    lastname,
    title,
    tel,
    skills,
    organization,
    terms
  ) {
    const httpOptions: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    console.log(`kek`);
    return this.http
      .post(
        `${SERVER_URL}/user`,
        {
          username,
          password,
          role,
          hpsGroups: terms,
          description,
          email,
          firstname,
          lastname,
          title,
          tel,
          skills,
          organization,
        },
        httpOptions
      )
      .toPromise()
      .then((data: any) => {
        // this.notificationService.displaySnackbar("User created!");
        // TODO
      });
  }
  public async updateUser(
    username,
    password,
    role,
    description,
    email,
    firstname,
    lastname,
    title,
    tel,
    skills,
    organization,
    id,
    terms
  ) {
    const httpOptions: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    console.log(`kek`);
    let result = null;
    try {
      result = await this.http
        .put(
          `${SERVER_URL}/user/${id}`,
          {
            username,
            password,
            role,
            hpsGroups: terms,
            description,
            email,
            firstname,
            lastname,
            title,
            tel,
            skills,
            organization,
          },
          httpOptions
        )
        .toPromise();
      // this.notificationService.displaySnackbar("User updated!");
      // TODO
    } catch (err) {
      this.logout();
      this.router.navigate(["login"]);
      // this.notificationService.displaySnackbar("Please login with your new username!");
      // TODO
      throw new Error("Please login again");
    }

    return result;
  }
  public deleteUser(id) {
    return this.http.delete(`${SERVER_URL}/user/${id}`).toPromise();
  }

  public getUser(id) {
    return this.http.get(`${SERVER_URL}/user/${id}`).toPromise();
  }
  public getUsers() {
    return this.http.get(`${SERVER_URL}/user`).toPromise();
  }

  public getUsersBrief() {
    return this.http.get(`${SERVER_URL}/user?brief`).toPromise();
  }
}
