import { Component, OnInit, ViewChild, AfterViewInit, ViewChildren  } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets, ChartPoint } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import * as moment from 'moment';
import { NewsService } from 'src/app/services/news.service';
import { BaseChartDirective } from 'ng2-charts';
import { MentionsService } from 'src/app/services/mentions.service';
import { MiscService } from 'src/app/services/misc.service';
import { StatsService } from 'src/app/services/stats.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit, AfterViewInit {


  @ViewChildren(BaseChartDirective) chart: any;

  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];

    public lineChartData: ChartDataSets[] = [// (function() {const arr = []; for (let i = -12; i <= 12; i++) {arr.push(Math.pow(i,2)+10) }return arr;})()
    { data: [0, 0], label: 'Series A' },
    ];

    public lineChartLabels: Label[] = [];
    public lineChartOptions: (ChartOptions ) = {
    responsive: true,
    };
    public lineChartColors: Color[] = [


    ];



    public lineChartLegend2 = true;
    public lineChartType2 = 'line';
    public lineChartPlugins2 = [];

    public lineChartData2: ChartDataSets[] = [// (function() {const arr = []; for (let i = -12; i <= 12; i++) {arr.push(Math.pow(i,2)+10) }return arr;})()
    { data: [0, 0], label: 'Series A' },
    ];

    public lineChartLabels2: Label[] = [];
    public lineChartOptions2: (ChartOptions ) = {
    responsive: true,
    };
    public lineChartColors2: Color[] = [


    ];


    scatterXlabels = []
    scatterYlabels = [];

// scatter
public scatterChartOptions: any = {
  responsive: true,
  
  scales: {
    xAxes: [
      {
        ticks: {
            userCallback: (label, index, labels)=> {
                return this.scatterXlabels &&this.scatterXlabels[index]?this.scatterXlabels[index]:index;
            },
            maxTicksLimit:100,
            stepSize:1,
            minRotation: 30,
            autoSkip: false
         }
        }
    ],
    yAxes: [
      {
        ticks: {
            userCallback: (label, index, labels)=> {
                return this.scatterYlabels && this.scatterYlabels[index]?this.scatterYlabels[index]:index;
            },
            maxTicksLimit:100,
            stepSize:1,
            autoSkip: false
         }
        }
    ]
 },
 tooltips:{
  callbacks: {
    label: (tooltipItem, data) =>{
      
       // console.log("tooltip",tooltipItem,data)
        const element = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        return "EventSource: "+element.eventSource+ "\r\nMentionSource: "+element.mentionSource;
    },
    
}
}

};

public scatterChartData: ChartDataSets[] = [
  {
    data: [
    /*  { x: 0, y: 1, },
      { x: 1, y: 3 },
      { x: 3, y: -2 },
      { x: 4, y: 4 },
      { x: 5, y: -3, r: 20 },*/
    ],
    pointBackgroundColor: ["#ff00ff"],
    label: '2 A',
    pointRadius: 3,
  },
];

public scatterChartLabels: Label[] = ["test"];


public scatterChartType: ChartType = 'scatter';


  constructor(private statsService: StatsService,private mentionsService: MentionsService) { }

  ngOnInit(){

  }

  ngAfterViewInit(): void  {
    /*for (let i = 0; i <= 5; i++) {
      this.lineChartLabels.unshift(moment().subtract('d').fromNow());
    }*/
    this.statsService.getStatsByUrl().then((data: any) => {
      console.log("domaindata",data);
      const urlMap = {};
      const timeSet = new Set();
      for (let i = 0; i < data.urlTime.length; i++) {
        const domain = data.urlTime[i];
        this.lineChartData[i] = {};
        this.lineChartData[i].label = domain.url;
        this.lineChartData[i].data = [];
        this.lineChartColors.push({
          borderColor: 'black',
          backgroundColor: 'rgba(' + Math.random() * 255 + ',' + Math.random() * 255 + ',' + Math.random() * 255 + ',0.3)',
        });



        for (const bucket  of domain.buckets) {
          timeSet.add(bucket.key_as_string);

          this.lineChartData[i].data.push(bucket.doc_count);
        }

      }
      for (const time  of timeSet.keys()) {
        console.log("timestamp",time)
        this.lineChartLabels.push(moment(time).subtract('h').fromNow());
      }

      console.log(this.lineChartData);
    });

    this.statsService.getStatsByEventCode().then((data: any) => {
      console.log(data);
      const urlMap = {};
      const timeSet = new Set();
      for (let i = 0; i < data.length; i++) {
        const domain = data[i];
        this.lineChartData2[i] = {};
        this.lineChartData2[i].label = domain.eventcode;
        this.lineChartData2[i].data = [];
        this.lineChartColors2.push({
          borderColor: 'black',
          backgroundColor: 'rgba(' + Math.random() * 255 + ',' + Math.random() * 255 + ',' + Math.random() * 255 + ',0.3)',
        });



        for (const bucket  of domain.hits) {
          timeSet.add(bucket.time);

          this.lineChartData2[i].data.push(bucket.mentionsCount);
        }

      }
      for (const time  of timeSet.keys()) {
        console.log("timestamp",time)
        this.lineChartLabels2.push(moment(time).subtract('h').fromNow());
      }

      console.log(this.lineChartData2);
    });


    const xLabels = new Set();
    const yLabels = new Set();
    /*this.mentionsService.getStatsMentions().subscribe((dataSet:any)=>{
      console.log("Mentions",dataSet);
      for(let dataEntry of dataSet){
        xLabels.add(new URL(dataEntry.event.article.source).hostname);
        for(let mention of dataEntry.hits){
          yLabels.add(mention.MentionSourceName)
        }
      }
      console.log("Xlabels",xLabels);
      console.log("Ylabels",yLabels);
      this.setData(dataSet,xLabels.values(),yLabels.values());
    })*/
  }
  setData(data,xLabels,yLabels){
    this.scatterXlabels = [...xLabels];
    this.scatterYlabels = [...yLabels];
    
    for(const entry of data){
      for(const mention of entry.hits){
        this.scatterChartData[0].data.push({
          x: this.scatterXlabels.indexOf(new URL(entry.event.article.source).hostname),
          y:this.scatterYlabels.length-1-this.scatterYlabels.indexOf(mention.MentionSourceName),
          eventSource: entry.event.article.source,
          mentionSource: mention.MentionIdentifier
        } as any)
      }
    }
    console.log("data",this.scatterChartData[0].data)


    console.log("chart",this.chart.last.chart, this.scatterXlabels, this.scatterYlabels)
    this.chart.last.chart.update();
  }
}
