import { Injectable } from '@angular/core';
import { Observable, of , throwError, from } from 'rxjs';
import { News } from '../models/news.model';
import { NewsStats, NewsCompound } from '../models/news-stats.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { flatMap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SearchParameter } from '../models/search-parameter';
import { Tag } from '../models/tags.model';

@Injectable({
    providedIn: 'root'
})
export class ReportService {

    constructor(private http: HttpClient) {}
    
    public loadReports() {
        return this.http.get(`${environment.SERVER_URL}/reports/`) as Observable<News[]>;
      }
      public createReport(summary) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.SERVER_URL}/reports/create`, {summary}, httpOptions) as Observable<News[]>;
      }

      public resetReport() {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.delete(`${environment.SERVER_URL}/reports/`, httpOptions) as Observable<News[]>;
      }

      public downloadReport(summary) {
        window.open(`${environment.SERVER_URL}/reports/create?summary=${summary}`, '_blank');
      }
      
      public setReportsItem(id, operation) {
        console.log('DB con', id, operation);
        return this.http.get(`${environment.SERVER_URL}/reports/setItem?id=${id}&operation=${operation}`) as Observable<any>;
      }
      

}