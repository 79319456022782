import { createSelector } from "@ngrx/store";
import { State } from "../reducers";
import { Tag } from "../models/tags.model";

export const selectTags = (state: State) => state.tags;

export const selectCAMEO = createSelector(selectTags, (selectedTags: Tag[]) => {
  if (selectedTags) {
    return selectedTags.filter((tag) => tag.source == "CAMEO");
  } else {
    return selectedTags;
  }
});

export const selectActive = createSelector(selectTags, (selectedTags: Tag[]) => {
  if (selectedTags) {

    let mapo = new Map<number, any>();
    let filTag = selectedTags.filter((tag) => tag.active && !tag.deleted);
    filTag.forEach((e) => {
      mapo.set(e.uid, e);
    });
    return filTag.filter((tag) => {
      let tg = tag;
      let setps = 20; // Just in case break the loop
      while (tg && tg.parent && setps) {
        tg = mapo.get(tg.parent);
        setps--;
      }
      if (!setps) {
        console.warn("WARNING: Tagtree has to many layers. Possible problem on display. (tag.selector.ts)");
      }
      return !!tg; // When tg exists then its the first tag. When not then a parent is missing.
    });
  } else {
    return selectedTags;
  }
});

export const selectCDRC = createSelector(selectTags, (selectedTags: Tag[]) => {
  if (selectedTags) {
    return selectedTags.filter(
      (tag) =>
        tag.source == "CDRC" ||
        tag.source == "WEB-GUI-CDRC" ||
        tag.source == "WEB-GUI"
    );
  } else {
    return selectedTags;
  }
});
