<div id="wrapper">
    <div 
        gdAreas="tags | button"
        gdRows="auto auto"
        gdColumns="100%"
        >
        <mat-card class="mat-elevation-z1,card" gdArea="tags">
            <div class="card-header">Tag Editor
                <mat-slide-toggle style="margin-left: 1em; margin-top: 7px;" [(ngModel)]="showDeletedTags">Show deleted tags</mat-slide-toggle>
            </div>
            <mat-card-content style="width:100%;height:100%;" class="card-text">
                <div class="mat-elevation-z8">
                    <app-tag-edit-tree *ngIf="tags.length > 0" [TREE_DATA]="tags" [showDeletedTags]="showDeletedTags" (onSave)="saveNewTagTree($event)"></app-tag-edit-tree> <!-- s -->
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="mat-elevation-z1,card" gdArea="button">
            <div class="card-header">Export Tags: 
                <button mat-raised-button color="warn" (click)="exportTags()">Export</button>
            </div>
            <mat-card-content  style="width:100%;height:100%;" class="card-text"></mat-card-content>
        </mat-card>
    
    </div>
</div>