// Search
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { NgxSpinnerService } from "ngx-spinner";
import { of } from "rxjs";
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import {
  genericError,
  newsSucess,
  searchRequest,
  searchError,
  addGeoFilter,
  removeGeoFilter,
} from "../actions/news.actions";
import { SearchParameter } from "../models/search-parameter";
import { SearchService } from "../services/search.service";

@Injectable()
export class SearchEffects {
  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchRequest),
      // tap((data) => this.spinner.show()),
      map((data) => data.request),
      withLatestFrom(this.store.select((state) => state.currentSearch)),
      switchMap(([request, store]) =>
        this.searchService.search(store).pipe(
          map((news) => newsSucess({ news })),
          catchError((err) => {
            console.log("ERR", err);
            if (err.status==401) {
              this.router.navigate(['login']);
            }
            return of(searchError());
          })
        )
      )
    )
  );

  onGeoFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addGeoFilter,removeGeoFilter),
      // tap((data) => this.spinner.show()),
      map(()=>{
        return searchRequest({request:{}})
      })
    )
  );

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private actions$: Actions,
    private searchService: SearchService,
    private spinner: NgxSpinnerService,
    private store: Store<{ currentSearch: SearchParameter }>
  ) {}
}
