<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding [ngStyle]="{'background-color': getNodeColor(node), 'display': !flatNodeMap.get(node).data.active ? 'none' : ''}">
    <!--<button mat-icon-button disabled></button>-->
    <mat-checkbox class="checklist-leaf-node" 
                  [checked]="checklistSelection.isSelected(node) && node.rlySelected"
                  [indeterminate]="checklistSelection.isSelected(node) && !node.rlySelected" 
                  (click)="$event.preventDefault(); todoLeafItemSelectionToggle(node);" [matTooltip]="node.id+' | '+node.description + ' UID: ' + node.uid">{{node.item}}</mat-checkbox>
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding [ngClass]="node.platform">
    <!--<button mat-icon-button disabled></button>-->
    <mat-form-field>
      <input matInput #itemValue placeholder="New item...">
    </mat-form-field>
    <button mat-button >Save</button>
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding [ngStyle]="{'background-color': getNodeColor(node), 'display': !flatNodeMap.get(node).data.active ? 'none' : ''}" class="checklist-branch-node" >
    <button mat-icon-button matTreeNodeToggle
            [attr.aria-label]="'toggle ' + node.filename">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <mat-checkbox [checked]="checklistSelection.isSelected(node) && node.rlySelected" 
                    [matTooltip]="node.id+' | '+node.description + ' UID: ' + node.uid" 
                    [indeterminate]="checklistSelection.isSelected(node) && !node.rlySelected" 
                    (click)="$event.preventDefault(); todoLeafItemSelectionToggle(node);">{{node.item}}</mat-checkbox><!--[checked]="descendantsAllSelected(node)" [indeterminate]="descendantsPartiallySelected(node)"class="disabled"-->
   <!-- <button mat-icon-button (click)="addNewItem(node)"><mat-icon>add</mat-icon></button>-->
  </mat-tree-node>
</mat-tree>