import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { State } from "../reducers";

import {
  loginSucess,
  login,
  logout,
  logoutSucess,
} from "../actions/news.actions";

const SERVER_URL = environment.SERVER_URL;

@Injectable({
  providedIn: "root",
})
export class RoleService {
  username = null;
  role;
  terms;

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store<State>
  ) {}

  public createRole(name, roles) {
    const httpOptions: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    const payload = {
      name,
    };
    for (const role of roles) {
      payload[role] = true;
    }

    return this.http
      .post(`${SERVER_URL}/role`, payload, httpOptions)
      .toPromise()
      .then((data: any) => {
        console.log("finished uploading role");
      });
  }
  public updateRole(name, roles, id) {
    const httpOptions: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    const payload = {
      name,
    };
    for (const role of roles) {
      payload[role] = true;
    }

    return this.http
      .put(`${SERVER_URL}/role/${id}`, payload, httpOptions)
      .toPromise()
      .then((data: any) => {
        console.log("finished uploading role");
      });
  }
  public deleteRole(id) {
    return this.http.delete(`${SERVER_URL}/role/${id}`).toPromise();
  }

  public getRole(id) {
    return this.http.get(`${SERVER_URL}/role/${id}`).toPromise();
  }
  public getRoleByName(name) {
    return this.http.get(`${SERVER_URL}/role/byName/${name}`);
  }
  public getRoles() {
    return this.http.get(`${SERVER_URL}/role`).toPromise();
  }
}
