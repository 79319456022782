import { createReducer, on } from "@ngrx/store";
import {
  requestNews,
  newsSucess,
  addItem,
  articleSuccess,
  resetArticleForm,
  addCurrentTag,
  removeCurrentTag,
  updateArticle,
  programmaticArticleUpdate,
  graphSearchRequest,
  setSemanticGraphPeople,
} from "../actions/news.actions";
import { News } from "../models/news.model";

export const initialState: any = {
  people:[],
  results:[]
}


const _semanticGraphReducer = createReducer(
  initialState,
  on(graphSearchRequest, (state, action) => {
    return { ...state, results:state.people.filter((person)=>person.domain.includes(action.request.fulltext))};
  }),
  on(setSemanticGraphPeople, (state, action) => {
    return { ...state, people: action.people, results:action.people};
  }),

);

export function semanticGraphReducer(state, action) {
  return _semanticGraphReducer(state, action);
}
