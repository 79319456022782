import { Injectable } from "@angular/core";
import { Observable, of, throwError, from } from "rxjs";
import { News } from "../models/news.model";
import { NewsStats, NewsCompound } from "../models/news-stats.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { flatMap, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { SearchParameter } from "../models/search-parameter";
import { Tag } from "../models/tags.model";
import { Store } from "@ngrx/store";
import { State } from "../reducers";
@Injectable({
  providedIn: "root",
})
export class NewsService {
  constructor(private http: HttpClient, private store: Store<State>) {
    this.tagMapping$.subscribe((data)=>{
      this.tagMapping = data;
    })
  }

  tagMapping$ = this.store.select((store)=>store.tagMapping);
  tagMapping: Map<number, any>;

  requestNews() {
    const result: Observable<{
      results: NewsCompound[];
      count: number;
    }> = this.http.get(
      `${environment.SERVER_URL}/get100Articles`
    ) as Observable<any>;
    return result;
  }

  checkURLDuplicates(url){
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    const result = this.http.post(
      `${environment.SERVER_URL}/news/checkURLDuplicates`,{url},httpOptions
    ) as Observable<any>;
    return result;
  }

  requestArticle(id: string): Observable<News> {
    const result: Observable<News> = this.http.get(
      `${environment.SERVER_URL}/news/${id}`
    ) as Observable<any>;
    return result;
  }


  saveArticle(article: any) {
    let output = {...article}
    console.log("BEFORE save", article);


    let used_plats = new Set();

    for(let tag of output.article_tags){
      if(this.tagMapping.get(tag)){
        used_plats.add(this.tagMapping.get(tag).platform);
      } else {
        console.log("NOT FOUND",tag);
      }
    }

    output.article_platforms=[...used_plats].filter((e)=>e!="ALL")
    console.log(output,"BEFORE SEND")

    if (output.id == null || output.id == "") {
      if (output.gdelt && !output.gdelt.GlobalEventID) {
        delete output.gdelt;
      }

      if (output.date) {
        delete output.date; // Now set serverside.
      }

      const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
      return this.http
        .post(`${environment.SERVER_URL}/news`, output, httpOptions)
        .pipe(
          map((result) => {
            console.log("RESULT EDITOR ADD", result);
            return { data: output, type: "add" };
          })
        );
    } else {
      const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
      return this.http
        .put(
          `${environment.SERVER_URL}/news/${output.id}`,
          output,
          httpOptions
        )
        .pipe(
          map((result) => {
            console.log("RESULT EDITOR UPDATE", output);
            return { data: output, type: "update" };
          })
        );
    }
  }

  checkItem(id,state:boolean) : Observable<any> {
    if (!id) {
      return throwError('Invalid ID');
    }

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http
      .post(`${environment.SERVER_URL}/news/${id}/check`, {state}, httpOptions);
  }

  confirmItem(id,state:boolean) : Observable<any> {
    if (!id) {
      return throwError('Invalid ID');
    }

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http
      .post(`${environment.SERVER_URL}/news/${id}/confirm`, {state}, httpOptions);
  }

  confirmPage(ids) : Observable<any> {
    console.log("Stuff: ", ids);
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http
      .post(`${environment.SERVER_URL}/news/confirmPage`, {items:ids}, httpOptions);
  }
  

  public deleteArticle(id) {
    console.log(`Delete`, id);
    return this.http.delete(`${environment.SERVER_URL}/news/${id}`);
  }
}
