import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NewsService } from "../../../services/news.service";
import { ChromeService } from "../../../services/chrome.service";
import { MatDialog } from "@angular/material/dialog";
import { MappingsContext } from "source-list-map";
import { FormControl, FormGroup } from "@angular/forms";
import { DeleteDialogComponent } from "../../misc-components/delete-dialog/delete-dialog.component";
import { MappingService } from "src/app/services/mapping.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Mapping, MappingField } from "src/app/models/mapping.model";
import { Store } from "@ngrx/store";
import { State } from "src/app/reducers";
import {
  saveMapping,
  requestMapping,
  updateMapping,
  clearMapping,
  genericError
} from "../../../actions/news.actions";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime, distinctUntilChanged, take } from "rxjs/operators";
import { updateMappingField } from 'src/app/actions/news.actions';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER, SEMICOLON } from "@angular/cdk/keycodes";

declare var chrome;
@UntilDestroy()
@Component({
  selector: "app-source-mapping",
  templateUrl: "./source-mapping.component.html",
  styleUrls: ["./source-mapping.component.scss"],
})
export class SourceMappingComponent implements OnInit, OnDestroy {
  constructor(
    private mappingService: MappingService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private chromeService: ChromeService,
    private store: Store<State>
  ) {}
  readonly separatorKeysCodes = [ENTER, COMMA, SEMICOLON] as const;


  availablePlatforms = ["cyber", "innotech", "krimisi"];

  mappings = [];
  statuscomment = "";
  currentFocus;
  currentField = 0;
  newHPS = false;

  translationLanguages$: Observable<any[]> = this.store.select(
    (state) => state.translationLanguages
  ).pipe(untilDestroyed(this), distinctUntilChanged());
  languages; // All languages before filter
  languagesDisplay; // Languages after search of user

  inputForm = new FormGroup({
    name: new FormControl(""),
    amount_total: new FormControl(0),
    amount_relevant: new FormControl(0),
    importance: new FormControl(0),
    truthfulness: new FormControl(0),
    disinformation: new FormControl(false),
    language: new FormControl(""),
    description: new FormControl(""),
    domain: new FormControl(""),
    note: new FormControl(""),
    platforms: new FormControl(""),
    active: new FormControl(true),
  });
  entry_domains = [];


  inputMappings: FormGroup;

  created_at;
  created_by;
  updated_at;
  updated_by;

  orig_active_state;

  type = "HPS";
  id = "";

  defaultFields = [
    "article_title",
    "article_subtitle",
    "article_author",
    "article_place",
    "article_created_at",
    "article_content",
    "consent"
  ];

  mapping$: Observable<Mapping> = this.store.select(
    (state) => state.currentMapping
  );

  backgroundPageConnection;

  updateOptionCheck(event, i) {
    this.setFocus(i);
  }

  ngOnInit() {
    const inputMappingControls = {};

    const fixedFields = [
      "whitelist",
      "blacklist",
      "regex",
      "regexSelect",
      "result",
    ];

    const defaultFields = [
      "article_title",
      "article_subtitle",
      "article_author",
      "article_place",
      "article_created_at",
      "article_content",
      "consent"
    ];

    for (const defaultField of defaultFields) {
      for (const fixedField of fixedFields) {
        inputMappingControls[`${defaultField}_${fixedField}`] = new FormControl(
          "", {updateOn: "blur"}
        );
      }
    }
    console.log(inputMappingControls);

    this.inputMappings = new FormGroup(inputMappingControls);

    this.chromeService.inSourceMgmt = true;

    this.id = this.activatedRoute.snapshot.params.id;

    

    const routeSnapshot: any = this.activatedRoute.snapshot;

    // Try to connect to extension
    this.chromeService.loadExtensionConnection();
    this.chromeService.sendMessage({ type: "enableSelector" });


    this.translationLanguages$.subscribe((data) => {
      this.languages = data;
      this.languagesDisplay = data;
    });


    // Add new Article
    if (routeSnapshot.url[2] && routeSnapshot.url[2].path === "new") {
      this.newHPS = true;
      this.type = "HPS";
      this.store.dispatch(clearMapping());
      this.chromeService.sendMessage({ type: "requestDocumentURL" });
    } else {
      this.store.dispatch(requestMapping({ domain: this.id }));
    }

    let bav = new Subject();
    bav.pipe(take(1)).subscribe(el => {
      this.orig_active_state = el;
    });

    // Initialize the store when new data is arriving
    this.mapping$.pipe(untilDestroyed(this)).subscribe((mapping: Mapping) => {
      // To make sure old data gets the new fields and make it editable...
      mapping = {entry_domains: [], amount_total: 0, amount_relevant: 0,
        truthfulness: 0, importance: 0, disinformation: false, active: true, note: "", ...mapping};

      bav.next(mapping.active);

      console.log("mapping", mapping);

      this.entry_domains = [...mapping.entry_domains];

      this.inputForm.setValue(
        {
          name: mapping.name,
          amount_total: mapping.amount_total,
          amount_relevant: mapping.amount_relevant,
          importance: mapping.importance,
          truthfulness: mapping.truthfulness,
          language: mapping.language,
          description: mapping.description,
          domain: mapping.domain,
          note: mapping.note,
          disinformation: mapping.disinformation,
          platforms: mapping.platforms,
          active: mapping.active,
        },
        { emitEvent: false }
      );

      // Build mappingcontrols

      const fixedFields = [
        "whitelist",
        "blacklist",
        "regex",
        "regexSelect",
        "result",
      ];


      const updateValueMappings = {};

      for (const defaultField of mapping.mappings) {
        for (const fixedField of fixedFields) {
          /* console.log(
            "def",
            `${defaultField.field}_${fixedField}`,
            defaultField[fixedField] || ""
          );*/
          updateValueMappings[`${defaultField.field}_${fixedField}`] =
            defaultField[fixedField] || "";
        }
      }
      console.log("valuemappings", updateValueMappings);
      this.mappings = [...mapping.mappings.map((e)=>({...e}))];
      this.inputMappings.setValue(updateValueMappings, { emitEvent: false });
    });
    // Update store when the form is changed
    this.inputForm.valueChanges
      .pipe(untilDestroyed(this), debounceTime(250), distinctUntilChanged())
      .subscribe((data: Mapping) => {
        this.store.dispatch(updateMapping({ mapping: data }));
      });

    this.inputMappings.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe((data: MappingField[]) => {
        this.store.dispatch(
          updateMapping({ mapping: { mappings: data } } as any)
        );
      });
  }
  ngOnDestroy() {
    this.chromeService.sendMessage({ type: "disableSelector" });
    this.chromeService.inSourceMgmt = false;
  }

  openLink(url){
    window.open(url)
  }

  triggerRegex(rowNumber) {
    const mapping = this.mappings[rowNumber];
    const regex = mapping.regex;
    const test = mapping.result;
    const regExp = new RegExp(regex).exec(test);
    if (regExp) {
      mapping.regexBuffer = regExp.map((element, indexint) => ({
        instruction: indexint,
        value: element,
      }));
      console.log(mapping.regexBuffer);
      console.log(mapping);
    } else {
      console.log("Regex failed");
    }
  }
  setFocus(index) {
    this.currentField = index;
    console.log(index);

    this.chromeService.setCurrentField(this.defaultFields[index]);
    
      this.currentFocus = index;
      /*
      if (
        this.mappings[this.currentFocus].querySelector.whitelist.trim().length > 0
      ) {
        this.chromeService.sendMessage({
          type: "restrict-blacklist",
          data: this.mappings[this.currentFocus].querySelector.whitelist,
        });
      }*/
      // this.onEnter(this.mappings[this.currentFocus].querySelector.whitelist.trim(),null);
  
  }
  openTab() {
    const url ="example.com";// TODO placeholder add logic
    window.open(url, "_blank");
  }
  openInSameOrigin() {
    const url ="example.com";// TODO placeholder add logic
    this.chromeService.changeCurrentTabUrl(url);
  }

  
  addEntryDomain(event: MatChipInputEvent): void {
    let value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.entry_domains.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.store.dispatch(
      updateMapping({ mapping: { entry_domains: this.entry_domains} } as any)
    );
  }

  removeEntryDomain(syn: string): void {
    const index = this.entry_domains.indexOf(syn);

    if (index >= 0) {
      this.entry_domains.splice(index, 1);
    }
    this.store.dispatch(
      updateMapping({ mapping: { entry_domains: this.entry_domains} } as any)
    );
  }

  
  onLanguageKey(input) {
    this.languagesDisplay = this.languages.filter(lang => {
      return ((lang.name.toLowerCase().startsWith(input.toLowerCase()) || lang.iso.toLowerCase().startsWith(input.toLowerCase())));
    });
  }

  backClicked() {
    this.router.navigateByUrl("sourceManagement");
  }
  
  async save() {
    // This entire HPS code is so extremely broken... I hate that i had to do the below...
    // Props to whoever wrote the HPS before me...
    console.log("Save...");

    let el: MappingField[] = this.inputMappings.value;
    this.store.dispatch(
      updateMapping({ mapping: { mappings: el } } as any)
    );

    // Disabled and enabled tell you when the active state was switched.
    let dat: any = this.inputForm.value;
    this.store.dispatch(
      // NOTICE: If no enabled set then use creation date.
      updateMapping({ mapping: {disabled: this.orig_active_state != dat.active && !dat.active ? new Date().toISOString() : null,
        ...(this.orig_active_state != dat.active && dat.active && {enabled: new Date().toISOString()})} 
      } as any)
    );

    this.store.dispatch(saveMapping());
    console.log("Saved!");
  }
  saveAndExtract() {
    this.mappings.forEach((el) => {
      console.log(el);
      delete el.regexBuffer;
      if (el.regex.length == 0 && el.regexSelect == "") {
        delete el.regexSelect;
        delete el.regex;
      }
    });
    console.log("asdasa", this);
    /* this.mappingService.saveMapping({
      status: this.status,
      statuscomment: this.statuscomment,
      organization: this.organization,
      platforms: this.platforms.value,
      originLink: this.originLink,
      type: this.type,
      domain: this.domain,
      name: this.name,
      mappings: this.mappings,
      language: this.language,
      description: this.description,
      id: this.id,
      created_at: this.created_at,
      created_by: this.created_by,
      updated_at: this.updated_at,
      updated_by: this.updated_by,
    });
    setTimeout(() => this.router.navigate(["/editor/new/" + this.id]), 1000);*/
  }
  delItem(pos) {
    this.mappings.splice(pos, 1);
  }
  onEnter(selector, i, event?, fromButton = false, useColor = true) {
    console.log("Onenter", event);
    this.chromeService.sendMessage({
      type: "updateSelector",
      data: { selector, index: i, option: { fromButton } },
      useColor,
    });
    if (event) {
      event.stopImmediatePropagation();
      event.preventDefault();
    }
  }
  toggleLinks(event) {
    this.chromeService.sendMessage({
      type: "toggleLinks",
      value: event.checked,
    });
  }
  dec(i) {
    this.chromeService.setCurrentField(this.defaultFields[i]);
    this.currentFocus = i;

    const splitLength = this.mappings[i].whitelistOrig.replace(/\s*>\s*/g, ">").replace(/\s*:/g, ":").split(/(?=:[^:])|(?=[>\.#])| /).filter((string) => string.trim().length > 0).length;
    if(this.mappings[i].whitelistCount<splitLength-1)
      this.mappings[i].whitelistCount++;
    this.comp(i);
  }

  inc(i) {
    this.chromeService.setCurrentField(this.defaultFields[i]);
    this.currentFocus = i;
    if(this.mappings[i].whitelistCount>0)
    this.mappings[i].whitelistCount--;
    this.comp(i);
  }
  comp(i) {
    console.log(this.mappings);
    const whit = this.mappings[i];
    if (whit && whit.whitelistOrig) {
      let test = whit.whitelistOrig;
      console.log(whit, test);
      test = test.replace(/\s*>\s*/g, ">");
      test = test.replace(/\s*:/g, ":");
      const splitted = test
        .split(/(?=:[^:])|(?=[>\.#])| /)
        .filter((string) => string.trim().length > 0);
      splitted.splice(
        splitted.length - this.mappings[i].whitelistCount,
        this.mappings[i].whitelistCount
      );

      let joinedString = splitted.join(" ");
      joinedString = joinedString.replace(/\s*\./g, ".");
      joinedString = joinedString.replace(/\s*#/g, "#");
      joinedString = joinedString.replace(/\s*:/g, ":");
      whit.whitelist = joinedString;
      this.store.dispatch(updateMappingField({ mapping: whit }));
      this.chromeService.sendMessage({type:'updateSelector', data: {selector:joinedString, index: i, option: {fromButton:true}}, useColor:true});
    }
  }

  promptDelete() {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.mappingService.deleteSourceMapping(this.id).then(() => {
          setTimeout(
            () => this.router.navigate(["/sourceManagement/HPSManagement"]),
            1000
          );
        });
      }
    });
  }

}
