import { Component, OnInit, Input } from "@angular/core";
import { FeatureGroup, circle, polygon, tileLayer, latLng } from "leaflet";
import * as mapData from "./countries";
import { NewsService } from "src/app/services/news.service";
import { MiscService } from "src/app/services/misc.service";
declare var L;
declare var HeatmapOverlay;
@Component({
  selector: "app-choropleth-graph",
  templateUrl: "./choropleth-graph.component.html",
  styleUrls: ["./choropleth-graph.component.scss"],
})
export class ChoroplethGraphComponent implements OnInit {
  optionsSpec: any = {
    layers: [
      {
        url: "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution: "",
      },
    ],
    zoom: 2,
    center: [25.274234, 0.455118],
  };

  layers: any = [
    circle([48.274234, 16.455118], { radius: 100 }),
    polygon([
      [46.8, -121.85],
      [46.92, -121.92],
      [46.87, -121.8],
    ]),
  ];
  data1 = {
    data: [],
  };

  // Leaflet bindings

  drawnItems = new FeatureGroup();
  drawOptions = {
    position: "topleft",
    draw: {
      marker: false,
      polyline: false,
      polygon: false,
      rectangle: false,
      circlemarker: false,
      circle: {
        shapeOptions: {
          color: "#aaaaee",
        },
      },
    },
    edit: {
      featureGroup: this.drawnItems,
    },
  };

  map;
  geojson;
  data;
  info;

  zoom = this.optionsSpec.zoom;
  center = latLng(this.optionsSpec.center);
  options = {
    // preferCanvas: true,
    layers: [
      // tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', { attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' }) ,
      tileLayer(this.optionsSpec.layers[0].url, {
        attribution: this.optionsSpec.layers[0].attribution,
      }),

      this.drawnItems,
    ],
    zoom: this.optionsSpec.zoom,
    center: latLng(this.optionsSpec.center),
  };

  constructor(
    private newsService: NewsService,
    private miscService: MiscService
  ) {}

  @Input("updateMap") updateMap$: any;

  ngOnInit() {
    const sub2 = this.updateMap$.subscribe((e) => {
      if (!this.map) return;
      console.log("map update called", e);
      if (e) {
        setTimeout(() => {
          this.map.invalidateSize();
        }, 10);
        console.log(this.map.getSize());
      }
    });
  }

  onMapReady(map: L.Map) {
    console.log("Map is ready");
    // Do stuff with map
    const legend = new (L.Control.extend({
      options: { position: "bottomright" },
    }))();

    legend.onAdd = function (map) {
      const div = L.DomUtil.create("div", "legend");
      div.innerHTML = "<div><b>Legend</b></div>";
      div.innerHTML +=
        '<i style="background:' +
        "green" +
        '"> &nbsp; &nbsp;</i> &nbsp; &nbsp;' +
        "+10<br>";
      div.innerHTML +=
        '<i style="background:' +
        "red" +
        '"> &nbsp; &nbsp;</i> &nbsp; &nbsp;' +
        "-10<br>";
      return div;
    };
    legend.addTo(map);

    const info = L.control();

    info.onAdd = function (map) {
      this._div = L.DomUtil.create("div", "info"); // create a div with a class "info"
      this.update();
      return this._div;
    };

    // method that we will use to update the control based on feature properties passed
    info.update = function (props) {
      this._div.innerHTML =
        "<h4>Goldsteinscale per Country</h4>" +
        (props
          ? "<b>" +
            props.name +
            "</b><br />" +
            props.goldsteinCount +
            " Entries<br>Avg. Goldstein Scale: " +
            props.goldstein
          : "Hover over a state");
    };

    info.addTo(map);

    this.info = info;

    this.map = map;
    // Fix for weird bug when host container has an ngIf, size is determined wrong
    setTimeout(() => {
      map.invalidateSize();
    }, 0);

    this.miscService.requestGoldstein().then((data) => {
      this.data = data;
      this.choroPlethInit();
    });
  }

  getColor(d) {
    return d > 5
      ? "#004400"
      : d > 3
      ? "#007600"
      : d > 1
      ? "#38A638"
      : d > 0
      ? "#D04646"
      : d == 0
      ? "#FFFFFF"
      : d < 0
      ? "#D04646"
      : d < 1
      ? "#930000"
      : d < 3
      ? "#930000"
      : d < -5
      ? "#550000"
      : "#420D0D";
  }
  style(feature) {
    // console.log(feature,this.getColor(feature.properties.density))

    const countries = this.data.filter(
      (feature2) => feature2.country == feature.properties.iso_a2
    );
    const country = countries.length == 1 ? countries[0] : null;
    // console.log(country)

    return {
      fillColor: this.getColor(
        country && country.avgGoldSteinScale ? country.avgGoldSteinScale : 0
      ),
      weight: 2,
      opacity: 1,
      color: "white",
      // dashArray: '3',
      fillOpacity: 0.7,
    };
  }
  choroPlethInit() {
    //  console.log(mapData.default)
    L.geoJson(mapData.default).addTo(this.map);
    this.geojson = L.geoJson(mapData.default, {
      style: (feature) => {
        return this.style(feature);
      },
      onEachFeature: (feature, layer) => {
        return this.onEachFeature(feature, layer);
      },
    }).addTo(this.map);
  }
  onEachFeature(feature, layer) {
    const search = this.data.find((e) => e.country == feature.properties.iso_a2);
    feature.properties.goldstein = search ? search.avgGoldSteinScale : null;
    feature.properties.goldsteinCount = search ? search.count : null;
    layer.on({
      mouseover: (e) => this.highlightFeature(e),
      mouseout: (e) => this.resetHighlight(e),
      click: (e) => this.zoomToFeature(e),
    });
  }
  highlightFeature(e) {
    const layer = e.target;
    // console.log("hover")
    layer.setStyle({
      weight: 5,
      color: "#666",
      dashArray: "",
      fillOpacity: 0.7,
    });
    this.info.update(layer.feature.properties);
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  }
  resetHighlight(e) {
    this.geojson.resetStyle(e.target);
  }
  zoomToFeature(e) {
    this.map.fitBounds(e.target.getBounds());
  }
}
