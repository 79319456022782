import { createReducer, on } from "@ngrx/store";
import { setDefaultPlatform } from "../actions/news.actions";

export const initialState = "cyber";

// tslint:disable-next-line: variable-name
const _defaultPlattformReducer = createReducer(initialState,
  on(setDefaultPlatform,(state, action) => {
    return action.platform;
    })
  );

export function defaultPlattformReducer(state, action) {
  return _defaultPlattformReducer(state, action);
}
