<!--<mat-sidenav-container class="example-container" [hasBackdrop]="false" autosize>


    <mat-sidenav  #drawer mode="side" [opened]="openMap$ | async" class="sidenav left-sidenav"  [fixedInViewport]="true">
        <div mwlResizable class="resizeConf" [enableGhostResize]="true"[resizeCursorPrecision]="25" [validateResize]="validate"  (resizeEnd)="onResizeEnd($event)" [ngStyle]="sidebarStyle">
            <editor-map style="width:100%;height:100%;" [updateMap]="updateMap$" ></editor-map>
            <div class="resize-handle-right"
            mwlResizeHandle
            [resizeEdges]="{ right: true }"></div>
        </div>

    </mat-sidenav>


    <mat-sidenav-content style="top:inherit;" [ngClass]="{'disableScroll': (openMap$ | async)}" >
        <div [ngClass]="{'gray': (openMap$ | async)}" (click)="closeMap()" style="display:none;">
            <div style="flex: 0 0 auto;color:white;font-size: xxx-large;">Click to return</div>
        </div>
        <app-editor [isNew]="true"  ></app-editor>

    </mat-sidenav-content>
</mat-sidenav-container>-->
<div >
<app-editor [isNew]="true" #editor></app-editor>
</div>