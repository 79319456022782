import { createAction, props } from "@ngrx/store";
import { News } from "../models/news.model";
import {
  NewsStats,
  StatsGeo,
  NewsCompound,
  StatsGeoHash,
} from "../models/news-stats.model";
import { SearchParameter } from "../models/search-parameter";
import { Tag } from "../models/tags.model";
import { Mapping, MappingField } from "../models/mapping.model";

export const requestNews = createAction("[News Component] Request News");
export const editSearch = createAction(
  "[News Component] Edit Search Request",
  props<{ state: any }>()
);
export const newsSucess = createAction(
  "[News Component] News Gdelt Array Loaded Success",
  props<{ news: { results: NewsCompound[]; count: number } }>()
);

export const checkItem = createAction(
  "[News Component] Check news",
  props<{ id: string}>()
);
export const confirmItem = createAction(
  "[News Component] Confirm news",
  props<{ id: string }>()
);

export const uncheckItem = createAction(
  "[News Component] Uncheck news",
  props<{ id: string}>()
);
export const unconfirmItem = createAction(
  "[News Component] Unconfirm news",
  props<{ id: string}>()
);

export const confirmPage = createAction(
  "[News Component] Confirm Page",
  props<{ids: string[]}>()
);

export const pageConfirmedSuccess = createAction(
  "[News Component] Confirm Page success"
);



export const checkItemSuccess = createAction(
  "[News Component] Check news success",
  props<{id:string, state:boolean, operator: string}>()
);

export const confirmItemSuccess = createAction(
  "[News Component] Confirm news success",
  props<{ id:string,state:boolean}>()
);

// Editor

export const enableCreateButton = createAction("[Editor Component] Enable Create Button");
export const disableCreateButton = createAction("[Editor Component] Disable Create Button");

export const addItem = createAction("[Editor Component] Add Item");
export const updateItem = createAction("[Editor Component] Update Item");

export const checkURLDuplicatesOnCreate = createAction("[Editor Component] Check URL Duplicates",props<{ url: string }>());

export const addItemSuccess = createAction(
  "[Editor Component] Add Item Sucess",
  props<{ news: any }>()
);
export const updateItemSuccess = createAction(
  "[Editor Component] Update Item Sucess",
  props<{ news: any }>()
);
export const resetArticleForm = createAction("[Editor Component] Reset Form");

export const deleteItem = createAction(
  "[Editor Component] Delete Item",
  props<{ id: string }>()
);
export const deleteItemSuccess = createAction(
  "[Editor Component] Delete Item Sucess"
);

export const updateContent = createAction(
  "[Editor Component] Update content"
);


// Editor Actions

export const requestArticle = createAction(
  "[Editor Component] Request Article",
  props<{ id: string }>()
);
export const updateArticle = createAction(
  "[Editor Component] Manual Article Update  ",
  props<News>()
);
export const programmaticArticleUpdate = createAction(
  "[Editor Component] Programmatic Article Update  ",
  props<News>()
);
export const articleSuccess = createAction(
  "[Editor Component] Article Load Success",
  props<{ news: News }>()
);

// Stats
export const requestStatsNews24h = createAction(
  "[Dashboard Component] Request news stats for last 24 hours",
  props<{ resolution: number, startDate: string, endDate: string }>()
);
export const statsNews24hSuccess = createAction(
  "[Dashboard Component] Request news stats for last 24 hours succeeded",
  props<{ news: NewsCompound }>()
);
export const statsNews24hGeomapSuccess = createAction(
  "[Dashboard Component] Request news geomap for last 24 hours succeeded",
  props<{ news: StatsGeoHash }>()
);

export const requestStatsUsers7d = createAction(
  "[Dashboard Component] Request user stats of last 7 days"
);
export const statsUsers7dSuccess = createAction(
  "[Dashboard Component] Request news stats for last 7 days succeeded"
);

export const requestStatsTagUsageCount = createAction(
  "[Dashboard Component] Request tag usage count for stats"
);
export const statsTagUsageCountSuccess = createAction(
  "[Dashboard Component] Request tag usage count succeeded",
  props<{ result: {key, doc_count}[] }>()
);
export const requestGeneralStats = createAction(
  "[Dashboard Component] Request general stats",
  props<{ timestamp: string }>()
);
export const generalStatsSuccess = createAction(
  "[Dashboard Component] Request general stats succeeded",
  props<{ result: any }>()
);
// Search Requets

export const searchRequest = createAction(
  "[Search Component] Search Request",
  props<{ request: SearchParameter }>()
);
export const addSearchTag = createAction(
  "[Search Component] Add Search Tag",
  props<{ tag: Tag }>()
);
export const removeSearchTag = createAction(
  "[Search Component] Remove Search Tag",
  props<{ tag: Tag }>()
);

export const addSearchTag2 = createAction(
  "[Search Component] Add Search Tag2",
  props<{ tag: Tag }>()
);
export const removeSearchTag2 = createAction(
  "[Search Component] Remove Search Tag2",
  props<{ tag: Tag }>()
);
export const changeSortField = createAction(
  "[Search Component] Change sort field or direction",
  props<{ direction, field }>()
);

export const setListView = createAction(
  "[Search Component] Set list view",props<{ value:boolean }>());


export const addGeoFilter = createAction(
  "[Map Component] Add Geo Filter",
  props<{ radius: number, coords:{lat:number,lng:number} }>()
);

export const removeGeoFilter = createAction("[Map Component] Remove Geo Filter");

// Pagination

export const changePage = createAction(
  "[News Component] Pagination updated",
  props<{ size: number; index: number }>()
);
export const reloadResults = createAction("[News Component] Reload Results");

// Errors
export const articleError = createAction(
  "[Editor Component] Article Gdelt Loaded Error"
);
export const duplicateURLError = createAction(
  "[Editor Component] Article Duplicate URL Error"
);
export const genericError = createAction(
  "Generic Error Occured",
  props<{ error: string }>()
);

export const factorsError = createAction("[Tree Component] Can't modiy!");
export const factorsErrorRoot = createAction(
  "[Tree Component] Can't modiy root!"
);

export const searchError = createAction(
  "[Search Component] Search Query Failed!"
);

// Notification

export const genericNotification = createAction(
  "[App Component] Generic Notification Triggered",
  props<{ info: string }>()
);

// Factors

export const requestFactors = createAction("[App Component] Request Factors");
export const requestFactorsSuccess = createAction(
  "[App Component] Request Factors succeeded",
  props<{ tags: Tag[] }>()
);

export const requestFactors2 = createAction("[App Component] Request Factors2");
export const requestFactors2Success = createAction(
  "[App Component] Request Factors 2succeeded",
  props<{ tags: Tag[] }>()
);

export const saveFactors = createAction(
  "[App Component] Save Factors",
  props<{ tags: Tag[] }>()
);
export const saveFactorsSuccess = createAction(
  "[Tree Component] Save Factors Success!"
);

/******************~~~Update Graphs~~~******************/
// Mentions

export const requestMentionsGraph = createAction(
  "[Mentions Graph] Request Data",
  props<{ id?: string }>()
);
export const requestMentionsGraphSuccess = createAction(
  "[Mentions Graph] Request Succeeded",
  props<{ data: any }>()
);

/******************~~~Video Manager~~~******************/

export const videoManagerUpdate = createAction(
  "[Video Service] Update",
  props<{ data: any }>()
);

export const fileUpload = createAction(
  "[Video Component] Upload Complete",
  props<{ name: string; id: string }>()
);
export const deleteFile = createAction(
  "[Video Component] Delete File",
  props<{ file: any, id:any }>()
);
export const fileUploadError = createAction("[Video Component] Uplaod Error");

/******************~~~Reports~~~******************/

export const setItem = createAction(
  "[News Component] Set Reports",
  props<{ id: string; operation: boolean }>()
);
export const setItemFeedback = createAction(
  "[News Component] Set Reports Feedback",
  props<{ operation: boolean }>()
);
export const loadReports = createAction("[Reports Component] Load Reports");
export const loadReportsSuccess = createAction(
  "[Reports Component] Load Reports Success",
  props<{ news: News[] }>()
);

export const createReport = createAction("[Reports Component] Create Report",props<{ summary: string }>());
export const createReportResult = createAction(
  "[Reports Component] Create Report Success"
);
export const resetReport = createAction(
  "[Report Component] Reset Report");
export const resetReportSuccess = createAction(
  "[Report Component] Reset Report Success"
);

/******************~~~Mappings~~~******************/

export const requestMappings = createAction(
  "[Mappings Component] Request Mappings"
);
export const requestMappingsSuccess = createAction(
  "[Mappings Component] Request Mappings Succeeded",
  props<{ mappings: Mapping[] }>()
);

/******************~~~Mapping~~~******************/

export const requestMapping = createAction(
  "[Detail Component] Request Mapping",
  props<{ domain: string }>()
);
export const updateMapping = createAction(
  "[Detail Component] Manual Mapping Update  ",
  props<{ mapping: Mapping }>()
);
export const updateMappingURL = createAction(
  "[Detail Component] Manual Mapping URL Update  ",
  props<{ update:{url:string,domain:string} }>()
);
export const updateMappingField = createAction(
  "[Detail Component] Manual MappingField Update  ",
  props<{ mapping: MappingField }>()
);
export const programmaticMappingUpdate = createAction(
  "[Detail Component] Programmatic Mapping Update  ",
  props<News>()
);
export const requestMappingSuccess = createAction(
  "[Detail Component] Request Mapping Succeeded",
  props<{ mapping: Mapping }>()
);
export const clearMapping = createAction(
  "[Detail Component] Clear Mapping Data"
);
export const saveMapping = createAction("[Detail Component] Save Mapping");
export const saveMappingSuccess = createAction(
  "[Detail Component] Save Mapping Succeeded"
);

/******************~~~Editor~~~******************/

export const addCurrentTag = createAction(
  "[Search Component] Add Current Tag",
  props<{ id: number }>()
);
export const removeCurrentTag = createAction(
  "[Search Component] Remove Current Tag",
  props<{ id: number }>()
);

/******************~~~Authentication~~~******************/

export const login = createAction(
  "[Login Component] Login",
  props<{ username: string; password: string; platform: string }>()
);
export const loginSucess = createAction(
  "[Search Component] Login successful",
  props<{ role: string, username:string }>()
);

export const logout = createAction("[Login Component] Logout");
export const logoutSucess = createAction(
  "[Search Component] Logout successful"
);

/******************~~~Analyzer~~~******************/

export const requestAnalyzer = createAction(
  "[Analyzer Component] Request analyzer stats for last 24 hours",
  props<{ resolution: number; fromDate?: string; toDate?: string }>()
);
// tslint:disable-next-line: max-line-length
export const analyzerSliderSuccess = createAction(
  "[Analyzer Component] Request analyzer stats for last 24 hours succeeded",
  props<{ news: NewsCompound }>()
);
export const analyzerGeomapSuccess = createAction(
  "[Analyzer Component] Request analyzer geomap for last 24 hours succeeded",
  props<{ news: StatsGeoHash }>()
);

/******************~~~Permissions~~~******************/

export const requestPermissions = createAction(
  "[Login Component] Request Permissions",
  props<{ role: string }>()
);
export const requestPermissionsSucess = createAction(
  "[Search Component] Request Permissions",
  props<{ permissions: any }>()
);

/******************~~~Semantic Graph~~~******************/

export const graphSearchRequest = createAction(
  "[Graph Search Component] Graph Search Request",
  props<{ request: SearchParameter }>()
);

export const setSemanticGraphPeople = createAction(
  "[Graph Search Component] Set People",
  props<{ people: any }>()
);

export const truncateSemanticGraph = createAction("[Graph Search Component] Truncate Graph");


/******************~~~Editor Map~~~******************/


export const updateEditorMap = createAction(
  "[Editor Map Component] Update Geo",
  props<{ place:string,lat:number,lon:number,iso_code:string}>()
);
export const openEditorMap = createAction(
  "[Editor Map Component] Open Editor Map"
);
export const closeEditorMap = createAction(
  "[Editor Map Component] Close Editor Map"
);

/******************~~~Plattform~~~******************/

export const setDefaultPlatform = createAction(
  "[Plattform Service] Set default Plattform",
  props<{ platform:string}>()
);

/******************~~~Translate~~~******************/

export const translateDocument = createAction(
  "[Editor Component] Translate current document"
);

export const translateDocumentSuccess = createAction(
  "[Editor Component] Translate current document success",
  props<{ content:string, title: string, subtitle: string, language:string}>()
);

export const requestDocumentLanguages = createAction(
  "[Editor Component] Request document languages"
);

export const requestDocumentLanguagesSuccess = createAction(
  "[Editor Component] Request document languages succeded",
  props<{ result: any[]}>()
);

export const saveDocumentLanguages = createAction(
  "[Editor Component] Save document languages",
  props<{ languages: any[]}>()
);

/******************~~~Export cForm~~~******************/
export const requestReindexCForm = createAction(
  "[Site Management] Request reindexation of cForm"
);
export const requestReindexCFormFinished = createAction(
  "[Site Management] The request for cform reindex has returned",
  props<{ failed:boolean}>()
);

/******************~~~Logging~~~******************/
export const requestLogEntrys = createAction(
  "[Site Management] Request general stats",
  props<{ fromDate: string, toDate: string, size: number, from: number, query: string, minLevel: number, category: string | null }>()
);
export const logEntrysReceived = createAction(
  "[Site Management] Request general stats succeeded",
  props<{ result: any }>()
);
