import { createReducer, on } from '@ngrx/store';
import {loginSucess,logoutSucess} from '../actions/news.actions';


export const initialState = {
  loggedIn: false,
  username:null,
  role: "restricted"
};

const _authenticationReducer = createReducer(initialState,
    on(loginSucess, (state, action) => {
        return {...state,loggedIn:true,username:action.username, role: action.role};
        // TODO migrate more info
    }),
    on(logoutSucess, (state, action) => {
        return {...state,loggedIn:false,username:null, role: "restricted"};
    }),

  );

export function authenticationReducer(state, action) {
    return _authenticationReducer(state, action);
  }
