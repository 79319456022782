import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'delete-diag',
  template: `
  
<div mat-dialog-content>
<p class="mat-h4">Do you really want to delete this entry?</p>
</div>
<div mat-dialog-actions>
<button mat-button color="warn" (click)="onClick(true)" cdkFocusInitial>Delete</button>
<button mat-button (click)="onClick(false)" cdkFocusInitial>Cancel</button>
</div>
  `,
})
export class DeleteDialogComponent {

  factor;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef < DeleteDialogComponent > ) {}

  onClick(wantDeleted: boolean): void {
      this.dialogRef.close(wantDeleted);
  }

}