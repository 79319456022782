<mat-card>
    <mat-form-field appearance="outline" class="full-width">
        <mat-label>Search</mat-label>
        <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Search">
        <mat-icon class="chooser" style="cursor:pointer" matSuffix [routerLink]="['/roleEditor/new']">note_add</mat-icon>
    </mat-form-field>
    <mat-card-title>Roles</mat-card-title>
    <table mat-table [dataSource]="roles"  class="full-width" matSort>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>   Option </th>
            <td mat-cell *matCellDef="let row"><button mat-raised-button (click)="navigateDetail(row)" style="float:right;">Edit</button></td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Role name </th>
            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>

    </table>
    <mat-paginator id="paginator" #paginator [length]="totalLength" [pageSize]="10" [pageSizeOptions]="[5,10,100,10000]" (page)="changePage($event)">
    </mat-paginator>
</mat-card>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" color="#fff" type="ball-scale-pulse"></ngx-spinner>
