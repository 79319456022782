<div gdAreas="form form | waiting active | completed completed" gdRows="auto auto" gdColumns="50% 50%">



    <mat-card gdArea="form">
        <mat-form-field appearance="outline" id="url-input" >
            <mat-label>Insert Video URL</mat-label>
            <input matInput placeholder="Placeholder" [(ngModel)]="url" name="url">
        </mat-form-field>

        <button mat-button mat-raised-button (click)="addToQueue()" id="search">Add to Queue</button>
    </mat-card>



    <mat-card gdArea="waiting">
        <mat-card-title>
            Items in Queue
        </mat-card-title>
        <mat-card-content>
        <table mat-table [dataSource]="waiting$" class="mat-elevation-z8">

            <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <ng-container matColumnDef="url">
                <th mat-header-cell *matHeaderCellDef> URL </th>
                <td mat-cell *matCellDef="let element"> {{element.url}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="progress">
                <th mat-header-cell *matHeaderCellDef> Progress </th>
                <td mat-cell *matCellDef="let element"> {{element.progress}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element"> waiting</td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </mat-card-content>
    </mat-card>

    <mat-card gdArea="active">
        <mat-card-title>
            Items in Queue
        </mat-card-title>
        <mat-card-content>
        <table mat-table [dataSource]="active$" class="mat-elevation-z8">

            <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <ng-container matColumnDef="url">
                <th mat-header-cell *matHeaderCellDef> URL </th>
                <td mat-cell *matCellDef="let element"> {{element.url}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="progress">
                <th mat-header-cell *matHeaderCellDef> Progress </th>
                <td mat-cell *matCellDef="let element"> {{element.progress}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element"> waiting</td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </mat-card-content>
    </mat-card>

    <mat-card gdArea="completed">
        <mat-card-title>
            Done Items
        </mat-card-title>
    <mat-card-content>
        <table mat-table [dataSource]="completed$" class="mat-elevation-z8">

            <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <ng-container matColumnDef="url">
                <th mat-header-cell *matHeaderCellDef> URL </th>
                <td mat-cell *matCellDef="let element"> {{element.url}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="progress">
                <th mat-header-cell *matHeaderCellDef> Progress </th>
                <td mat-cell *matCellDef="let element"> {{element.progress}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element"> completed </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element"> <mat-icon style="cursor:pointer;" (click)="download(element.token)">  cloud_download</mat-icon> </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </mat-card-content>
    </mat-card>

    <mat-card gdArea="stats">
        <mat-card-title>
            Stats
        </mat-card-title>
    <mat-card-content>
        <h1>Stats</h1>
    </mat-card-content>
    </mat-card>
</div>