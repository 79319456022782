import { requestMentionsGraphSuccess } from '../actions/news.actions';
import { createReducer, on } from '@ngrx/store';

export const initialState = [];

const _mentionsStatsReducer= createReducer(initialState,
    on(requestMentionsGraphSuccess, (state, action) => {
        return [...action.data];
    })
  );

export function mentionsStatsReducer(state, action) {
    return _mentionsStatsReducer(state, action);
  }
