<mat-card class="mat-elevation-z1" style="width:calc(100%-4em) ;margin:2em;margin-bottom:0">
    <h2>Beschreibung (optional)</h2>
    <textarea style="width:100%; " [(ngModel)]="summary">

    </textarea>
        <table style="width:100%" mat-table [dataSource]="newsTable$">
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef> Title</th>
                <td mat-cell *matCellDef="let row" [routerLink]="['/editor/'+row.id]" style="cursor:pointer;"> {{truncate(row.article_title)|| "No Title found"}} &nbsp;&nbsp;&nbsp;&nbsp;</td>
            </ng-container>
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef> Date</th>
                <td mat-cell *matCellDef="let row"> {{row.date | date}}&nbsp;&nbsp; </td>
            </ng-container>
            <ng-container matColumnDef="source">
                <th mat-header-cell *matHeaderCellDef> Source</th>
                <td mat-cell *matCellDef="let row"> GDELT </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action</th>
                <td mat-cell *matCellDef="let row"> <button mat-button (click)="deleteRow(row.id)">Delete</button> </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </mat-card>
    <mat-card style="width:calc(100%-4em) ;margin:2em;margin-top:-2px">
        <h2>Export Report</h2>
        <button mat-button (click)="download()">Generate Report & Download</button>
       <!-- <button mat-button (click)="download()"  [disabled]="!(gen$ | async)">Dowload</button>-->
        <button mat-button (click)="promptDelete()" color="warn" >Reset (Clears Entries)</button>
    </mat-card>