import { Action, createReducer, on } from '@ngrx/store';
import { statsTagUsageCountSuccess } from '../actions/news.actions';


export const tagCountFeatureKey = 'tagCount';


export const initialState: any = [];


export const tagUsageCountReducer = createReducer(
  initialState,
  on(statsTagUsageCountSuccess, (state, action) => {
    return [...action.result];
  })
);

