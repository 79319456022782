<div mat-dialog-content>
    <form [formGroup]="inputForm" class="form">
      <p class="mat-h4">UID: {{data.node.data.uid}} POS: {{data.node.data.position}} ID: {{data.node.id}}</p>
      <p>
        <mat-form-field style="width: calc(50% - .5em);" appearance="fill">
          <mat-label>Name</mat-label>
          <input matInput placeholder="Name" formControlName="name">
        </mat-form-field>
        <mat-form-field style="margin-left: 1em; width: calc(50% - .5em);" appearance="fill">
          <mat-label>EN-Name</mat-label>
          <input matInput placeholder="EN-Name" formControlName="nameEN">
        </mat-form-field>
      </p>
      <p>
        <mat-slide-toggle formControlName="colorToggle">
          Inherit color
        </mat-slide-toggle>
        <mat-form-field *ngIf="!this.inputForm.controls.colorToggle.value" style="margin-left: 1em;">
          <mat-label>Color</mat-label>
          <input matInput [ngxMatColorPicker]="picker" formControlName="color">
          <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
          <ngx-mat-color-picker #picker ></ngx-mat-color-picker>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Platform</mat-label>
          <mat-select formControlName="platform">
            <mat-option value="cyber">cyber</mat-option>
            <mat-option value="innotech">innotech</mat-option>
            <mat-option value="krimisi">krimisi</mat-option>
            <mat-option value="ALL">NONE/ALL</mat-option>
          </mat-select>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Synonyme</mat-label>
          <mat-chip-list #chipList aria-label="Syn selection">
            <mat-chip *ngFor="let syn of this.data.node.data.synonyme" (removed)="removeSyn(syn)">
              {{syn}}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
            <input placeholder="New synonym..."
                   [matChipInputFor]="chipList"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   [matChipInputAddOnBlur]="addOnBlur"
                   (matChipInputTokenEnd)="addSyn($event)">
          </mat-chip-list>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Description</mat-label>
          <textarea matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="50" formControlName="description"></textarea>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Note</mat-label>
          <textarea matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="50" formControlName="note"></textarea>
        </mat-form-field>
      </p>
      <p>
        <mat-checkbox formControlName="active">Active</mat-checkbox>
        <mat-checkbox formControlName="deleted" color="warn" style="margin-left: 1em;">Deleted</mat-checkbox>
    </p>
    </form>
  </div>
  <div mat-dialog-actions>
    <button
      mat-button
      color="warn"
      (click)="onClick(true)"
      cdkFocusInitial [disabled]="saveDisabled"
    >
      Save
    </button>&nbsp;
    <button mat-button (click)="onClick(false)">
      Abort
    </button>
  </div>