<mat-expansion-panel class="mat-elevation-z1" expanded="true">
    <mat-expansion-panel-header style="background-color: lightgray;">
        <mat-panel-title>
            Search
        </mat-panel-title>
        <mat-panel-description>
            Click here to expand search
        </mat-panel-description>
    </mat-expansion-panel-header>
    <div id="center">
        <!--<app-tri-state-checkbox  (change)="checkChange($event)">Checked</app-tri-state-checkbox>
    <app-tri-state-checkbox >Confirmed</app-tri-state-checkbox><br>-->

        <mat-form-field class="fullWidth" >
            <mat-label>Volltextsuche mit Operatoren</mat-label>
            <input matInput name="query"  #fulltext value="" [(ngModel)]="query">
            <mat-icon matSuffix matTooltip="e.G. Iran AND migration, USA OR china">info</mat-icon>
        </mat-form-field><br>

        <mat-form-field class="fullWidth" >
            <mat-label>Title</mat-label>
            <input matInput name="query" #titleTemp value="" [(ngModel)]="title">
        </mat-form-field><br>
        <!--<mat-form-field><input name="tags" matInput [formControl]="termCtrl" ></mat-form-field>-->

        <mat-form-field id="termChooser" class="fullWidth" >
                <mat-chip-list #chipList>
                    <mat-chip  *ngFor="let term of (searchParameters$ | async).tags"  (removed)="remove(term)">
                        {{term.name}}
                        <mat-icon matChipRemove >cancel</mat-icon>
                    </mat-chip>
                    <input  name="tags" [formControl]="termCtrl"  placeholder="Include Tags" #termInput  [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" matChipInputAddOnBlur="addOnBlur"  >
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" mat-min-length="0">
                    <ng-container *ngFor="let plat of tagsGroups$  | async" ><!--[label]="plat.type"-->
                        <mat-option [value]="plat">
                            <div  [matTooltip]="plat.id" style="display:inline-block">
                                {{plat.name}}
                            </div>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </mat-form-field><br>

            
            <mat-form-field id="termChooser2" class="fullWidth" >
                <mat-chip-list #chipList2>
                    <mat-chip  *ngFor="let term of (searchParameters$ | async).exTags"  (removed)="remove2(term)">
                        {{term.name}}
                        <mat-icon matChipRemove >cancel</mat-icon>
                    </mat-chip>
                    <input  name="tags" [formControl]="termCtrl2"  placeholder="Exclude Tags" #termInput2  [matAutocomplete]="auto2" [matChipInputFor]="chipList2" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" matChipInputAddOnBlur="addOnBlur"  >
                </mat-chip-list>
                <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="selected2($event)" mat-min-length="0">
                    <mat-option *ngFor="let term of tagsGroups2$ | async" [value]="term">
                        <div  [matTooltip]="term.id" style="display:inline-block">
                            {{term.name}}
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field><br>
            


<!--
        <mat-form-field id="termChooser">
            <mat-chip-list #chipList>
                <mat-chip *ngFor="let term of terms" [selectable]="selectable" [removable]="removable" (removed)="remove(term)">
                    {{term}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
               <input placeholder="Tags" #termInput name="w" [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-optgroup *ngFor="let plat of filteredTermsByPlatform" [label]="plat.name">
                    <mat-option *ngFor="let term of plat.observable | async" [value]="term">
                        <div [matTooltip]="term" style="display:inline-block">
                            {{term.split("\t")[term.split("\t").length-1]}}
                        </div>
                    </mat-option>
                </mat-optgroup>


            </mat-autocomplete>
        </mat-form-field><br>
-->

<!--
        <mat-form-field appearance="outline" class="selects">
            <mat-label>Language</mat-label>
            <mat-select name="language" [formControl]="selectedLanguages" multiple>
                <mat-option *ngFor="let language of languages" [value]="language.key" [matTooltip]="'Count: '+language.doc_count">
                    {{language.key}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="selects">
            <mat-label>Country</mat-label>
            <mat-select name="country" [formControl]="selectedCountries" multiple>
                <mat-option *ngFor="let country of countries" [value]="country.key" [matTooltip]="'Count: '+country.doc_count">
                    {{country.key}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        -->
        <mat-form-field appearance="outline" class="selects" *ngIf="(permissions$ | async).canSearch">
            <mat-label>Users</mat-label>
            <mat-select name="users" [formControl]="selectedUsers" (change)="updateSearch(false)" multiple>
                <mat-option disabled style="position: sticky; z-index: 1; top: 0; background-color: white; box-shadow: 1px -2px 10px 0px grey;">
                    <input matInput (keyup)="filterUsers($event.target.value)" placeholder="Search...">
                </mat-option>
                <mat-option *ngFor="let user of displayUsers" [value]="user.username">
                    {{user.username}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div id="timePicker" >
            <!-- <mat-form-field>
            <input matInput name="datepicker" formControlName="datepicker" placeholder="Choose a date" [satDatepicker]="picker" >
            <sat-datepicker #picker [rangeMode]="true">
            </sat-datepicker>
            <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
        </mat-form-field>-->

            <mat-form-field appearance="outline" class="selects">
                <mat-label>Datum von/bis</mat-label>
                <mat-date-range-input [formGroup]="searchDateRange" [rangePicker]="picker3">
                    <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="updateSearch(false)">
                    <input matEndDate formControlName="end" placeholder="End date" (dateChange)="updateSearch(false)">
                    <mat-error *ngIf="searchDateRange.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                    <mat-error *ngIf="searchDateRange.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                </mat-date-range-input>

                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-date-range-picker #picker3></mat-date-range-picker>
            </mat-form-field>
            <!--<mat-form-field appearance="outline">
                <mat-label>Datum bis</mat-label>
                <input matInput [matDatepicker]="picker4" name="datepicker2" (dateChange)="updateSearch(false)"  [formControl]="toDateCtrl">
                <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                <mat-datepicker #picker4></mat-datepicker>
            </mat-form-field> -->
            



            <!--
            <div >
                <div *ngFor="let plat of tagsGroups$ | async" >
                    {{plat.type}}
                        <div *ngFor="let term of plat.tags |async" >
                                {{term.id}}
                        </div>
                </div>
            </div>
        -->
        <button mat-button (click)="clearTimeRange()" style="position:relative;" >Clear time range</button>

        </div>
        <div >
            <span style="margin-right: 0.5em;position:relative;bottom: 0.25em; margin-left:0.5em;">Platforms: </span>
            <mat-checkbox [formControl]="cyber" (change)="updateSearch(false)" >Cyber</mat-checkbox>
            <mat-checkbox [formControl]="krimisi" (change)="updateSearch(false)">KriMiSi</mat-checkbox>
            <mat-checkbox [formControl]="innotech" (change)="updateSearch(false)">InnoTech</mat-checkbox>
        </div>
        <span *ngIf="(permissions$ | async).canSearch">
            <mat-slide-toggle [formControl]="ownEntries" (change)="updateSearch(false)" *ngIf="(permissions$ | async).canSearch" >Show only own entries</mat-slide-toggle>
        </span>
        <mat-slide-toggle (change)="onList($event)" style="margin-left:0.5em;">List View</mat-slide-toggle>
        
        <div style="width:100%">
            <mat-radio-group [formControl]="checked" (change)="updateSearch(false)">
                <mat-radio-button value="none" >Show all</mat-radio-button>
                <mat-radio-button value="checked">Only not checked</mat-radio-button>
                <mat-radio-button value="confirmed">Only checked but not confirmed</mat-radio-button>
            </mat-radio-group>

        </div>

       
        <ng-container *ngIf="(searchParameters$ | async).radius">
            Radius: {{(searchParameters$ | async).radius | number:'1.0-5'}}
            Lat: {{(searchParameters$ | async).lat | number:'1.0-5'}}
            Lon: {{(searchParameters$ | async).lng | number:'1.0-5'}}
        </ng-container>
    </div>
</mat-expansion-panel>
