import {
  Component,
  OnInit,
  ViewEncapsulation,
  EventEmitter,
  Output,
  Inject,
  AfterViewInit,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
declare var d3: any;
declare var $: any;
declare var _: any;

import { FormControl } from "@angular/forms";
import { Observable, Subject, BehaviorSubject, ReplaySubject, Subscription } from "rxjs";
import { startWith, map, withLatestFrom, debounceTime } from "rxjs/operators";
import { zoomIdentity, stratify } from "d3";
import { NewsService } from "../../services/news.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Store, select } from "@ngrx/store";
import { State } from "src/app/reducers";
import {
  factorsError,
  saveFactors,
  factorsErrorRoot,
  genericNotification,
  genericError,
} from "src/app/actions/news.actions";
import { Tag } from "src/app/models/tags.model";
import { selectCAMEO, selectCDRC } from "src/app/selectors/tag.selector";

@Component({
  selector: "tree",
  templateUrl: "./tag-editor.component.html",
  styleUrls: ["./tag-editor.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TagEditorComponent implements OnInit, AfterViewInit {
  constructor(
    private dialog: MatDialog,
    private newsService: NewsService,
    private store: Store<State>
  ) {}

  @Output() value: EventEmitter<any> = new EventEmitter();
  myControl = new FormControl();
  options: any = ["One", "Two", "Three"];
  filteredOptions: Observable<string[]>;

  // Svg settings

  dragOffsetX = 0;
  dragOffsetY = 0;

  create_node_modal_active = false;
  create_node_parent = null;
  rename_node_modal_active = false;
  node_to_rename = null;
  outer_update = null;
  translateCoords = null;
  panTimer = null;
  scale;
  node;
  dragStarted;
  domNode;
  links;
  nodes;
  rootNodeData;

  currentTree = "CDRC";
  currentData = [];

  updateGraph$ = new BehaviorSubject(2);

  tags$ = this.updateGraph$.asObservable().pipe(
    debounceTime(200),
    withLatestFrom(this.store.pipe(select(selectCDRC))),
    map(([first, second]) => {
      return second;
    })
  );

  tagsSubscription: Subscription;

  added = [];
  deleted = [];

  @ViewChild('importFile') importInputFile: ElementRef;
  @ViewChild('treeContainer') container: ElementRef;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.updateGraph$.next(Math.random());
  }

  matChange(event) {
    if (this.tagsSubscription)
      this.tagsSubscription.unsubscribe();
    console.log(event);
    if (event.value == "CAMEO") {
      d3.selectAll(".overlay").remove();
      this.tags$ = this.updateGraph$.pipe(
        debounceTime(200),
        withLatestFrom(this.store.pipe(select(selectCAMEO))),
        map(([first, second]) => {
          return second;
        })
      );
    } else {
      d3.selectAll(".overlay").remove();
      this.tags$ = this.updateGraph$.pipe(
        debounceTime(200),
        withLatestFrom(this.store.pipe(select(selectCDRC))),
        map(([first, second]) => {
          return second;
        })
      );
    }
    this.ngAfterViewInit();
  }


  importCDFZ(event: any) {
    let files = event.target.files;
    let fileReader = new FileReader();
    let platform = "ALL";
    let source = "CDRC";
    let permission = "USER";


    let tags = [];

    fileReader.onload = () => {
      try {
        var text: string | string[] = fileReader.result as string;
        text = text.split("\n");
        for (var i = 0; i < text.length; i++) {
          let element = text[i];
          let description = "";
          if (element.indexOf(";") > 0) {
            description = element.split(";")[1];
            if (description.endsWith("\r")) {
              description = description.substring(0, description.length - 1);
            }
            element = element.split(";")[0];
          }

          if (element.startsWith("<<MO")) {
            platform = element.split("\t")[1];
            if (platform.endsWith("\r")) {
              platform = platform.substring(0, platform.length - 1);
            }
            continue;
          }

          while (element.endsWith("\r")) {
            element = element.substring(0, element.length - 1);
          }

          while (element.endsWith("\t")) {
            element = element.substring(0, element.length - 1);
          }

          let tag = {
            name: element,
            id: element,
            permission: permission,
            type: platform,
            platform: platform,
            description: description,
            source: source
          };
          if (tag.name) {
            tags.push(tag);
          }
        }
        console.log("FILE IMPORT COMPLETED");
        console.log(tags);
        this.importInputFile.nativeElement.value = '';
        this.reInitFromArray(tags);
      } catch(e) {
        this.store.dispatch(genericError({ error: "Error on Tag import: " + e.name + ": " + e.message}));
      }
    };
    fileReader.readAsText(files[0]);
  }


  async reInitFromArray(tags: Tag[]) {
    this.tagsSubscription.unsubscribe();
    this.currentTree = "CDRC";
    d3.selectAll(".overlay").remove();
    //this.tags$ = (new BehaviorSubject(tags)).asObservable();
    this.tags$ = (new BehaviorSubject(tags)).asObservable();

    /*
    this.tags$ = this.updateGraph$.asObservable().pipe(
      debounceTime(200),
      withLatestFrom(this.test),
      map(([first, second]) => {
        return second;
      })
    );*/
    this.ngAfterViewInit();
  }


  exportTags() {
    let tags = this.currentlyDisplayedTagsToJson();
    this.downloadExport(tags);
  }

  downloadExport(tags: Tag[]) {
    let csv = [];
    let mode = "ALL";
    for (let i = 0; i < tags.length; i++) {
      let tag = tags[i] as any;
      if (tag.platform != mode) {
        csv.push("<<MODECHANGE>>\t" + tag.platform);
        mode = tag.platform;
      }

      let text = tag.name + (tag.description ? (";" + tag.description) : "");
      csv.push(text);
    }

    let csvArray = csv.join('\n');
    console.log(csvArray);
    var blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]),csvArray], {type: 'text/csv;charset=utf-8;' });
    const a = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
  
    a.href = url;
    a.download = 'output.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    this.store.dispatch(genericNotification({ info: "Tags exported to: output.csv"}));
}


  ngOnInit() {}
  ngAfterViewInit(): void {
    window.document.getElementById("tree-container").innerHTML = "";

    this.store.select("tags").subscribe((d) => {
      console.log("CURRENT DATA", d);
      this.currentData = d;
    });

    const that = this;
    //this.updateGraph$.next(Math.random());
    this.tagsSubscription = this.tags$.subscribe((data: any) => {
      if (data.length == 0) {
        return;
      }

      // data = [{id: 'ALL', name: 'ALL'}, ...data];
      console.log(data);
      /*
            const strat = stratify()
                .id((d) => d.name)
                  .parentId(function(d: any) {
                       if (d.id == 'ALL') { return null; }
                       else if (d.id.indexOf("ALL")>-1){
                           let splitted = d.id.split("\t");
                           return splitted[splitted.length-2];
                       }
                       else if (d.id.length == 2) { return 'ALL'; }
                        else {
                           return data.find((e) => e.id == d.id.substring(0, d.id.length - 1)).name;
                         }
                    // return  d.id == 'ALL' ? null : 'ALL';
                  });
            console.log(strat);
            const treed: any = strat(data)
                  .sort(function(a, b) {
                      console.log(a.id, b.id);
                      return (a.height - b.height) || a.id - b.id;
                  });

            (function transform(parent) {
                  const splitted = parent.id.split('\t');
                  parent.name = splitted.pop();
                  if (!parent.children || (parent.children && parent.children.length == 0)) { return; }
                  for (const node of parent.children) {
                      transform(node);
                  }
              })(treed);
            console.log(treed);
              */

      if (this.currentTree == "CDRC") {
        data = [...data.map((e) => ({ ...e }))];
        const strat = stratify().parentId(function (d: any) {
          // console.log(d)
          return d.id.substring(0, d.id.lastIndexOf("\t"));
        });


        try {
          var treed: any = strat(data).sort(function (a, b) {
            return a.height - b.height || a.id.localeCompare(b.id);
          });
        } catch(e) {
          this.store.dispatch(genericError({ error: "Error on Tag import: " + e.name + ": " + e.message}));
          throw e;
        }
        (function transform(parent) {
          const splitted = parent.id.split("\t");
          parent.name = splitted.pop();
          if (
            !parent.children ||
            (parent.children && parent.children.length == 0)
          ) {
            return;
          }
          for (const node of parent.children) {
            transform(node);
          }
        })(treed);
        console.log(treed);
      } else {
        data = [{ id: "ALL", name: "ALL" }, ...data];
        const strat = stratify()
          .id((d) => d.name)
          .parentId(function (d: any) {
            if (d.id == "ALL") {
              return null;
            } else if (d.id.indexOf("ALL") > -1) {
              const splitted = d.id.split("\t");
              return splitted[splitted.length - 2];
            } else if (d.id.length == 2) {
              return "ALL";
            } else {
              return data.find(
                (e) => e.id == d.id.substring(0, d.id.length - 1)
              ).name;
            }
            // return  d.id == 'ALL' ? null : 'ALL';
          });
        console.log(strat);
        var treed: any = strat(data).sort((a, b) => {
          console.log(a.id, b.id);
          return a.height - b.height || a.id - b.id;
        });

        (function transform(parent) {
          const splitted = parent.id.split("\t");
          parent.name = splitted.pop();
          if (
            !parent.children ||
            (parent.children && parent.children.length == 0)
          ) {
            return;
          }
          for (const node of parent.children) {
            transform(node);
          }
        })(treed);
      }

      console.log("TREE", treed);
      this.rootNodeData = treed;

      // Tree based on:  https://github.com/adamfeuer/d3js-tree-editor

      // Get JSON data
      const treeData = treed;
      console.log("TREEED", treeData);
      // Calculate total nodes, max label length
      let totalNodes = 0;
      let maxLabelLength = 0;
      // variables for drag/drop
      const selectedNode = null;
      const draggingNode = null;
      // panning variables
      const panSpeed = 200;
      const panBoundary = 20; // Within 20px from edges will pan when dragging.
      // Misc. variables
      let i = 0;
      const duration = 750;
      let root;

      // size of the diagram
      const viewerWidth = $(window).width();
      const viewerHeight = $(window).height();

      let tree = d3.layout.tree().size([viewerHeight, viewerWidth]);

      // define a d3 diagonal projection for use by the node paths later on.
      const diagonal = d3.svg.diagonal().projection(function (d) {
        return [d.y, d.x];
      });

      function checkReadOnly(node) {
        if (node.data.source == "CAMEO") {
          that.store.dispatch(factorsError());
          return false;
        } else if (node.id == "ALL") {
          that.store.dispatch(factorsErrorRoot());
          return false;
        } else {
          return true;
        }
      }

      const menu = [
        /*{
          title: "Rename node",
          action(elm, d, i) {
            if (!checkReadOnly(d)) {
              return;
            }
            console.log("Rename node");
            that.rename_node_modal_active = true;
            that.node_to_rename = d;
            that.rename_node();
          },
        },*/
        {
          title: "Delete node",
          action(elm, d, i) {
            if (!checkReadOnly(d)) {
              return;
            }
            console.log("Delete node");

            (function transform(parent) {
              that.deleted.push(parent);
              if (
                !parent.children ||
                (parent.children && parent.children.length == 0)
              ) {
                return;
              }
              for (const node of parent.children) {
                transform(node);
              }
            })(d);

            delete_node(d);
          },
        },
        {
          title: "Create child node",
          action(elm, d, i) {
            if (!checkReadOnly(d)) {
              return;
            }
            console.log("Create child node");
            that.create_node_parent = d;
            that.create_node_modal_active = true;
            that.create_node();
          },
        },
      ];

      // A recursive helper function for performing some setup by walking through all nodes

      function visit(parent, visitFn, childrenFn) {
        if (!parent) {
          return;
        }

        visitFn(parent);

        const children = childrenFn(parent);
        if (children) {
          const count = children.length;
          for (let i = 0; i < count; i++) {
            visit(children[i], visitFn, childrenFn);
          }
        }
      }

      // Call visit function to establish maxLabelLength
      visit(
        treeData,
        function (d) {
          totalNodes++;
          maxLabelLength = Math.max(d.name.length, maxLabelLength);
        },
        function (d) {
          return d.children && d.children.length > 0 ? d.children : null;
        }
      );

      function delete_node(node) {
        visit(
          treeData,
          function (d) {
            if (d.children) {
              for (const child of d.children) {
                if (child == node) {
                  d.children = _.without(d.children, child);
                  update(root);
                  break;
                }
              }
            }
          },
          function (d) {
            return d.children && d.children.length > 0 ? d.children : null;
          }
        );
      }

      // sort the tree according to the node names

      function sortTree() {
        tree.sort(function (a, b) {
          return b.name.toLowerCase() < a.name.toLowerCase() ? 1 : -1;
        });
      }
      // Sort the tree initially incase the JSON isn't in a sorted order.
      sortTree();

      // TODO: Pan function, can be better implemented.

      function pan(domNode, direction) {
        const speed = panSpeed;
        let translateX = null;
        let translateY = null;
        if (that.panTimer) {
          clearTimeout(that.panTimer);
          that.translateCoords = d3.transform(svgGroup.attr("transform"));
          if (direction == "left" || direction == "right") {
            translateX =
              direction == "left"
                ? that.translateCoords.translate[0] + speed
                : that.translateCoords.translate[0] - speed;
            translateY = that.translateCoords.translate[1];
          } else if (direction == "up" || direction == "down") {
            translateX = that.translateCoords.translate[0];
            translateY =
              direction == "up"
                ? that.translateCoords.translate[1] + speed
                : that.translateCoords.translate[1] - speed;
          }
          const scaleX = that.translateCoords.scale[0];
          const scaleY = that.translateCoords.scale[1];
          that.scale = zoomListener.scale();
          svgGroup
            .transition()
            .attr(
              "transform",
              "translate(" +
                translateX +
                "," +
                translateY +
                ")scale(" +
                that.scale +
                ")"
            );
          d3.select(domNode)
            .select("g.node")
            .attr(
              "transform",
              "translate(" + translateX + "," + translateY + ")"
            );
          zoomListener.scale(zoomListener.scale());
          zoomListener.translate([translateX, translateY]);
          that.panTimer = setTimeout(function () {
            pan(domNode, speed);
          }, 50);
        }
      }

      // Define the zoom function for the zoomable tree

      function zoom() {
        svgGroup.attr(
          "transform",
          "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")"
        );
      }

      // define the zoomListener which calls the zoom function on the "zoom" event constrained within the scaleExtents
      const zoomListener = d3.behavior
        .zoom()
        .scaleExtent([0.1, 3])
        .on("zoom", zoom);

      d3.selectAll(".overlay").remove();

      // define the baseSvg, attaching a class for styling and the zoomListener
      const baseSvg = d3
        .select("#tree-container")
        .append("svg")
        .attr("width", viewerWidth)
        .attr("height", viewerHeight)
        .attr("class", "overlay")
        .call(zoomListener);

      // Helper functions for collapsing and expanding nodes.

      function collapse(d) {
        if (d.children) {
          d._children = d.children;
          d._children.forEach(collapse);
          d.children = null;
        }
      }

      function expand(d) {
        if (d._children) {
          d.children = d._children;
          d.children.forEach(expand);
          d._children = null;
        }
      }

      // Function to center node when clicked/dropped so node doesn't get lost when collapsing/moving with large amount of children.

      function centerNode(source) {
        that.scale = zoomListener.scale();
        let x = -source.y0;
        let y = -source.x0;
        x = x * that.scale + viewerWidth / 2;
        y = y * that.scale + viewerHeight / 2;
        d3.select("g")
          .transition()
          .duration(duration)
          .attr(
            "transform",
            "translate(" + x + "," + y + ")scale(" + that.scale + ")"
          );
        zoomListener.scale(that.scale);
        zoomListener.translate([x, y]);
      }

      // Toggle children function

      function toggleChildren(d) {
        if (d.children) {
          d._children = d.children;
          d.children = null;
        } else if (d._children) {
          d.children = d._children;
          d._children = null;
        }
        return d;
      }

      // Toggle children on click.

      function click(d) {
        if (d3.event.defaultPrevented) {
          return;
        } // click suppressed
        d = toggleChildren(d);
        update(d);
        centerNode(d);
      }

      function update(source) {
        // Compute the new height, function counts total children of root node and sets tree height accordingly.
        // that prevents the layout looking squashed when new nodes are made visible or looking sparse when nodes are removed
        // that makes the layout more consistent.
        const levelWidth = [1];
        const childCount = function (level, n) {
          if (n.children && n.children.length > 0) {
            if (levelWidth.length <= level + 1) {
              levelWidth.push(0);
            }

            levelWidth[level + 1] += n.children.length;
            n.children.forEach(function (d) {
              childCount(level + 1, d);
            });
          }
        };
        childCount(0, root);
        const newHeight = d3.max(levelWidth) * 25; // 25 pixels per line
        tree = tree.size([newHeight, viewerWidth]);

        // Compute the new tree layout.
        const nodes = tree.nodes(root).reverse(),
          links = tree.links(nodes);

        // Set widths between levels based on maxLabelLength.
        nodes.forEach(function (d) {
          d.y = d.depth * (maxLabelLength * 10); // maxLabelLength * 10px
          // alternatively to keep a fixed scale one can set a fixed depth per level
          // Normalize for fixed-depth by commenting out below line
          // d.y = (d.depth * 300); //300px per level.
        });

        // Update the nodes…
        that.node = svgGroup.selectAll("g.node").data(nodes, function (d) {
          return d.id || (d.id = ++i);
        });
        console.log("SOURCE", source);
        // Enter any new nodes at the parent's previous position.
        const nodeEnter = that.node
          .enter()
          .append("g")
          .attr("class", "node")
          .attr("transform", function (d) {
            return "translate(" + source.y0 + "," + source.x0 + ")";
          })
          .on("click", click);

        nodeEnter
          .append("circle")
          .attr("class", "nodeCircle")
          .attr("r", 0)
          .style("fill", function (d) {
            return d._children ? "lightsteelblue" : "#fff";
          });

        nodeEnter
          .append("text")
          .attr("x", function (d) {
            return d.children || d._children ? -10 : 10;
          })
          .attr("dy", ".35em")
          .attr("class", "nodeText")
          .attr("text-anchor", function (d) {
            return d.children || d._children ? "end" : "start";
          })
          .text(function (d) {
            return d.name;
          })
          .style("fill-opacity", 0);

        // Update the text to reflect whether node has children or not.
        that.node
          .select("text")
          .attr("x", function (d) {
            return d.children || d._children ? -10 : 10;
          })
          .attr("text-anchor", function (d) {
            return d.children || d._children ? "end" : "start";
          })
          .text(function (d) {
            return d.id == "ALL" ? "" : d.data.name;
          });

        // Change the circle fill depending on whether it has children and is collapsed
        that.node
          .select("circle.nodeCircle")
          .attr("r", 4.5)
          .style("fill", function (d) {
            return d._children ? "lightsteelblue" : "#fff";
          });

        // Add a context menu
        that.node.on("contextmenu", d3.contextMenu(menu));
        console.log("D§ CONTEXT", d3.contextMenu);

        // Transition nodes to their new position.
        const nodeUpdate = that.node
          .transition()
          .duration(duration)
          .attr("transform", function (d) {
            return "translate(" + d.y + "," + d.x + ")";
          });

        // Fade the text in
        nodeUpdate.select("text").style("fill-opacity", 1);

        // Transition exiting nodes to the parent's new position.
        const nodeExit = that.node
          .exit()
          .transition()
          .duration(duration)
          .attr("transform", function (d) {
            return "translate(" + source.y + "," + source.x + ")";
          })
          .remove();

        nodeExit.select("circle").attr("r", 0);

        nodeExit.select("text").style("fill-opacity", 0);

        // Update the links…
        const link = svgGroup.selectAll("path.link").data(links, function (d) {
          return d.target.id;
        });

        // Enter any new links at the parent's previous position.
        link
          .enter()
          .insert("path", "g")
          .attr("class", "link")
          .attr("d", function (d) {
            const o = {
              x: source.x0,
              y: source.y0,
            };
            return diagonal({
              source: o,
              target: o,
            });
          });

        // Transition links to their new position.
        link.transition().duration(duration).attr("d", diagonal);

        // Transition exiting nodes to the parent's new position.
        link
          .exit()
          .transition()
          .duration(duration)
          .attr("d", function (d) {
            const o = {
              x: source.x,
              y: source.y,
            };
            return diagonal({
              source: o,
              target: o,
            });
          })
          .remove();

        // Stash the old positions for transition.
        nodes.forEach(function (d) {
          d.x0 = d.x;
          d.y0 = d.y;
        });
      }

      that.outer_update = update;

      // Append a group which holds all nodes and which the zoom Listener can act upon.
      const svgGroup = baseSvg.append("g");

      // Define the root
      root = treeData;
      root.x0 = viewerHeight / 2;
      root.y0 = 0;

      // Layout the tree initially and center on the root node.
      update(root);
      centerNode(root);
      // tree_root = root;
    });

    $(document).on("opened", "[data-reveal]", function () {
      const element = $(".inputName:visible").first();
      element.focus(function () {
        this.selectionStart = this.selectionEnd = this.value.length;
      });
      element.focus();
    });
    $("#RenameNodeName").keyup((e) => {
      if (e.keyCode == 13) {
        this.rename_node();
      }
    });
    $("#CreateNodeName").keyup((e) => {
      if (e.keyCode == 13) {
        this.create_node();
      }
    });
  }

  create_node() {
    const dialogRef = this.dialog.open(FactorDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (this.create_node_parent && this.create_node_modal_active) {
        if (this.create_node_parent._children != null) {
          this.create_node_parent.children = this.create_node_parent._children;
          this.create_node_parent._children = null;
        }
        if (this.create_node_parent.children == null) {
          this.create_node_parent.children = [];
        }
        console.log(result,"kek","parent",this.create_node_parent)
        const new_node = {
          name: this.create_node_parent.id + "\t" + result.id,
          id: this.create_node_parent.id + "\t" + result.id,
          data: {
            description: result.description,
            id: this.create_node_parent.id + "\t" + result.id,
            type: this.create_node_parent.data.platform=="ALL"?result.type:this.create_node_parent.data.platform,
            platform: this.create_node_parent.data.platform=="ALL"?result.type:this.create_node_parent.data.platform,
            permission: result.permission,
            source: "WEB-GUI-CDRC",
            name: this.create_node_parent.id + "\t" + result.id,
          },
          depth: this.create_node_parent.depth + 1,
          parent: this.create_node_parent,
          children: [],
          _children: null,
        };

        console.log("NEWNODE", new_node);

        this.added.push(new_node);

        this.create_node_parent.children.push(new_node);
        console.log(this.create_node_parent);
        this.create_node_modal_active = false;
      }
      this.outer_update(this.create_node_parent);
    });
  }

  rename_node() {
    const dialogRef = this.dialog.open(FactorDialogComponent, {
      data: this.node_to_rename.data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (this.node_to_rename && this.rename_node_modal_active) {
        const name = result;
        console.log("New Node name: ", this.node_to_rename, name);

        this.node_to_rename.data.name = name.id;
        this.rename_node_modal_active = false;
      }
      this.outer_update(this.node_to_rename);
    });
  }

  private _filter(value: any): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }
  onSubmit(e) {
    e.preventDefault();
    this.value.emit(this.myControl.value);
  }
  onAuto() {
    this.value.emit(this.myControl.value);
  }


  currentlyDisplayedTagsToJson() {
    console.log(this.rootNodeData);
    const flattedTree: Tag[] = [];
    (function transform(parent): any {
      flattedTree.push(parent.data);
      if (
        !parent.children ||
        (parent.children && parent.children.length == 0)
      ) {
        return;
      }
      for (const node of parent.children) {
        transform(node);
      }
    })(this.rootNodeData);
    console.log("old", this.currentData);
    console.log("new", flattedTree);
    console.log(this.deleted);
    console.log(this.added);

    const deletedIDsBH = this.deleted.map((e) => e.id);

    const filteredTags = flattedTree.filter(
      (e) => !deletedIDsBH.includes(e.id)
    );
    const out = [...filteredTags, ...this.added.map((d) => d.data)];
    console.log(out);
    return out;
  }


  save() {
    let tags = this.currentlyDisplayedTagsToJson();
    this.store.dispatch(saveFactors({ tags: tags }));
  }


  isTreeShown() {
    try {
      if (this.container.nativeElement.firstChild) {
        return true;
      }
      return false;
    } catch(e) {
      return false;
    }
  }
}

@Component({
  selector: "factor-dialog",
  template: `
    <div mat-dialog-content>
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="factor" /> </mat-form-field
      ><br />
      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <input matInput [(ngModel)]="description" /> </mat-form-field
      ><br />

      <mat-form-field>
        <mat-label>Visibility</mat-label>
        <select matNativeControl required [(ngModel)]="permission">
          <option value="user">User</option>
          <option value="admin">Admin</option>
        </select> </mat-form-field
      ><br />

      <mat-form-field>
        <mat-label>Platform</mat-label>
        <select matNativeControl required [(ngModel)]="platform">
          <option value="ALL">Alle</option>
          <option value="cyber">Cyber</option>
          <option value="innotech">InnoTech</option>
          <option value="krimisi">KriMiSi</option>
        </select>
      </mat-form-field>
      <!--
    <mat-form-field>
        <mat-label>Factor Type</mat-label>
        <select matNativeControl required [(ngModel)]="platform">
            <option value="undefined">Undefined</option>
            <option value="push">Push</option>
            <option value="pull">Pull</option>
        </select>
    </mat-form-field><br>-->
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onClick()" cdkFocusInitial>Ok</button>
    </div>
  `,
})
export class FactorDialogComponent {
  factor;
  permission = "user";
  platform = "ALL"; // TODO undefined for gdelt tree
  description = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FactorDialogComponent>
  ) {
    if (data) {
      this.factor = data.id.split("\t").pop();
      if (data.permission) {
        this.permission = data.permission;
      }
      if (data.platform) {
        this.platform = data.platform;
      }
      if (data.description) {
        this.platform = data.description;
      }
    }
  }

  onClick(): void {
    this.dialogRef.close({
      id: this.factor,
      type: this.platform,
      permission: this.permission,
      description: this.description,
    });
  }
}
