import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Tag } from '../models/tags.model';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  tags$ = this.store.select((store)=>store.tags);
  tagMapping$ = this.tags$.pipe(map((tags) => {
    let toTag = new Map<number, any>();
    tags.forEach((tag) => {
      toTag.set(tag.uid, tag);
    });
    return toTag;
  }));

  constructor(private http: HttpClient, private store: Store<State>) {

  }



  // TODO Mapping von Tag IDs auf Tag Full Name

  public saveFactors(factors: Tag[]) {
      const httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json'
          })
      };
      return this.http.post(`${environment.SERVER_URL}/tags`, factors, httpOptions);
  }


  requestFactors(): Observable < Tag[] > {
      return this.http.get(`${environment.SERVER_URL}/tags`) as Observable < Tag[] >;
  }

}
