import { createReducer, on } from '@ngrx/store';
import { requestFactorsSuccess } from '../actions/news.actions';


export const initialState = new Map<number, any>();

const _tagMappingReducer = createReducer(initialState,
    on(requestFactorsSuccess, (state, action) => {
        let toTag = new Map<number, any>();
        action.tags.forEach((tag) => {
          if (!tag.deleted)
            toTag.set(tag.uid, tag);
        });
        return toTag;
    })
  );

export function tagMappingReducer(state, action) {
    return _tagMappingReducer(state, action);
  }
