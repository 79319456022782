import {ActionReducer, Action} from '@ngrx/store';
import {merge, pick,omit} from 'lodash-es';

function setSavedState(state: any, localStorageKey: string) {
  localStorage.setItem(localStorageKey, JSON.stringify(state));
}
function getSavedState(localStorageKey: string): any {
  return JSON.parse(localStorage.getItem(localStorageKey));
}

// the keys from state which we'd like to save.
const stateKeys = ['currentSearch','tags','defaultPlattform', 'tagMapping', 'generalStats', 'tagUsageCount', 'permissions'];
const filteredKeys = ['lat','lng','radius','listView'];
// the key for the local storage.
const localStorageKey = '__app_storage__';

export function storageMetaReducer<S, A extends Action = Action>(reducer: ActionReducer<S, A>) {
  let onInit = true; // after load/refresh…
  return function(state: S, action: A): S {
    // reduce the nextState.
    const nextState = reducer(state, action);
    // init the application state.
    if (onInit) {
      onInit           = false;
      const savedState = getSavedState(localStorageKey);
      return merge(nextState, savedState);
    }
    // save the next state to the application storage.
    const stateToSave = pick(nextState, stateKeys);
    if(stateToSave.currentSearch)
      stateToSave.currentSearch = omit(stateToSave.currentSearch,filteredKeys)
    console.log("savedstate",stateToSave)
    setSavedState(stateToSave, localStorageKey);
    return nextState;
  };
}
