import { Component, OnInit, ViewChild } from "@angular/core";
import { NewsService } from "../../../services/news.service";
import { Router } from "@angular/router";
import * as SHA256 from "crypto-js/sha256";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { RoleService } from "../../../services/role.service";

@Component({
  selector: "app-role-manager",
  templateUrl: "./role-manager.component.html",
  styleUrls: ["./role-manager.component.css"],
})
export class RoleManagerComponent {
  @ViewChild("paginator") paginator: MatPaginator;
  constructor(private roleService: RoleService, private router: Router) {}
  DATA;

  roles;

  columnsToDisplay = ["name", "id"];
  totalLength;

  skip = 0;
  show = 10;

  search = "";
  size = 1;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngAfterViewInit() {
    this.roleService.getRoles().then((data: any) => {
      console.log(data);

      this.DATA = data;
      this.roles = new MatTableDataSource();
      this.roles.sort = this.sort;
      this.roles.data = this.DATA;
      this.totalLength = this.DATA.length;
      this.roles.paginator = this.paginator;
    });
  }
  public doFilter = (value: string) => {
    this.roles.filter = value.trim().toLocaleLowerCase();
  };

  changePage(event) {
    console.log("event", event);
    this.show = event.pageSize;
    this.skip = event.pageSize * event.pageIndex;
    this.roles.data = this.DATA;
  }

  navigateDetail(row) {
    const url = `roleEditor/` + row.id;
    console.log(url);
    this.router.navigate([url]);
  }
}
