
<form [formGroup]="inputForm">
    <div fxLayout="row wrap" fxLayoutAlign="start none"  style="background-color: white;width:100%;border-bottom: 1px solid rgba(0, 0, 0, 0.12);">
        <button type="button" mat-raised-button color="accent" id="saveButton" [disabled]="createButtonDisabled$ | async" (click)="updateData(newArticle)">{{newArticle?'Create':'Update'}}</button>
        <button type="button" mat-raised-button color="primary" class="saveButton2" *ngIf="isExtension" (click)="extractData()" >Extract</button>
        <button type="button" mat-raised-button color="primary" class="saveButton2" *ngIf="!(article$ | async).article_checked" (click)="serverSideExtract({detailed: false})" >Fast Extract</button>
        <button type="button" mat-raised-button color="primary" class="saveButton2" *ngIf="!(article$ | async).article_checked" (click)="serverSideExtract({detailed: true})" >Detail Extract</button>
        <button type="button" mat-button mat-raised-button class="saveButton2" *ngIf="!(article$ | async).article_checked" (click)="translate()" >Translate</button><!--*ngIf="news.id && !checkReport(news.id,reports$ | async)"-->
    <!--  <button mat-raised-button color="primary" id="saveButton" *ngIf="isExtension" >Extract with Screenshot</button><!--(click)="extractData(true)"-->
    <!-- <button mat-raised-button color="warn" id="resetButton" (click)="resetForm()">Reset</button>-->
        <button type="button" mat-raised-button color="warn" *ngIf="!newArticle" class="saveButton2" (click)="promptDelete()">Delete</button>
        <button type="button" mat-button mat-raised-button class="saveButton2"  *ngIf="!newArticle" #check  (click)="addToReport(news.id)" >Add to Report</button><!--*ngIf="news.id && !checkReport(news.id,reports$ | async)"-->
        <div fxLayout="row" fxLayoutAlign="space-between" style="margin-left: 1em; margin-top: 1em; flex-direction: row; box-sizing: border-box; display: flex; place-content: stretch space-between; align-items: stretch; min-width: 300px;" *ngIf="((permissions$ | async).canMarkEntriesChecked || (permissions$ | async).canMarkEntriesConfirmed) && !isNew">
            <mat-checkbox formControlName="article_checked" (change)="onCheck($event)" *ngIf="(permissions$ | async).canMarkEntriesChecked">Checked</mat-checkbox> 
            <mat-checkbox formControlName="article_confirmed" (change)="onConfirm($event)" *ngIf="(permissions$ | async).canMarkEntriesConfirmed" [disabled]="!(article$ | async).article_checked && !(article$ | async).article_confirmed">Confirmed</mat-checkbox>
        </div>
    </div>

<div fxLayout="row">
    <mat-tab-group animationDuration="0ms" style="height:100%;" fxFlex="100 0 300px">
        <mat-tab label="Native">
            
            <div id="inputCenter">

                <div id="centering" fxLayout="row wrap" fxLayoutAlign="space-evenly" style="position:relative; margin:auto;" [ngStyle]="embedded?{width:'100%'}:{}">
                    <mat-card id="contentMiddle" style="height: 100%;" >
                        <div class="optionsContainer" fxShow.lg.display fxShow="true" *ngIf="false"><!--check for gdelt-->
                            <div  fxLayout="row" fxLayoutAlign="space-between">
                                <div formArrayName="article_platforms" class="select">
                                    <ng-container *ngFor="let platform of article_platforms.controls; index as i">
                                        <mat-checkbox [formControlName]="i">{{platforms[i].name}}</mat-checkbox>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <mat-card-content fxLayout="column">
                        <mat-chip-list>
                        <mat-chip *ngIf="news && news.id && checkReport(news.id,reports$ | async)" [matTooltip]="news.id" >Reported<mat-icon matChipRemove (click)="deleteReport(news.id)">cancel</mat-icon></mat-chip></mat-chip-list>    <!---->
                        <mat-chip-list>
                            <ng-container *ngFor="let tag of displayTags">
                                <mat-chip *ngIf="isNew || containsTagById(this.rawDetailTags, tag.id)" [ngStyle]="this.rawDetailTags[this.rawDetailTags.length - 1].id == tag.id && isNew ? {'background-color': tag.color, 'color': 'red'} : {'background-color': tag.color}"  [matTooltip]="tag.id" class="chips">
                                        {{tag.id.split("\t")[tag.id.split("\t").length-1]}}
                                    <mat-icon *ngIf="(!(article$ | async).article_checked) && containsTagById(this.rawDetailTags, tag.id)" matChipRemove (click)="deleteTag(tag.uid)">cancel</mat-icon>
                                </mat-chip>
                            </ng-container>
                        </mat-chip-list>
                            <div fxLayout="row" fxLayoutAlign="space-between">
                                    <!--<button mat-mini-fab  (click)="checkAndGoBack()"  *ngIf="!newArticle" id="backButton">
                                            <mat-icon aria-label="back" >chevron_left</mat-icon>
                                        </button>-->
                                        <mat-form-field appearance="outline" style="padding-bottom:10px;width:100%;">
                                                <mat-label>Source</mat-label>
                                                <input matInput formControlName="article_source" name="source" (focusout)="checkURLDuplicates()">
                                                <mat-icon class="chooser" matSuffix (click)="openTab()">open_in_new</mat-icon>
                                        </mat-form-field>
                                    <!-- <button mat-mini-fab id="nextButton"  *ngIf="!newArticle"  (click)="checkAndGoNext()" [matTooltip]="'Confirmed:'+authService.settings.confirmed+' '+'Check:'+authService.settings.check">
                                                <mat-icon aria-label="next">chevron_right</mat-icon>
                                            </button>-->
                            </div>
                            <mat-form-field appearance="outline">
                                <mat-label>Title</mat-label>
                                <input matInput style="font-size:2em;" formControlName="article_title" name="first">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Subtitle</mat-label>
                                <input matInput formControlName="article_subtitle" name="subtitle">
                            </mat-form-field>
                            <mat-form-field appearance="outline" *ngIf="isGDELT">
                                <mat-label>Date</mat-label>
                                <input matInput [matDatepicker]="picker2" [max]="maxDate" formControlName="article_created_at">
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker> 
                            </mat-form-field>
                            <p style="color:rgba(0, 0, 0, 0.6);margin-bottom: 0.3em;margin-left:0.6em;">Content</p>
                            <ckeditor [editor]="Editor" (ready)="onCKReady($event)" formControlName="article_content" id="editck"></ckeditor>

                        </mat-card-content>
                        <mat-card-actions id="buttonContainer" >
                            
                        </mat-card-actions>
                        <div *ngIf="zoomToggle" style="width:100%;height:100%;position: fixed;top:0;left:0;" ></div><!--(click)="zoomPreview()"-->
                        <div style="position:relative;">
                            <img id="preview" *ngIf="displayPreview" [class.zoom-in]="zoomToggle" [src]="previewData" class="zoom" [style.width]="previewWidth" [style.height]="previewHeight"><!--(click)="zoomPreview()"-->
                            <!--<mat-icon aria-label="back"  *ngIf="!zoomToggle" style="position: relative;left:-100px;top:-40px;">zoom_in</mat-icon>-->
                        </div>
                        <br>
                    <mat-expansion-panel *ngIf="isGDELT"><!--TODO gdelt-->
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Gdelt Fields
                            </mat-panel-title>
                            <mat-panel-description>

                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-list>
                            <mat-list-item class="list" *ngFor="let entry of gdeltToArray()" fxLyout="row wrap"> <b>{{entry.key}} </b>
                                <div style="flex:1 0 auto"></div>{{entry.value | json}}
                            </mat-list-item>
                        </mat-list>
                    </mat-expansion-panel>
                    </mat-card>


                    


                </div>
            </div>
            
        </mat-tab>
        <mat-tab label="Translated" style="position: relative;height:100%">
            <!--<div style="width:100%;height:100%;background-color: black; opacity: 0.7; pointer-events: all;position: absolute;z-index:9999;display: flex;
            justify-content: center;
            align-items: center;">
                <div style="flex: 0 0 auto;color:white;font-size: xxx-large;">TBD - in progress</div>
            </div>-->
                <div id="inputCenter2">
        
                    <div class="centering" fxLayout="row wrap" fxLayoutAlign="space-evenly" style="position:relative; margin:auto;" [ngStyle]="embedded?{width:'100%'}:{}">
                        <mat-card id="contentMiddle" fxFlex="100 0 400px">
                            <mat-chip-list>
                                <mat-chip *ngIf="news && news.id && checkReport(news.id,reports$ | async)" [matTooltip]="news.id" >Reported<mat-icon matChipRemove (click)="deleteReport(news.id)">cancel</mat-icon></mat-chip></mat-chip-list>    <!---->
                                <mat-chip-list>
                                    <ng-container *ngFor="let tag of displayTags">
                                        <mat-chip *ngIf="isNew || containsTagById(this.rawDetailTags, tag.id)" [ngStyle]="this.rawDetailTags[this.rawDetailTags.length - 1].id == tag.id && isNew ? {'background-color': tag.color, 'color': 'red'} : {'background-color': tag.color}"  [matTooltip]="tag.id" class="chips">
                                            {{tag.id.split("\t")[tag.id.split("\t").length-1]}}
                                        <mat-icon *ngIf="(!(article$ | async).article_checked) && containsTagById(this.rawDetailTags, tag.id)" matChipRemove (click)="deleteTag(tag.uid)">cancel</mat-icon>
                                        </mat-chip>
                                    </ng-container>
                                </mat-chip-list>
                            <mat-card-content fxLayout="column">
                                <mat-form-field appearance="outline" style="padding-bottom:10px;width:100%;">
                                    <mat-label>Source</mat-label>
                                    <input matInput formControlName="article_source" name="source" (focusout)="checkURLDuplicates()">
                                    <mat-icon class="chooser" matSuffix (click)="openTab()">open_in_new</mat-icon>
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-label>Title</mat-label>
                                    <input  matInput style="font-size:2em;" formControlName="article_title_translated" name="first">
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>Subtitle</mat-label>
                                    <input  matInput formControlName="article_subtitle_translated" name="subtitle">
                                </mat-form-field>

                                <p style="color:rgba(0, 0, 0, 0.6);margin-bottom: 0.3em;margin-left:0.6em;">Content</p>
                                <ckeditor  [editor]="Editor2" (ready)="onCKReady2($event)" formControlName="article_content_translated" id="editck2"></ckeditor>
        
                            </mat-card-content>
                        </mat-card>
        
        
                    </div>
                </div>
        
                

        </mat-tab>
    </mat-tab-group>
    <!-- Begin right side bar -->
    <div id="sideRight" fxFlex="1 0 400px">
        <mat-tab-group animationDuration="0ms" style="height:100%;">
            <mat-tab label="Metadata">
                <mat-card class="sideCardLeft" fxLayout="column" style="height: 100%;">

                    <mat-form-field appearance="outline">
                        <mat-label>Date</mat-label>
                        <input  matInput [matDatepicker]="picker2" [max]="maxDate" formControlName="article_created_at">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Author</mat-label>
                        <input matInput formControlName="article_author" name="author">
                    </mat-form-field>
                    
                    <mat-form-field appearance="outline">
                        <mat-label>Language Source</mat-label>
                        <mat-select  formControlName="article_language" >
                            <mat-option disabled style="position: sticky; z-index: 1; top: 0; background-color: white; box-shadow: 1px -2px 10px 0px grey;">
                                <input matInput (keyup)="onLanguageSourceKey($event.target.value)" placeholder="Search...">
                            </mat-option>
                            <ng-container *ngFor="let language of this.languagesSource">
                                <mat-option *ngIf="language.languageSource" [value]="language.iso">
                                    {{language.name}}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Language Document</mat-label>
                        <mat-select formControlName="article_languageErfassung">
                            <mat-option disabled style="position: sticky; z-index: 1; top: 0; background-color: white; box-shadow: 1px -2px 10px 0px grey;">
                                <input matInput (keyup)="onLanguageDocumentKey($event.target.value)" placeholder="Search...">
                            </mat-option>
                            <ng-container *ngFor="let language of this.languagesDocument">
                                <mat-option *ngIf="language.languageDocument" [value]="language.iso">
                                    {{language.name}}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Place</mat-label>
                        <input matInput formControlName="article_place" name="place">
                        <button [disabled]="(article$ | async).article_checked" type="button" matSuffix mat-button (click)="openMap()" ><mat-icon >map</mat-icon></button>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Latitude</mat-label>
                        <input  matInput formControlName="lat" name="lat">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Longitude</mat-label>
                        <input  matInput formControlName="lon" name="lon">
                    </mat-form-field>
                    <a style="margin-bottom: 1.24375em;" *ngIf="(article$ | async).article_raw_url" mat-raised-button color="primary" aria-label="Open downloaded raw content" [href]="(article$ | async).article_raw_url" target="_blank">
                        Open Raw Content<mat-icon>offline_pin</mat-icon>
                    </a>
                    <file-upload [disabled]="(article$ | async).article_checked" [clear]="clearSub" [id]="filesID" [files]="news.article_files" ></file-upload>
                    <div *ngIf="!newArticle">
                        <h2>Document Info</h2>
                        <p>Created by: {{(article$ | async).article_created_by || "anonymous"}}</p>
                        <p>Created on: {{(article$ | async).article_created_at | date}}</p>
                        <p *ngIf="(article$ | async).article_updated_by">Updated by: {{(article$ | async).article_updated_by}}</p>
                        <p *ngIf="(article$ | async).article_updated_at">Updated on: {{(article$ | async).article_updated_at | date}}</p>
                        <h3 *ngIf="articleLogHistory">History</h3>
                        <div class="mat-elevation-z8" *ngIf="articleLogHistory">
                            <table mat-table [dataSource]="articleLogHistory">
                              <!-- Position Column -->
                              <ng-container matColumnDef="type">
                                <th mat-header-cell *matHeaderCellDef > Action </th>
                                <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                              </ng-container>
                          
                              <!-- Name Column -->
                              <ng-container matColumnDef="operator">
                                <th mat-header-cell *matHeaderCellDef > Operator </th>
                                <td mat-cell *matCellDef="let element"> {{element.operator}} </td>
                              </ng-container>
                          
                              <!-- Weight Column -->
                              <ng-container matColumnDef="timestamp">
                                <th mat-header-cell *matHeaderCellDef > Time </th>
                                <td mat-cell *matCellDef="let element"> {{element.timestamp | date :'short'}} </td>
                              </ng-container>
                          
                              <!-- Symbol Column -->
                              <ng-container matColumnDef="state">
                                <th mat-header-cell *matHeaderCellDef > State </th>
                                <td mat-cell *matCellDef="let element"> {{element.state}} </td>
                              </ng-container>
                          
                              <tr mat-header-row *matHeaderRowDef="['type', 'operator', 'timestamp', 'state']"></tr>
                              <tr mat-row *matRowDef="let row; columns: ['type', 'operator', 'timestamp', 'state'];"></tr>
                            </table>
                          
                            <mat-paginator #historyPaginator [pageSizeOptions]="[5, 10, 20]"
                                           aria-label="Select page of entries">
                            </mat-paginator>
                          </div>
                    </div>
                    <div style="height:5em;width:100vw;"></div><!--spacer-->
                </mat-card>
            </mat-tab>
            <mat-tab *ngIf="!(article$ | async).article_checked" label="Tags">
                <mat-card class="sideCardLeft" fxLayout="column">
                    <ng-container >
                        <mat-form-field id="termChooser" appearance="outline" >
                            <input matInput id="tags" placeholder="Search Terms" #termInput [formControl]="termCtrl" [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                <mat-optgroup *ngFor="let plat of filteredTermsByPlatform" [label]="plat.name">
                                    <mat-option *ngFor="let term of plat.observable | async" [value]="term.uid">
                                        <div  [matTooltip]="term.id + (term.description ? (' | ' + term.description) : '')" style="display:inline-block">
                                            {{term.id.split("\t")[term.id.split("\t").length-1]}}
                                            </div>
                                    </mat-option>
                                </mat-optgroup>
                            </mat-autocomplete>
                        </mat-form-field>
                    </ng-container>
                    
                    <div class="sideRightScrollCard">
                        <tag-tree style="direction: ltr;"></tag-tree>
                    </div>
                </mat-card>
            </mat-tab>
        </mat-tab-group>

    </div>
    <!-- End right sidebar -->

</div>
</form>

<!--This won't be displayed-->

<div id="map"></div>
<!--<tree (value)="onValue($event)"></tree>-->
