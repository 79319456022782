import { Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LogEntry } from 'src/app/models/logentry.model';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { requestLogEntrys } from 'src/app/actions/news.actions';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

@UntilDestroy()
@Component({
  selector: 'app-logging',
  templateUrl: './logging.component.html',
  styleUrls: ['./logging.component.css']
})
export class LoggingComponent implements OnInit {

  minLevel = new FormControl(0);
  category = new FormControl(null);

  searchDateRange = new FormGroup({
    start: new FormControl(new Date(new Date().setDate(new Date().getDate() - 7)).toISOString()),
    end: new FormControl(new Date(new Date().setDate(new Date().getDate() + 1)).toISOString()),
  });
  filterText = "";
  size: number = 10;
  total: number = 0;
  from: number = 0;

  logEntrys$: Observable<any> = this.store.select(
    (state) => state.logEntrys
  ).pipe(untilDestroyed(this), distinctUntilChanged());

  logentrys = new MatTableDataSource<LogEntry>([]);
  @ViewChild("pagi") paginator: MatPaginator;

  constructor(private store: Store<State>, private dialog: MatDialog) {
    this.logEntrys$.subscribe((data) => {
      this.total = data.total;
      this.logentrys = new MatTableDataSource<LogEntry>(data.result);
    });
  }

  ngOnInit(): void {
    this.updateLog();
  }

  updatePage(event) {
    this.size = event.pageSize;
    this.from = event.pageIndex;
    this.updateLog();
  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterText = filterValue;
    this.paginator.firstPage();
    this.updateLog();
  }


  showDetails(element: LogEntry) {
    const dialogRef = this.dialog.open(HistoryDetailsComponent, {data: {entry: element}});
    dialogRef.afterClosed().subscribe(result => {
    });
  }


  updateLog() {
    const fromDate =
    typeof this.searchDateRange.controls.start.value == "object" && this.searchDateRange.controls.start.value!=null
      ? this.searchDateRange.controls.start.value.toISOString()
      : this.searchDateRange.controls.start.value;
    const toDate =
    typeof this.searchDateRange.controls.end.value == "object" && this.searchDateRange.controls.start.value!=null
      ? this.searchDateRange.controls.end.value.toISOString()
      : this.searchDateRange.controls.end.value;
    const query = this.filterText;
    const from = this.from;
    const size = this.size;
    const category = this.category.value;

    this.store.dispatch(requestLogEntrys({fromDate: fromDate, toDate: toDate, query: query, from: from * size, size: size, minLevel: this.minLevel.value, category: category}));
    this.logentrys = new MatTableDataSource<LogEntry>([]);
  }
}

@Component({
  selector: "edit-tag-diag",
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class HistoryDetailsComponent {
  inputForm = new FormGroup({});
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<HistoryDetailsComponent>,     private _ngZone: NgZone,
    private _snackBar: MatSnackBar
  ) {
    this.inputForm = new FormGroup({
      level: new FormControl(this.data.entry.level),
      modul: new FormControl(this.data.entry.modul),
      operator: new FormControl(this.data.entry.operator),
      message: new FormControl(this.data.entry.message),
      timestamp: new FormControl(this.data.entry.timestamp),
      new_state: new FormControl(this.data.entry.new_state),
      old_state: new FormControl(this.data.entry.old_state)
    });
    this.inputForm.disable();
  }

  onClick(wantDeleted: boolean): void {
    this.dialogRef.close();
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }
}
