
<button mat-raised-button (click)="save()" style="position:relative;z-index:888;top:1em;left:1em;">Save</button>
<mat-form-field style="position:relative;z-index:999;top:1em;left:4em;">
        <mat-label>Select Tree</mat-label>
        <mat-select [disabled]="true" (selectionChange)="matChange($event)" [(ngModel)]="currentTree">
                <mat-option value="CDRC">CDRC</mat-option>
                <mat-option value="CAMEO">CAMEO</mat-option>
        </mat-select>
</mat-form-field>
<div>
    <button mat-raised-button (click)="importFile.click()" style="left: 1em; top: 1em; z-index: 1000; width: 10em;">Import</button>
    <button mat-raised-button *ngIf="isTreeShown()" (click)="exportTags()" style="left: 2em; top: 1em; z-index: 1000; width: 10em;">Export</button>
    <input style="display:none" type="file" (change)="importCDFZ($event)" #importFile>
</div>

<div id="tree-container" #treeContainer></div>

<script src="js/vendor/underscore-min.js"></script>
<script src="js/vendor/fastclick.js"></script>
<script src="http://d3js.org/d3.v3.min.js"></script>
<script src="js/vendor/jquery.js"></script>
<script src="js/foundation.min.js"></script>
<script src="js/dndTree.js"></script>
<script src="js/vendor/toastr.js"></script>
<script src="js/vendor/d3-context-menu.js"></script>
