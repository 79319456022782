import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, HostListener, AfterViewInit } from '@angular/core';
import { tileLayer, latLng, featureGroup, DrawEvents, Control, DrawOptions, icon, marker, } from 'leaflet';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { StatsGeo, NewsStats } from 'src/app/models/news-stats.model';
import { addGeoFilter, closeEditorMap, removeGeoFilter, requestStatsNews24h, updateEditorMap } from 'src/app/actions/news.actions';
import {environment} from '../../../../environments/environment';
import { State } from 'src/app/reducers';
import { News } from 'src/app/models/news.model';
//import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';

declare var HeatmapOverlay;
declare var L;
declare var GeoSearch;



@Component({
  selector: 'editor-map',
  templateUrl: './editor-map.component.html',
  styleUrls: ['./editor-map.component.scss']
})
export class EditorMapComponent implements OnInit, OnDestroy,AfterViewInit {

  constructor(private store: Store<{ newsStats: NewsStats[] , geoStats: StatsGeo[], news: {results: any[], count: number},mapSettings:any, currentArticle:News }>) { }
  
  @Input('updateMap') updateMap$: any;
  @Output('onChange') onChange = new EventEmitter();

  currentArticle$ = this.store.select((store)=>store.currentArticle);


  map: L.Map;
  markerLayer = L.layerGroup([]);

  marker;

  subs: Subscription[] = [];

  updateMap(e) {
    console.log('DRAW Event', e);
  }
  createMap() {
    console.log('Map is ready');

   const map = L.map('editor-map').setView( [ 20.374234, 0.455118 ], 2);

    L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);

    // Do stuff with map
    this.map = map;
    const assetsPrefix = environment.wp ? '/migration/wp-content/angular/' : '';
    



    map.on("geosearch/showlocation",(e)=>{
      this.markerLayer.clearLayers();
      console.log("map event",e)
      const location_name = e.location.label;
      const lat = e.location.y;
      const lon = e.location.x;
      const iso_code = e.location.raw.country_code;
      console.log(map)

      this.marker = e.marker;
      
      this.onChange.emit({place:location_name,lat,lon,iso_code})
     // this.store.dispatch(closeEditorMap())
    })


    const search = new GeoSearch.GeoSearchControl({
      style: 'bar',
      animateZoom:true,
      zoomLevel:5,
      showMarker:true,
      marker:{

        icon: icon(
          {iconSize: [ 16.5, 26.65 ],
        // iconAnchor: [ 6.5, 20.5 ],
          iconUrl: assetsPrefix + 'assets/img/leaflet/marker-icon-push.png' 
        })
      },
      provider: new GeoSearch.AlgoliaProvider(),
    });
    map.addControl(search)

    map.on('click', (e)=>{
      this.map.removeLayer(this.markerLayer);
      this.markerLayer.clearLayers();
      if(this.marker){
        this.marker.remove();
        this.marker=null;
      }
      console.log(e.latlng)
      this.markerLayer.addLayer(marker(e.latlng, {

        icon: icon(
          {iconSize: [ 16.5, 26.65 ],
          iconUrl: assetsPrefix + 'assets/img/leaflet/marker-icon-push.png' 
        })
      }));
      this.map.addLayer(this.markerLayer);
      this.onChange.emit({lat:e.latlng.lat,lon:e.latlng.lng})
    });


    this.subs.push(this.currentArticle$.subscribe((news: News)=>{
      if(news.article_geolocation && news.article_geolocation.lat && news.article_geolocation.lon){
        this.map.removeLayer(this.markerLayer);
        this.markerLayer.clearLayers();
        this.markerLayer.addLayer(marker([ news.article_geolocation.lat,news.article_geolocation.lon ], {
          title: news.article_title,

          icon: icon(
            {iconSize: [ 16.5, 26.65 ],
            iconUrl: assetsPrefix + 'assets/img/leaflet/marker-icon-push.png' 
          })
        }));//.on('click', () => this.showArticle.emit(entry)));
        this.map.addLayer(this.markerLayer);
      }
    }));
  }

  ngOnInit() {
    const assetsPrefix = environment.wp ? '/migration/wp-content/angular/' : '';
    const sub2 = this.updateMap$?.subscribe((e) => {
      if (!this.map) {return; }
      console.log('map update called', e);
      if (e) {
        setTimeout(() => {
          this.map.invalidateSize();
        }, 10);
        console.log(this.map.getSize());
      }
    });
    
  }
  ngAfterViewInit(): void {
    this.createMap();
    this.map.invalidateSize();
    setTimeout(() => {
      this.map.invalidateSize();
    }, 500);
  }
  
  ngOnDestroy() {
    this.subs.forEach((subscription) => subscription.unsubscribe());
  }
}
