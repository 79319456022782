import { Injectable } from '@angular/core'
import {
  HttpClient,
  HttpRequest,
  HttpEventType,
  HttpResponse,
  HttpHeaders,
} from '@angular/common/http'
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import {sha256}  from 'hash.js';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { fileUpload, fileUploadError } from '../actions/news.actions';
import { tap } from 'rxjs/operators';

const url = environment.SERVER_URL;
@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(private http: HttpClient,private store: Store<State>) {}

  public upload(files: Set<File>,id : string):
    { [key: string]: { progress: Observable<number> } } {
      console.log("Upload file")

    // this will be the our resulting map
    const status: { [key: string]: { progress: Observable<number> } } = {};

    files.forEach(file => {
      // create a new multipart-form for every file
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);
      formData.append('id',id);

      // create a http-post request and pass the form
      // tell it to report the upload progress
      const req = new HttpRequest('POST', `${url}/news/${id}/uploadFile`, formData, {
        reportProgress: true
      });

      // create a new progress-subject for every file
      const progress = new Subject<number>();

      // send the http-request and subscribe for progress-updates
      this.http.request(req).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {

          // calculate the progress percentage
          const percentDone = Math.round(100 * event.loaded / event.total);

          // pass the percentage into the progress-stream
          progress.next(percentDone);
        } else if (event instanceof HttpResponse) {

          // Close the progress-stream if we get an answer form the API
          // The upload is complete
          progress.complete();
        }
      });

      // Save every progress-observable in a map of all observables
      status[file.name] = {
        progress: progress.asObservable()
      };
    });

    // return the map of progress.observables
    return status;
  }

  deleteFile(id,file){
    if(file.name)file = file.name;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    console.log(`kek`);
    file = file.substring(file.lastIndexOf("/")+1);
    return this.http.post(`${url}/news/${id}/deleteFile`,{id,file}, httpOptions).pipe(tap((data)=>{
        console.log(data)
      //  window.document.getElementById(`clickProxyDelete`).click();//No direct call to snackbar possible, weird glitch
        return data;
    }));
  }

}