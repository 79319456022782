// Search
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { tap, map } from 'rxjs/operators';
import { articleError, factorsError, factorsErrorRoot, setItemFeedback, searchError, loadReports, saveMappingSuccess, graphSearchRequest } from '../actions/news.actions';



@Injectable()
export class SemanticGraphEffects {

    
  mappingAdded$ = createEffect(() => this.actions$.pipe(
    ofType(graphSearchRequest),
    tap(() => {
    //  this.toastr.success( 'Mapping successfully updated!', 'Success');
    })
    ),
    {dispatch: false}
  );

  

    constructor(
        private actions$: Actions,
        private toastr: ToastrService,
        private router: Router,
      ) {}

}