import { createReducer, on } from "@ngrx/store";
import { generalStatsSuccess, statsNews24hSuccess } from "../actions/news.actions";
import { NewsCompound } from "../models/news-stats.model";

export const initialState: any = {
    total: {},
    today: -1,
    yesterday: -1,
    week: -1,
    perUserWeek: {},
};

const _generalStatsReducer = createReducer(
  initialState,
  on(generalStatsSuccess, (state, action) => {
    return {
        total: {total: action.result[0].total.value, platforms: action.result[0].platforms.buckets},
        today: action.result[1].total.value,
        yesterday: action.result[2].total.value,
        week: action.result[3].total.value,
        perUserWeek: {total: action.result[4].total.value, users: action.result[4].sub.buckets }
    };
    })
);

export function generalStatsReducer(state, action) {
  return _generalStatsReducer(state, action);
}
