import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog';
import { UploadService } from '../../services/upload.service'
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { deleteFile, fileUpload } from 'src/app/actions/news.actions';
import {environment} from 'src/environments/environment'
import { Subject } from 'rxjs';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit,OnDestroy {
  @ViewChild('file') file;
  
  @Input() id;
  @Input() disabled;

  progress
  canBeClosed = true
  primaryButtonText = 'Upload'
  showCancelButton = true
  uploading = false
  uploadSuccessful = false

  serverBaseUrl = environment.SERVER_URL;


  public files: Set<File> = new Set()
  @Input("files") serverFiles;

  @Input("clear") clear:Subject<void>;

  constructor(private uploadService: UploadService,private store: Store<State>) { }

  clearSub;

  ngOnInit() {
    this.clearSub = this.clear.subscribe(()=>{
      this.files.clear();
    })
  }
  ngOnDestroy(){
    this.clearSub.unsubscribe();
  }
  addFiles() {
    this.file.nativeElement.click();
  }
  deleteFiles(file){
    const arr = Array.from(this.files);

    this.files = new Set(arr.filter((needle)=>file!=needle));
    this.serverFiles = this.serverFiles.filter((needle)=>file!=needle);
    // this.uploadService.deleteFile(this.id,file);
    if(!file.name)
      this.store.dispatch(deleteFile({file,id:this.id}))
  }
  onFilesAdded() {
    const files = this.file.nativeElement.files;
    for (const key in files) {
      if (!isNaN(parseInt(key))) {
        files[key].isNew = true;
        this.files.add(files[key]);
        this.store.dispatch(fileUpload({name:files[key].name,id:this.id}));
      }
    }
    this.progress = this.uploadService.upload(this.files,this.id);
  }
  getFilteredServer(){
    return this.serverFiles.filter((e)=>![...this.files].map((es)=>es.name).includes(e))
  }

  fileName(file){
    return file.substring(file.lastIndexOf("/")+1);
  }


}
