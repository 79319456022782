import { ActionReducerMap, MetaReducer } from "@ngrx/store";
import { NewsCompound, StatsGeoHash } from "../models/news-stats.model";
import { SearchParameter } from "../models/search-parameter";
import { Tag } from "../models/tags.model";
import { articleReducer } from "./article.reducer";
import { geoReducer } from "./geo.reducer";
import { mentionsStatsReducer } from "./mentionsStats.reducer";
import { newsReducer } from "./news.reducer";
import { reportsReducer } from "./reports.reducer";
import { searchReducer } from "./search.reducer";
import { statsReducer } from "./stats.reducer";
import { statsAnalyzerReducer } from "./stats_analyzer.reducer";
import { geoAnalyzerReducer } from "./geo_analyzer.reducer";

import { storageMetaReducer } from "./storage.metareducer";
import { tagsReducer } from "./tags.reducer";
import { videoReducer } from "./video.reducer";
import { mappingReducer } from "./mapping.reducer";
import { Mapping } from "../models/mapping.model";
import { mappingsReducer } from "./mappings.reducer";
import { authenticationReducer } from "./authentication.reducer";
import { globalSettingsReducer } from "./global_settings.reducer";
import { userSettingsReducer } from "./user_settings.reducer";
import { permissionReducer } from "./permission.reducer";
import { semanticGraphReducer } from "./semantic-graph.reducer";
import { defaultPlattformReducer } from "./default-plattform.reducer";
import * as fromRouter from "@ngrx/router-store";
import { News } from "../models/news.model";
import { SemanticGraphEffects } from "../effects/semantic-graph.effects";
import { mapReducer } from "./map.reducer";
import { editorStateReducer } from "./editorState.reducer";
import { tagUsageCountReducer } from "./tag-count.reducer";
import { reindexStateReducer } from "./reindex-state.reducer";
import { generalStatsReducer } from "./stats-general.reducer";
import { languageReducer } from "./language.reducer";
import { tagMappingReducer } from "./tagMapping.reducer";
import { logReducer } from "./log.reducer";
import { LogEntry } from "../models/logentry.model";
export interface State {
  currentSearch: SearchParameter;
  // Search Results + count
  news: { results: News[]; count: number };
  // Current article that is shown in EditorComponent
  currentArticle: News;
  // Current source mapping that is shown in SourceMappingComponent
  currentMapping: Mapping;
  // TODO
  newsStats: NewsCompound;
  geoStats: StatsGeoHash;
  // AnalyzerComponent
  sliderAnalyzer: NewsCompound;
  geoAnalyzer: StatsGeoHash;
  // Tags DB
  tags: Tag[];
  tagMapping: Map<number, any>
  mappings: Mapping[];
  mentionsStats: any[];
  videoManager: any;
  reports: { reports: News[]; generated: boolean,refresh:any };
  authentication: { loggedIn: boolean, username: string | null };
  router: fromRouter.RouterReducerState<any>;
  globalSettings: any;
  userSettings: any;
  permissions: any;
  semanticGraphResults: any;
  defaultPlattform: string;
  mapSettings:any;
  editorState: {createButtonDisabled};
  tagUsageCount: any[];
  reindexState: {inProgress, failed},
  generalStats: {total, today, yesterday, week, perUserWeek},
  translationLanguages: any[],
  logEntrys: {total: number, result: LogEntry[]}
}

export const reducers: ActionReducerMap<State> = {
  // News Component
  news: newsReducer,

  // Search Component
  currentSearch: searchReducer,

  // Editor Component
  currentArticle: articleReducer,
  currentMapping: mappingReducer,

  // Map Component
  mentionsStats: mentionsStatsReducer,

  mapSettings: mapReducer,

  // Dashboard
  newsStats: statsReducer,
  geoStats: geoReducer,

  // Analyzer
  sliderAnalyzer: statsAnalyzerReducer,
  geoAnalyzer: geoAnalyzerReducer,

  // Videomanager Component
  videoManager: videoReducer,

  // Report Component
  reports: reportsReducer,

  // Sourcemanagement Component

  mappings: mappingsReducer,

  // Everywhere
  tags: tagsReducer,
  tagMapping: tagMappingReducer,
  authentication: authenticationReducer,

  // Router State
  router: fromRouter.routerReducer,

  // Global Settings
  globalSettings: globalSettingsReducer,
  // User Settings
  userSettings: userSettingsReducer,

  permissions: permissionReducer,

  semanticGraphResults: semanticGraphReducer,

  defaultPlattform: defaultPlattformReducer,

  editorState: editorStateReducer,
  tagUsageCount: tagUsageCountReducer,

  // Site Management
  reindexState: reindexStateReducer,
  logEntrys: logReducer,

  generalStats: generalStatsReducer,
  translationLanguages: languageReducer
};

export const metaReducers: MetaReducer<State>[] = [
  // Save current search between sessions
  storageMetaReducer,
];
