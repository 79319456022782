// TODO: This whole file does not follow standard coding conventions and should be rewritten

import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import {
  Component,
  Injectable,
  Output,
  Input,
  EventEmitter,
  ChangeDetectorRef,
  AfterViewInit,
  SimpleChanges,
  OnDestroy,
} from "@angular/core";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from "@angular/material/tree";
import {
  BehaviorSubject,
  Subject,
  Subscription,
  Observable,
  interval,
} from "rxjs";
import { stratify } from "d3";
import { NewsService } from "../../services/news.service";
import { AuthService } from "src/app/services/auth.service";
import { State } from "src/app/reducers";
import { Store, select } from "@ngrx/store";
import { Tag } from "src/app/models/tags.model";
import { distinctUntilChanged, filter, take, throttle, withLatestFrom } from "rxjs/operators";
import { addCurrentTag, removeCurrentTag } from "src/app/actions/news.actions";
import { combineLatest } from "rxjs";
import { selectActive, selectCAMEO, selectCDRC } from "src/app/selectors/tag.selector";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

/**
 * Node for to-do item
 */
export class TagNode {
  children: TagNode[];
  item: string;
  id: string;
  data: any;
}

/** Flat to-do item node with expandable and level information */
export class TagFlatNode {
  item: string;
  level: number;
  expandable: boolean;
  rlySelected: boolean = true;
  uid: number;
  color?: string;
  parent?: number;
}

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<TagNode[]>([]);

  public root;

  availableTags$: Observable<Tag[]> = this.store.pipe(select(selectActive));

  constructor(
    private store: Store<State>
  ) {
    this.initialize();
    this.dataChange.asObservable().subscribe((items) => {
      console.log("TAGLIST CHANGED");
    });
  }

  initialize(enabledPlatforms?) {
    if (enabledPlatforms && enabledPlatforms.length == 0)
      enabledPlatforms = ["cyber"];
    if (enabledPlatforms && !enabledPlatforms.includes("ALL"))
      enabledPlatforms.push("ALL");
    if (enabledPlatforms) {
      enabledPlatforms = enabledPlatforms.map((e) => {
        return e.trim();
      });
    }

    combineLatest([
      this.availableTags$,
      this.store.select((state) => state.currentArticle.article_platforms),
    ])
      .pipe(distinctUntilChanged((a,b)=>{
        return a[1].map((e,i)=>[e,b[1][i]]).every((e)=>e[0]==e[1]) && b[1].map((e,i)=>[e,a[1][i]]).every((e)=>e[0]==e[1])
      }))
      .subscribe(
        ([srcTags, allowedPlatforms]) => {
          console.log(
            "TAGS###################################",
            srcTags,
            allowedPlatforms
          );

          let rawTags: any = srcTags;

          allowedPlatforms = allowedPlatforms || ["cyber"];
          if (!rawTags) return;
          if (rawTags.length == 0) return;
          console.log("DATA");

          rawTags = rawTags.filter((tag: any) => {
            return (
              (tag && tag.type == "ALL") || allowedPlatforms.includes(tag.type)
            );
          });

          console.log("AFTER FILT ", rawTags);

          let strat = stratify().parentId(function (d: any) {
            // console.log(d)
            return d.id.substring(0, d.id.lastIndexOf("\t"));
          });

          // Temporarely use UID as ID for stratify if new tags are used...
          if (typeof rawTags[rawTags.length - 1].parent != 'undefined') { // First Tag has no parent.
            rawTags = rawTags.map(e => {return {...e, name: e.id, id: e.uid};}); 
            // Save original ID in name so we can set it back later on transform.
            strat = stratify().parentId(function (d: any) {
              return d.parent;
            });
          }

          const data: any = strat(rawTags).sort(function (a, b) {
            var aVal = 0;
            var bVal = 0;

            if (a.data && b.data && typeof a.data.position != 'undefined' && typeof b.data.position != 'undefined') {
              aVal = a.data.position;
              bVal = b.data.position;
            }

            return aVal - bVal || a.height - b.height || a.id.localeCompare(b.id);
          });

          (function transform(parent) {
            if (parent.data && parent.data.name) {
              parent.id = parent.data.name; // Undo themporary ID change...
              parent.data = {...parent.data, id: parent.id};
            }

            const splitted = parent.id.split("\t");
            parent.item = splitted.pop();
            if (
              !parent.children ||
              (parent.children && parent.children.length == 0)
            )
              return;
            // parent.children = parent.children.filter((node)=>node.data.permission=="user")
            for (const node of parent.children) {
              transform(node);
            }
          })(data);
          console.log("EMPEROR TAGS", data.children);
          this.dataChange.next(data.children);
          this.root = data;
        },
        () => null,
        () =>
          console.log(
            "COMPLETE TAGS#####################################################"
          )
      );

    // Notify the change.
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(obj: { [key: string]: any }, level: number): TagNode[] {
    return Object.keys(obj).reduce<TagNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new TagNode();
      node.item = key;

      if (value != null) {
        if (typeof value === "object") {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.item = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }
}
@UntilDestroy()
@Component({
  selector: "tag-tree",
  templateUrl: "./tag-tree.component.html",
  styleUrls: ["./tag-tree.component.css"],
  providers: [ChecklistDatabase],
})
export class TagTreeComponent implements OnDestroy {
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TagFlatNode, any>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TagNode, TagFlatNode>();

  /** */
  idToNodeMap = new Map<any, any>();

  /** A selected parent node to be inserted */
  selectedParent: TagFlatNode | null = null;

  /** The new item's name */
  newItemName = "";

  treeControl: FlatTreeControl<TagFlatNode>;

  treeFlattener: MatTreeFlattener<TagNode, TagFlatNode>;

  dataSource: MatTreeFlatDataSource<TagNode, TagFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TagFlatNode>(true, undefined, false);

  notify = new BehaviorSubject("");

  hasExpandedOnce = false;
  hasExpandedID = null;

  tagMap: Map<number, any>;

  constructor(
    private database: ChecklistDatabase,
    private store: Store<State>
  ) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<TagFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
      
    database.dataChange.subscribe((e: any) => {
      console.log(e);

      this.checklistSelection.clear();
      this.dataSource.data = e;

      const out = [];

      const recurse = function transform(parent) {
        out.push(parent);
        if (
          !parent.children ||
          (parent.children && parent.children.length == 0)
        )
          return;
        // parent.children = parent.children.filter((node)=>node.data.permission=="user")
        for (const node of parent.children) {
          transform(node);
        }
      };
      e.forEach((element) => {
        recurse(element);
      });

      for (const i of out) {
        this.idToNodeMap.set(i.data.uid, i);
      }
      this.notify.next("kek");
      console.log("update")
      
      
    });


    combineLatest([this.notify,this.store.select("currentArticle"), this.store.select("tagMapping")]).pipe(untilDestroyed(this)).subscribe(([notify,data,tagMap]) => {
      this.tagMap = tagMap;

      //Reset expanded state on reload of tags
      if(this.hasExpandedID != data.id){
        this.hasExpandedOnce = false;
      }
      if(!this.hasExpandedID && !this.hasExpandedOnce)
        this.treeControl.collapseAll();

      this.hasExpandedID = data.id;
      
      this.checklistSelection.clear();
      if (data.article_tags && data.article_tags.length > 0) {
        
        if(!this.hasExpandedOnce)
          this.treeControl.collapseAll();


        for (const tag of data.article_tags) {
          let otag = tagMap.get(tag);
          let flatnode = this.nestedNodeMap.get(
            this.idToNodeMap.get(tag)
          );

          // Also mark perent nodes.
          flatnode.rlySelected = true;
          let noudes = [flatnode];
          let nod = this.getParentNode(flatnode);
          while (nod) {
            nod.rlySelected = false;
            noudes.push(nod);
            nod = this.getParentNode(nod);
          }

          // Begin of actually mark them
          for (let par of noudes) {

            this.checklistSelection.select(
              par
            );
            
            
            //expand parents
            let recurse = (nodes)=>{
              let parent = this.getParentNode(nodes[0]);
              if(parent!=null)
                return recurse([parent,...nodes])
              else
                return nodes;
            } 
            let parentPath:[any] = recurse([par]);

            if(!this.hasExpandedOnce){
              for(let node of parentPath.slice(0,parentPath.length-1)){
                if(!node.item.includes("Staaten"))
                this.treeControl.expand(node);
              }
            }
          }
        }

        if(!this.hasExpandedOnce){
          this.hasExpandedOnce = true;
        }
      }
    });

  }
  ngOnDestroy() {
  }

  getLevel = (node: TagFlatNode) => node.level;

  isExpandable = (node: TagFlatNode) => node.expandable;

  getChildren = (node: TagNode): TagNode[] => node.children;

  hasChild = (_: number, _nodeData: TagFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TagFlatNode) => _nodeData.item === "";


  // Gets Tag backgroundcolor... if necasarry from parent
  getNodeColor(tag) {
    if (!tag) return "#ffffff";

    if (tag.color && (tag.color.startsWith('#') || tag.color.startsWith('r'))) {
      return tag.color;
    } else {
      return this.getNodeColor(this.tagMap.get(tag.parent));
    }
  }


  getTreeParent(node: TagNode, currentRoot: TagNode = this.database.root): TagNode {
    if (currentRoot.children && currentRoot.children.length > 0) {
      for (let i = 0; i < currentRoot.children.length; ++i) {
        const child = currentRoot.children[i];
        if (child.data.uid == node.data.uid) {
          return currentRoot;
        } else if (child.children && child.children.length > 0) {
          const parent = this.getTreeParent(node ,child);
          if (parent != null) {
            return parent;
          }
        }
      }
    }
    return null;
  }



  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: any, level: number) => {
    console.log();
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode: any =
      existingNode && existingNode.item === node.item
        ? existingNode
        : new TagFlatNode();
    flatNode.item = node.item;
    flatNode.id = node.id;
    flatNode.level = level;
    flatNode.uid = node.data.uid;
    flatNode.color = node.data.color;
    flatNode.parent = node.data.parent;
    flatNode.platform = node.data.platform;
    flatNode.description = node.data.description;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TagFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TagFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: any): void {
    let note = this.flatNodeMap.get(node);
    console.log("toggle node",node,note);
    if (this.checklistSelection.isSelected(node)) {
      this.store.dispatch(removeCurrentTag({ id: note.data.uid }));
    } else {
      this.store.dispatch(addCurrentTag({ id: note.data.uid }));
    }

    //this.checkAllParentsSelection(node);//Only for indeterminate
     console.log(this.checklistSelection.selected)
    // this.list.tagBase.next(this.checklistSelection.selected)
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TagFlatNode): void {
    let parent: TagFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TagFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TagFlatNode): TagFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

}
