import { createReducer, on } from '@ngrx/store';
import { requestFactorsSuccess } from '../actions/news.actions';


export const initialState = [];

const _tagsReducer = createReducer(initialState,
    on(requestFactorsSuccess, (state, action) => {

        
        
        const convert = action.tags.map((tag)=>{
          const temp:any =  {...tag};
          if(!tag.type) temp.type = "ALL";
          if(!tag.name) temp.name = tag.id;
          if(!tag.source) temp.source = "CDRC";
          return temp;
        });

        return convert;
    })
  );

export function tagsReducer(state, action) {
    return _tagsReducer(state, action);
  }
