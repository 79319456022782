import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { NgxSpinnerService } from "ngx-spinner";
import { forkJoin, of, zip } from "rxjs";
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
  flatMap,
  mergeMap,
} from "rxjs/operators";
import {
  addItem,
  addItemSuccess,
  addSearchTag,
  addSearchTag2,
  articleError,
  articleSuccess,
  deleteItem,
  deleteItemSuccess,
  newsSucess,
  reloadResults,
  removeSearchTag,
  removeSearchTag2,
  requestArticle,
  requestNews,
  searchRequest,
  updateItemSuccess,
  resetArticleForm,
  updateItem,
  checkItem,
  uncheckItem,
  unconfirmItem,
  confirmItem,
  genericError,
  checkItemSuccess,
  confirmItemSuccess,
  editSearch,
  deleteFile,
  translateDocument,
  translateDocumentSuccess,
  checkURLDuplicatesOnCreate,
  duplicateURLError,
  confirmPage,
  pageConfirmedSuccess,
  requestDocumentLanguages,
  requestDocumentLanguagesSuccess,
  saveDocumentLanguages,
  enableCreateButton
} from "../actions/news.actions";
import { SearchParameter } from "../models/search-parameter";
import { NewsService } from "../services/news.service";
import { State } from "../reducers";
import { UploadService } from "../services/upload.service";
import { TranslateService } from "../services/translate.service";
import { Route } from "@angular/compiler/src/core";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable()
export class NewsEffects {

  loadNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestNews),
      tap(() => this.spinner.show()),
      switchMap(() =>
        this.newsService.requestNews().pipe(map((news) => newsSucess({ news })))
      )
    )
  );

  loadArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestArticle),
      tap((data) => console.log("DATA IN EFF", data)),
      tap((data) => this.spinner.show()),
      map((payload) => payload.id),
      switchMap((id) =>
        this.newsService.requestArticle(id).pipe(
          tap((data) => this.spinner.hide()),
          map((news) => articleSuccess({ news })),
          catchError((err) => {
            this.spinner.hide()
            console.log("ERR", err);
            if (err.status==401) {
              this.router.navigate(['login']);
            }
            return of(articleError());
          })
        )
      )
    )
  );

  addItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addItem),
      tap((data) => console.log("DATA IN EFF", data)),
      withLatestFrom(this.store.select((state) => state.currentArticle)),
      switchMap(([action, article]) =>
        this.newsService.saveArticle(article).pipe(
          switchMap((news) => {
            if (news.type == "add") {
              return [resetArticleForm(), addItemSuccess({ news: news.data })];
            } else {
              return [
                resetArticleForm(),
                updateItemSuccess({ news: news.data })
              ];
            }
          }),
          catchError((err) => {
            console.log("ERR", err);
            if (err.status==401) {
              this.router.navigate(['login']);
            }
            return of(articleError());
          })
        )
      )
    )
  );

  updateItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateItem),
      tap((data) => console.log("DATA IN EFF", data)),
      withLatestFrom(this.store.select((state) => state.currentArticle)),
      switchMap(([action, article]) =>
        this.newsService.saveArticle(article).pipe(
          switchMap((news) => {
            if (news.type == "add") {
              return [resetArticleForm(), addItemSuccess({ news: news.data })];
            } else {
              return [
                updateItemSuccess({ news: news.data }),
                reloadResults()
              ];
            }
          }),
          catchError((err) => {
            //check duplicate entry error
            if (err.status==401) {
              window.open("https://news.cdfz.at/#/login?closeAfterLogin=true", "_blank", "popup width=800 height=700");
            }
            if(err.status==409){
              return of(duplicateURLError(), enableCreateButton());
            }else if(err.status==403){
              return of(genericError({error:"User can only edit own entries!"}), enableCreateButton());
            }

            console.log("ERR", err);
            return of(articleError(), enableCreateButton());
          })
        )
      )
    )
  );

  deleteFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteFile),
      tap((data) => console.log("DATA IN EFF", data)),
      withLatestFrom(this.store.select((state) => state.currentArticle)),
      mergeMap(([action, article]) =>{
        let newArt = {...article,article_files:article.article_files.filter((e)=>e!==action.file)};
        return forkJoin([this.newsService.saveArticle(newArt),this.uploadService.deleteFile(action.id,action.file)]).pipe(
          mergeMap(([news,deleted]) => {
              return [
                updateItemSuccess({ news: news.data }),
                reloadResults()
              ];
          }),
          catchError((err) => {
            console.log("ERR", err);
            return of(articleError());
          })
        )
      }
      )
    )
  );

  deleteItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteItem),
      tap((data) => console.log("DATA IN EFF", data)),
      map((payload) => {
        return payload.id;
      }),
      switchMap((id) =>
        this.newsService.deleteArticle(id).pipe(
          flatMap((news) => {
            return [resetArticleForm(),deleteItemSuccess(), searchRequest({ request: {} })];
          }),
          catchError((err) => {
            console.log("ERR", err);
            if (err.status==401) {
              this.router.navigate(['login']);
            }
            return of(articleError());
          })
        )
      )
    )
  );

  reloadResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reloadResults),
      withLatestFrom(this.store.select((state) => state.currentSearch)),
      tap((data) => this.spinner.show()),
      map(([action, storeState]) => {
        return searchRequest({ request: storeState });
      })
    )
  );


  confirmPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmPage),
      tap((data) => this.spinner.show()),
      switchMap((action) =>
        this.newsService.confirmPage(action.ids).pipe(
          tap((data) => this.spinner.hide()),
          switchMap((news) => [pageConfirmedSuccess(),reloadResults()]),
          catchError((err) => {
            this.spinner.hide()
            console.log("ERR", err);
            if (err.status==401) {
              this.router.navigate(['login']);
            }
            return of(genericError({error:"Error while confirming page!"}));
          })
        )
      )
    )
  );


  tagChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSearchTag, removeSearchTag, addSearchTag2, removeSearchTag2),
      withLatestFrom(this.store.select((state) => state.currentSearch)),
      tap((data) => this.spinner.show()),
      map(([action, storeState]) => {
        return searchRequest({ request: storeState });
      })
    )
  );

  checkItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkItem),
      switchMap((action) =>
        this.newsService.checkItem(action.id,true).pipe(
          withLatestFrom(this.store.select((state) => state.authentication)), switchMap(([_, auth]) => {
            return [checkItemSuccess({id:action.id,state:true, operator: auth.username})]
          }),
          catchError((err) => {
            console.log("ERR", err);
            if (err.status==401) {
              this.router.navigate(['login']);
            }
            return of(genericError({error:"Can't check item!"}));
          })
        )
      )
    )
  );
  uncheckItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uncheckItem),
      switchMap((action) =>
        this.newsService.checkItem(action.id,false).pipe(
          withLatestFrom(this.store.select((state) => state.authentication)), switchMap(([_, auth]) => {
            return [checkItemSuccess({id:action.id,state:false, operator: auth.username})]
          }),
          catchError((err) => {
            console.log("ERR", err);
            if (err.status==401) {
              this.router.navigate(['login']);
            }
            return of(genericError({error:"Can't uncheck item!"}));
          })
        )
      )
    )
  );

  confirmItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmItem),
      switchMap((action) =>
        this.newsService.confirmItem(action.id,true).pipe(
          switchMap(() => {
            return [confirmItemSuccess({id:action.id,state:true})]
          }),
          catchError((err) => {
            console.log("ERR", err);
            if (err.status==401) {
              this.router.navigate(['login']);
            }
            return of(genericError({error:"Can't confirm item!"}));
          })
        )
      )
    )
  );

  unconfirmItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unconfirmItem),
      switchMap((action) =>
        this.newsService.confirmItem(action.id,false).pipe(
          switchMap(() => {
            return [confirmItemSuccess({id:action.id,state:false})]
          }),
          catchError((err) => {
            console.log("ERR", err);
            if (err.status==401) {
              this.router.navigate(['login']);
            }
            return of(genericError({error:"Can't unconfirm item!"}));
          })
        )
      )
    )
  );

  translate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(translateDocument),
      withLatestFrom(this.store.select((state) => state.currentArticle)),
      switchMap(([action,currentArticle]) =>
        this.translateService.translate(currentArticle.article_content,currentArticle.article_title,currentArticle.article_subtitle,currentArticle.article_language,currentArticle.article_languageErfassung).pipe(
          switchMap((data:any) => {
            return [translateDocumentSuccess({content:data.content,language:data.language,title:data.title,subtitle:data.subtitle})]
          }),
          catchError((err) => {
            console.log("ERR", err);
            
            return of(genericError({error:"Error while translating\n"+err.error.message}));
          })
        )
      )
    )
  );


  translateLanguages$ = createEffect(() =>
  this.actions$.pipe(
    ofType(requestDocumentLanguages),
    switchMap(() =>
      this.translateService.getLanguages().pipe(
        switchMap((data: any[]) => {
          return [requestDocumentLanguagesSuccess({result: data})]
        }),
        catchError((err) => {
          console.log("ERR", err);
          if (err.status==401) {
            this.router.navigate(['login']);
          }
          return of(genericError({error:"Error while retrieving languages\n"+err.error.message}));
        })
      )
    )
  )
);

  saveTranslateLanguages$ = createEffect(() =>
  this.actions$.pipe(
    ofType(saveDocumentLanguages),
    switchMap((action) =>
      this.translateService.setLanguages(action.languages).pipe(
        switchMap((data: any[]) => {
          return [requestDocumentLanguagesSuccess({result: action.languages})]
        }),
        catchError((err) => {
          console.log("ERR", err);
          if (err.status==401) {
            this.router.navigate(['login']);
          }
          return of(genericError({error:"Error while saving languages\n"+err.error.message}));
        })
      )
    )
  )
  );

  checkURLDuplicates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkURLDuplicatesOnCreate),
      switchMap((action) =>
        this.newsService.checkURLDuplicates(action.url).pipe(
          switchMap(() => {
            return []
          }),
          catchError((err) => {
            console.log("ERR", err);
            if (err.status==401) {
              return of(genericError({error: "Not logged in!"}));
            } else {
              return of(duplicateURLError());
            }
            
          })
        )
      )
    )
  );


  //

  constructor(
    private router: Router,
    private route:  ActivatedRoute,
    private actions$: Actions,
    private newsService: NewsService,
    private translateService: TranslateService,
    private uploadService: UploadService,
    private spinner: NgxSpinnerService,
    private store: Store<State>
  ) {}
}
