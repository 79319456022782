import { createReducer, on } from '@ngrx/store';
import { addItemSuccess, checkItemSuccess, confirmItemSuccess, newsSucess, updateItemSuccess } from '../actions/news.actions';


export const initialState = {results: [], count: 0};

const _newsReducer = createReducer(initialState,
    on(newsSucess, (state, action) => {
      console.log('REDUCER:', action);
      return {...action.news,highlight:computePreview(action.news)};
    }),
    on(addItemSuccess, (state, action) => {
      return {results: [...state.results, action.news], count: state.count + 1};
    }),
    /*on(updateItemSuccess, (state, action) => {
      return {results: [...state.results, action.news], count: state.count};
    }),*/
    on(checkItemSuccess, (state, action) => {
      const item = state.results.filter((e)=>e.id==action.id);
      console.log("test",item)
      if(item){
        const item2  = item[0];
        const results = [];
        for(const i of state.results){
          if(action.id!=i.id){
            results.push(i);
          }else{
            results.push({...item2,article:{...item2.article,checked:action.state}});
          }
        }
        return {results, count: state.count};
      }
      return state;
    }),
    on(confirmItemSuccess, (state, action) => {
      const item :any= state.results.filter((e)=>e.id==action.id);
      if(item){
        const item2 = item[0];
        const results = [];
        for(const i of state.results){
          if(action.id!=i.id){
            results.push(i);
          }else{
            results.push({...item2,article:{...item2.article,confirmed:action.state}});
          }
        }
        return {results, count: state.count};
      }
      return state;
    }),
  );

export function newsReducer(state, action) {
    return _newsReducer(state, action);
  }


 function  computePreview(item) {
    if (item.highlight) {
      let concat = '';
      for (const highlight of item.highlight) {
          concat += highlight + ' ... ';
      }
      return concat;
  }
    return '';
}