import { createReducer, on } from "@ngrx/store";
import { closeEditorMap, openEditorMap } from '../actions/news.actions';

export const initialState = {
  openMap:false
};



const _mapReducer = createReducer(
  initialState,
  on(openEditorMap, (state, action) => {
    return {...state,openMap:true};
  }),
  on(closeEditorMap, (state, action) => {
    return {...state,openMap:false};
  }),
);

export function mapReducer(state, action) {
  return _mapReducer(state, action);
}
