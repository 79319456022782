import { createReducer, on } from '@ngrx/store';
import { requestMappingsSuccess } from '../actions/news.actions';
import { Mapping } from '../models/mapping.model';


export const initialState: Mapping[] = [];

const _mappingsReducer = createReducer(initialState,
    on(requestMappingsSuccess, (state, action) => {
      console.log('REDUCER:', action);
      return action.mappings;
    })
  );

export function mappingsReducer(state, action) {
    return _mappingsReducer(state, action);
  }
