import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { tileLayer, latLng, Map, circle, polygon, marker, Icon, icon, FeatureGroup, featureGroup, DrawEvents, Control, DrawOptions, } from 'leaflet';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { StatsGeo, NewsStats } from 'src/app/models/news-stats.model';
import { addGeoFilter, removeGeoFilter, requestStatsNews24h } from 'src/app/actions/news.actions';
import {environment} from 'src/environments/environment';
declare var L;
declare var HeatmapOverlay;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {

  constructor(private store: Store<{ newsStats: NewsStats[] , geoStats: StatsGeo[], news: {results: any[], count: number} }>) { }

  @Input('data') geoStats$: any;
  @Input('updateMap') updateMap$: any;
  @Input('avgTone') avgTone = false;

  @Output("showArticle") showArticle = new EventEmitter();


  optionsSpec: any = {
    layers: [
      { url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', attribution: '' },

  ],
    zoom: 2,
    center: [ 20.374234, 0.455118 ]
};


layers: any = [
  circle([ 48.274234, 16.455118 ], { radius: 100 }),
  polygon([[ 46.8, -121.85 ], [ 46.92, -121.92 ], [ 46.87, -121.8 ]]),

];
data1 = {
  data: []
};
data2 = {
  data: []
};
data3 = {
  data: []
};
data4 = {
  data: []
};
heatmapLayer = new HeatmapOverlay({
  radius: 6,
  maxOpacity: 0.5,
  scaleRadius: true,
  useLocalExtrema: true,
  latField: 'lat',
  lngField: 'lng',
  valueField: 'count',
  gradient: {
    // enter n keys between 0 and 1 here
    // for gradient color customization
    '.5': 'green',
  },
  // minimum opacity. any value > 0 will produce
  // no transparent gradient transition
});
heatmapLayer2 = new HeatmapOverlay({
  radius: 6,
  maxOpacity: 0.5,
  scaleRadius: true,
  useLocalExtrema: true,
  latField: 'lat',
  lngField: 'lng',
  valueField: 'count',
  gradient: {
    // enter n keys between 0 and 1 here
    // for gradient color customization
    '.5': 'blue',
  },
  // minimum opacity. any value > 0 will produce
  // no transparent gradient transition
});
heatmapLayer3 = new HeatmapOverlay({
  radius: 6,
  maxOpacity: 0.5,
  scaleRadius: true,
  useLocalExtrema: true,
  latField: 'lat',
  lngField: 'lng',
  valueField: 'count',
  gradient: {
    // enter n keys between 0 and 1 here
    // for gradient color customization
    '.5': 'gray',
  },
  // minimum opacity. any value > 0 will produce
  // no transparent gradient transition
});
heatmapLayer4 = new HeatmapOverlay({
  radius: 10,
  maxOpacity: 0.5,
  scaleRadius: true,
  useLocalExtrema: true,
  latField: 'lat',
  lngField: 'lng',
  valueField: 'count',
  gradient: {
    // enter n keys between 0 and 1 here
    // for gradient color customization
    '0': 'blue',
    '1': 'red',
  }
  // minimum opacity. any value > 0 will produce
  // no transparent gradient transition
});

// Leaflet bindings

drawnItems = featureGroup();
drawOptions= {
  position: 'topleft',
  draw: {
     marker: false,
     polyline: false,
     polygon: false,
     rectangle: false,
     circlemarker: false,

     circle: {
         shapeOptions: {
             color: '#aaaaee',
         },

     }
  },
  edit: {
    featureGroup: this.drawnItems,
    edit:false,
}
};


zoom = this.optionsSpec.zoom;
center = latLng(this.optionsSpec.center);
options = {
 // preferCanvas: true,
    layers: [
       // tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', { attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' }) ,
       tileLayer(this.optionsSpec.layers[0].url, { attribution: this.optionsSpec.layers[0].attribution }),
       this.heatmapLayer4,
       this.heatmapLayer3,
       this.heatmapLayer,
       this.heatmapLayer2,

       this.drawnItems
  ],
    zoom: this.optionsSpec.zoom,
    center: latLng(this.optionsSpec.center)
};
map: L.Map;

subs: Subscription[] = [];

  updateMap(e) {
    console.log('DRAW Event', e);
  }
  onMapReady(map: L.Map) {
    console.log('Map is ready');
    // Do stuff with map
    const legend = new (L.Control.extend({
        options: { position: 'bottomright' }
      }));

    legend.onAdd = (map) => {
        const div = L.DomUtil.create('div', 'legend');
        div.innerHTML = '<div><b>Legend</b></div>';
        console.log(this.avgTone);
        if (!this.avgTone) {
          div.innerHTML += '<i style="background:' + 'green' + '"> &nbsp; &nbsp;</i> &nbsp; &nbsp;' + 'Push<br>';
          div.innerHTML += '<i style="background:' + 'blue' + '"> &nbsp; &nbsp;</i> &nbsp; &nbsp;' + 'Pull<br>';
          div.innerHTML += '<i style="background:' + 'white' + '"> &nbsp; &nbsp;</i> &nbsp; &nbsp;' + 'Undefined<br>';
        } else {
          div.innerHTML += '<i style="background:' + 'Red' + '"> &nbsp; &nbsp;</i> &nbsp; &nbsp;' + 'Negative<br>';
          div.innerHTML += '<i style="background:' + 'White' + '"> &nbsp; &nbsp;</i> &nbsp; &nbsp;' + 'Neutral<br>';
          div.innerHTML += '<i style="background:' + 'Blue' + '"> &nbsp; &nbsp;</i> &nbsp; &nbsp;' + 'Positive<br>';
        }

        return div;
      };
    legend.addTo(map);
    this.map = map;
      // Fix for weird bug when host container has an ngIf, size is determined wrong
    setTimeout(() => {
        map.invalidateSize();
      }, 0);
  }

  ngOnInit() {
    const assetsPrefix = environment.wp ? '/migration/wp-content/angular/' : '';
    const sub = this.geoStats$.subscribe((geo) => {
      this.data1.data = [];
      this.data2.data = [];
      this.data3.data = [];
      this.data4.data = [];
      this.layers = [];
      for (const entry2 of geo.slice(0, 1000)) {// SHow only 100 for performance reasons

        if (!entry2.gdelt) {
          if(entry2?.article?.geolocation){
            if(Array.isArray(entry2.article.geolocation) && entry2.article.geolocation.length==2){
              // geolocation is array
              const entry: any = entry2;
              this.layers.push(marker([ parseInt(entry.article.geolocation[0], 10)+ Math.random()-0.5, parseInt(entry.article.geolocation[1], 10)+ Math.random()-0.5 ], {
                title: entry.article.title,

                icon: icon(
                  {iconSize: [ 16.5, 26.65 ],
                // iconAnchor: [ 6.5, 20.5 ],
                  iconUrl: assetsPrefix + 'assets/img/leaflet/marker-icon-undef.png' 
                })
              }).on('click', () => this.showArticle.emit(entry)));// .on('click', () => window.open(entry.article.source, '_blank')));
              this.data3.data.push({
                lat: entry.article.geolocation[0],
                lng: entry.article.geolocation[1],
                count: 1
              });
            }else if(entry2.article.geolocatio?.lon && entry2.article.geolocation?.lat){
              // geolocation is obj
              const entry: any = entry2;
              this.layers.push(marker([ parseInt(entry.article.geolocation.lat, 10), parseInt(entry.article.geolocation.lon, 10) ], {
                title: entry.article.title,

                icon: icon(
                  {iconSize: [ 16.5, 26.65 ],
                // iconAnchor: [ 6.5, 20.5 ],
                  iconUrl: assetsPrefix + 'assets/img/leaflet/marker-icon-undef.png'
                })
              }).on('click', () => this.showArticle.emit(entry)));// .on('click', () => window.open(entry.article.source, '_blank')));
              this.data3.data.push({
                lat: entry.article.geolocation.lat,
                lng: entry.article.geolocation.lon,
                count: 1
              });
            }
          }
          continue;
        }
        
        const entry: any = entry2;
        this.layers.push(marker([ parseInt(entry.article.geolocation.lat, 10), parseInt(entry.article.geolocation.lon, 10) ], {
          title: entry.gdelt.EventCode.name,

          icon: icon(
            {iconSize: [ 16.5, 26.65 ],
           // iconAnchor: [ 6.5, 20.5 ],
            iconUrl: entry.gdelt.EventCode.rating == 'undefined' ?
             assetsPrefix + 'assets/img/leaflet/marker-icon-undef.png' :
               entry.gdelt.EventCode.rating == 'push' ?
               assetsPrefix +  'assets/img/leaflet/marker-icon-push.png' :
               assetsPrefix +  'assets/img/leaflet/marker-icon-pull.png',
            //  shadowUrl: assetsPrefix + 'assets/img/leaflet/marker-shadow.png'
          })
        }).on('click', () => window.open(entry.article.source, '_blank')));
        if (this.avgTone) {
          console.log(entry)
          const grad = this.hsbGradient(200, [{h:0, s:1, b:0.9},{h:0, s:1, b:0.5},{h:0, s:1, b:0.5},{h:0, s:1, b:1},{h:240/360, s:1, b:0.5},{h:240/360, s:1, b:0.5}, {h:240/360, s:1, b:0.9}]);
          const index = (Math.round(entry.gdelt.AvgTone)+100);
       //   console.log(index)
        //  console.log(grad,grad[index].h*360,grad[index].s*100,grad[index].b*100,this.hslToHex(grad[index].h*360,grad[index].s*100,grad[index].b*100) )
          this.layers.push(
            circle([ entry.article.geolocation.lat, entry.article.geolocation.lon], { radius: 
              1000000 ,color:this.hslToHex(grad[index].h*360,grad[index].s*100,grad[index].b*100) }),
           )
        } else {


            if (entry.gdelt.EventCode.rating == 'push') {
            this.data1.data.push({
              lat: entry.article.geolocation.lat,
              lng: entry.article.geolocation.lon,
              count: 1
            });
            }
            if (entry.gdelt.EventCode.rating == 'pull') {
              this.data2.data.push({
                lat: entry.article.geolocation.lat,
                lng: entry.article.geolocation.lon,
                count: 1
              });
            }
            if (entry.gdelt.EventCode.rating == 'undefined') {
              this.data3.data.push({
                lat: entry.article.geolocation.lat,
                lng: entry.article.geolocation.lon,
                count: 1
              });
            }

            }

      }
      this.heatmapLayer.setData(this.data1);
      this.heatmapLayer2.setData(this.data2);
      this.heatmapLayer3.setData(this.data3);
      this.heatmapLayer4.setData(this.data4);
      // console.log('kkwkkwk', this.layers);
    });
    const sub2 = this.updateMap$.subscribe((e) => {
      if (!this.map) {return; }
      console.log('map update called', e);
      if (e) {
        setTimeout(() => {
          this.map.invalidateSize();
        }, 10);
        console.log(this.map.getSize());
      }
    });
    this.subs.push(sub, sub2);
  }
  ngOnDestroy() {
    this.subs.forEach((subscription) => subscription.unsubscribe());
  }
  hsbGradient(steps, colours) {
    const parts = colours.length - 1;
    const gradient = new Array(steps);
    let gradientIndex = 0;
    let partSteps = Math.floor(steps / parts);
    const remainder = steps - (partSteps * parts);
    for (let col = 0; col < parts; col++) {
      // get colours
      const c1 = colours[col], 
          c2 = colours[col + 1];
      // determine clockwise and counter-clockwise distance between hues
      const distCCW = (c1.h >= c2.h) ? c1.h - c2.h : 1 + c1.h - c2.h;
       const    distCW = (c1.h >= c2.h) ? 1 + c2.h - c1.h : c2.h - c1.h;
       // ensure we get the right number of steps by adding remainder to final part
      if (col == parts - 1) partSteps += remainder; 
      // make gradient for this part
      for (let step = 0; step < partSteps; step ++) {
        const p = step / partSteps;
        // interpolate h, s, b
        let h = (distCW <= distCCW) ? c1.h + (distCW * p) : c1.h - (distCCW * p);
        if (h < 0) h = 1 + h;
        if (h > 1) h = h - 1;
        const s = (1 - p) * c1.s + p * c2.s;
        const b = (1 - p) * c1.b + p * c2.b;
        // add to gradient array
        gradient[gradientIndex] = {h, s, b};
        gradientIndex ++;
      }
    }
    return gradient;
  }
  hslToHex(h, s, l) {
    h /= 360;
    s /= 100;
    l /= 100;
    let r, g, b;
    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }
    const toHex = x => {
      const hex = Math.round(x * 255).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  }
  public onDrawCreated(e: any) {
    this.drawnItems.clearLayers();
    this.drawnItems.addLayer((e as DrawEvents.Created).layer);

    console.log(e)

      console.log("geo created")
      console.log(e.layer.getRadius())
      console.log(e.layer.getLatLng())

      this.store.dispatch(addGeoFilter({radius:e.layer.getRadius(),coords:e.layer.getLatLng()}))
    
  }

  public onDrawDeleted(e: any) {
      console.log("geo deleted")
      this.store.dispatch(removeGeoFilter())
  }
}
