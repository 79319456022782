import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { AuthService } from "../../../services/auth.service";
import {
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { NewsService } from "src/app/services/news.service";
import { MatDialog } from "@angular/material/dialog";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { Observable, of } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { treemapSquarify } from "d3";
import { DeleteDialogComponent } from "../../misc-components/delete-dialog/delete-dialog.component";
import { RoleService } from "src/app/services/role.service";

@Component({
  selector: "app-role-editor",
  templateUrl: "./role-editor.component.html",
  styleUrls: ["./role-editor.component.css"],
})
export class RoleEditorComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private newsService: NewsService,
    private router: Router,
    private roleService: RoleService
  ) {}
  id;
  name;
  role;
  form;
  newUser = false;

  description;
  email;
  organization;

  isSuperAdmin = false;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  termCtrl = new FormControl();
  filteredTerms: Observable<string[]>;
  terms: any = [];
  allTerms: string[] = [];

  availableRoles = [
    "canSearch",
    "canCreateEntries",
    "canUpdateEntries",
    "canMarkEntriesChecked",
    "canMarkEntriesConfirmed",
    "canViewUsers",
    "canCreateUsers",
    "canCreateMappings",
    "canAccessAnalyzer",
    "canCreateTags",
    "canViewDashboard",
    "canAccessVideoDownloader",
    "canAddEntryToReport",
    "canViewOwnEntries",
    "canDeleteNewsEntries",
    "canManageRoles",
    "canTranslateNewsEntry",
    "canViewStatsAndGraphs",
    "canAccessSiteManagement"
  ];

  @ViewChild("termInput") termInput: ElementRef;

  ngOnInit() {
    const id = this.activatedRoute.snapshot.params.id;
    this.id = id;
    console.log("ID", this.id, this.id == "new");
    if (!this.id || this.id == "new") {
      this.newUser = true;
    }
    if (!this.newUser) {
      this.roleService.getRole(id).then((d: any) => {
        console.log("ROLE DATA", d);
        this.name = d.name;
        if (this.name == "superadmin") {
          //this.isSuperAdmin = true;
          this.removable = false;
        }
        this.terms = Object.entries(d)
          .map(([key, value]) => {
            if (key && key != "name" && key != "id" && value) return key;
          })
          .filter((e) => e);
        console.log("filtered roles", this.terms);
      });
    }

    this.filteredTerms = this.termCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) =>
        fruit ? this._filter(fruit) : this.availableRoles.slice().filter((fruit)=> !this.terms.includes(fruit))
      )
    );
  }
  deleteRole() {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      this.roleService.deleteRole(this.id).then(() => {
        setTimeout(() => this.router.navigate(["/roleManagement"]), 1000);
      });
    });
  }
  save() {
    if (this.newUser) {
      this.roleService.createRole(this.name, this.terms).then(() => {
        this.router.navigateByUrl("/roleManagement");
      });
    } else {
      this.roleService
        .updateRole(this.name, this.terms, this.id)
        .then(() => {
          this.router.navigateByUrl("/roleManagement");
        })
        .catch(() => {});
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
  const value = event.value;
  // Add our fruit
  if ((value || '').trim()) {
    this.terms.push(value.trim());
  }

  // Reset the input value
  if (input) {
    input.value = '';
  }


  this.termCtrl.setValue(null);
  }
  remove(fruit: string): void {
    const index = this.terms.indexOf(fruit);
    console.log(
      this.terms.map((d: any) => d.name),
      fruit
    );

    if (index >= 0) {
      this.terms.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    console.log(event.option.value);
    this.terms.push(event.option.value);
    this.termInput.nativeElement.value = "";
    this.termCtrl.setValue(null);
  }

  private _filter(value): string[] {
    console.log("FILTER attack",this.terms)
    const filterValue = value.toLowerCase();

    return this.availableRoles.filter(
      (fruit: any) =>
        fruit.toLowerCase().includes(filterValue) && !this.terms.includes(fruit)
    );
  }
  change(e) {
    console.log(e);
  }
}
