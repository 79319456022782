<mat-card>
    <form [formGroup]="formGroup">
    <h2>{{newUser ? "Create new User" : "Update existing user"}}</h2>
    <div style="margin-bottom:0.6em;">
        <!--
        <div *ngIf="username2.invalid && (username2.dirty || username2.touched)" class="alert alert-danger">
            <div *ngIf="username2.errors.required" style="color:red;">
                Name is required.
            </div>
        </div>

        <div *ngIf="password3.invalid && (password3.dirty || password3.touched)" class="alert alert-danger">
            <div *ngIf="password3.errors.required" style="color:red;">
                Password is required.
            </div>
        </div>-->

    </div>
    <mat-form-field style="width:100%">
        <input autocomplete="username" required matInput formControlName="username" name="username" placeholder="Username" >
    </mat-form-field>
    <div *ngIf="formGroup.controls.username.errors && (formGroup.controls.username.touched || formGroup.controls.username.dirty) " class="error">
        <small
            class="form-text text-danger"
            *ngIf="formGroup.controls.username.errors.required"
            >Username is required
        </small>
        <small
            class="form-text text-danger"
            *ngIf="formGroup.controls.username.errors.minlength"
            >Username must be longer than or equal to 3 characters
        </small>
    </div>
    <mat-form-field style="width:100%">
        <input autocomplete="new-password" matInput formControlName="password" name="password" type="text" placeholder="New password" >
        <button matSuffix mat-button (click)="genPassword()"><mat-icon >bolt</mat-icon></button>
    </mat-form-field>
    <div *ngIf="formGroup.controls.password.errors && (formGroup.controls.password.touched || formGroup.controls.password.dirty) " class="error">
        <small
            class="form-text text-danger"
            *ngIf="formGroup.controls.password.errors.required"
            >Password is required
        </small>
        <small
            class="form-text text-danger"
            *ngIf="formGroup.controls.password.errors.minlength"
            >Password must be longer than or equal to 10 characters
        </small>
    </div>
    <mat-form-field style="width:100%">
        <input autocomplete="new-password" matInput formControlName="confirmPassword" name="confirmPassword" type="text" placeholder="Confirm New password">
    </mat-form-field>
    <div *ngIf="formGroup.controls.confirmPassword.errors && (formGroup.controls.confirmPassword.touched || formGroup.controls.confirmPassword.dirty) " class="error">
        <small
            class="form-text text-danger"
            *ngIf="formGroup.controls.confirmPassword.errors.required"
            >Password is required
        </small>
    </div>
    <div *ngIf="formGroup.errors && (formGroup.controls.password.touched || formGroup.controls.password.dirty || formGroup.controls.confirmPassword.touched || formGroup.controls.confirmPassword.dirty) " class="error">
        <small
            class="form-text text-danger"
            *ngIf="formGroup.errors.passwordsUnequal"
            >Passwords are unequal
        </small>
    </div>
    <mat-form-field style="width:100%">
        <input matInput formControlName="email" name="email" placeholder="Email">
    </mat-form-field>
    <div *ngIf="formGroup.controls.email.errors && (formGroup.controls.email.touched || formGroup.controls.email.dirty) " class="error">
        <small
            class="form-text text-danger"
            *ngIf="formGroup.controls.email.errors.required"
            >Email is required
        </small>
        <small
            class="form-text text-danger"
            *ngIf="formGroup.controls.email.errors.email"
            >Email must be valid
        </small>
    </div>
    <mat-form-field style="width:100%">
        <input matInput formControlName="description" name="description" placeholder="Description" >
    </mat-form-field>
    <div *ngIf="formGroup.controls.description.errors && (formGroup.controls.description.touched || formGroup.controls.description.dirty) " class="error">
        <small
            class="form-text text-danger"
            *ngIf="formGroup.controls.description.errors.required"
            >Description is required
        </small>
    </div>
    <mat-form-field style="width:100%">
        <input matInput formControlName="firstname" name="firstname" placeholder="First Name" >
    </mat-form-field>
    <div *ngIf="formGroup.controls.firstname.errors && (formGroup.controls.firstname.touched || formGroup.controls.firstname.dirty) " class="error">
        <small
            class="form-text text-danger"
            *ngIf="formGroup.controls.firstname.errors.required"
            >First Name is required
        </small>
    </div>
    <mat-form-field style="width:100%">
        <input matInput formControlName="lastname" name="lastname" placeholder="Last Name" >
    </mat-form-field>
    <div *ngIf="formGroup.controls.lastname.errors && (formGroup.controls.lastname.touched || formGroup.controls.lastname.dirty) " class="error">
        <small
            class="form-text text-danger"
            *ngIf="formGroup.controls.lastname.errors.required"
            >Last Name is required
        </small>
    </div>
    <mat-form-field style="width:100%">
        <input matInput formControlName="title" name="title" placeholder="Title" >
    </mat-form-field>
    <mat-form-field style="width:100%">
        <input matInput formControlName="tel" name="tel" placeholder="Telephone number" >
    </mat-form-field>
    <div *ngIf="formGroup.controls.tel.errors && (formGroup.controls.tel.touched || formGroup.controls.tel.dirty) " class="error">
        <small
            class="form-text text-danger"
            *ngIf="formGroup.controls.tel.errors.pattern"
            >Telephone number is invalid
        </small>
    </div>
    <mat-form-field style="width:100%">
        <input matInput formControlName="skills" name="skills" placeholder="Skills" >
    </mat-form-field>

    <mat-form-field style="width:100%">
      <input required  matInput formControlName="organization" name="organization" placeholder="Organization" >
  </mat-form-field>
  <div *ngIf="formGroup.controls.organization.errors && (formGroup.controls.organization.touched || formGroup.controls.organization.dirty) " class="error">
    <small
        class="form-text text-danger"
        *ngIf="formGroup.controls.organization.errors.required"
        >Organization is required
    </small>
    </div>


    <mat-form-field>
        <mat-label>Permission</mat-label>
        <select required autocomplete="new-password" matNativeControl required formControlName="role">
            <option *ngFor="let opt of roles" [value]="opt">{{opt}}</option>
        </select>
    </mat-form-field>
    <div *ngIf="formGroup.controls.role.errors && (formGroup.controls.role.touched || formGroup.controls.role.dirty) " class="error">
        <small
            class="form-text text-danger"
            *ngIf="formGroup.controls.role.errors.required"
            >Role is required
        </small>
    </div>
    <br>
    <button id="save" mat-raised-button (click)="save();">{{newUser ? "Create" : "Update"}}</button>
    <button (click)="deleteUser();" mat-raised-button color="warn" id="resetButton" style="margin-left:0.5em;">Delete</button>
    <button  mat-raised-button (click)="export();" style="margin-left:0.5em;">Export Handout</button>
    </form>
</mat-card>