import {
    Component,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { NewsService } from '../../services/news.service';
import { reduce } from 'rxjs/operators';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { MiscService } from 'src/app/services/misc.service';
import {config} from './particles'
import { Store } from '@ngrx/store';
import { setDefaultPlatform } from 'src/app/actions/news.actions';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class LoginComponent implements OnInit {
    style: object = {};
    params: object = {};
    width = 100;
    height = 100;
    platforms = ['cyber','innotech','krimisi']
    platform = "cyber";
    _username
    _password
    wrongUsernamePassword=false;
    form: FormGroup;

    closeAfterLogin = false;

    constructor(private miscService: MiscService, private authService: AuthService, private router: Router, private store: Store, private activatedRoute: ActivatedRoute) {

    }

    get username() { return this.form.get('username'); }

    get password() { return this.form.get('password'); }

    ngOnInit() {
        this.style = {
            'position': 'fixed',
            'width': '100%',
            'height': '100%',
            'z-index': 0,
            'top': 0,
            'left': 0,
            'right': 0,
            'bottom': 0,
            'background-color':"#000000",
        };

          // this.params = config;
        console.log(this.params);

        this.activatedRoute.queryParams.subscribe((params) => {
            console.log("PARAMS", params);
            if (params.closeAfterLogin) {
                this.closeAfterLogin = true;
            }
            console.log("CloseAfterLogin?", this.closeAfterLogin);
        });

        this.form = new FormGroup({
            'username': new FormControl(this._username, Validators.required),
            'password': new FormControl(this._password)
        });
    }

    login(){
        this.form.get('username').markAsDirty();
        this.form.get('password').markAsDirty();
        if(this.form.valid){
            this.authService.authenticate(this.form.get('username').value,this.form.get('password').value).then((data)=>{
                if(this.platform && (this.platform=="cyber" || this.platform=="innotech") || this.platform=="krimisi"){
                    // Set default plattform
                    this.store.dispatch(setDefaultPlatform({platform:this.platform}));  
                }

                if (this.closeAfterLogin) {
                    window.close();
                } else {
                    this.router.navigateByUrl("/news");
                }
                // TODO reset colors
            }).catch((err)=>{
                this.wrongUsernamePassword=true;
            })
        }
    }
}
