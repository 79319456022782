import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from "@angular/core";

import {
  tileLayer,
  latLng,
  Map,
  circle,
  polygon,
  marker,
  Icon,
  icon,
} from "leaflet";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { BaseChartDirective, Color, Label } from "ng2-charts";

import * as moment from "moment";
import { generate, Observable, Subscription } from "rxjs";
import { distinctUntilChanged, reduce, withLatestFrom } from "rxjs/operators";
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {
  NewsStats,
  StatsGeo,
  StatsGeoHash,
} from "../../models/news-stats.model";
import { select, Store } from "@ngrx/store";
import { requestGeneralStats, requestStatsNews24h, requestStatsTagUsageCount } from "src/app/actions/news.actions";
import { State } from "src/app/reducers";
import { CloudData, CloudOptions } from 'angular-tag-cloud-module';
import { selectCDRC } from "src/app/selectors/tag.selector";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { FormControl, FormGroup } from "@angular/forms";

export interface UserData {
  id: string;
  name: string;
  no_articles_day: string;
  no_articles: string;
}
const NAMES: string[] = [
  'User1', 'User2', 'User3', 'User4', 'User5', 'User6', 'User7', 'User8', 'User9'
];

declare var L;
declare var HeatmapOverlay;

@UntilDestroy()
@Component({
  selector: "app-bh-stats",
  templateUrl: "./bh-stats.component.html",
  styleUrls: ["./bh-stats.component.scss"],
})
export class BhStatsComponent implements OnInit, OnDestroy,AfterViewInit  {
  constructor(private store: Store<State>) {

    // Create 100 users
    const users = Array.from({length: 100}, (_, k) => createNewUser(k + 1));

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(users);
  }

  public mostUsedTag = undefined;
  public totalSetTags = -1;

  @ViewChild("doughnut") doughnutChart?: BaseChartDirective;

  geoSlider = 3;
  geoRadii = [
    5000_000,
    1200_000,
    156_000,
    39_000,
    5_000,
    1_000,
    150,
    40,
    5,
    1,
    1,
    1,
  ];

  newsStats$: Observable<NewsStats[]> = this.store.select(
    (state) => state.newsStats.aggs
  );

  generalStats$: Observable<any> = this.store.select(
    (state) => state.generalStats
  );

  geoStats$: Observable<StatsGeoHash> = this.store.select(
    (state) => state.geoStats
  );

  tagUsageCount$: Observable<any[]> = this.store.select(
    (state) => state.tagUsageCount
  );

  optionsSpec: any = {
    layers: [
      {
    //  url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png",
     //
     url: "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution: "",
      },
    ],
    zoom: 1.2,
    center: [22.274234, 16.455118],
  };

  // Leaflet bindings
  zoom = this.optionsSpec.zoom;
  center = latLng(this.optionsSpec.center);
  options = {
    preferCanvas: true,
    layers: [
      // tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', { attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' }) ,
      tileLayer(this.optionsSpec.layers[0].url, {
        attribution: this.optionsSpec.layers[0].attribution,
      }),
    ],
    zoom: this.optionsSpec.zoom,
    center: latLng(this.optionsSpec.center),
  };

  layers: any = [
    circle([48.274234, 16.455118], { radius: 100 }),
    polygon([
      [46.8, -121.85],
      [46.92, -121.92],
      [46.87, -121.8],
    ]),
  ];


  public lineChartData: ChartDataSets[] = [
    // (function() {const arr = []; for (let i = -12; i <= 12; i++) {arr.push(Math.pow(i,2)+10) }return arr;})()
    { data: [], label: "All", borderColor: 'rgba(220,220,220,1)', backgroundColor: 'rgba(220,220,220,0.5)' },
    { data: [], label: "Cyber", borderColor: '#86c7f3', backgroundColor: '#86c7f3c0' },
    { data: [], label: "Krimisi", borderColor: '#97ffb5', backgroundColor: '#97ffb5c0'},
    { data: [], label: "Innotech", borderColor: '#ffd28c', backgroundColor: '#ffd28cc0' },
  ];
  public lineChartColors: Color[] = [
    {
      borderColor: 'rgba(220,220,220,1)', backgroundColor: 'rgba(220,220,220,0.5)'
    },
    {
      borderColor: '#86c7f3', backgroundColor: '#86c7f3b5'
    },
    {
      borderColor: '#97ffb5', backgroundColor: '#97ffb5b5'
    },
    {
      borderColor: '#ffd28c', backgroundColor: '#ffd28cb5'
    },
  ];


  public lineChartLabels: Label[] = [];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio:false,
    scales: {
      yAxes: [{
         ticks: {
            stepSize: 50
         }
      }]
  },
  tooltips: {
    mode: 'index',
    intersect: false
  },
  hover: {
      mode: 'index',
      intersect: false
  }
  };

  public lineChartLegend = true;
  public lineChartType = "line";
  public lineChartPlugins = [];

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  public barChartLabels: Label[] = [];

  public barChartType: ChartType = "bar";
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    { data: [66, 59, 80, 81, 56, 55, 40, 50], label: "Series AB" },
    // { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  ];

  public barChartOptions2: ChartOptions = {
    responsive: true,
    
  };
  public barChartLabels2: Label[] =   ["Cyber","Krimisi","Innotech"];

  public barChartType2: ChartType = "pie";
  public barChartLegend2 = true;
  public barChartPlugins2 = [];

  public barChartData2: ChartDataSets[] = [
    { data: [25,10,50] },
    // { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  ];

  public barChartOptions3: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
        }
      ]
    },
  };
  public barChartLabels3: Label[] = [];

  public barChartType3: ChartType = "bar";
  public barChartLegend3 = true;
  public barChartPlugins3 = [];

  public barChartData3: ChartDataSets[] = [
    { data: [50], label: "Gesellschaft" },
    { data: [25], label: "Cyber" },
    { data: [10], label: "Innotech" },
    { data: [5], label: "Krimisi" },
    // { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  ];
  subs: Subscription[] = [];


  tagcloud_options: CloudOptions = {
    // if width is between 0 and 1 it will be set to the width of the upper element multiplied by the value
    width: 1,
    // if height is between 0 and 1 it will be set to the height of the upper element multiplied by the value
    height: 400,
    overflow: false,
  };

  tagcloud_data: CloudData[] = [];

  tags$ = this.tagUsageCount$.pipe(untilDestroyed(this), withLatestFrom(this.store.select((state) => state.tagMapping)), distinctUntilChanged()).subscribe(([res, tagMap]) => {
    //console.log("############################### tag counts",res)

    this.tagcloud_data = res.slice(0, 100).filter((e) => !!tagMap.get(parseInt(e.key + '', 10))).map((tag)=>{
      let fullTag = tagMap.get(parseInt(tag.key + '', 10));
      let resInfo: CloudData = {text: fullTag.item, weight: 0, color: "#b3b3b3"};

      if (fullTag.deleted || this.isIgnoredTag(fullTag.id)) {
        return resInfo;
      }

      var colorstring = this.getTagColor(fullTag, tagMap);
      if (!colorstring) colorstring = "#b3b3b3";
          
      resInfo = {text: fullTag.item, weight: Math.sqrt(tag.doc_count),  color: colorstring};
      return resInfo;
    });
    console.log("TAG CLOUD", this.tagcloud_data);
  });

  public doughnutChartOptions: ChartOptions = {
    responsive: true,
    
  };
  public doughnutChartLabels: Label[] =   ["Cyber","Krimisi","Innotech"];

  public doughnutChartType: ChartType = "doughnut";
  public doughnutChartLegend = true;
  public doughnutChartPlugins = [];
  public doughnutChartColors = [{ backgroundColor: [
    '#86c7f3',
     '#97ffb5',
     '#ffd28c'
     ]}];

  public doughnutChartData: ChartDataSets[] = [
    { data: [1,1,1] },
    // { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  ];

  displayedColumns: string[] = ['id', 'name', 'no_articles_day', 'no_articles'];
  dataSource: MatTableDataSource<UserData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  searchDateRange = new FormGroup({
    start: new FormControl(new Date(new Date().valueOf() - 77760000000).toISOString()),
    end: new FormControl(new Date().toISOString()),
  });

  hsv2rgb(h,s,v) {                              
    const f= (n,k=(n+h/60)%6) => v - v*s*Math.max( Math.min(k,4-k,1), 0);     
    return [f(5),f(3),f(1)];       
  } 

  updateMap() {
    this.store.dispatch(requestStatsNews24h({ resolution: this.geoSlider, startDate: new Date(this.searchDateRange.controls.start.value).toISOString(), endDate: new Date(this.searchDateRange.controls.end.value).toISOString()}));
  }
  onMapReady(map) {
    // Do stuff with map
    // tslint:disable-next-line: new-parens
    const legend = new (L.Control.extend({
      options: { position: "bottomright" },
    }))();

    const vm = this;
    legend.onAdd = function (map) {
      const div = L.DomUtil.create("div", "legend");
      const labels = [
        "Sales greater than " + 5,
        "Sales greater than " + 3,
        "Sales equal or less than " + 2,
      ];
      const grades = [5 + 1, 2 + 1, 3];
      div.innerHTML = "<div><b>Legend</b></div>";
      div.innerHTML +=
        '<i style="background:' +
        "green" +
        '"> &nbsp; &nbsp;</i> &nbsp; &nbsp;' +
        "Push<br>";
      div.innerHTML +=
        '<i style="background:' +
        "blue" +
        '"> &nbsp; &nbsp;</i> &nbsp; &nbsp;' +
        "Pull<br>";
      div.innerHTML +=
        '<i style="background:' +
        "white" +
        '"> &nbsp; &nbsp;</i> &nbsp; &nbsp;' +
        "Undefined<br>";
      return div;
    };
    legend.addTo(map);
  }
  ngOnInit() {
    this.store.dispatch(requestStatsTagUsageCount());
    this.store.dispatch(requestGeneralStats({timestamp: new Date().toISOString()}));
    this.updateMap();
    for (let i = 0; i <= 5; i++) {
      this.barChartLabels.unshift(moment().subtract("d").fromNow());
    }
    for (let i = 0; i <= 24; i++) {
      // this.lineChartLabels.unshift(moment().subtract('h').fromNow())
    }

    // Fetched News
    const sub2 = this.newsStats$.subscribe((stats) => {
      this.lineChartLabels = [];
      this.lineChartData[0].data = [];
      this.lineChartData[1].data = [];
      this.lineChartData[2].data = [];
      this.lineChartData[3].data = [];
      console.log("STATS", stats);
      for (let entry of stats) {
        this.lineChartLabels.push(
          moment(entry.key_as_string).subtract("h").fromNow()
        );
        this.lineChartData[0].data.push(entry.doc_count);
        for (let spec of entry.sub.buckets) {
          if (spec.key == "cyber") {
            this.lineChartData[1].data.push(spec.doc_count);
          } else if (spec.key == "krimisi") {
            this.lineChartData[2].data.push(spec.doc_count);
          } else {
            this.lineChartData[3].data.push(spec.doc_count);
          }
        }

        for (let i = 1; i <= 3; i++) {
          if (this.lineChartData[i].data.length < this.lineChartData[0].data.length)
            this.lineChartData[i].data.push(0);
        }
      }
    });

    // GEOMAP
    const sub = this.geoStats$.subscribe((geo) => {
      this.layers = [];
      const resolution = this.geoSlider - 1;
      console.log("GEO", geo);
      for (const entry2 of geo.undefined) {
        const entry: any = { ...entry2 };
        entry.event = {};
        entry.event.rating = "pull";
        entry.event.name = "";
        this.layers.push(
          circle([entry.location.latitude, entry.location.longitude], {
            radius: this.geoRadii[resolution],
            color: "white",
          })
        );
      }
      for (const entry2 of geo.pull) {
        const entry: any = { ...entry2 };
        entry.event = {};
        entry.event.rating = "pull";
        entry.event.name = "";
        this.layers.push(
          circle([entry.location.latitude, entry.location.longitude], {
            radius: this.geoRadii[resolution],
            color: "green",
          })
        );
      }
      for (const entry2 of geo.push) {
        const entry: any = { ...entry2 };
        entry.event = {};
        entry.event.rating = "push";
        entry.event.name = "";
        this.layers.push(
          circle([entry.location.latitude, entry.location.longitude], {
            radius: this.geoRadii[resolution],
            color: "blue",
          })
        );
      }

      // console.log('kkwkkwk', this.layers);
    });

    this.subs.push(sub, sub2);

    this.tagUsageCount$.pipe(untilDestroyed(this), withLatestFrom(this.store.select((state) => state.tagMapping)), distinctUntilChanged()).subscribe(([data, tagMap]) => {
      this.totalSetTags = 0;
      let i = 0;
      while (!this.mostUsedTag && i < data.length) {
        let tag = tagMap.get(parseInt(data[i++].key + '', 10));
        if (!tag || tag.deleted) continue;
        if (!this.isIgnoredTag(tag.id)) {
          this.mostUsedTag = tag;
        }
      }

      for (let el of data) {
        this.totalSetTags += el.doc_count;
      }

    });


    this.generalStats$.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(data => {
      data = data.total;
      console.log("###############", data);
      if(!data.platforms) return;
      this.doughnutChartLabels.splice(0, this.doughnutChartLabels.length);
      this.doughnutChartLabels.push(...data.platforms.map(plt => plt.key));
      this.doughnutChartData[0].data.splice(0, 3);
      this.doughnutChartData[0].data.push(...data.platforms.map(plt => plt.doc_count));

      let i = 0;
      for (let key of data.platforms) {
        key = key.key.toLowerCase();
        if (key.startsWith("cyber")) {
          this.doughnutChartColors[0].backgroundColor[i] = '#86c7f3';
        } else if (key.startsWith("krimisi")) {
          this.doughnutChartColors[0].backgroundColor[i] = '#97ffb5';
        } else {
          this.doughnutChartColors[0].backgroundColor[i] = '#ffd28c';
        }
        i++;
      }
    });
    
  }
  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
  toISO(dateString) {
    return `${dateString.substring(0, 4)}-${dateString.substring(
      4,
      6
    )}-${dateString.substring(6, 8)}T${dateString.substring(
      8,
      10
    )}:${dateString.substring(10, 12)}:${dateString.substring(12, 14)}`;
  }

  isIgnoredTag(tag: any) {
    if (tag.toLowerCase().includes("dokumententyp") || tag.toLowerCase().includes("zeitlicher aspekt")
    || tag.toLowerCase().includes("auftragstags") || tag.toLowerCase().includes("quellentyp")) {
      return true;
    }
    return false;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getTagColor(tag, tagMap) {
    if (!tag) return undefined;

    if (tag.color && (tag.color.startsWith('#') || tag.color.startsWith('r'))) {
      return tag.color;
    } else {
      return this.getTagColor(tagMap.get(tag.parent), tagMap);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}

/** Builds and returns a new User. */
function createNewUser(id: number): UserData {
  const name = NAMES[Math.round(Math.random() * (NAMES.length - 1))];

  return {
    id: id.toString(),
    name,
    no_articles_day: Math.round(Math.random() * 20+30).toString(),
    no_articles: Math.round(Math.random() * 200+1000).toString()
  };


}
