<div mat-dialog-content>
    <form [formGroup]="inputForm" class="form">
      <p class="mat-h4">Level: {{data.entry.level}} Timestamp: {{data.entry.timestamp}}</p>
      <p>
        <mat-form-field style="width: calc(33.3% - .5em);" appearance="fill">
          <mat-label>Modul</mat-label>
          <input matInput placeholder="Modul" formControlName="modul">
        </mat-form-field>
        <mat-form-field style="margin-left: 1em; width: calc(66.6% - .5em);" appearance="fill">
          <mat-label>Operator</mat-label>
          <input matInput placeholder="Operator" formControlName="operator">
        </mat-form-field>
      </p>
      <p>
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Message</mat-label>
          <textarea matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="50" formControlName="message"></textarea>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field style="width: calc(50% - .5em);" appearance="fill">
          <mat-label>Old State</mat-label>
          <textarea matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="50" formControlName="old_state"></textarea>
        </mat-form-field>
        <mat-form-field style="margin-left: 1em; width: calc(50% - .5em);" appearance="fill">
            <mat-label>New State</mat-label>
            <textarea matInput
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="50" formControlName="new_state"></textarea>
          </mat-form-field>
      </p>
    </form>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onClick(false)">
      Close
    </button>
  </div>