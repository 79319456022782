import { Injectable } from "@angular/core";
import { Observable, of, throwError, from } from "rxjs";
import { NewsStats, NewsCompound } from "../models/news-stats.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { distinctUntilChanged, flatMap, map, take } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { SearchParameter } from "../models/search-parameter";
import { Tag } from "../models/tags.model";
import { Store } from "@ngrx/store";
import { State } from "../reducers";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Injectable({
  providedIn: "root",
})
export class SearchService {

  tags$: Observable<Tag[]> = this.store.select((state) => state.tags);
  tags;

  constructor(private http: HttpClient, private store: Store<State>,) {
    this.tags$.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(tags => {this.tags = tags;});
  }

  search(requestParameters: SearchParameter) {
    console.log("Service:", requestParameters);
    requestParameters = {...requestParameters};

    // toDate +24h to make selection inclusive and fix single day selection issue
    try {
      let temp = new Date(requestParameters.dateEnd);
      temp.setHours(temp.getHours() + 24);
      requestParameters.dateEnd = temp.toISOString();
    } catch(e) {}

    // Also include subordinate tags in search.
    let tagUIds = [...requestParameters.tags].map((e) => e.uid);
    for (let tag of this.tags) {
      for (let selTag of requestParameters.tags) {
        try {
          if (tag.id.startsWith(selTag.id) && !tag.deleted) {
            tagUIds.push(tag.uid);
          }
        } catch(e) {}
      }
    }

    // Also exclude subordinate tags in search.
    let exTagUIds = [...requestParameters.exTags].map((e) => e.uid);
    for (let tag of this.tags) {
      for (let selTag of requestParameters.exTags) {
        try {
          if (tag.id.startsWith(selTag.id) && !tag.deleted) {
            exTagUIds.push(tag.uid);
          }
        } catch(e) {}
      }
    }

    const requestParameters2 = {
      query: requestParameters.fulltext.trim(),
      fromDate: requestParameters.dateBegin,
      toDate: requestParameters.dateEnd,
      onlyMeta: true,
      from: requestParameters.index * requestParameters.size,
      size: requestParameters.size,
      tags: tagUIds,
      exTags: exTagUIds, // Tags to be not included in result.
      lat: requestParameters.lat,
      lng: requestParameters.lng,
      radius: requestParameters.radius,
      hasMentions: requestParameters.hasMentions,
      ownEntries: requestParameters.ownEntries,
      platforms: requestParameters.platforms,
      title: requestParameters.title,
      selectedUsers: requestParameters.selectedUsers,
      sort: requestParameters.sort,
      checked: requestParameters.checked,
      confirmed: requestParameters.confirmed
    };

    console.log("BUFF", requestParameters2.query);


    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http.post(
      `${environment.SERVER_URL}/search`,
      requestParameters2,
      httpOptions
    ) as Observable<{ results: any[]; count: number }>;
  }
}
