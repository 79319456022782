// Search
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { tap, map } from 'rxjs/operators';
import { articleError, factorsError, factorsErrorRoot, setItemFeedback, searchError, loadReports, saveMappingSuccess, duplicateURLError, enableCreateButton } from '../actions/news.actions';



@Injectable()
export class ErrorEffects {

      
// Errors
addItemError$ = createEffect(() => this.actions$.pipe(
    ofType(articleError),
    tap(() => {
      this.toastr.error( 'Article not found!\n Redirecting to /new', 'Error');
      this.router.navigateByUrl('/editor/new');
    })
    ),
    {dispatch: false}
  );
  genericError$ = createEffect(() => this.actions$.pipe(
    ofType(articleError),
    tap(() => {
      this.toastr.error( 'An Error Occured', 'Error');
     // this.router.navigateByUrl('/editor/new');
    })
    ),
    {dispatch: false}
  );
  setItemFeedback$ = createEffect(() => this.actions$.pipe(
    ofType(setItemFeedback),
    tap(() => {
      this.toastr.success( 'Added Item to Report', 'Success');
     // this.router.navigateByUrl('/editor/new');
    }),
    map((e)=>loadReports())
    )
    
  );
  factorsError$ = createEffect(() => this.actions$.pipe(
    ofType(factorsError),
    tap(() => {
      this.toastr.error( 'Cannot modify Read Only factor', 'Error');
    })
    ),
    {dispatch: false}
  );
  factorsErrorRoot$ = createEffect(() => this.actions$.pipe(
    ofType(factorsErrorRoot),
    tap(() => {
      this.toastr.error( 'Cannot modify Root Node', 'Error');
    })
    ),
    {dispatch: false}
  );
  searchError$ = createEffect(() => this.actions$.pipe(
    ofType(searchError),
    tap(() => {
      this.toastr.error( 'Check your query syntax!', 'Search Error', {timeOut : 10000});
    })
    ),
    {dispatch: false}
  );

  duplicateURLError$ = createEffect(() => this.actions$.pipe(
    ofType(duplicateURLError),
    tap(() => {
      this.toastr.error( 'Duplicate, URL already in the database!', 'Error');
    }),
    map(()=>enableCreateButton())
    )
  );

  mappingAdded$ = createEffect(() => this.actions$.pipe(
    ofType(saveMappingSuccess),
    tap(() => {
      this.toastr.success( 'Mapping successfully updated!', 'Success');
      this.router.navigateByUrl('/sourceManagement');
    })
    ),
    {dispatch: false}
  );

  

    constructor(
        private actions$: Actions,
        private toastr: ToastrService,
        private router: Router,
      ) {}

}