import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";

import { AppRoutingModule } from "./modules/app-routing.module";
import { AppComponent } from "./app.component";
import { StoreModule } from "@ngrx/store";
import { reducers, metaReducers } from "./reducers";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { EffectsModule } from "@ngrx/effects";

// Effects

import { NewsEffects } from "./effects/news.effects";
import { SearchEffects } from "./effects/search.effects";
import { ErrorEffects } from "./effects/error.effects";
import { FactorsEffects } from "./effects/factors.effects";
import { MiscEffects } from "./effects/misc.effects";
import { UserFeedbackEffects } from "./effects/user-feedback.effects";
import { ReportEffects } from "./effects/report.effects";
import { RouterEffects } from "./effects/router.effects";
import { MappingEffects } from "./effects/mapping.effects";
import { SemanticGraphEffects } from "./effects/semantic-graph.effects";

// Guards

import { CanDeactivateGuard } from './guards/can-deactivate.guard';

// Components

import { NewsComponent } from "./components/news/news.component";
import { NewsEditorComponent } from "./components/news-editor/news-editor.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialComponentsModule } from "./modules/material-components.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { newsReducer } from "./reducers/news.reducer";
import { NavComponent } from "./components/nav/nav.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { EditorComponent } from "./components/editor/editor.component";

import { TagTreeComponent } from "./components/tag-tree/tag-tree.component";

import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { LeafletDrawModule } from "@asymmetrik/ngx-leaflet-draw";
import { ChartsModule } from "ng2-charts";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { HttpClientModule } from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { SearchComponent } from "./components/search/search.component";
import {
  DateAdapter,
  DateAdapter as dateAdapterMaterial,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE as dateLocalMaterial,
} from "@angular/material/core";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import { NgxSpinnerModule } from "ngx-spinner";
import { MapComponent } from "./components/graphs/map/map.component";
import { EditorMapComponent } from "./components/news-editor/editor-map/editor-map.component";
import { DeleteDialogComponent } from "./components/misc-components/delete-dialog/delete-dialog.component";
import { SourceMappingComponent } from "./components/source-management/source-mapping/source-mapping.component";
import { SourceManagementComponent } from "./components/source-management/source-management.component";
import {
  TagEditorComponent,
  FactorDialogComponent,
} from "./components/tag-editor/tag-editor.component";
import { StatsComponent } from "./components/stats/stats.component";
import { FileUploadComponent } from "./components/file-upload/file-upload.component";

import { ResizableModule } from "angular-resizable-element";
import { MentionsGraphComponent } from "./components/graphs/mentions-graph/mentions-graph.component";
import { ChoroplethGraphComponent } from "./components/graphs/choropleth-graph/choropleth-graph.component";
import { VideoManagerComponent } from "./components/video-manager/video-manager.component";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { ReportsComponent } from "./components/reports/reports.component";
import { UserEditorComponent } from "./components/userComponents/user-editor/user-editor.component";
import { UserManagerComponent } from "./components/userComponents/user-manager/user-manager.component";
import { RoleManagerComponent } from "./components/roleComponents/role-manager/role-manager.component";
import { RoleEditorComponent } from "./components/roleComponents/role-editor/role-editor.component";

import { LoginComponent } from "./components/login/login.component";
const config: SocketIoConfig = { url: "localhost", options: {} };

import { CommonModule } from "@angular/common";
import { ContentLoaderModule } from "@ngneat/content-loader";
import { VisualAnalyzerComponent } from "./components/visual-analyzer/visual-analyzer.component";
import { RangeSliderComponent } from "./components/range-slider/range-slider.component";
import { registerLocaleData } from "@angular/common";
import localede from "@angular/common/locales/de";
import { StoreRouterConnectingModule, routerReducer } from "@ngrx/router-store";
import { CustomSerializer } from "./CustomRouterSerializer";
import { BhStatsComponent } from './components/bh-stats/bh-stats.component';
import { TagCloudModule } from 'angular-tag-cloud-module';
import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';
import { EditorMapModalWrapperComponent } from "./components/news-editor/editor-map-modal-wrapper/editor-map-modal-wrapper.component";
import { PermissionErrorComponent } from './components/permission-error/permission-error.component';
import { ReIndexDialogComponent, SiteManagementComponent } from './components/site-management/site-management.component';
import { TagsComponent } from './components/tags/tags.component';
import { TagEditDialogComponent, TagEditTreeComponent } from './components/tags/tag-edit-tree/tag-edit-tree.component';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { HistoryDetailsComponent, LoggingComponent } from './components/site-management/logging/logging.component';
registerLocaleData(localede);

@NgModule({
  declarations: [
    AppComponent,
    NewsComponent,
    NavComponent,
    EditorComponent,
    TagTreeComponent,
    DashboardComponent,
    SearchComponent,
    MapComponent,
    DeleteDialogComponent,
    SourceMappingComponent,
    SourceManagementComponent,
    TagEditorComponent,
    FactorDialogComponent,
    StatsComponent,
    MentionsGraphComponent,
    ChoroplethGraphComponent,
    VideoManagerComponent,
    FileUploadComponent,
    ReportsComponent,
    UserEditorComponent,
    UserManagerComponent,
    LoginComponent,
    VisualAnalyzerComponent,
    RangeSliderComponent,
    RoleManagerComponent,
    RoleEditorComponent,
    BhStatsComponent,
    NewsEditorComponent,
    EditorMapComponent,
    EditorMapModalWrapperComponent,
    PermissionErrorComponent,
    SiteManagementComponent,
    TagsComponent,
    TagEditTreeComponent,
    TagEditDialogComponent,
    HistoryDetailsComponent,
    ReIndexDialogComponent,
    DeleteDialogComponent,
    FactorDialogComponent,
    EditorMapModalWrapperComponent,
    LoggingComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    LeafletModule,
    LeafletDrawModule,
    CKEditorModule,
    ChartsModule,
    HttpClientModule,
    NgxSpinnerModule,
    ResizableModule,
    ContentLoaderModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    AppRoutingModule,
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
    StoreDevtoolsModule.instrument(),
    EffectsModule.forRoot([
      NewsEffects,
      SearchEffects,
      ErrorEffects,
      FactorsEffects,
      MiscEffects,
      MappingEffects,
      UserFeedbackEffects,
      ReportEffects,
      RouterEffects,
      SemanticGraphEffects
    ]),
    BrowserAnimationsModule,
    ToastrModule.forRoot(), // ToastrModule added
    MaterialComponentsModule,
    SocketIoModule.forRoot(config),
    TagCloudModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMatColorPickerModule
  ],
  providers: [
    { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: dateLocalMaterial, useValue: "en-GB" },
    { provide: LOCALE_ID, useValue: "de-DE" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [dateLocalMaterial, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    CanDeactivateGuard
  ],
  bootstrap: [AppComponent],
  entryComponents: [DeleteDialogComponent, FactorDialogComponent,EditorMapModalWrapperComponent, TagEditDialogComponent,
    ReIndexDialogComponent],
})
export class AppModule { }
