import { Injectable } from "@angular/core";
import { Observable, of, throwError, from } from "rxjs";
import { NewsStats, NewsCompound } from "../models/news-stats.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { flatMap, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { SearchParameter } from "../models/search-parameter";
import { Tag } from "../models/tags.model";

@Injectable({
  providedIn: "root",
})
export class MentionsService {
  constructor(private http: HttpClient) {}

  public getStatsMentions(search?: SearchParameter, id?) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    if (id) {
      return this.http.get(`${environment.SERVER_URL}/mentions/${id}`);
    } else {
      return this.http.get(`${environment.SERVER_URL}/mentions`);
    }
  }
}
