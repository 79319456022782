import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { Socket } from 'ngx-socket-io';
import { videoManagerUpdate } from '../actions/news.actions';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  constructor(private store: Store<State>,private socket: Socket) { 
    this.socket.fromEvent<string[]>('infoResult').subscribe((data)=>{

      console.log("SOCKET:",data);

      this.store.dispatch(videoManagerUpdate({data}));
    })

    
  }
  addToQueue(url){
    console.log("GET info")
    this.socket.emit("add",url);
  }
}
