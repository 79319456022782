import { Injectable } from "@angular/core";
import { Observable, of, throwError, from } from "rxjs";
import { NewsStats, NewsCompound } from "../models/news-stats.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { flatMap, map, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { SearchParameter } from "../models/search-parameter";
import { Tag } from "../models/tags.model";
import { Mapping } from "../models/mapping.model";
import * as languages from './languages.json'

@Injectable({
  providedIn: "root",
})
export class TranslateService {
  constructor(private http: HttpClient) {}


  public translate(content,title,subtitle,language,language_translated) {

    content = content.replaceAll("<b>", "").replaceAll("</b>", "").replaceAll("<strong>", "").replaceAll("</strong>", "");
    title = title.replaceAll("<b>", "").replaceAll("</b>", "").replaceAll("<strong>", "").replaceAll("</strong>", "");
    subtitle = subtitle.replaceAll("<b>", "").replaceAll("</b>", "").replaceAll("<strong>", "").replaceAll("</strong>", "");
    console.log(content)
    const httpOptions: any = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };
    if(language && language_translated){
      return this.http
      .post(`${environment.SERVER_URL}/translate`,{content,title,subtitle,language,language_translated}, httpOptions);
    }else{
      return this.http
      .post(`${environment.SERVER_URL}/translate`,{content,title,subtitle}, httpOptions);
    }
  }

  public getLanguages(){

    const result: Observable<any> = this.http.get(
      `${environment.SERVER_URL}/translate/languages`
    ) as Observable<any>;

    return result.pipe(map((res) => {
      let arry = res.hits.hits.map(rl => {return {...rl._source, iso: rl._source.id}});

      for (let lan of Object.entries((languages as any).default).map(([key,value])=>({iso:key,name:value}))) {
        if (arry.findIndex(x => x.iso == lan.iso) == -1) {
          arry.push({...lan, id: lan.iso, languageSource: false, languageDocument: false});
        }
      }
      return arry;
    }));
    /*
    return of(Object.entries((languages as any).default).map(([key,value])=>({iso:key,name:value})))*/
  }


  public setLanguages(languages: any[]){

    const result: Observable<any> = this.http.post(
      `${environment.SERVER_URL}/translate/languages`, languages
    ) as Observable<any>;

    return result;
  }
}
