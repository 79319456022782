import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import {
  requestNews,
  searchRequest,
  addSearchTag,
  removeSearchTag,
  addSearchTag2,
  removeSearchTag2,
  setListView,
} from "src/app/actions/news.actions";
import { Store } from "@ngrx/store";
import {
  fromEvent,
  Observable,
  ReplaySubject,
  of,
  combineLatest,
  from,
} from "rxjs";
import { SearchParameter } from "src/app/models/search-parameter";
import {
  tap,
  map,
  debounceTime,
  distinctUntilChanged,
  filter,
  groupBy,
  flatMap,
  mergeMap,
  toArray,
  take,
  mergeAll,
  startWith,
  withLatestFrom,
  bufferCount,
  reduce,
  switchMap,
} from "rxjs/operators";
import { Location } from "@angular/common";
import { ActivatedRoute, Router, NavigationStart } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import { State } from "src/app/reducers";
import { Tag } from "src/app/models/tags.model";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { AuthService } from "src/app/services/auth.service";
@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit, AfterViewInit, OnDestroy {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild("fulltext", { read: ElementRef }) fulltext: ElementRef;
  @ViewChild("termInput", { read: ElementRef }) termInput: ElementRef;
  @ViewChild("termInput2", { read: ElementRef }) termInput2: ElementRef;
  @ViewChild("titleTemp", { read: ElementRef }) titleRef: ElementRef;


  searchParameters$: Observable<SearchParameter> = this.store.select(
    (state) => state.currentSearch
  );
  searchTags$: Observable<Tag[]> = this.store.select((state) => state.tags).pipe(map((e) => {
    let mappi = new Map();
    e.filter((tag) => !tag.deleted).forEach((tag) => {
      if (mappi.has(tag.id)) {
        if (!mappi.get(tag.id).active && tag.active) {
          mappi.set(tag.id, tag);
        }
      } else {
        mappi.set(tag.id, tag);
      }
    });
    return Array.from(mappi.values());
  }));
  termCtrl = new FormControl();
  termCtrl2 = new FormControl();
  ownEntries = new FormControl(true);
  filteredTags = this.termCtrl.valueChanges.subscribe((e) => {
    console.log(e);
  });
  filteredTags2 = this.termCtrl2.valueChanges.subscribe((e) => {
    console.log(e);
  });

  tagsGroups2$ = combineLatest(
    this.searchTags$.pipe(
      filter((arr) => arr.length > 0),
      take(1)
      // flatMap((e)=>e),
      // map((e)=>e.name),
      //  toArray(),
    ),
    this.termCtrl2.valueChanges
  ).pipe(
    filter(([_, query]) => { return typeof query == 'string';}),
    map(([tags, query]) => {
      return tags.filter((tag: Tag) =>
          tag.id.toLowerCase().includes(query.toLowerCase())
      )
    }),
    tap((e) => console.log("AFTER2:", e))
  );

  test = this.tagsGroups2$.subscribe((e) => {
    console.log("-.------------------------ss", e);
  });

  tagsGroups$ = combineLatest(
    this.searchTags$.pipe(
      filter((term) => term.length > 0),
      take(1),
      /*flatMap((searchParameter) => searchParameter),
      groupBy(
        (tag) => tag.type,
        null,
        null,
        () => new ReplaySubject()
      ),
      map((e) => ({
        tags: e.pipe(toArray()),
        type: e.key,
      })),
      toArray()*/
    ),
    this.termCtrl.valueChanges
  ).pipe(
    filter(([_, query]) => { return typeof query == 'string';}),
    map(([tags, query]) => {
      return tags.filter((tag: Tag) =>
          tag.id.toLowerCase().includes(query.toLowerCase())
      )
    }),
    tap((e) => console.log("AFTER:", e))
  );

  // Ng Model  bindings
  query = "";
  currentQueryParams = {};
  searchDateRange = new FormGroup({
    start: new FormControl(new Date().toISOString()),
    end: new FormControl(new Date().toISOString()),
  });


  permissions$: Observable<any> = this.store.select(
    (state) => state.permissions
  );

  subs = [];

  title;
  selectedUsers=new FormControl([]);
  cyber=new FormControl(false);
  krimisi=new FormControl(false);
  innotech=new FormControl(false);
  checked=new FormControl("none");
  users:any = [];
  displayUsers: any = [];

  listView = false;

  constructor(
    private store: Store<State>,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: AuthService
  ) {}

  ngOnInit() {
    const sub3 = this.searchParameters$.subscribe((parameters) => {
      if (
        parameters &&
        parameters.fulltext &&
        parameters.fulltext != "undefined"
      ) {
        this.query = parameters.fulltext;
      }
    });
    this.store.select((state)=>state.currentSearch.listView).pipe(take(1)).subscribe((view)=>{
      this.listView = view;
    })

    const sub2 = this.activatedRoute.queryParams.subscribe((params) => {
      this.currentQueryParams = params;
      console.log("change router", params);
      if (params && params.fulltext && params.fulltext != "undefined") {
        this.query = params.query;
      }
      this.ownEntries.setValue(params.ownEntries=="true")
      this.searchDateRange.controls.start.setValue(params.from);
      this.searchDateRange.controls.end.setValue(params.to);

      if(params.platforms!=null){
        this.cyber.setValue(false);
        this.krimisi.setValue(false);
        this.innotech.setValue(false);
        if(params.platforms.includes("cyber"))
          this.cyber.setValue(true);
        if(params.platforms.includes("krimisi"))
          this.krimisi.setValue(true);
        if(params.platforms.includes("innotech"))
          this.innotech.setValue(true);
      }

      if(params.users)
        this.selectedUsers.setValue(params.users.split(","))
      this.updateSearch(true);
    });
    const sub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart && event.url.startsWith("/news")) {
        console.log(event);
        this.updateSearch(true);
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });

    const sub4 = this.tagsGroups$.subscribe((e) => {
      // console.log(e);

    });

    const sub5 = this.userService.getUsersBrief().then((users)=>{
      console.log("ausers",users)
      this.users = users;
      this.displayUsers = [...this.users].sort((x, y) => x.username.localeCompare(y.username));
    })

    this.subs.push(sub, sub2, sub3, sub4);
  }

  filterUsers(input: string) {
    this.displayUsers = [...this.users].sort((x, y) => x.username.localeCompare(y.username)).filter(lang => {
      return (lang.username && (lang.username.toLowerCase().startsWith(input.toLowerCase())));
    });
  }

  onList({checked}){
    console.log(checked)
    this.store.dispatch(setListView({value:checked}))
  }

  selected(event) {
    console.log(event.option.value);
    this.store.dispatch(addSearchTag({ tag: event.option.value }));
    this.termCtrl.setValue("");
    this.termInput.nativeElement.value = "";
  }

  selected2(event) {
    console.log(event.option.value);
    this.store.dispatch(addSearchTag2({ tag: event.option.value }));
    this.termCtrl2.setValue("");
    this.termInput2.nativeElement.value = "";
  }

  clearTimeRange(){
    this.searchDateRange.reset();
    this.updateSearch(false);
  }

  remove(term) {
    this.store.dispatch(removeSearchTag({ tag: term }));
  }

  remove2(term) {
    this.store.dispatch(removeSearchTag2({ tag: term }));
  }

  ngAfterViewInit(): void {
    console.log(this.fulltext);
    fromEvent(this.fulltext.nativeElement, "keyup")
      .pipe(
        map((event: any) => event.target.value),
        // if character length greater then 2
        // filter(res => res.length > 2),
        // Time in milliseconds between key events
        debounceTime(1000),
        // If previous query is diffent from current
        distinctUntilChanged(),
        // subscription for response
        tap((e) => console.log(e))
      )
      .subscribe((data) => {
        this.updateSearch(false);
      });

      fromEvent(this.titleRef.nativeElement, "keyup")
      .pipe(
        map((event: any) => event.target.value),
        // if character length greater then 2
        // filter(res => res.length > 2),
        // Time in milliseconds between key events
        debounceTime(1000),
        // If previous query is diffent from current
        distinctUntilChanged(),
        // subscription for response
        tap((e) => console.log(e))
      )
      .subscribe((data) => {
        this.updateSearch(false);
      });

      this.selectedUsers.valueChanges
      .pipe(
        // subscription for response
        tap((e) => console.log(e))
      )
      .subscribe((data) => {
        this.updateSearch(false);
      });
    this.termCtrl.setValue("");
  }
  ngOnDestroy(): void {
    this.subs.forEach((e) => e.unsubscribe());
  }

  updateSearch(initial) {
    const queryParams:any = this.currentQueryParams;
    console.log("UPDATE SEARCH-----------------------------------------------");
    const fromDate =
      typeof this.searchDateRange.controls.start.value == "object" && this.searchDateRange.controls.start.value!=null
        ? this.searchDateRange.controls.start.value.toISOString()
        : this.searchDateRange.controls.start.value;
    let toDate =
      typeof this.searchDateRange.controls.end.value == "object" && this.searchDateRange.controls.start.value!=null
        ? this.searchDateRange.controls.end.value.toISOString()
        : this.searchDateRange.controls.end.value;

    const selectedUsers = this.selectedUsers.value;
    const cyber = this.cyber.value;
    const krimisi = this.krimisi.value;
    const innotech = this.innotech.value;
    const newsParameters = {
      fulltext: this.query,
      dateBegin: fromDate,
      dateEnd: toDate,
      ownEntries: this.ownEntries.value,
      selectedUsers: selectedUsers,
      platforms: {cyber,krimisi,innotech},
      title: this.title,
      checked: this.checked.value=="checked",
      confirmed: this.checked.value=="confirmed"
    } ;

    // Reset pagination index on search
    if (!initial) {
      (<any>newsParameters).index = 0;
    }

    let queryString = "";

    if (newsParameters.fulltext && newsParameters.fulltext.length > 0) {
      queryString += "&query=" + newsParameters.fulltext;
    }

    if (newsParameters.title && newsParameters.title.length > 0) {
      queryString += "&title=" + newsParameters.title;
    }


    if (newsParameters.dateBegin) {
      queryString += "&from=" + newsParameters.dateBegin;
    }

    if (newsParameters.dateEnd) {
      queryString += "&to=" + newsParameters.dateEnd;
    }

    if (newsParameters.checked) {
      queryString += "&checked=true"
    }
    if (newsParameters.confirmed) {
      queryString += "&confirmed=true"
    }

    if (queryParams && queryParams.article_id) {
      queryString += "&article_id=" + queryParams.article_id;
    }
    


    if (newsParameters.selectedUsers.length >0) {
      queryString += "&users=" + newsParameters.selectedUsers.join(",");
    }

    queryString += "&ownEntries=" + (newsParameters.ownEntries ?? true);

    if(newsParameters.platforms.cyber && newsParameters.platforms.innotech && newsParameters.platforms.krimisi){

    }else{
      let platString = "";
      if(newsParameters.platforms.cyber)
        platString+=",cyber"
      if(newsParameters.platforms.krimisi)
        platString+=",krimisi"
      if(newsParameters.platforms.innotech)
        platString+=",innotech"

      if(platString.charAt(0)==",")
         platString= platString.substring(1)

        queryString += "&platforms=" + platString;
    }

    if (queryString.charAt(0) == "&") {
      queryString = "?" + queryString.substring(1);
    }
    console.log(queryString)

    this.location.replaceState("/news", queryString);
    this.store.dispatch(searchRequest({ request: newsParameters }));
  }
}
