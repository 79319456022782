import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { Router } from "@angular/router";
import { State } from "src/app/reducers";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";


@Component({
  selector: "app-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.scss"],
})
export class NavComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private store: Store<State>
  ) {}

  username;
  permission;
  permissions$: Observable<any> = this.store.select(
    (state) => state.permissions
  );

  ngOnInit() {
    this.authService.isAuthenticated().then(() => {
      this.username = this.authService.username;
      this.permission = this.authService.role;
    });
  }

  logout() {
    this.authService.logout();
    this.router.navigate(["/login"]);
  }
}
