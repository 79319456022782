<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node"
                   matTreeNodeToggle
                   matTreeNodePadding [ngStyle]="{'background-color': (!isParentInActive(node) && flatNodeMap.get(node).data.active) ? getNodeColor(node) : (flatNodeMap.get(node).data.deleted ? '#ff7c7c' : '#eddcdcdc'), 'text-decoration': (!isParentInActive(node) && flatNodeMap.get(node).data.active) ? '' : 'line-through', 'display': flatNodeMap.get(node).data.deleted && !this.showDeletedTags ? 'none' : ''}">
      <button mat-icon-button disabled></button>
      <h5 class="checklist-leaf-node"
                    [ngClass]="{'drop-above': dragNodeExpandOverArea === 'above' && dragNodeExpandOverNode === node,
                                'drop-below': dragNodeExpandOverArea === 'below' && dragNodeExpandOverNode === node,
                                'drop-center': dragNodeExpandOverArea === 'center' && dragNodeExpandOverNode === node}"
                    draggable="true"
                    (dragstart)="handleDragStart($event, node);"
                    (dragover)="handleDragOver($event, node);"
                    (drop)="handleDrop($event, node);"
                    (dragend)="handleDragEnd($event);">{{node.item}}</h5>
                    <button mat-icon-button (click)="editItem(node)"><mat-icon>edit</mat-icon></button>
                    <button *ngIf="!flatNodeMap.get(node).data.deleted" mat-icon-button (click)="addNewItem(node)"><mat-icon>add</mat-icon></button>
    </mat-tree-node>
  
    <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding [ngStyle]="{'background-color': getNodeColor(node)}">
      <button mat-icon-button disabled></button>
      <mat-form-field>
        <input matInput #itemValue placeholder="New item...">
      </mat-form-field>
      <button mat-button (click)="saveNode(node, itemValue.value)">Save</button>
    </mat-tree-node>
  
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild"
                   matTreeNodePadding [ngStyle]="{'background-color': (!isParentInActive(node) && flatNodeMap.get(node).data.active) ? getNodeColor(node) : (flatNodeMap.get(node).data.deleted ? '#ff7c7c' : '#eddcdcdc'), 'text-decoration': (!isParentInActive(node) && flatNodeMap.get(node).data.active) ? '' : 'line-through', 'display': flatNodeMap.get(node).data.deleted && !this.showDeletedTags ? 'none' : ''}">
      <button mat-icon-button matTreeNodeToggle
              [attr.aria-label]="'toggle ' + node.filename">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <h5 [ngClass]="{'drop-above': dragNodeExpandOverArea === 'above' && dragNodeExpandOverNode === node,
                                'drop-below': dragNodeExpandOverArea === 'below' && dragNodeExpandOverNode === node,
                                'drop-center': dragNodeExpandOverArea === 'center' && dragNodeExpandOverNode === node}"
                    draggable="true"
                    (dragstart)="handleDragStart($event, node);"
                    (dragover)="handleDragOver($event, node);"
                    (drop)="handleDrop($event, node);"
                    (dragend)="handleDragEnd($event);">{{node.item}}</h5>
      <button mat-icon-button (click)="editItem(node)"><mat-icon>edit</mat-icon></button>
      <button *ngIf="!flatNodeMap.get(node).data.deleted" mat-icon-button (click)="addNewItem(node)"><mat-icon>add</mat-icon></button>
    </mat-tree-node>
  </mat-tree>
  <button mat-button color="primary" (click)="saveNewTags()">Save</button>
  <span #emptyItem></span>
  