// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  extension: false,
  wp: false,
  HOST: process.env.FRONTEND_BASE_URL,
  SERVER_URL: process.env.FRONTEND_URL,
  ENABLE_GDELT: process.env.ENABLE_GDELT
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
//import "zone.js/plugins/zone-error"; // Included with Angular CLI.
