import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, HostListener, AfterViewInit } from '@angular/core';
import { tileLayer, latLng, featureGroup, DrawEvents, Control, DrawOptions, icon, } from 'leaflet';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { StatsGeo, NewsStats } from 'src/app/models/news-stats.model';
import { addGeoFilter, closeEditorMap, removeGeoFilter, requestStatsNews24h, updateEditorMap } from 'src/app/actions/news.actions';
import {environment} from '../../../../environments/environment';
import { State } from 'src/app/reducers';
//import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';

declare var HeatmapOverlay;
declare var L;
declare var GeoSearch;



@Component({
  selector: 'editor-map-wrapper',
  templateUrl: './editor-map-modal-wrapper.component.html',
  styleUrls: ['./editor-map-modal-wrapper.component.scss']
})
export class EditorMapModalWrapperComponent {

  constructor() { }

  returnValue = null;

  onChange(event){
    //
    this.returnValue = event;
  }
 


}
