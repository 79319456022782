import { createSelector } from '@ngrx/store';
import { State } from '../reducers';

export const selectNews = (state: State) => state.news.results;

export const selectUnique = createSelector(
    selectNews,
    (selectedNews: any[]) => {
      const dupStore = new Set();
      const out = [];
      for (const entry of selectedNews) {
         const url =  entry.article.source;
         if (dupStore.has(url)) {
            continue;
         }
         dupStore.add(url);
         out.push(entry);
      }
      return out;
    }
  );
