// Search
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { addItemSuccess, deleteItemSuccess, fileUpload, fileUploadError, newsSucess, updateItemSuccess, genericError, genericNotification, checkItemSuccess, confirmItemSuccess, translateDocumentSuccess, pageConfirmedSuccess } from '../actions/news.actions';



@Injectable()
export class UserFeedbackEffects {

        // Effects for user feedback

  displayAddItem$ = createEffect(() => this.actions$.pipe(
    ofType(addItemSuccess),
    tap(() => {
      this.toastr.success( 'Item Added!', 'Success', );
   //   this.router.navigateByUrl('/news');
    })
    ),
    {dispatch: false}
  );
  displayDeleteItem$ = createEffect(() => this.actions$.pipe(
    ofType(deleteItemSuccess),
    tap(() => {
      this.toastr.success( 'Item Deleted!', 'Success', );
    //  this.router.navigateByUrl('/news');
    })
    ),
    {dispatch: false}
  );
  displayUpdateItem$ = createEffect(() => this.actions$.pipe(
    ofType(updateItemSuccess),
    tap(() => {
      this.toastr.success( 'Item Updated!', 'Success', );
     // this.router.navigateByUrl('/news');
    })
    ),
    {dispatch: false}
  );
  newsSucess$ = createEffect(() => this.actions$.pipe(
    ofType(newsSucess),
    tap(() => {
      this.spinner.hide();
    })
    ),
    {dispatch: false}
  );

  fileUpload$ = createEffect(() => this.actions$.pipe(
    ofType(fileUpload),
    tap(() => {
      this.toastr.success( 'File Upload Complete', 'Success', );
    })
    ),
    {dispatch: false}
  );

  fileUploadError$ = createEffect(() => this.actions$.pipe(
    ofType(fileUploadError),
    tap(() => {
      this.toastr.success( 'File Upload Failed', 'Error', );
    })
    ),
    {dispatch: false}
  );

  genericError$ = createEffect(() => this.actions$.pipe(
    ofType(genericError),
    tap((action) => {
      this.toastr.error( action.error, 'Error', );
    })
    ),
    {dispatch: false}
  );
  genericNotification$ = createEffect(() => this.actions$.pipe(
    ofType(genericNotification),
    tap((action) => {
      this.toastr.success( action.info, 'Info', );
    })
    ),
    {dispatch: false}
  );
  checkNotification$ = createEffect(() => this.actions$.pipe(
    ofType(checkItemSuccess),
    tap((action) => {
      if(action.state)
        this.toastr.success( "Item checked!", 'Info', );
      else
        this.toastr.success( "Item unchecked!", 'Info', );
    })
    ),
    {dispatch: false}
  );
  confirmNotification$ = createEffect(() => this.actions$.pipe(
    ofType(confirmItemSuccess),
    tap((action) => {
      if(action.state)
        this.toastr.success( "Item confirmed!", 'Info', );
      else
        this.toastr.success( "Item unconfirmed!", 'Info', );
    })
    ),
    {dispatch: false}
  );

  translateSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(translateDocumentSuccess),
    tap((action) => {
      this.toastr.success( "Document was successfully translated!", 'Info', );
    })
    ),
    {dispatch: false}
  );

  pageConfirmSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(pageConfirmedSuccess),
    tap((action) => {
      this.toastr.success( "Page was successfully confirmed!", 'Success', );
    })
    ),
    {dispatch: false}
  );


  


  




    constructor(
        private actions$: Actions,
        private toastr: ToastrService,
        private router: Router,
        private spinner: NgxSpinnerService,
      ) {}

}


