import { createReducer, on } from '@ngrx/store';
import { sha256 } from 'hash.js';
import { deleteFile, fileUpload, fileUploadError, videoManagerUpdate } from '../actions/news.actions';


      
export const initialState: any = {
    active:{},
    waiting:{},
    completed:{}
};


const _videoReducer = createReducer(initialState,
    on(videoManagerUpdate, (state, action) => {
        const newState = {...state,active:{...state.active},waiting:{...state.waiting},completed:{...state.completed}};
        console.log("oldstate",state)
        console.log("action",action)
        if(action.data.active)
            for(const update of action.data.active){
                newState.active = {...newState.active};
                newState.active[update.token] = update;
            }
        if(action.data.waiting)
            for(const update of action.data.waiting){
                newState.waiting = {...newState.waiting};
                newState.waiting[update.token] = update;
            }
        if(action.data.completed)
            for(const update of action.data.completed){
                newState.completed = {...newState.completed};
                newState.completed[update.token] = update;
            }
        console.log("oldstate",newState);

        return newState;
    })
    
  );

export function videoReducer(state, action) {
    return _videoReducer(state, action);
  }
