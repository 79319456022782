import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged } from 'rxjs/operators';
import { saveFactors } from 'src/app/actions/news.actions';
import { State } from 'src/app/reducers';
import { selectCDRC } from 'src/app/selectors/tag.selector';

@UntilDestroy()
@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css']
})
export class TagsComponent implements OnInit {

  tags$ = this.store.select(state => state.tags).pipe(distinctUntilChanged(), untilDestroyed(this));
  showDeletedTags = false;
  tags = [];


  constructor(private store: Store<State>) {
    this.tags$.subscribe(data => {
      this.tags = data;
    });
  }

  ngOnInit(): void {
  }

  saveNewTagTree(data: any) {
    console.log(data);
    this.store.dispatch(saveFactors({ tags: data }));
    this.tags = data;
  }

  exportTags() {
    this.downloadJson(this.tags, "tag_export.json");
  }

  downloadJson(myJson, fileName){
    var sJson = JSON.stringify(myJson);
    var element = document.createElement('a');
    element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(sJson));
    element.setAttribute('download', fileName);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
}

}
