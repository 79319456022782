import { createReducer, on } from '@ngrx/store';
import { addGeoFilter, addSearchTag, addSearchTag2, changeSortField, editSearch, removeGeoFilter, removeSearchTag, removeSearchTag2, searchRequest, setListView } from '../actions/news.actions';
import { SearchParameter } from '../models/search-parameter';


export const initialState: SearchParameter = {index: 0, size: 25, tags: [], exTags: [], listView:false};

const _searchReducer = createReducer(initialState,
    on(searchRequest, (state, action) => {
        console.log('Search Request Reducer', state, action);
        console.log('comp state', {...state, ...action.request});
        return {...state, ...action.request};
    }),
    on(addSearchTag, (state, action) => {
        return {...state, tags: [...state.tags, action.tag]};
    }),
    on(removeSearchTag, (state, action) => {
        return {...state, tags: state.tags.filter((dbTag) => dbTag.id != action.tag.id)};
    }),
    on(addGeoFilter, (state, action) => {
        return {...state, radius:action.radius,lat:action.coords.lat,lng:action.coords.lng}
    }),

    on(removeGeoFilter, (state, action) => {
        const obj = {...state}
        if(obj.radius)
            delete obj.radius;
        if(obj.lat)
            delete obj.lat;
        if(obj.lng)
            delete obj.lng;
        return obj;
    }),


    on(addSearchTag2, (state, action) => {
        return {...state, exTags: [...state.exTags, action.tag]};
    }),
    on(removeSearchTag2, (state, action) => {
        return {...state, exTags: state.exTags.filter((dbTag) => dbTag.id != action.tag.id)};
    }),


    on(changeSortField, (state, action) => {
        return {...state, sort:{direction:action.direction,field:action.field}};
    }),

    on(setListView, (state, action) => {
        return {...state, listView:action.value};
    }),

  )

export function searchReducer(state, action) {
    return _searchReducer(state, action);
  }
