import { Injectable } from "@angular/core";
import { Observable, of, throwError, from } from "rxjs";
import { NewsStats, NewsCompound } from "../models/news-stats.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { flatMap, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { SearchParameter } from "../models/search-parameter";
import { Tag } from "../models/tags.model";

@Injectable({
  providedIn: "root",
})
export class StatsService {
  constructor(private http: HttpClient) {}

  requestStats(resolution: number = 3, startDate: string = "2021-10-31T23:00:00.000Z", endDate: string = new Date().toISOString()): Observable<NewsCompound> {
    const result: Observable<NewsCompound> = this.http.get(
      `${environment.SERVER_URL}/stats/summary?resolution=${resolution}&startDate=${startDate}&endDate=${endDate}`
    ) as Observable<NewsCompound>;

    return result;
  }

  requestAnalyzerStats(
    resolution: number = 3,
    fromDate?: string,
    toDate?: string
  ): Observable<NewsCompound> {
    console.log();

    let query = `?resolution=${resolution}`;
    if (fromDate) {
      query += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      query += `&toDate=${toDate}`;
    }
    const result: Observable<NewsCompound> = this.http.get(
      `${environment.SERVER_URL}/stats/analyzer${query}`
    ) as Observable<NewsCompound>;

    return result;
  }

  byUser24(weekly: boolean = false): Observable<{key,doc_count}[]> {
    if (!weekly) {
      const result: Observable<{key,doc_count}[]> = this.http.get(
        `${environment.SERVER_URL}/stats/byUser24`
      ) as Observable<{key,doc_count}[]>;
      return result;
    } else {
      const result: Observable<{key,doc_count}[]> = this.http.get(
        `${environment.SERVER_URL}/stats/byUser24?weekly=${weekly}`
      ) as Observable<{key,doc_count}[]>;
      return result;
    }
  }


  tagUsageCount(): Observable<{key,doc_count}[]> {
    const result: Observable<{key,doc_count}[]> = this.http.get(
      `${environment.SERVER_URL}/stats/tagUsage`
    ) as Observable<{key,doc_count}[]>;

    return result;
  }


  requestGeneralStats(timestamp: string = new Date().toISOString()): Observable<any[]> {
    const result: Observable<any[]> = this.http.get(
      `${environment.SERVER_URL}/stats/general?timestamp=${timestamp}`
    ) as Observable<any[]>;

    return result;
  }
  

  public getStatsByUrl() {
    return this.http.get(`${environment.SERVER_URL}/stats/24h`).toPromise();
  }

  public getStatsByEventCode() {
    return this.http
      .get(`${environment.SERVER_URL}/mentionsPerEventCode`)
      .toPromise();
  }
}
