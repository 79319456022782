// Search
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import {
  createReport,
  createReportResult,
  genericError,
  loadReports,
  loadReportsSuccess,
  setItem,
  setItemFeedback,
  requestMappingSuccess,
  requestMapping,
  saveMappingSuccess,
  saveMapping,
} from "../actions/news.actions";
import { MappingService } from "../services/mapping.service";
import { State } from "../reducers";
import { Store } from "@ngrx/store";

@Injectable()
export class MappingEffects {
  requestMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestMapping),
      switchMap((data) =>
        this.mappingService.getNewsMapping(data.domain).pipe(
          map((mapping) => {
            mapping.mappings = mapping.mappings.map((mappingField) => {
              mappingField.whitelist = mappingField.whitelist || "";
              mappingField.blacklist = mappingField.blacklist || "";
              mappingField.whitelistSplitted =
                mappingField.whitelistSplitted || [];
              mappingField.whitelistCount = mappingField.whitelistCount || 0;
              mappingField.whitelistOrig = mappingField.whitelistOrig || "";
              mappingField.regexSelect = mappingField.regexSelect || "";
              mappingField.regex = mappingField.regex || "";
              mappingField.regexBuffer = mappingField.regexBuffer || "";
              return mappingField;
            });
            return mapping;
          }),
          map((mapping) => requestMappingSuccess({ mapping })),
          catchError((err) => {
            console.log("ERR", err);
            return of(genericError({ error: "Mapping not found" }));
          })
        )
      )
    )
  );

  saveMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveMapping),
      withLatestFrom(this.store.select((state) => state.currentMapping)),
      switchMap(([action, data]) =>
        this.mappingService.saveMapping(data).pipe(
          map(() => saveMappingSuccess()),
          catchError((err) => {
            console.log("ERR", err);
            return of(genericError({ error: "Mapping not found" }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private mappingService: MappingService,
    private store: Store<State>
  ) {}
}
