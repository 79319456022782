<mat-toolbar  fxLayout="row wrap"  style="">
    <a *ngIf="!!(permissions$ | async).canViewDashboard" [routerLink]="['/dashboard']"><img height="50px" src="assets/img/eule.svg"></a>
    <a *ngIf="!(permissions$ | async).canViewDashboard" [routerLink]="['/news']"><img height="50px" src="assets/img/eule.svg"></a><div >&nbsp;OSDP </div>
    <span class="fill-remaining-space"></span>
    
    
    <a routerLinkActive="active-link" [routerLink]="['/newsEditor']"  *ngIf="(permissions$ | async).canCreateEntries"><button mat-button class="nav-button" >
      <i class="material-icons">add</i>
      Add Item
    </button></a>
    <a routerLinkActive="active-link" [routerLink]="['/dashboard']" *ngIf="(permissions$ | async).canViewDashboard" ><button mat-button class="nav-button" >Dashboard</button></a>
    <a id="news-link" routerLinkActive="active-link"   [routerLink]="['/news']" *ngIf="(permissions$ | async).canSearch || (permissions$ | async).canViewOwnEntries"  [queryParams]="{query: ''}"><button mat-button class="nav-button" >Search</button></a>
    <a routerLinkActive="active-link" [routerLink]="['/bh-stats']" *ngIf="(permissions$ | async).canViewStatsAndGraphs" ><button mat-button class="nav-button" >Stats</button></a>
    <!-- <a [routerLink]="['/analyzer']" *ngIf="(permissions$ | async).canAccessAnalyzer" ><button mat-button class="nav-button" >Analyzer</button></a>-->
     <a routerLinkActive="active-link" [routerLink]="['/tagEdit']" *ngIf="(permissions$ | async).canCreateTags" ><button mat-button class="nav-button"  >Tags</button></a> 
     <a routerLinkActive="active-link" [routerLink]="['/sourceManagement']" *ngIf="(permissions$ | async).canCreateMappings" ><button mat-button class="nav-button" >HPS</button></a> 
     <a routerLinkActive="active-link" [routerLink]="['/userManagement']" *ngIf="(permissions$ | async).canViewUsers" ><button mat-button class="nav-button" >Users</button></a> 
     <a routerLinkActive="active-link" [routerLink]="['/roleManagement']" *ngIf="(permissions$ | async).canManageRoles" ><button mat-button class="nav-button" >Roles</button></a> 
   <!-- <a [routerLink]="['/videoManager']"><button mat-button class="nav-button" >Video Manager</button></a>-->
     <a routerLinkActive="active-link" [routerLink]="['/reports']" *ngIf="(permissions$ | async).canAddEntryToReport"><button mat-button class="nav-button" >Reports</button></a>
     <a routerLinkActive="active-link" [routerLink]="['/siteManagement']" *ngIf="(permissions$ | async).canAccessSiteManagement"><button mat-button class="nav-button" >Site</button></a>
    <!--<button mat-button class="nav-button" [routerLink]="['/tools']">Tools</button>-->
    <span class="example-fill-remaining-space"></span>
    <button mat-button [matMenuTriggerFor]="userMenu">{{username}}</button>
    <mat-menu #userMenu="matMenu">
      <button mat-menu-item class="nav-button" (click)="logout()">Logout</button>
    </mat-menu>
    
  </mat-toolbar>
  
