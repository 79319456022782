import { Injectable } from "@angular/core";
import { Observable, of, throwError, from } from "rxjs";
import { News } from "../models/news.model";
import { NewsStats, NewsCompound } from "../models/news-stats.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { flatMap, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { SearchParameter } from "../models/search-parameter";
import { Tag } from "../models/tags.model";

@Injectable({
  providedIn: "root",
})
export class MiscService {
  constructor(private http: HttpClient) {}

  requestGoldstein(): any {
    return this.http
      .get(`${environment.SERVER_URL}/stats/goldstein`)
      .toPromise();
  }

  public downloadZip(e) {
    window.open(
      `${environment.SERVER_URL}/downloadVideos/${e
        .map((file) => file.id)
        .join(",")}`,
      "_blank"
    );
  }

  public getParticlesJson() {
    return this.http.get(`./assets/particlesjs-config.json`).toPromise();
  }

  public getDemoD3() {
    return this.http
      .get(
        `https://gist.githubusercontent.com/bumbeishvili/a0ab3299b4e4eb1557085317b9136ec1/raw/ba488da953d2fa0e64ff2469a209c520fd408055/dateData.json`
      )
      .toPromise();
  }


  public reindexCForm(): Observable<any> {
    const result: Observable<any> = this.http.get(
      `${environment.SERVER_URL}/news/reindexcform`
    );

    return result;
  }

  public requestLogentrys(params: any): Observable<any> {
    const result: Observable<any> = this.http.post(
      `${environment.SERVER_URL}/log/list`, params
    );

    return result;
  }
}
