import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { environment } from "../../environments/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { Store } from "@ngrx/store";
import { MappingService } from "./mapping.service";
import { sha256 } from "hash.js";
import { Mapping, MappingField } from "src/app/models/mapping.model";
import { State } from '../reducers';
import {
    saveMapping,
    requestMapping,
    updateMapping,
    updateMappingField,
    genericNotification,
    programmaticArticleUpdate,
    updateMappingURL,
    genericError
} from "../actions/news.actions";
import { ScrappingService } from "./scrapping.service";
import { News } from "../models/news.model";

declare var chrome;

@Injectable({
    providedIn: "root",
})
export class ChromeService {
    backgroundPageConnection;
    inSourceMgmt = false;
    private loaded = false;

    currentField = "article_title";



    currentMappings: MappingField[];
    currentMapping = {};

    mapping$: Observable < Mapping > = this.store.select(
        (state) => state.currentMapping
    );

    constructor(
        private spinner: NgxSpinnerService,
        private store: Store < State > ,
        private mappingService: MappingService,
        private scrapeService: ScrappingService
    ) {
        this.mapping$.subscribe((mappings) => {
            this.currentMappings = mappings.mappings;
            this.currentMapping = { ...this.currentMappings.filter((entry) => entry.field == this.currentField)[0] };
        })

    }

    setCurrentField(field) {
        this.currentField = field;
        this.currentMapping = { ...this.currentMappings.filter((entry) => entry.field == this.currentField)[0] };
    }

    async receiveMessage(message) {
        console.log("Message from extension", message)
        if (message && message.type && message.type === "extractedData") {
            const field = message.field;
            const data = message.data;
            if (!data) {
                this.store.dispatch(
                    genericNotification({
                        info: `Can't extract field ${field}`,
                    })
                );
                return;
            }

            const outputObject: any = {};
            outputObject[field] = this.processData(field, data);

            console.log("From extraction:", outputObject)
            this.store.dispatch(programmaticArticleUpdate(outputObject));
        } else if (message && message.type && message.type === "rawContent") {
            /*const outputObject:Partial<News> = {};
            outputObject.article_raw_url = message.data;
            // contentRaw: Theoratically replaced with path to folder where full page is stored.
            this.store.dispatch(programmaticArticleUpdate(<News>outputObject)); */
        } else if (message && message.type && message.type === "documentURL") {
            const url = message.data;
            let urlObj: URL = null;
            // Try to parse URL
            try {
                urlObj = new URL(url);
            } catch (e) {
                console.log("Url invalid");
            }
            // Start Extraction
            this.startExtraction({ domain: urlObj.origin, url });
            this.store.dispatch(updateMappingURL({ update: { domain: urlObj.origin.replace(/https?:\/\//, ""), url: message.data } }))
        } else if (message && message.type && message.type == "selection") {
            // TODO select correct mapping
            const mapping: any = this.currentMapping;

            if (message.option == "whitelist") {
                mapping.whitelist = message.data;
                mapping.whitelistSplitted = [];
                // First time only
                if (!message.fromButton) {
                    mapping.whitelistCount = 0;
                    mapping.whitelistOrig = message.data;
                }

                mapping.result = message.text;
            } else if (message.option == "blacklist") {
                mapping.blacklist = message.data;
                mapping.result = message.text;
            } else if (message.index != undefined) {
                const message_index = message.index;

                // TODO extract mapping index

                mapping.whitelist = message.data;
                this.sendMessage({
                    type: "restrict-blacklist",
                    data: message.data,
                });
                mapping.result = message.text;
                mapping.whitelistSplitted = [];
                // First time only
                if (!message.fromButton) {
                    mapping.whitelistCount = 0;
                    mapping.whitelistOrig = message.data;
                }
            }
            this.store.dispatch(updateMappingField({ mapping }));
        }

        // let regex =  new RegExp(this.mappings[this.currentFocus].regex);
        // let res = regex.exec(this.mappings[this.currentFocus].result);
        // console.log(res)
    }

    processData(field, data) {
        console.log("PROCESS DATA", field, data);
        let input = data.trim();

        if (!(field == "date" || field == "created_at")) {
            input = input.replace(/\s+?/g, " ");
        }
        return input;
    }

    extractData() {
        // Request URL of selected Tab
        this.sendMessage({
            type: "requestDocumentURL",
        });

    }

    async startExtraction({ domain, url }) {
        console.log("Start Extraction")
        // Get Domain Mappings from the database
        let result = null;
        try {
            result = await this.mappingService.getNewsMapping(
                sha256().update(domain).digest("hex")
            ).toPromise();
        } catch (err) {
            console.log(err)
            //use fallback extractor
            this.store.dispatch(
                genericError({
                    error: `No Mapping Found for Domain: ${domain}, please create a new mapping ore use the serverside extractor.`,
                })
            );
            /*
            this.store.dispatch(
                genericNotification({
                    info: `No Mapping Found for Domain: ${domain} using fallback scrapper`,
                })
            );
            
            try{
                let data:any = {article_author:"Autor ungenannt",article_source:url,article_domain:domain};
                data = {...data,  ...await this.scrapeService.scrape(url).toPromise()};
                data = {...data, article_raw_url: data.article_content}
                if(data.article_author.length==""){
                    data.article_author="Autor ungenannt";
                }
                this.store.dispatch(programmaticArticleUpdate(data));
                return
            }catch(e){
                this.store.dispatch(
                    genericNotification({
                        info: `Fallback scrapper failed\n${e.message}`,
                    })
                );
            }*/
            return
        }
        console.log("Mapping", result)

        const outObject: any = {};

        const mappings = result.mappings;

        if (result && mappings) {
            outObject.article_author = "Autor ungenannt";
            outObject.article_language = result.language;
            outObject.article_languageErfassung = result.language;
            outObject.article_source = url;
            outObject.article_domain = domain;
            this.store.dispatch(programmaticArticleUpdate(outObject));
        }

        if (mappings) {
            this.sendMessage({
                type: "extractRawContent",
            });
        }
        if (mappings) {
            for (const mapping of mappings) {
                console.log("Extract data for: ", mapping)
                this.sendMessage({
                    type: "extractData",
                    data: mapping,
                });
            }
        }

    }

    loadExtensionConnection() {
        if (!environment.extension) return false;
        if (!this.loaded) {
            console.log("invoked");
            const backgroundPageConnection = chrome.runtime.connect(
                chrome.runtime.id, {
                    name: "devtools-page",
                }
            );

            console.log("background", backgroundPageConnection);
            backgroundPageConnection.onMessage.addListener((message) => {
                // Handle responses from the background page, if any
                console.log("Message form bg script", message);
                this.receiveMessage(message);
            });

            // Relay the tab ID to the background page
            backgroundPageConnection.postMessage({
                tabId: chrome.devtools.inspectedWindow.tabId,
                scriptToInject: "content_script.js",
            });
            this.backgroundPageConnection = backgroundPageConnection;
            this.loaded = true;
            chrome.tabs.onUpdated.addListener((tabId, info) => {
                console.log("update",info, tabId, chrome.devtools.inspectedWindow.tabId);
                if (
                    info.status === "complete" &&
                    tabId == chrome.devtools.inspectedWindow.tabId
                ) {
                    this.spinner.hide();
                   // this.sendMessage({ type: "enableSelector" });
                } else if (
                    info.status === "loading" &&
                    tabId == chrome.devtools.inspectedWindow.tabId
                ) {
                    this.spinner.show();
                }
            });
            return backgroundPageConnection;
        } else {
            return this.backgroundPageConnection;
        }
    }
    sendMessage(...args: any[]) {
        if (!environment.extension) {
            console.log(environment);
            console.log("Extension not enabled");
            return false;
        }
        return this.backgroundPageConnection.postMessage.apply(
            this.backgroundPageConnection,
            args
        );
    }

    changeCurrentTabUrl(url) {
        this.spinner.show();
        if (!environment.extension) {
            console.log(environment);
            console.log("Extension not enabled");
            return false;
        }
        chrome.tabs.update(chrome.devtools.inspectedWindow.tabId, { url });
    }
}