import { Component, OnInit, ViewChild, HostListener } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NewsService } from "../../services/news.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { Sort } from "@angular/material/sort";
import { MappingService } from "src/app/services/mapping.service";
import { environment } from "src/environments/environment";
import { combineAll, concatMap, distinctUntilChanged, take, withLatestFrom } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { State } from "src/app/reducers";
import { genericNotification } from "src/app/actions/news.actions";
import { FormControl } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest, forkJoin, merge, Observable, of } from "rxjs";

@UntilDestroy()
@Component({
  selector: "app-source-management",
  templateUrl: "./source-management.component.html",
  styleUrls: ["./source-management.component.css"],
})
export class SourceManagementComponent implements OnInit {
  @ViewChild("paginator", { static: true }) paginator: MatPaginator;
  constructor(
    private mappingService: MappingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private store: Store<State>
  ) {}
  DATA;

  unknownDataSource;
  exportData;

  columnsToDisplayUser = [
    "name",
    "domain",
    "language",
    //"user",
    //"date",
    "active",
    "done",
    "usage",
    "id"
  ];
  columnsToDisplay = this.columnsToDisplayUser;
  totalLength;
  loading: boolean = true;

  skip = 0;
  show = 10;

  search = "";
  size = 1;

  tabindex;
  moreDetail;

  showInactive = new FormControl(false);

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild("paginator", { static: true }) vc: MatPaginator;

  @HostListener("window:keydown", ['$event'])
  _keydown(event) {
    if (event.key == "ArrowRight") {
      this.vc.nextPage();
    }
    if (event.key == "ArrowLeft") {
      this.vc.previousPage();
    }
  }
  updateTable() {
      this.columnsToDisplay = this.columnsToDisplayUser;
  }
  ngOnInit() {}
  ngAfterViewInit() {

    let dataSource: Observable<any> = this.mappingService.getNewsMappings();

    this.showInactive.valueChanges.pipe(untilDestroyed(this), withLatestFrom(dataSource),
     distinctUntilChanged()).subscribe(([showInact, data]) => {
      console.log("DATATAT", data, showInact);

      if (!showInact) {
        this.columnsToDisplay = this.columnsToDisplay.filter(e => e != "usage");
      } else if (!this.columnsToDisplay.includes("usage")) {
        this.columnsToDisplay.splice(this.columnsToDisplay.length - 1, 0, "usage");
      }

      this.DATA = data.filter((dat) => {return dat.active || showInact;});
      this.unknownDataSource = new MatTableDataSource();
      this.unknownDataSource.data = this.DATA.filter((e, i) => !e.known);
      this.unknownDataSource.sort = this.sort;
      this.totalLength = this.DATA.filter((e, i) => !e.known).length;
      this.unknownDataSource.paginator = this.paginator;

      if (data) {
        this.loading = false;
      }

      let neu = [];
      for (let el of data) {
        let ele = {domain: el.domain, 
          name: el.name, total: el.amount_total, relevant: el.amount_relevant, 
          importance: ['low', 'medium', 'high'].length <= el.importance ? el.importance : ['low', 'medium', 'high'][el.importance], 
          truthfulness: ['correct','lurid', 'dubious', 'fakenews/disinformation'].length <= el.truthfulness ? el.truthfulness : ['correct','lurid', 'dubious', 'fakenews/disinformation'][el.truthfulness],
          platforms: el.platforms.join(" "), language: el.language, description: el.description,
          note: el.note, disinformation: el.disinformation, active: el.active || true, active_span: (el.created_at || el.enabled) + " - " + (el.disabled || "ongoing")};
        if (el.entry_domains) {
          for (let [idx, dom] of el.entry_domains.entries()) {
            ele["entry_domain_" + idx] = dom;
          }
        }
        neu.push(ele);
      }
      this.exportData = neu;

      // Pure madness ahead....
      for (let i = 0; i < this.DATA.length; i++) {
        setTimeout(() => {
          this.hpsCheckDone(this.DATA[i]);
        }, 25 * i);
        if (i == this.DATA.length - 1) {
          setTimeout(() => {
            this.store.dispatch(genericNotification({info: "\"Done\" fields loaded."}));
          }, 25 * i + 100);
        }
      }
    });

    dataSource.toPromise().then((e) => {
      this.showInactive.setValue(false);
    });
    
  }
  public doFilter = (value: string) => {
    this.unknownDataSource.filter = value.trim().toLocaleLowerCase();
  };

  openDownload(){
    window.open(`https://news.cdfz.at/extension.zip`, "_blank");
        event.preventDefault();
        event.stopPropagation();
  }

  downloadExport(name) {
    CsvDataService.exportToCsv(name, this.exportData);
  }

  changePage(event) {
    console.log("event", event);
    this.show = event.pageSize;
    this.skip = event.pageSize * event.pageIndex;
    let elemets = [];
    this.unknownDataSource.data = elemets = this.DATA.filter((e, i) => !e.known);
  }

  navigateDetail(row) {
    const url = `sourceManagement/detail/${row.id}`;
    console.log(url);
    this.router.navigate([url]);
  }

  async hpsCheckDone(row) {
    let domains = [row.domain];
    let startDate = new Date().toISOString();
    let endDate = new Date().toISOString();

    let res = await this.mappingService.getHPSStats({domains: domains, startDate: startDate, endDate: endDate}).pipe(take(1)).toPromise();
    try {
      row.done = res[0].hits.hits.total.value;
    } catch (e) {
      row.done = false;
    }
  }
}

declare global{
  interface Navigator{
     msSaveBlob:(blob: Blob,fileName:string) => boolean
     }
  }

class CsvDataService {
  static exportToCsv(filename: string, rows: object[]) {
    if (!rows || !rows.length) {
      return;
    }

    let key_obj = Object.assign({}, ...rows);

    const separator = ',';
    const keys = Object.keys(key_obj);
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows.map(row => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');

    const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]),csvContent], {type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}