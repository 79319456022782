<input [disabled]="disabled" type="file" #file style="display: none" (change)="onFilesAdded()" multiple />
<p>Files <mat-icon *ngIf="!disabled" style="cursor:pointer;position:relative;top:5px;" (click)="addFiles()">add</mat-icon>
</p>
<div id="filesContainer">
    <div *ngFor="let file of getFilteredServer()" class="files"  fxLayout="row wrap">
        <a [href]="serverBaseUrl+'/files/'+id+'/'+file" target="_blank" ><span style="word-break: break-all;">{{fileName(file)}}</span>&nbsp;</a>
        <mat-icon style="cursor:pointer;position:relative;top:-2px;" (click)="deleteFiles(file)">delete</mat-icon>
    </div>
    <div *ngFor="let file of files" class="files"  fxLayout="row wrap">
        <span style="word-wrap: break-word;">{{file.name}}</span>
        <mat-progress-bar mode="determinate" style="flex:1 0 100px; position: relative;top:8px;" [value]="progress[file.name].progress | async"></mat-progress-bar>
        <mat-icon style="cursor:pointer;position:relative;top:-2px;" (click)="deleteFiles(file)">delete</mat-icon>
    </div>
</div>