import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { loadReports, createReport, setItem, resetReport } from 'src/app/actions/news.actions';
import { NewsService } from 'src/app/services/news.service';
import { ReportService } from 'src/app/services/report.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../editor/editor.component';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {

  constructor(private store: Store<State>, private newsService: NewsService, private reportService: ReportService, private spinnerService: NgxSpinnerService,private dialog: MatDialog) { }
  displayedColumns = ['title', 'date', 'action'];
  newsTable$: Observable<any[]> = this.store.select(state => state.reports.reports);
  gen$: Observable<boolean> = this.store.select(state => state.reports.generated);
  refresh$: Observable<any> = this.store.select(state => state.reports.refresh);
summary = "";
subs = [];
init = false;
  ngOnDestroy(): void {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }
ngOnInit() {
  this.store.dispatch(loadReports());

  
}

truncate(text) {
  if (text.length > 150) {
    return text.substring(0, 150) + '...';
  }
  return text;
}
generateReport(){
  this.store.dispatch(createReport({ summary: this.summary }));
}
deleteRow(id){
  
    const sub = this.refresh$.subscribe(() => {
      if(this.init){
        this.store.dispatch(loadReports());
        console.log("fresh");
        this.spinnerService.hide();
        this.init = false;
      }
    })
    this.subs.push(sub);
    
    

    this.store.dispatch(setItem({ id, operation: false }));
    this.spinnerService.show();
    this.init = true;
  
  
  
}
promptDelete() {
  const dialogRef = this.dialog.open(DeleteDialogComponent);

  
  dialogRef.afterClosed().subscribe((result) => {
    if(result){
      this.reset();
    }
    
  });
}
reset(){
  this.store.dispatch(resetReport()),
  this.summary = "";
}
download(){
  this.reportService.downloadReport(this.summary);
}

}
